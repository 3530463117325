import React from "react";
import {Input} from "antd";
import FilterItem from "../../FilterItem";


const { Search } = Input;

const SearchFilter = (props) => {

  return (
    <FilterItem
      field={props.field}
      type="search"
    >
      {({onChange, value}) =>
        <Search
          allowClear
          value={value}
          placeholder={props.placeholder}
          onChange={(e, v) => onChange(e.target.value)}
          onSearch={(v) => onChange(v)}
        />
      }
    </FilterItem>
  )
};

export default SearchFilter
