import {
  RANK_OPTIONS, RANK_TYPE_INTER,
  RANK_TYPE_LOCAL
} from "../../../admin/referee_certifications/utils";
import {formatDate} from "../../../utils";
const sortByAlphabet = (a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});

const prepareOption = (value, label, children) => {return {value, label, children}};

const prepareToMap = (acc, curr) => {
  const option = prepareOption(curr.id, curr.short_name);
  if (!acc[curr.region_id]) {
    acc[curr.region_id] = [].concat(option)
  } else {
    acc[curr.region_id] = acc[curr.region_id].concat(option);
  }
  return acc
};

export const prepareFilterOptions = (regions, clubs) => {
  const regionClubMap = clubs.reduce(prepareToMap, {});
  return regions
    .sort(sortByAlphabet)
    .map((r) => {return prepareOption(r.id, r.name, regionClubMap[r.id])})
};

export const sortRankByOrder = (a, b) => {
  const rankMap = getRankMap();
  return rankMap[a.rank].order - rankMap[b.rank].order
};

export const getSeniorInterCertification = (certifications) => {
  return certifications
    .filter((c) => getRankType(c.rank) === RANK_TYPE_INTER)
    .sort(sortRankByOrder)[0]
};


export const getSeniorLocalCertification = (certifications) => {
  return certifications
    .filter((c) => getRankType(c.rank) === RANK_TYPE_LOCAL)
    .sort(sortRankByOrder)[0]
};

export const getRankMap = () => {
   return RANK_OPTIONS.reduce((acc, curr) => {
    acc[curr.value] = curr;
    return acc
  }, {});
};

export const getRankType = (rank) => {
  const rankMap = getRankMap();
  return rankMap[rank] && rankMap[rank].type
};

export const getRankTitle = (rank) => {
  const rankMap = getRankMap();
  return rankMap[rank] && rankMap[rank].label
};

export const formatRank = (rank) => {
  const res = RANK_OPTIONS.filter(r => r.value === rank)[0];
  if (res.type === RANK_TYPE_INTER) {
    return 'Міжнародна'
  }
  return res.label
};


export const formatCertification = (certification) => {
  const cert = getSeniorInterCertification(certification) || getSeniorLocalCertification(certification);
  return {
    label: cert ? formatRank(cert.rank) : "Невказано",
    certificate_date: cert ? formatDate(cert.certificate_date) : "Невказано"
  }
};
