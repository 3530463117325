export const DEFAULT_OPTIONS = [
  {value: 'public', label: 'Опубліковано'},
  {value: 'draft', label: 'Черновик'},
  {value: 'deleted', label: 'Видалити'},
];

export const DEFAULT_USER_OPTIONS = [
  {value: 'public', label: 'Активний'},
  {value: 'draft', label: 'Архів'},
  {value: 'deleted', label: 'Заблокований'},
];