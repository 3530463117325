import actionTypes from './actionTypes';
import service from '../../services/admin/users';
import {getSubmitData} from './utils';
import {message} from 'antd';
import {getAdminSections} from "../../services/admin/utils";
import {SUCCESS_DELETED_MSG} from "../../utils";

const SERVER_BASE_ERROR_MSG = {level: 'error', text: "Error on server"};
const SUCCESS_UPDATE_MSG = {level: 'success', text: "Успішно збережено"};

function onInit() {
  return async (dispatch) => {
    onSetLoading(true)(dispatch);
    try {
      await fetchUsers()(dispatch);
      onSetLoading(false)(dispatch)
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}

function onAdd() {
  return (dispatch) => {
    setInitial()(dispatch);
    showModal(true)(dispatch)
  }
}

function setInitial() {
  return async (dispatch) => {
    try {
      const res_section = await getAdminSections();
      setSection(res_section.data.sections)(dispatch);
      dispatch({
        type: actionTypes.USERS_SET_INITIAL_USER
      })
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}

function onSetLoading(loading) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USERS_SET_LOADING,
      loading: loading,
    })
  }
}

export const showMessage = (msg) => {
  return async (dispatch) => {
    message[msg.level](msg.text)
  }
};

function fetchUsers() {
  return async (dispatch) => {
    try {
      const res = await service.getAll();
      dispatch({
        type: actionTypes.USERS_SET_USERS,
        users: res.data.users
      });
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  }
}



function onEdit(itemId) {
  return async (dispatch) => {
    try {
      const res = await service.getById(itemId);
      const res_section = await getAdminSections();
      if (res.data.status === 'ok') {
        setUser(res.data.user)(dispatch);
        setSection(res_section.data.sections)(dispatch);
        showModal(true)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}

export const setUser = (user) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.USERS_SET_USER,
      user: user,
    })
  }
};


export const setSection = (sections) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.USERS_SET_SECTIONS,
      sections: sections,
    })
  }
};


export const onChangeSection = (sections) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.USERS_SET_SELECTED_SECTION,
      sections: sections,
    })
  }
};

function showModal(visible) {
  return (dispatch) => {
    if (visible) {
      dispatch({
        type: actionTypes.USERS_SHOW_FORM
      })
    } else {
      dispatch({
        type: actionTypes.USERS_HIDE_FORM
      })
    }

  }
}

function onCancel() {
  return (dispatch) => {
    showModal(false)(dispatch)
  }
}

function onChangeInput(name, value) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USERS_CHANGE_INPUT,
      name: name,
      value: value,
    })
  }
}

function onSubmit() {
  return async (dispatch, getState) => {
    onSetLoading(true)(dispatch);
    try {
      const state = getState().users;
      const data = getSubmitData(state);
      let res = null;
      if (state.editMode && state.user) {
        res = await service.save(state.user.id, data);
      } else {
        res = await service.add(data);
      }
      onSetLoading(false)(dispatch);
      if (res.data.status === 'ok') {
        fetchUsers()(dispatch);
        showModal(false)(dispatch);
        showMessage(SUCCESS_UPDATE_MSG)(dispatch);
      } else {
        setErrors(res.data.errors)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}

export const setErrors = (errors) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.USERS_SET_ERRORS,
      errors: errors,
    })
  }
};

function onDelete(itemId) {
  return async (dispatch) => {
    try {
      onSetLoading(true)(dispatch);
      const res = await service.remove(itemId);
      if (res.data.status === 'ok') {
        await fetchUsers()(dispatch);
        onSetLoading(false)(dispatch);
        showMessage(SUCCESS_DELETED_MSG)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}

export default {
  onInit,
  onEdit,
  onCancel,
  onAdd,
  onChangeInput,
  onSubmit,
  onChangeSection,
  onDelete,
}