import React from "react";
import moment from "moment";
import {Avatar, Button, Col, Divider, Pagination, Row, Table} from "antd";
import AdminActionDropdown from "../../../components/AdminActionDropdown";
import TrainerForm from "../components/TrainerForm";
import trainersService from "../../../services/admin/trainers";
import clubService from "../../../services/admin/clubs";
import regionService from "../../../services/admin/agencies";
import DownloadListFilterWraper from "../../components/DownloadLils/index";
import {getNoun} from "../../../utils";
import FilterProvider from "../../components/Filters/FilterProvider";
import SearchFilter from "../../components/Filters/components/SearchFilter/index";
import FilterResults from "../../components/Filters/FilterResults";
import RemoteSelectFilter from "../../components/Filters/components/RemoteSelectFilter/index";
import {handleOnSort} from "../../libs/helpers";
import {getTrainerCertifications} from "../../../services/admin/trainer_certifications";


const fieldOptions = [
  { label: 'Прізвище', value: 'last_name' },
  { label: 'Імя', value: 'first_name' },
  { label: 'По батькові', value: 'middle_name' },
  { label: 'Клуб', value: 'club_name' },
  { label: 'Регіон', value: 'region_name' },
  { label: 'Категорія', value: 'sport_title' },
  { label: 'Телефон', value: 'phone_number' },
  { label: 'Email', value: 'email' },
  { label: 'Адреса', value: 'address' },
  { label: 'Статус', value: 'status' },
];
const defaultFieldOptions = [
  'first_name',
  'last_name',
  'club_name',
  'region_name',
  'middle_name',
  'sport_title',
  'phone_number',
  'email',
];


export default class TrainersListView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentId: null,
      status: "public",
      showModal: false,
      editMode: false,
      showSubModal: false,
      loading: false,
      errors: {}
    }
  }

  onAdd = () => {
    this.setState({
      currentId: null,
      editMode: false,
      showModal: true,
      status: 'public',
    })
  };

  onEdit = (rowId) => {
    trainersService.getById(rowId).then((res) => {
      const {data} = res.data;
      this.setState({
        currentId: rowId,
        editMode: true,
        showModal: true,
        status: data.status,
        first_name: data.first_name,
        last_name: data.last_name,
        middle_name: data.middle_name,
        eng_name: data.eng_name,
        gender: data.gender,
        club_id: data.club_id,
        sport_title: data.sport_title,
        league_id: data.league_id,
        kyu: data.kyu,
        dan: data.dan,
        email: data.email,
        phone_number: data.phone_number,
        owner_name: data.owner_name,
        address: data.address,
        website: data.website,
        birth_date: moment(data.birth_date),
        file: data.logo,
        rank: data.rank,
        reason: data.reason,
      })
    });
    getTrainerCertifications(rowId).then((res) => {
      this.setState({certifications: res.data.data})
    })
  };

  onChangeInput = (name, value) => {
    this.setState({[name]: value})
  };

  preparePostData = (data) => {
    return {
      status: data.status,
      reason: data.reason,
    }
  };

  onCancel = () => {
    this.setState({showModal: false})
  };


  onSubmit = async (postData) => {
    this.setState({loading: true});
    const data = this.preparePostData(postData);
    let res;
    if (this.state.editMode) {
      res = await trainersService.save(this.state.currentId, data);
    } else {
      // res = await trainersService.add(data);
    }

    if (res.data.status === 'ok') {
      this.setState({
        loading: false,
        showModal: false,
        editMode: false,
        currentId: null,
      })
    } else {
      this.setState({
        loading: false,
        errors: res.data.errors,
      })
    }
  };

  render() {
    const FilterRows = 3;
    return (
      <div>
        <h2>Список тренерів</h2>
        <Divider/>
        <FilterProvider
          onFetchUrl={(queryString) => {return trainersService.getAllUrl(queryString)}}
          onRemoveUrl={trainersService.getRemoveUrl}
        >
          {({loading, onChange, fields, onClearAll, onRemove}) => {
            const columns = [
              {
                title: 'Фото',
                dataIndex: 'logo',
                key: 'logo',
                render: (t, r) => <Avatar
                  src={r.logo && r.logo.url}
                  size={60}
                  shape="square"
                >
                  Avatar
                </Avatar>
              },
              {
                title: 'Прізвище',
                dataIndex: 'last_name',
                key: 'last_name',
                sorter: true,
              },
              {
                title: 'Ім’я',
                dataIndex: 'first_name',
                key: 'first_name',
                sorter: true,
              },
              {
                title: 'Клуб',
                dataIndex: 'club_name',
                key: 'club_name',
                sorter: true,
              },
              {
                title: 'Регіон',
                dataIndex: 'region_name',
                key: 'region_name',
                sorter: true,
              },
              {
                title: 'Категория',
                dataIndex: 'category',
                key: 'category',
                sorter: true,
              },
              {
                title: 'Статус',
                dataIndex: 'status',
                key: 'status',
                sorter: true,
              },
              {
                title: 'Дії',
                dataIndex: 'action',
                key: 'action',
                render: (text, item) => (
                  <AdminActionDropdown
                    {...this.props}
                    labelKey="last_name"
                    onDelete={onRemove}
                    onEdit={this.onEdit}
                    item={item}
                  />
                ),
              }
            ];
            return (
              <React.Fragment>
                <Row gutter={24}>
                  <Col lg={FilterRows + 1}>
                    <SearchFilter
                      field="query"
                      placeholder="Введіть імя або прізвище"
                    />
                  </Col>
                  <Col lg={FilterRows}>
                    <RemoteSelectFilter
                      field="club_id"
                      fetchUrl={clubService.getAllClubsUrl()}
                      dataKey="clubs"
                      valueKey="id"
                      titleKey="name"
                      placeholder="Виберіть клуб"
                      allowClear
                    />
                  </Col>
                  <Col lg={FilterRows}>
                    <RemoteSelectFilter
                      field="region_id"
                      fetchUrl={regionService.getAllUrl()}
                      dataKey="agencies"
                      valueKey="id"
                      titleKey="short_name"
                      placeholder="Виберіть регіон"
                      allowClear
                    />
                  </Col>
                  <Col lg={FilterRows}>
                    <RemoteSelectFilter
                      field="category"
                      fetchUrl={trainersService.getCategoryUrl()}
                      dataKey="data"
                      valueKey="id"
                      titleKey="title"
                      placeholder="Виберіть категорію"
                      allowClear
                    />
                  </Col>
                  <Col lg={FilterRows}>
                    <Button
                      icon="close"
                      onClick={onClearAll}
                    >
                      Скинути
                    </Button>
                  </Col>
                  <Col lg={FilterRows}>
                    <DownloadListFilterWraper
                      filters={fields}
                      fieldOptions={fieldOptions}
                      defaultFieldOptions={defaultFieldOptions}
                      downloadAction={trainersService.download}
                    />
                  </Col>
                  <Col lg={FilterRows}>
                    <Button type="primary" icon="plus" onClick={this.onAdd}>
                      Додати
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <FilterResults
                    dataKey="trainers"
                  >
                    {({data, paginator}) => {
                      return (
                        <React.Fragment>
                          <Row>
                            <br/>
                            <div>
                              <b>{paginator.total}</b>
                              {` ${getNoun(paginator.total, 'тренер', 'тренера', 'тренерів')}`}
                            </div>
                            <br/>
                          </Row>
                          <Row>
                            <Table
                              columns={columns}
                              dataSource={data}
                              rowKey="id"
                              loading={loading}
                              locale={{emptyText: "Немає данних"}}
                              pagination={false}
                              onChange={handleOnSort(onChange)}
                            />
                          </Row>
                          <br/>
                          <Row type="flex" justify="end">
                            <Pagination
                              current={paginator.page}
                              total={paginator.total}
                              pageSize={paginator.page_size}
                              onChange={(page) => {
                                window.scrollTo(0, 0);
                                onChange('page', 'text', page)
                              }}
                            />
                          </Row>
                        </React.Fragment>
                      )
                    }}
                  </FilterResults>
                </Row>
              </React.Fragment>
            )
          }}
        </FilterProvider>
        {this.state.showModal &&
        <TrainerForm
          {...this.state}
          title={this.state.editMode ? "Редагувати тренера" : "Додати тренера"}
          onChangeInput={this.onChangeInput}
          errors={this.state.errors}
          showModal={this.state.showModal}
          onCancel={this.onCancel}
          onSubmit={this.onSubmit}
        />
        }
      </div>
    );
  }
}
