import {connect} from "react-redux";
import moment from "moment";
import CompetitionsCalendar from "./CompetitionsCalendar";
import {changePanel, fetchEvent, saveCalendar, fetchDateChoices} from "./CompetitionsCalendarActions";


const mapStateToProps = state => {
  return {
    ...state.competitionsCalendar,
  };
};

const mapDispatchToProps = dispatch => {
  return {

    onPanelChange: (value, mode) => {
      return dispatch(changePanel(value, mode))
    },
    onInit: () => {
      return dispatch(fetchEvent(moment()))
    },
    onMonthChange: (date) => {
      return dispatch(fetchEvent(moment(date)))
    },
    onSaveCalendar: (year) => {
      return dispatch(saveCalendar(year))
    },
    onOpenModal: () => {
      return dispatch(fetchDateChoices())
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompetitionsCalendar);
