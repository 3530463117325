import React from "react";
import AdminBaseLayoutContainer from "../../components/BaseLayout/AdminBaseLayoutContainer";
import SyncsView from "./views/SyncsView";


export default class Syncs extends React.Component {

  render() {
    return (
      <AdminBaseLayoutContainer>
        <SyncsView />
      </AdminBaseLayoutContainer>
    )
  }
}
