import {getBaseUrl} from "../../utils";
import io from "../../lib/io";

export async function add(data) {
  return await io.post(`${getBaseUrl()}/admin/referee-certifications`, data);
}

export function getAllUrl(queryString) {
  const query = queryString ? `?${queryString}` : '';
  return `${getBaseUrl()}/admin/referee-certifications${query}`;
}


export function getRemoveUrl(id) {
  return `${getBaseUrl()}/admin/referee-certifications/${id}/delete`;
}


export async function getAll() {
  return await io.get(getAllUrl());
}


export async function save(id, data) {
  return await io.post(`${getBaseUrl()}/admin/referee-certifications/${id}/edit`, data);
}


export async function getById(id) {
  return await io.get(`${getBaseUrl()}/admin/referee-certifications/${id}`);
}


export async function remove(id) {
  return await io.post(getRemoveUrl(id));
}


export async function getCertificatedMembers(certID) {
  return await io.get(`${getBaseUrl()}/admin/certified-referees?cert_id=${certID}`);
}

export function getDownloadUrl(id) {
  return `${getBaseUrl()}/admin/referee-certifications/${id}/download`;
}

export async function getRefereeCertifications(refereeId) {
  return await io.get(`${getBaseUrl()}/admin/referee-certifications/get-referee-certifications/${refereeId}`);
}

export default {
  getAllUrl,
  add,
  getAll,
  save,
  getById,
  remove,
  getRemoveUrl,
  getCertificatedMembers,
  getDownloadUrl,
  getRefereeCertifications,
}
