import React from "react";
import {Button, Icon, Input, Upload, Card} from "antd";
import IFormItem from "../../../../components/IFormItem";

class Uploader extends React.Component {

  onUpload = (e) => {
    this.props.onUploadFile(e.file)
  };

  render() {
    const filesList = [];
    if (this.props.file) {
      filesList.push({
        uid: '1',
        name: this.props.file.original_name,
        status: 'done',
        url: this.props.file.url,
      })
    }
    return (
      <Upload
        onRemove
        fileList={filesList}
        customRequest={this.onUpload}
      >
        <Button>
          <Icon type="upload" /> Завантажити
        </Button>
      </Upload>
    );
  }
}

const RefereeDocumentForm = ({loading, title, file, errors, onChange, onUploadFile, onSubmit}) => {

  return (
    <Card loadin={loading}>
      <IFormItem
        label="Назва документу"
        errorKey="title"
        errors={errors}
      >
        <Input
          name="title"
          value={title}
          onChange={onChange}
        />
      </IFormItem>
      <IFormItem
        label="Документ"
        errorKey="file"
        errors={errors}
      >
        <Uploader
          file={file}
          onUploadFile={onUploadFile}
        />
      </IFormItem>
      <Button
        icon="plus"
        htmlType="button"
        type="primary"
        onClick={onSubmit}
      >
        Додати документ
      </Button>
    </Card>
  );
};


export default RefereeDocumentForm;
