import React from "react";
import {Avatar, Col, Descriptions, Row, Divider, Table} from "antd";
import {calculateAge, formatDate} from "../../../../../utils";
import {getRankTitle} from "../../../../../admin/referee_certifications/utils";


const columns = [
  {
    title: 'Категорія',
    dataIndex: 'rank',
    render: (t, r) => getRankTitle(r.rank),
  },
  {
    title: 'Дата атестації',
    dataIndex: 'date_exam',
    render: (t, r) => formatDate(r.date_exam)
  },
  {
    title: 'Строк ліцензії',
    dataIndex: 'certificate_date',
    render: (t, r) => formatDate(r.certificate_date)
  },
];

const RefereeDescription = ({logo, firstName, lastName, middleName, regionName, clubName, birthDate, certifications}) => {
  return (
    <React.Fragment>
      <Row gutter={24}>
        <Col span={8}>
          <Avatar
            src={logo && logo.url}
            style={{margin: "0 auto", display: "block"}}
            shape="square" size={100}
          >
            Avatar
          </Avatar>
        </Col>
        <Col span={16}>
          <Descriptions
            size="small"
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          >
            <Descriptions.Item label="Регіон">{regionName}</Descriptions.Item>
            <Descriptions.Item label="Дата народження">{birthDate ? `${formatDate(birthDate)} (${calculateAge(new Date(birthDate))})` : null}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Divider><b>Ліцензії</b></Divider>
      <Row type="flex" justify="space-around" align="middle">
        <Col span={24}>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={certifications}
            size="middle"
            locale={{emptyText: "Немає данних"}}
            pagination={false}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};


export default RefereeDescription;
