import React from "react";
import {Avatar, Button, Divider, Form, Icon, Input, Modal, Select, Table, Upload, Switch} from "antd";
import AdminActionDropdown from "../AdminActionDropdown";
import AdminBaseLayoutContainer from "../BaseLayout/AdminBaseLayoutContainer";
import {formatDate} from "../../utils";

const FormItem = Form.Item;
const Option = Select.Option;

class AvatarUploader extends React.Component {


  onUpload = (e) => {
    this.props.onUploadFile(e.file)
  };

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.props.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Завантажити</div>
      </div>
    );
    return (
      <Upload
        name="picture"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={this.onUpload}
      >
        {this.props.file ? <img width={200} src={this.props.file.url} alt="avatar" /> : uploadButton}
      </Upload>
    );
  }
}

export default class AdminFederationStructureForm extends React.Component {

  componentDidMount() {
    if (this.props.match.params.id && Number.isInteger(Number(this.props.match.params.id))) {
      this.props.onInit(Number(this.props.match.params.id))
    } else {
      this.props.onInit()
    }
  }


  onChangeInput = (e) => {
    this.props.changeInput(e.target.name, e.target.value)
  };

  onSubmit = (e) => {
    this.props.onSubmit(this.props.history)
  };

  getErrorMessageFields = (name) => {
    return {
      validateStatus: this.props.errors[name] ? "error": null,
      help: this.props.errors[name],
    }
  };

  onAddMember = () => {
    this.props.onAddMember()
  };

  render() {
    const columns = [
      {
        title: 'Фото',
        dataIndex: 'picture_url',
        key: 'picture_url',
        render: (t, r) => <Avatar src={r.file.url}/>
      },
      {
        title: 'ПІБ',
        dataIndex: 'full_name',
        key: 'full_name',
      },
      {
        title: 'Телефон',
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Дата створення',
        dataIndex: 'date_created',
        key: 'date_created',
        render: (t, r) => formatDate(t),
      },
      {
        title: 'Дата оновлення',
        dataIndex: 'date_updated',
        key: 'date_updated',
        render: (t, r) => formatDate(t),
      },
      {
        title: 'Дії',
        dataIndex: 'action',
        key: 'action',
        render: (text, item) => (
          <AdminActionDropdown
            {...this.props}
            onEdit={() => this.props.onEditMember(item.id)}
            onDelete={() => this.props.onDeleteMember(item.id)}
            item={item}
          />
        ),
      }
    ];
    return (
      <AdminBaseLayoutContainer>
      <Form>
        <FormItem
          label={'Статус'}
        >
          <Select
            style={{ width: 200 }}
            onChange={(s) => this.props.changeInput("status", s)}
            value={this.props.status}
          >
            <Option value="public">Опубліковано</Option>
            <Option value="draft">Черновик</Option>
          </Select>
        </FormItem>
        <FormItem
          label={'Назва розділу'}
          {...this.getErrorMessageFields('title')}
        >
          <Input
            name="title"
            value={this.props.title}
            onChange={this.onChangeInput}
          />
        </FormItem>
        <FormItem
          label={'Головний розділ'}
        >
          <Select
            allowClear
            showSearch
            style={{ width: 200 }}
            placeholder="Розділ"
            optionFilterProp="children"
            onChange={(s) => this.props.changeInput("parent_id", s)}
            value={this.props.parent_id}
            filterOption={this.props.parent_id}
          >
            {this.props.structureChoices.map((o) => {
                if (this.props.structure) {
                  if (this.props.structure.id === o.id) {
                    return null
                  } else {
                    return <Option key={o.id} value={o.id}>{o.title}</Option>
                  }
                } else {
                  return <Option key={o.id} value={o.id}>{o.title}</Option>
                }
              }

            )}
          </Select>
        </FormItem>
        <FormItem
          label="Вказати я псевдо родитель"
        >
            <Switch
              checked={this.props.is_pseudo_parent}
              onChange={(v) => this.props.changeInput("is_pseudo_parent", v)}
            />
        </FormItem>

        {this.props.structure &&
        <div>
          <Divider/>
          <Button
            icon="plus"
            htmlType="button"
            onClick={this.onAddMember}
          >
            Додати учасника
          </Button>
          <Divider/>
        </div>
        }
        <Table
          columns={columns}
          dataSource={this.props.members}
          rowKey="id"
          loading={this.props.loading}
          locale={{emptyText: "Немає учасників"}}
        />
        <Modal
          title="Учасник"
          visible={this.props.showModal}
          onCancel={this.props.onCancel}
          footer={[
            <Button key="back" onClick={this.props.onCancel}>Відмінити</Button>,
            <Button key="submit" type="primary" onClick={this.props.onSubmitMember}>
              Зберегти
            </Button>,
          ]}
        >
          <FormItem
            label={'Фото'}
          >
            <AvatarUploader {...this.props}/>
          </FormItem>
          <FormItem
            label={'ПІБ'}
            {...this.getErrorMessageFields('full_name')}
          >
            <Input
              name="member_full_name"
              value={this.props.member_full_name}
              onChange={this.onChangeInput}
            />
          </FormItem>
          <FormItem
            label={'Посада'}
            {...this.getErrorMessageFields('post_description')}
          >
            <Input
              name="member_post_description"
              value={this.props.member_post_description}
              onChange={this.onChangeInput}
            />
          </FormItem>
          <FormItem
            label={'Телефон'}
            {...this.getErrorMessageFields('phone')}
          >
            <Input
              name="member_phone"
              value={this.props.member_phone}
              onChange={this.onChangeInput}
            />
          </FormItem>
          <FormItem
            label={'Email'}
            {...this.getErrorMessageFields('email')}
          >
            <Input
              name="member_email"
              value={this.props.member_email}
              onChange={this.onChangeInput}
            />
          </FormItem>
        </Modal>
        <Divider/>
        <FormItem>
          <Button
            type="primary"
            htmlType="button"
            onClick={this.onSubmit}
          >
            Зберегти
          </Button>
        </FormItem>
      </Form>
      </AdminBaseLayoutContainer>
    );
  }
}
