import {connect} from "react-redux";

import AdminBaseLayout from "./AdminBaseLayout";
import {onToggleMenu, selectMenu, selectSubMenu} from "./AdminBaseLayoutActions";

const mapStateToProps = state => {
  return {
    ...state.adminApp,
    user: state.auth.user
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onSelectMenu: (selectedMenu) => {
      return dispatch(selectMenu(selectedMenu));
    },
    onOpenChange: (selectedSubMenu) => {
      return dispatch(selectSubMenu(selectedSubMenu));
    },
    onToggleMenu: () => {
      return dispatch(onToggleMenu());
    }

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminBaseLayout);
