import Cookies from 'js-cookie'


const initialState = {
  showModal: false,

  structureChoices: [],
  structuresTree: [],
  structure: null,
  editMode: false,
  member: null,
  memberEditMode: false,
  members: [],
  token: Cookies.get("token"),
  title: '',
  parent_id: null,
  is_pseudo_parent: false,
  loading: false,
  status: 'public',
  member_full_name: '',
  member_post_description: '',
  member_status: 'public',
  member_phone: '',
  member_email: '',
  member_picture_id: null,
  errors: {},
  file: null,
};


export default function structure(state=initialState, action) {
  switch (action.type) {

    case 'ADD_STRUCTURE':
      return {...initialState};

    case 'SET_STRUCTURE_CHOICES':
      return {...state, structureChoices: action.structureChoices};

    case 'SET_STRUCTURE_TREE':
      return {...state, structuresTree: action.structuresTree};

    case 'SET_STRUCTURE':
      return {...initialState, ...action.structure, structure: action.structure, editMode: true};

    case 'SET_MEMBERS':
      return {...state, members: action.members};

    case 'CHANGE_VALUE':
      return {...state, [action.name]: action.value};

    case 'LOADING':
      return {...state, loading: action.loading};

    case 'CLEAR_FORM':
      return {...initialState};

    case 'SET_ERRORS':
      return {...state, errors: action.errors || {}};

    case 'SHOW_MEMBER_FORM':
      const m = {
        member_full_name: '',
        member_post_description: '',
        member_status: 'public',
        member_phone: '',
        member_email: '',
        member_picture_id: null,
        file: null,
      };
      return {...state, showModal: true, ...m};

    case 'SET_MEMBER':
      const member_data = {
        member_full_name: action.member.full_name,
        member_post_description: action.member.post_description,
        member_status: action.member.status,
        member_phone: action.member.phone,
        member_email: action.member.email,
        member_picture_id: action.member.file.id,
        file: action.member.file,
      };
      return {
        ...state,
        ...member_data,
        showModal: true,
        member: action.member,
        memberEditMode: true
      };

    case 'HIDE_MEMBER_FORM':
      return {...state, showModal: false};

    case 'SET_FILE':
      return {...state, loading: false, file: action.file};

    default:
      return state;
  }
}