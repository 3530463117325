import React from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  Row,
  Select,
  Upload
} from "antd";
import locale from "antd/lib/date-picker/locale/uk_UA";
import moment from "moment";
import IFormItem from "../../../../components/IFormItem";
import MemberForm from "../MemberForm";
import MemberInfo from "../MemberInfo/index";
import { ListManager } from "react-beautiful-dnd-grid";
import "./TeamForm.css";
import {VIEW_DATE_FORMAT} from "../../../../utils";



class MemberCards extends React.Component {
  render = () => (
    <ListManager
      items={this.props.members}
      direction="horizontal"
      maxItems={4}
      render={item => <MemberCard {...this.props} item={item} />}
      onDragEnd={this.props.reorderList}
    />
  );
}


function MemberCard(props) {
  return <div className="item">
    {props.editMember && props.editMember.id === props.item.id
      ? <MemberForm
        id={props.editMember.id}
        category={props.category}
        challenges={props.challenges}
        order={props.editMember.order}
        type={props.type}
        loading={props.loading}
        errors={props.errors}
        onAdd={props.onAddMember}
        onChangeInput={props.onChangeInput}
        onCancel={props.onCancelMember}
      />
      : <MemberInfo
        id={props.item.id}
        fullName={props.item.full_name}
        category={props.item.category}
        challenges={props.item.challenges}
        logoUrl={props.item.logo && props.item.logo.url}
        onEdit={props.onEditMember}
        onDelete={props.onDeleteMember}
      />
    }
  </div>
}

class Uploader extends React.Component {


  onUpload = (e) => {
    this.props.onUploadFile(e.file)
  };

  onRemoveFile = (file) => {
    this.props.onRemoveFile(file)
  };

  render() {
    const filesList = this.props.files.map((f) => {
      return {
        uid: f.id,
        name: f.original_name,
        status: 'done',
        url: f.url,
      }
    });

    return (
      <Upload
        onRemove={this.onRemoveFile}
        fileList={filesList}
        customRequest={this.onUpload}
      >
        <Button>
          <Icon type="upload" /> Завантажити
        </Button>
      </Upload>
    );
  }
}

class Members extends React.Component {

  render() {
    return (
      <Row gutter={24}>
        <Col key="cards" style={{marginTop: "10px"}}>
          <MemberCards {...this.props}/>
        </Col>
        <Col key="empty" span={8} style={{marginTop: "10px"}}>
          <MemberForm
            id={this.props.id}
            category={this.props.category}
            type={this.props.type}
            challenges={this.props.challenges}
            order={this.props.order}
            loading={this.props.loading}
            errors={this.props.errors}
            onChangeInput={this.props.onChangeInput}
            onAdd={this.props.onAddMember}
          />
        </Col>
      </Row>
    );
  }

};

export default class TeamForm extends React.Component {

  render() {
    return (
      <Form>
        <Row gutter={24}>
          <Col span={12}>
            <IFormItem
              label="Статус"
              errorKey="status"
              errors={this.props.errors}
            >
              <Select
                onChange={(s) => this.props.onChangeInput("status", s)}
                value={this.props.status}
              >
                <Select.Option value="public">Опубліковано</Select.Option>
                <Select.Option value="draft">Черновик</Select.Option>
                <Select.Option value="archive">Архів</Select.Option>
              </Select>
            </IFormItem>
            <IFormItem
              label="Назва турніру"
              errorKey="title"
              errors={this.props.errors}
            >
              <Input
                name="title"
                placeholder="Введіть назву турніру"
                value={this.props.title}
                onChange={(e) => this.props.onChangeInput(
                  e.target.name,
                  e.target.value
                )}
              />
            </IFormItem>
            <IFormItem
              label={'Дата'}
              errorKey="date_started"
              errors={this.props.errors}
            >
              <DatePicker.RangePicker
                format={VIEW_DATE_FORMAT}
                locale={locale}
                value={[moment(this.props.date_started), moment(this.props.date_expired)]}
                onChange={(e) => {
                  this.props.onChangeInput("date_started", e[0]);
                  this.props.onChangeInput("date_expired", e[1]);
                }}
              />
            </IFormItem>
          </Col>
          <Col span={12}>
            <IFormItem
              label="Місце проведення"
              errorKey="location"
              errors={this.props.errors}
            >
              <Input
                name="location"
                placeholder="Введіть місце проведення"
                value={this.props.location}
                onChange={(e) => this.props.onChangeInput(e.target.name, e.target.value)}
              />
            </IFormItem>
            <IFormItem
              label="Посилання"
              errorKey="link"
              errors={this.props.errors}
            >
              <Input
                name="link"
                placeholder="Введіть посилання"
                value={this.props.link}
                onChange={(e) => this.props.onChangeInput(e.target.name, e.target.value)}
              />
            </IFormItem>
            <IFormItem
              label="Документи"
              errorKey="files"
              errors={this.props.errors}
            >
              <div style={{maxWidth: "30%"}}>
                <Uploader
                  {...this.props}
                  files={this.props.files}
                  onRemoveFile={this.props.removeFile}
                  onUploadFile={this.props.uploadFile}
                />
              </div>
            </IFormItem>
          </Col>
        </Row>
        <Divider>Спортсмени</Divider>
        <Members
          {...this.props}
          members={this.props.members.filter(m => m.type === this.props.type)}
          type={this.props.type}
        />
      </Form>
    );
  }
};
