import {getBaseUrl} from '../../utils';
import io from '../../lib/io'

export async function getAll() {
  return await io.get(`${getBaseUrl()}/admin/news`)
}

export async function remove(id) {
  return await io.post(`${getBaseUrl()}/admin/news/${id}/delete`);
}

export default {
  remove,
  getAll
}
