import React from "react";
import {Divider, Spin, Table, Row, Col, Button, Pagination} from "antd";
import IModalForm from "../../../components/IModalForm";
import AdminActionDropdown from "../../../components/AdminActionDropdown";
import AgencyForm from "../components/AgencyForm";
import service, {getAllUrl} from "../../../services/admin/agencies";
import DownloadListFilterWraper from "../../components/DownloadLils/index";
import {getNoun} from "../../../utils";
import FilterProvider from "../../components/Filters/FilterProvider";
import SearchFilter from "../../components/Filters/components/SearchFilter/index";
import FilterResults from "../../components/Filters/FilterResults";
import {handleOnSort} from "../../libs/helpers";

const fieldOptions = [
  { label: 'Регіон', value: 'name' },
  { label: 'Керівник', value: 'head' },
  { label: 'Телефон', value: 'phone_number' },
  { label: 'Email', value: 'email' },
  { label: 'Адреса', value: 'address' },
  { label: 'Сайт', value: 'website' },
  { label: 'Статус', value: 'status' },
];
const defaultFieldOptions = ['name', 'head', 'phone_number', 'email'];



export default class BeltListView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentId: null,
      status: "public",
      agencies: [],
      showModal: false,
      editMode: false,
      loading: false,
      errors: {}
    }
  }

  componentDidMount() {
    service.getAll().then((res) => {
      this.setState({agencies: res.data.agencies})
    });
  }

  onAdd = () => {
    this.setState({
      currentId: null,
      editMode: false,
      showModal: true,
      status: 'public',
      name: '',
      short_name: '',
      email: '',
      phone_number: '',
      head: '',
      address: '',
      website: '',
    })
  };

  onEdit = (row) => {
    service.getById(row.id).then((res) => {
      this.setState({
        currentId: row.id,
        editMode: true,
        showModal: true,
        status: res.data.agency.status,
        name: res.data.agency.name,
        short_name: res.data.agency.short_name,
        email: res.data.agency.email,
        phone_number: res.data.agency.phone_number,
        head: res.data.agency.head,
        address: res.data.agency.address,
        website: res.data.agency.website,
      })
    });
  };


  onChangeInput = (name, value) => {
    this.setState({[name]: value})
  };

  preparePostData = () => {
    return {
      status: this.state.status,
      name: this.state.name,
      short_name: this.state.short_name,
      email: this.state.email,
      phone_number: this.state.phone_number,
      head: this.state.head,
      address: this.state.address,
      website: this.state.website,
    }
  };

  onCancel = () => {
    this.setState({showModal: false})
  };


  async onSubmit() {
    return; // TODO need implement
    // const callBack = () => {
    //   service.getAll().then((res) => {
    //     this.setState({belts: res.data.belts})
    //   });
    // };
    // this.setState({loading: true});
    // const data = this.preparePostData();
    // let res;
    // if (this.state.editMode) {
    //   res = await service.save(this.state.currentId, data);
    // } else {
    //   res = await service.add(data);
    // }
    //
    // if (res.data.status === 'ok') {
    //   this.setState({
    //     loading: false,
    //     showModal: false,
    //     editMode: false,
    //     currentId: null,
    //   }, callBack)
    // } else {
    //   this.setState({
    //     loading: false,
    //     errors: res.data.errors,
    //   })
    // }
  };

  render() {
    return (
      <div>
        <h2>Список прдеставництв</h2>
        <Divider/>
        <FilterProvider
          onFetchUrl={(queryString) => {return getAllUrl(queryString)}}
          onRemoveUrl={service.getRemoveUrl}
        >
          {({loading, onChange, fields, onClearAll, onRemove}) => {
            const columns = [
              {
                title: 'Регіон',
                dataIndex: 'short_name',
                key: 'short_name',
                sorter: true,
              },
              {
                title: 'Керівник',
                dataIndex: 'head',
                key: 'head',
                sorter: true,
              },
              {
                title: 'Телефон',
                dataIndex: 'phone_number',
                key: 'phone_number',
              },
              {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
              },
              {
                title: 'Статус',
                dataIndex: 'status',
                key: 'status',
                sorter: true,
              },
              {
                title: 'Дії',
                dataIndex: 'action',
                key: 'action',
                align: 'right',
                render: (text, item) => (
                  <AdminActionDropdown
                    {...this.props}
                    labelKey="name"
                    fetchRow={true}
                    onDelete={onRemove}
                    onEdit={this.onEdit}
                    item={item}
                  />
                ),
              }
            ];
            return (
              <React.Fragment>
                <Row gutter={24}>
                  <Col lg={4}>
                    <SearchFilter
                      field="query"
                      placeholder="Введіть назву"
                    />
                  </Col>
                  <Col lg={4}>
                    <Button
                      icon="close"
                      onClick={onClearAll}
                    >
                      Скинути
                    </Button>
                  </Col>
                  <Col lg={4}>
                    <DownloadListFilterWraper
                      filters={fields}
                      fieldOptions={fieldOptions}
                      defaultFieldOptions={defaultFieldOptions}
                      downloadAction={service.download}
                    />
                  </Col>
                  <Col lg={4}>
                    <Button type="primary" icon="plus" onClick={this.onAdd}>
                      Додати
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <FilterResults
                    dataKey="agencies"
                  >
                    {({data, paginator}) => {
                      return (
                        <React.Fragment>
                          <Row>
                            <br/>
                            <b>{paginator.total}</b> {getNoun(paginator.total, 'регіон', 'регіона', 'регіонів')}
                            <br/>
                          </Row>
                          <Row>
                            <Table
                              columns={columns}
                              dataSource={data}
                              rowKey="id"
                              loading={loading}
                              locale={{emptyText: "Немає данних"}}
                              pagination={false}
                              onChange={handleOnSort(onChange)}
                            />
                          </Row>
                          <br/>
                          <Row type="flex" justify="end">
                            <Pagination
                              current={paginator.page}
                              total={paginator.total}
                              pageSize={paginator.page_size}
                              onChange={(page) => {
                                window.scrollTo(0, 0);
                                onChange('page', 'text', page)
                              }}
                            />
                          </Row>
                        </React.Fragment>
                      )
                    }}
                  </FilterResults>
                </Row>
              </React.Fragment>
            )
          }}
        </FilterProvider>
        <IModalForm
          title={this.state.editMode ? "Редагувати представництво" : "Додати представництво"}
          showModal={this.state.showModal}
          onCancel={this.onCancel}
          onSubmit={this.onSubmit}
        >
          <Spin spinning={this.state.loading} size="large">
            <AgencyForm
              {...this.state}
              onChangeInput={this.onChangeInput}
              errors={this.state.errors}
            />
          </Spin>
        </IModalForm>
      </div>
    );
  }
}
