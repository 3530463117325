import React from "react";
import {Form, Input, Select} from "antd";
import IFormItem from "../../components/IFormItem";
import IPhotoUploader from "../../components/IPhotoUploader";
import moment from "moment";
import MyDatePicker from "../components/MyDatePicker/MyDatePicker";

const Option = Select.Option;

export default function PartnerForm(props) {
  return (
    <Form>
      <IFormItem
        label="Статус"
        errorKey="status"
        errors={props.errors}
      >
        <Select
          showSearch
          onChange={(s) => props.onChangeInput("status", s)}
          value={props.status}
        >
          <Option value="public">Опубліковано</Option>
          <Option value="draft">Черновик</Option>
          <Option value="deleted">Видалено</Option>
        </Select>
      </IFormItem>
      <IFormItem
        label="Логотип"
        errorKey="file"
        errors={props.errors}
      >
        <IPhotoUploader
          name="file"
          loading={props.loading}
          file={props.file}
          onUpload={props.onUpload}
        />
      </IFormItem>
      <IFormItem
        label={'Дата початку'}
        errorKey="date_started"
        errors={props.errors}
      >
        <MyDatePicker
          value={moment(props.date_started)}
          onChange={(dt) => props.onChangeInput("date_started", dt)}
        />
      </IFormItem>
      <IFormItem
        label="Назва турніру"
        errorKey="title"
        errors={props.errors}
      >
        <Input
          name="title"
          value={props.title}
          onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
        />
      </IFormItem>
      <IFormItem
        label="Посилання"
        errorKey="link"
        errors={props.errors}
      >
        <Input
          name="link"
          value={props.link}
          onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
        />
      </IFormItem>
    </Form>
  );
}

