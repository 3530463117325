import {getBaseUrl} from '../../utils';
import io from '../../lib/io'

export async function postFile(file) {
  return await io.postFile(`${getBaseUrl()}/uploads/upload_banner_logo`, file);
}


export async function getAll() {
  return await io.get(`${getBaseUrl()}/admin/bnrs`);
}


export async function add(data) {
  return await io.post(`${getBaseUrl()}/admin/bnrs`, data);
}


export async function save(id, data) {
  return await io.post(`${getBaseUrl()}/admin/bnrs/${id}/edit`, data);
}


export async function getById(id) {
  return await io.get(`${getBaseUrl()}/admin/bnrs/${id}`);
}

export async function remove(id) {
  return await io.post(`${getBaseUrl()}/admin/bnrs/${id}/delete`);
}


export default {
  postFile,
  getAll,
  add,
  save,
  getById,
  remove,
}
