import React, {PureComponent} from "react";
import {
  Breadcrumb,
  Card,
  Col,
  Divider,
  Icon,
  Row,
  Select,
  Timeline,
  Typography
} from "antd";
import {Link} from "react-router-dom";
import axios from "axios";
import {
  getBaseUrl,
  getServiceDomain,
  sortByAlphabet,
  toQueryString
} from "../../../utils";
import PublicBaseLayout from "../../../components/BaseLayout/PublicBaseLayout";
import {Helmet} from "react-helmet";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import IFormItem from "../../../components/IFormItem";
import regionService from "../../../services/public/regions";

import "./Statistics.css";


const rangeYears = (startYear) => {
  const currentYear = new Date().getFullYear(), years = [];
  startYear = startYear || 2016;
  while ( startYear <= currentYear ) {
    years.push(startYear++);
  }
  return years;
}

const yearsOptions = rangeYears(new Date().getFullYear() - 2).map((y) => {
  return {id: y, name: y}
})


const SelectData = ({errors, value, onChange, options, placeholder}) => {
  return (
    <IFormItem
      errorKey="region"
      errors={errors}
    >
      <Select
        allowClear
        placeholder={placeholder}
        style={{width: "100%"}}
        onChange={onChange}
        value={value}
      >
        {options.map(o => (
          <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
        ))}
      </Select>
    </IFormItem>
  );
};

const { Title, Text } = Typography;


const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        {payload && <p style={{color: "#1890FF"}} className="label">{`${payload[0].value}`}</p>}
      </div>
    );
  }

  return null;
};

class Example extends PureComponent {

  render() {
    return (
      <div style={{ width: '100%', height: 300 }}>
        <ResponsiveContainer>
          <LineChart
            data={this.props.data}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Line type="monotone" dataKey="count" stroke="#1890ff" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}


const RowStatistics = ({title, data, iconType}) => {
  return (
    <Row gutter={[16, 16]}>
      <Col xs={2}>
        <Icon
          style={{fontSize: "25px", margin: "50px 0 10px 10px", color: "#1890FF"}}
          type={iconType}
        />
        <div className="pin_block">
          <div className="pin">
            <span><b>{title}</b></span>
          </div>
        </div>
      </Col>
      <Col xs={22}>
        <Example data={data}/>
      </Col>
    </Row>
  )
}

const RowBaseStat = ({title, data}) => {
  return (
    <div style={{paddingLeft: "10px"}}>
      <Title level={4}>
        <span style={{color: "blue"}}>{data.prev}</span>
        {data.added
          ? <span style={{color: "green"}}> + {data.added}</span>
          : null
        }
      </Title>
      <Text>{title}</Text>
    </div>
  )
}

class BaseStat extends PureComponent {
  render() {
    return (
      <Timeline>
        <Timeline.Item
          dot={<Icon style={{fontSize: "25px"}} type="apartment" />}
        >
          <RowBaseStat
            title="Спортивних клубів"
            data={this.props.clubs}
          />
        </Timeline.Item>
        <Timeline.Item
          dot={<Icon style={{fontSize: "25px"}} type="user" />}
        >
          <RowBaseStat
            title="Спортсменів"
            data={this.props.athletes}
          />
        </Timeline.Item>
        <Timeline.Item
          dot={<Icon style={{fontSize: "25px"}} type="team" />}
        >
          <RowBaseStat
            title="Тренери викладачі"
            data={this.props.trainers}
          />
        </Timeline.Item>
        <Timeline.Item
          dot={<Icon style={{fontSize: "25px"}} type="solution" />}
        >
          <RowBaseStat
            title="Суддів міжнародного та національного рангів"
            data={this.props.referees}
          />
        </Timeline.Item>
      </Timeline>
    )
  }
}


export default class Statistics extends React.Component {

  state = {
    loading: true,
    athletes: [],
    clubs: [],
    trainers: [],
    referees: [],
    regions: [],
    clubs_base: {},
    athletes_base: {},
    trainers_base: {},
    referees_base: {},
    region: null,
    year: new Date().getFullYear(),
  };

  componentDidMount() {
    this.setData()
    this.getRegion((res) => {
      this.setState({
        regions: res.data.agencies.sort(sortByAlphabet)
      })
    })
  }

  setData = () => {
    this.getData((res) => {
      this.setState({
        loading: false,
        athletes: res.data.data.athletes,
        clubs: res.data.data.clubs,
        trainers: res.data.data.trainers,
        referees: res.data.data.referees,
        clubs_base: res.data.data.clubs_base,
        athletes_base: res.data.data.athletes_base,
        trainers_base: res.data.data.trainers_base,
        referees_base: res.data.data.referees_base,
      });
    });
  }

  getData = (callback) => {
    const queryString = toQueryString({
      region: this.state.region,
      year: this.state.year,
    })
    const query = queryString ? `?${queryString}` : '';
    const url = `${getBaseUrl()}/public/statistics/athletes${query}`;
    axios.get(url)
      .then((res) => {
        callback(res);
      });
  };

  getRegion = (callback) => {
    regionService.getRegions().then(res => callback(res))
  }

  onChangeRegion = (o) => {
    this.setState({region: o}, this.setData)
  }

  onChangeYear = (o) => {
    this.setState({year: o}, this.setData)
  }

  findBaseStat = (data) => {
    let needYear = this.state.year
    const lenDate = Object.keys(data).length
    if (lenDate === 0) {
      return {prev: 0, added: 0}
    }
    let iter = 0
    while (iter <= lenDate) {
      iter++
      if (data[needYear]) {
        if (iter === 1) {
          return data[needYear]
        }
        return {prev: data[needYear].current, added: 0}
      }
      needYear--
    }
    return {prev: 0, added: 0}
  }

  render() {
    const titleCol = {xl: 6, md: 6, sm: 6}
    return (
      <PublicBaseLayout>
        <Helmet>
          <title>Статистика - Українська Федерація карате</title>
        </Helmet>
        <Breadcrumb style={{ marginTop: '20px', marginBottom: "5px" }}>
          <Breadcrumb.Item>
            <Link to="/">
              {getServiceDomain()}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Статистика
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col span={24}>
            <Card>
              <Row>
                <Col {...titleCol}>
                  <Title
                    level={4}
                    style={{marginTop: "8px", textAlign: "center"}}
                  >
                    Динаміка розвитку
                  </Title>
                </Col>
                <Col {...titleCol}>
                  <SelectData
                    placeholder="Україна"
                    options={this.state.regions}
                    value={this.state.region}
                    onChange={this.onChangeRegion}
                  />
                </Col>
                <Col {...titleCol}>
                  <Title
                    style={{marginTop: "8px", textAlign: "center"}}
                    level={4}
                  >
                    Рік
                  </Title>
                </Col>
                <Col {...titleCol}>
                  <SelectData
                    placeholder={new Date().getFullYear()}
                    options={yearsOptions}
                    value={this.state.year}
                    onChange={this.onChangeYear}
                  />
                </Col>
              </Row>
              <Divider/>
              <Row gutter={[12, 24]}>
                <Col xs={24} sm={0} md={0} xl={0} xxl={0}>
                  <Row type="flex" justify="center">
                    <BaseStat
                      clubs={this.findBaseStat(this.state.clubs_base)}
                      athletes={this.findBaseStat(this.state.athletes_base, )}
                      trainers={this.findBaseStat(this.state.trainers_base, )}
                      referees={this.findBaseStat(this.state.referees_base, )}
                    />
                  </Row>
                </Col>
                <Col xs={24} sm={12} md={12} xl={12} xxl={12} >
                  <RowStatistics
                    title={'клубів Кількість'}
                    data={this.state.clubs}
                    iconType="apartment"
                  />
                  <RowStatistics
                    title={'спортсменів Кількість'}
                    data={this.state.athletes}
                    iconType="user"
                  />
                  <RowStatistics
                    title={'тренерів Кількість'}
                    data={this.state.trainers}
                    iconType="team"
                  />
                  <RowStatistics
                    title={'суддів Кількість'}
                    data={this.state.referees}
                    iconType="solution"
                  />
                </Col>
                <Col xs={0} sm={12} md={12} xl={12} xxl={12}>
                  <Row type="flex" justify="center">
                    <BaseStat
                      clubs={this.findBaseStat(this.state.clubs_base)}
                      athletes={this.findBaseStat(this.state.athletes_base, )}
                      trainers={this.findBaseStat(this.state.trainers_base, )}
                      referees={this.findBaseStat(this.state.referees_base, )}
                    />
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </PublicBaseLayout>
    );
  }
}
