import actionTypes from './actionTypes';
import partnerService from '../../services/admin/partners';
import {getSubmitData} from './utils';
import {message} from 'antd';
import {SUCCESS_DELETED_MSG} from '../../utils';

const SERVER_BASE_ERROR_MSG = {level: 'error', text: "Error on server"};
const SUCCESS_UPDATE_MSG = {level: 'success', text: "Успішно збережено"};

function onInit() {
  return async (dispatch) => {
    onSetLoading(true)(dispatch);
    try {
      await fetchPartners()(dispatch);
      onSetLoading(false)(dispatch)
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}

function fetchPartners() {
  return async (dispatch) => {
    try {
      const res = await partnerService.getPartners();
      dispatch({
        type: actionTypes.SET_PARTNERS,
        partners: res.data.partners
      });
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  }
}


function setInitial() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PARTNERS_SET_INITIAL_PARTNER
    })
  }
}


function onAdd() {
  return (dispatch) => {
    setInitial()(dispatch);
    showModal(true)(dispatch)
  }
}

function showModal(visible) {
  return (dispatch) => {
    if (visible) {
      dispatch({
        type: actionTypes.PARTNER_SHOW_FORM
      })
    } else {
      dispatch({
        type: actionTypes.PARTNERS_HIDE_FORM
      })
    }

  }
}


function onCancel() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PARTNERS_HIDE_FORM
    })
  }
}


function onChangeInput(name, value) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PARTNERS_CHANGE_INPUT,
      name: name,
      value: value,
    })
  }
}

function onSetLoading(loading) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOADING,
      loading: loading,
    })
  }
}


export const onUpload = (e) => {
  return async (dispatch) => {
    onSetLoading(true)(dispatch);
    try {
      const res = await partnerService.postPartnerFile(e.file);
      dispatch({
        type: actionTypes.PARTNERS_SET_FILE,
        file: res.data.file,
      })
    } catch (err) {
      showMessage({level: 'error', text: "Error on upload file"})(dispatch);
    }
  }
};


function onSubmit() {
  return async (dispatch, getState) => {
    onSetLoading(true)(dispatch);
    try {
      const state = getState().partners;
      const data = getSubmitData(state);
      let res = null;
      if (state.editMode && state.partner) {
        res = await partnerService.updatePartner(state.partner.id, data);
      } else {
        res = await partnerService.postPartner(data);
      }
      onSetLoading(false)(dispatch);
      if (res.data.status === 'ok') {
        fetchPartners()(dispatch);
        showModal(false)(dispatch);
        showMessage(SUCCESS_UPDATE_MSG)(dispatch);
      } else {
        setErrors(res.data.errors)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}


export const setErrors = (errors) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.PARTNERS_SET_ERRORS,
      errors: errors,
    })
  }
};


export const showMessage = (msg) => {
  return async (dispatch) => {
    message[msg.level](msg.text)
  }
};


function onEdit(itemId) {
  return async (dispatch) => {
    try {
      const res = await partnerService.getPartner(itemId);
      if (res.data.status === 'ok') {
        setPartner(res.data.partner)(dispatch);
        showModal(true)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}


export const setPartner = (partner) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.PARTNERS_SET_PARTNER,
      partner: partner,
    })
  }
};


function onDelete(itemId) {
  return async (dispatch) => {
    try {
      onSetLoading(true)(dispatch);
      const res = await partnerService.remove(itemId);
      if (res.data.status === 'ok') {
        await fetchPartners()(dispatch);
        onSetLoading(false)(dispatch);
        showMessage(SUCCESS_DELETED_MSG)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}


export default {
  onInit,
  onAdd,
  onCancel,
  onUpload,
  onChangeInput,
  onSubmit,
  onEdit,
  onDelete,
}
