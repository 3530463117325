import React from "react";
import {Route, Switch} from "react-router-dom";
import PublicBaseLayout from "../../../components/BaseLayout/PublicBaseLayout";
import RefereesListView from "./views/RefereesListView";

export default class Athletes extends React.Component {

  render() {
    return (
      <PublicBaseLayout>
        <Switch>
          <Route
            exact
            path={"/members/referees"}
            component={RefereesListView}
          />
        </Switch>
      </PublicBaseLayout>
    );
  }
}
