import React from "react";
import {
  Badge, Breadcrumb, Card, Col, Icon,
  Popover, Row, Table, Timeline, Button,
  Modal, Select, Spin
} from "antd";
import {Link} from "react-router-dom";
import moment from "moment";
import "moment/locale/uk";
import DayPicker from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import "./CompetitionsCalendar.css";
import IFormItem from '../../components/IFormItem';
import PublicBaseLayout from "../BaseLayout/PublicBaseLayout";
import {Helmet} from "react-helmet";
import {formatDate, getServiceDomain} from "../../utils";

export default class CompetitionsCalendar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedYear: '',
      errors: {},
    }
  }

  componentDidMount() {
    this.props.onInit()
  }

  onPanelChange = (value, mode) => {
    this.props.onPanelChange(value, mode)
  };

  onOpenModal = () => {
    this.props.onOpenModal();
    this.setState({showModal: true})
  };

  onCloseModal = () => {
    this.setState({showModal: false})
  };

  onSaveCalendar = () => {
    this.props.onSaveCalendar(this.state.selectedYear)
  };

  onChangeYear = (value) => {
    this.setState({selectedYear: value})
  };


  renderDay = (day) => {
    const current = moment(day);
    const eventsPerDay = this.props.dayEventsMap[current.date()] || [];
    const style = {};
    let hasEvent = false;
    if (eventsPerDay.length > 0) {
      style.border = '1px solid #1890ff';
      style.borderRadius = '50%';
      hasEvent = true;
    }
    return (
      <div>
        {hasEvent
          ? <Popover
            zIndex={2000}
            placement="top"
            trigger="hover"
            content={
              <div>
                <Timeline>
                  {eventsPerDay.map((e) =>
                    <Timeline.Item key={e.id}>
                      <p key={e.id}>
                        <Badge
                          count={`${formatDate(e.date_started)} - ${formatDate(e.date_expired)}`}
                          style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }}
                        />
                        <br/>

                        <a href={e.link}>{e.title}</a>
                      </p>
                    </Timeline.Item>
                  )}
                </Timeline>
              </div>
            }
          >
            <div className="ant-calendar-date" style={style}>{current.date()}</div>
          </Popover>
          : <div>
            <div className="ant-calendar-date">{current.date()}</div>
          </div>
        }
      </div>
    );
  };

  render() {
    const columns = [
      {
        title: 'Назва заходу',
        dataIndex: 'title',
        key: 'title',
        responsive: ['lg'],
        // width: '35%',
        render: (text, record) => <a href={record.link}>{text}</a>,
      },
      {
        title: 'Дата',
        dataIndex: 'date_started',
        key: 'date_started',
        responsive: ['md'],
        // width: '15%',
        render: (text, record) => `${moment(record.date_started).format("DD.MM.YYYY")}-${moment(record.date_expired).format("DD.MM.YYYY")}`,
      },
      {
        title: 'Місце проведення',
        dataIndex: 'place',
        key: 'place',
        responsive: ['md'],
        // width: '25%',
      },
      {
        title: 'Учасники',
        dataIndex: 'members',
        key: 'members',
        responsive: ['md'],
        // width: '20%',
      },
      {
        title: () => <Popover
          trigger="hover"
          content="Додатки"
        >
          <div style={{
            align: "center",
            marginTop: "10px",
            textAlign: "center",
          }}>
            <Icon
              style={{fontSize: "25px",}}
              type="file-text"
            />
          </div>
        </Popover>,
        dataIndex: 'files',
        key: 'files',
        // width: '10%',
        responsive: ['md'],
        render: (text, item) =>
          <div>
            {item.files.map(f =>
              <Popover
                key={f.id}
                trigger="hover"
                content={<div><b>{f.original_name}</b></div>}
              >
                <div style={{
                  align: "center",
                  marginTop: "10px",
                  textAlign: "center",
                }}>
                  <a
                    key={f.id}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={f.url}
                  >
                    <Icon
                      style={{fontSize: "25px",}}
                      type="file-text"
                    />
                  </a>
                </div>
              </Popover>
            )}
          </div>
      },
    ];

    return (
      <PublicBaseLayout>
        <Helmet>
          <title>Реєстр подій - Українська Федерація карате</title>
        </Helmet>
        <Breadcrumb style={{ marginTop: '20px', marginBottom: "5px" }}>
          <Breadcrumb.Item>
            <Link to="/">
              {getServiceDomain()}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Події
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Row justify="center">
            <Col style={{marginTop: "7px"}} xs={24} sm={24} md={24} lg={8} xl={8}>
              <DayPicker
                locale="uk"
                localeUtils={MomentLocaleUtils}
                onMonthChange={this.props.onMonthChange}
                renderDay={this.renderDay}
              />
              <Button
                type="primary"
                icon="calendar"
                size="large"
                onClick={this.onOpenModal}
              >
                Завантажити календар
              </Button>
              <Modal
                title="Календар подій"
                visible={this.state.showModal}
                onCancel={this.onCloseModal}
                footer={[
                  <Button
                    key="submit"
                    icon="file-pdf"
                    size="large"
                    type="primary"
                    disabled={this.props.loading || this.props.pdfUrl}
                    onClick={this.onSaveCalendar}
                  >
                    Згенерувати
                  </Button>
                ]}
              >
                <Spin spinning={this.props.loading} size="large">
                  {this.props.pdfUrl && !this.props.loading
                    ? <div>
                      <p> Календар подій згенеровано</p>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={this.props.pdfUrl}
                        >
                          <Icon type="file-pdf"/> {this.props.pdfUrl}
                        </a>
                      </p>
                    </div>
                    : <IFormItem
                      label="Оберіть рік"
                      errorKey="year"
                      errors={this.props.errors || this.state.errors}
                    >
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Оберіть рік"
                        onChange={this.onChangeYear}
                        value={this.state.selectedYear}
                      >
                        {this.props.dateChoices.map(d =>
                          <Select.Option key={d} value={d}>{d}</Select.Option>
                        )}
                      </Select>
                    </IFormItem>
                  }
                </Spin>
              </Modal>
            </Col>
            <Col xs={0} sm={0} md={24} lg={16} xl={16}>
              <Table
                size="small"
                bordered
                pagination={false}
                columns={columns}
                dataSource={this.props.events}
                rowKey="id"
                loading={this.props.loading}
                locale={{emptyText: "Немає подій"}}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
              <Table
                pagination={false}
                columns={columns}
                dataSource={this.props.events}
                rowKey="id"
                loading={this.props.loading}
                locale={{emptyText: "Немає подій"}}
                scroll={{ x: 2500, y: 300 }}
              />
            </Col>
          </Row>
        </Card>
      </PublicBaseLayout>
    );
  }
}
