import React from "react";
import {Button, Card, Input} from "antd";
import IFormItem from "../../../../components/IFormItem";
import RemoteSelect from "../../../components/RemoteSelect/index";
import athleteService from "../../../../services/admin/athletes";


class MemberForm extends React.Component {

  render() {
    return (
      <Card
        loading={this.props.loading}
        actions={[
          this.props.onCancel
            ? <Button
                icon="close"
                onClick={this.props.onCancel}
              >
                Відмінити
              </Button>
            : null,
          <Button
            type="primary"
            icon="plus"
            onClick={this.props.onAdd}
          >
            Додати
          </Button>
        ]}
      >
        <Card.Meta title="Додати спортсмена"/>
        <div>
          <IFormItem
            label="Спортсмен"
            errorKey="id"
            errors={this.props.errors}
          >
            <RemoteSelect
              showSearch
              fetchUrl={this.props.fetchUrl}
              dataKey="athletes"
              valueKey="id"
              titleKeys={["first_name", "last_name"]}
              placeholder="Введіть спортсмена"
              value={this.props.id}
              onChange={(value) => this.props.onChange("id", value)}
            />
          </IFormItem>
          <IFormItem
            label="Категорія"
            errorKey="category"
            errors={this.props.errors}
          >
            <Input
              name="category"
              placeholder="Введіть категорію"
              value={this.props.category}
              onChange={(e) => this.props.onChange(
                e.target.name,
                e.target.value
              )}
            />
          </IFormItem>
          <IFormItem
            label="Викликів в збірну"
            errorKey="challenges"
            errors={this.props.errors}
          >
            <Input
              name="challenges"
              type="number"
              placeholder="Введіть виклики в збірну"
              value={this.props.challenges}
              onChange={(e) => this.props.onChange(
                e.target.name,
                e.target.value
              )}
            />
          </IFormItem>
        </div>
      </Card>
    );
  }
}


export default class AddMemberForm extends React.Component {

  onChange = (name, value) => {
    this.props.onChangeInput(name, value);
  };

  handleOnAdd = () => {
    this.props.onAdd({
      id: this.props.id,
      category: this.props.category,
      type: this.props.type,
      challenges: this.props.challenges,
      order: this.props.order,
    })
  };

  render() {
    return (
      <MemberForm
        fetchUrl={athleteService.getAthleteUrl()}
        loading={this.props.loading}
        id={this.props.id}
        category={this.props.category}
        challenges={this.props.challenges}
        errors={this.props.errors}
        onChange={this.onChange}
        onAdd={this.handleOnAdd}
        onCancel={this.props.onCancel}
      />
    )
  }
}