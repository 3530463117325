import React from "react";
import {DatePicker} from "antd";
import FilterItem from "../../FilterItem";
import locale from 'antd/lib/date-picker/locale/uk_UA';
import {formatSubmitDate} from "../../../../../utils";

const { RangePicker } = DatePicker;

const RangeFilter = (props) => {

  return (
    <FilterItem
      field={props.field}
      type="search"
    >
      {({onChange, value}) =>
        <RangePicker
          style={{ width: '100%' }}
          locale={locale}
          panelRender={()=> <div>ok</div>}
          onChange={(v) => onChange([formatSubmitDate(v[0]), formatSubmitDate(v[1])])}
        />
      }
    </FilterItem>
  )
};

export default RangeFilter
