import React from "react";
import {Form, Input, Select, Typography} from "antd";
import IFormItem from "../../../../components/IFormItem";
import RemoteSelect from "../../../components/RemoteSelect/index";
import * as athleteService from "../../../../services/admin/athletes";
import {
  getRankFromKyuDan,
  getRankOption,
  getRankTitle,
  RANK_OPTIONS
} from "./utils";
import {formatGrade, toQueryString} from "../../../../utils";

const {Text} = Typography

const MemberForm = ({athlete_id, region_id, club_id, member, certificate_num, rank, errors, onChange, queryData}) => {
  const [rankChanged, changeRank] = React.useState(false)
  const [selectedMember, setMember] = React.useState(member)

  const renderAthleteTitle = (r) => {
    const fullName = ["middle_name", "first_name", "last_name",].map(k => r[k]).reverse().join(" ")
    const rank = r.rank ? getRankTitle(r.rank) : formatGrade(r.kyu, r.dan)
    return `${fullName} (${rank}) - ${r.club_name}`
  }

  const getMemberRank = () => {
    if (!selectedMember) {
      return null
    } else if (selectedMember.rank) {
      return selectedMember.rank
    } else {
      return getRankFromKyuDan(selectedMember.kyu, selectedMember.dan)
    }
  }

  const prepareRankOptions = () => {
    const memberRank = getMemberRank()
    if (memberRank) {
      const rankOption = getRankOption(memberRank)
      return RANK_OPTIONS.filter(o => o.num > rankOption.num)
    } else {
      return RANK_OPTIONS
    }
  }

  const handleChangeRank = (v) => {
    changeRank(true)
    onChange("rank", v)
  }

  const prepareNextRank = () => {
    if (rankChanged) {
      return rank
    } else {
      return undefined
    }
  }

  const handleOnSelect = (v) => {
    setMember(v)
    onChange("athlete_id", v && v.id)
  }

  return (
    <Form>
      <IFormItem
        label="Спортсмен"
        errorKey="athlete_id"
        errors={errors}
      >
        <RemoteSelect
          showSearch
          prefetch
          allowClear
          passItem
          fetchUrlFunc={(value) => athleteService.getAthleteUrl(toQueryString({...queryData, query: value, athlete_id: athlete_id, club_id: club_id, region_id: region_id}))}
          dataKey="athletes"
          valueKey="id"
          titleKeys={["middle_name", "first_name", "last_name", "club_name"]}
          renderTitle={renderAthleteTitle}
          placeholder="Виберіть спортсмена"
          value={athlete_id}
          onChange={handleOnSelect}
        />
        {athlete_id &&
        <Text type="secondary">Щоб вибрати іншого спортсмена видаліть попередньо выбранного</Text>
        }
      </IFormItem>
      <IFormItem
        label="Номер сертіфікату"
        errorKey="certificate_num"
        errors={errors}
      >
        <Input
          name="certificate_num"
          placeholder="Введіть номер сертіфікату"
          value={certificate_num}
          onChange={(e) => onChange(
            e.target.name,
            e.target.value
          )}
        />
      </IFormItem>
      <IFormItem
        label="Кю/Дан"
        errorKey="rank"
        errors={errors}
      >
        <Select
          onChange={handleChangeRank}
          value={prepareNextRank()}
        >
          {prepareRankOptions().map(o =>
            <Select.Option key={o.value} value={o.value}>{o.label}</Select.Option>
          )}
        </Select>
      </IFormItem>
    </Form>
  );
};

export default MemberForm
