import {getBaseUrl} from '../../utils';
import io from '../../lib/io'


export async function add(data) {
  return await io.post(`${getBaseUrl()}/admin/trainers`, data);
}


export function getAllUrl(queryString) {
  const query = queryString ? `?${queryString}` : '';
  return `${getBaseUrl()}/admin/trainers${query}`;
}

export function getAllTrainersUrl() {
  return `${getBaseUrl()}/admin/trainers/get-all`;
}

export async function getAll(queryString) {
  return await io.get(getAllUrl(queryString));
}

export function getSportTitlesUrl() {
  return `${getBaseUrl()}/admin/trainers/get-sport-titles`;
}

export function getCategoryUrl() {
  return `${getBaseUrl()}/admin/trainers/get-categories`;
}

export async function getSportTitles() {
  return await io.get(getSportTitlesUrl());
}

export async function save(id, data) {
  return await io.post(`${getBaseUrl()}/admin/trainers/${id}/edit`, data);
}


export async function getById(id) {
  return await io.get(`${getBaseUrl()}/admin/trainers/${id}`);
}

export function getRemoveUrl(id) {
  return `${getBaseUrl()}/admin/trainers/${id}/delete`;
}

export async function remove(id) {
  return await io.post(getRemoveUrl(id));
}


export async function download(data) {
  return await io.post(`${getBaseUrl()}/admin/trainers/download`, data);
}

export default {
  getAllUrl,
  add,
  getAll,
  save,
  getById,
  remove,
  getRemoveUrl,
  download,
  getSportTitles,
  getSportTitlesUrl,
  getCategoryUrl,
  getAllTrainersUrl,
}
