import React from "react";
import {Button, DatePicker, Form, Icon, Input, Select, Upload} from "antd";
import moment from "moment";


import locale from "antd/lib/date-picker/locale/uk_UA";
import AdminBaseLayoutContainer from "../BaseLayout/AdminBaseLayoutContainer";
import {VIEW_DATE_FORMAT} from "../../utils";

const FormItem = Form.Item;
const Option = Select.Option;
moment.locale('uk');

class Uploader extends React.Component {


  onUpload = (e) => {
    this.props.onUploadFile(e.file)
  };

  onRemoveFile = (file) => {
    this.props.onRemoveFile(file)
  };

  render() {
    const filesList = this.props.files.map((f) => {
      return {
        uid: f.id,
        name: f.original_name,
        status: 'done',
        url: f.url,
      }
    });

    return (
      <Upload
        onRemove={this.onRemoveFile}
        fileList={filesList}
        customRequest={this.onUpload}
      >
        <Button>
          <Icon type="upload" /> Завантажити
        </Button>
      </Upload>
    );
  }
}

export default class AdminEventForm extends React.Component {

  componentDidMount() {
    if (this.props.match.params.id && Number.isInteger(Number(this.props.match.params.id))) {
      this.props.onInit(Number(this.props.match.params.id))
    } else {
      this.props.onInit()
    }
  }

  onChangeInput = (e) => {
    this.props.changeInput(e.target.name, e.target.value)
  };

  onSubmit = (e) => {
    this.props.onSubmit(this.props.history)
  };

  getErrorMessageFields = (name) => {
    return {
      validateStatus: this.props.errors[name] ? "error": null,
      help: this.props.errors[name],
    }
  };

  render() {
    return (
      <AdminBaseLayoutContainer>
        <Form>
          <FormItem
            label={'Статус'}
          >
            <Select
              style={{ width: 200 }}
              onChange={(s) => this.props.changeInput("status", s)}
              value={this.props.status}
            >
              <Option value="public">Опубліковано</Option>
              <Option value="draft">Черновик</Option>
            </Select>
          </FormItem>
          <FormItem
            label={'Дата'}
            {...this.getErrorMessageFields('date_started')}
          >
            <DatePicker.RangePicker
              format={VIEW_DATE_FORMAT}
              locale={locale}
              value={[moment(this.props.date_started), moment(this.props.date_expired)]}
              onChange={(e) => {
                this.props.changeInput("date_started", e[0]);
                this.props.changeInput("date_expired", e[1]);
              }}
            />
          </FormItem>
          <FormItem
            label={'Назва заходу'}
            {...this.getErrorMessageFields('title')}
          >
            <Input
              name="title"
              value={this.props.title}
              onChange={this.onChangeInput}
            />
          </FormItem>
          <FormItem
            label={'Посилання'}
            {...this.getErrorMessageFields('link')}
          >
            <Input
              name="link"
              value={this.props.link}
              onChange={this.onChangeInput}
            />
          </FormItem>
          <FormItem
            label={'Місце проведення'}
            {...this.getErrorMessageFields('place')}
          >
            <Input
              name="place"
              value={this.props.place}
              onChange={this.onChangeInput}
            />
          </FormItem>
          <FormItem
            label={'Учасники'}
            {...this.getErrorMessageFields('members')}
          >
            <Input.TextArea
              name="members"
              value={this.props.members}
              onChange={this.onChangeInput}
            />
          </FormItem>
          <FormItem
            label={'Документи'}
          >
            <div style={{maxWidth: "30%"}}>
              <Uploader {...this.props}/>
            </div>
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              htmlType="button"
              onClick={this.onSubmit}
            >
              Зберегти
            </Button>
          </FormItem>
        </Form>
      </AdminBaseLayoutContainer>
    );
  }
}
