import {getBaseUrl} from "../../utils";
import io from "../../lib/io";

// export async function add(data) {
//   return await io.post(`${getBaseUrl()}/admin/clubs`, data);
// }

export function getAllUrl(queryString) {
  const query = queryString ? `?${queryString}` : '';
  return `${getBaseUrl()}/admin/clubs${query}`;
}

export async function getAll() {
  //with paginator
  return await io.get(getAllUrl());
}


export function getAllClubsUrl() {
  return`${getBaseUrl()}/admin/clubs/get-all`
}


export async function getAllClubs() {
  return await io.get(getAllClubsUrl());
}

export async function save(id, data) {
  return await io.post(`${getBaseUrl()}/admin/clubs/${id}/edit`, data);
}

export async function getById(id) {
  return await io.get(`${getBaseUrl()}/admin/clubs/${id}`);
}

function getRemoveUrl(id) {
  return`${getBaseUrl()}/admin/clubs/${id}/delete`
}

export async function remove(id) {
  return await io.post(getRemoveUrl(id));
}

export async function saveContribution(clubId, data) {
  return await io.post(`${getBaseUrl()}/admin/clubs/${clubId}/contributions`, data);
}

export async function getContributions(clubId) {
  return await io.get(`${getBaseUrl()}/admin/clubs/${clubId}/get-contributions`);
}

export async function download(data) {
  return await io.post(`${getBaseUrl()}/admin/clubs/download`, data);
}

export async function removeContribution(id) {
  return await io.post(`${getBaseUrl()}/admin/contributions/${id}/delete`);
}

export async function getContribution(id) {
  return await io.get(`${getBaseUrl()}/admin/contributions/${id}`);
}

export async function updateContribution(id, data) {
  return await io.post(`${getBaseUrl()}/admin/contributions/${id}/edit`, data);
}

export default {
  getAllUrl,
  // add,
  getAll,
  save,
  getById,
  remove,
  getRemoveUrl,
  saveContribution,
  getContributions,
  download,
  removeContribution,
  getContribution,
  updateContribution,
  getAllClubs,
  getAllClubsUrl,
}
