import React from "react";
import AdminBaseLayoutContainer from "../../components/BaseLayout/AdminBaseLayoutContainer";
import TrainersListView from "./views/TrainersListView";


export default class TrainersList extends React.Component {

  render() {
    return (
      <AdminBaseLayoutContainer>
        <TrainersListView />
      </AdminBaseLayoutContainer>
    )
  }

}


