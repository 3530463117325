import React from "react";
import {Divider, Table} from "antd";
import AdminFilters from "../AdminFilters";
import AdminActionDropdown from "../AdminActionDropdown";
import AdminBaseLayoutContainer from "../BaseLayout/AdminBaseLayoutContainer";
import {formatDate} from "../../utils";


function getEventStatusTitle(status) {
  const statuses =  {
    public: 'Опубліковано',
    draft: 'Черновик'
  };
  return statuses[status] || 'Не вказано'
}

export default class AdminEventsList extends React.Component {

  componentDidMount() {
    this.props.onInit()
  }

  onEdit = (itemId) => {
    this.props.onEdit(itemId);
  };

  onCreate = () => {
    this.props.onCreate();
  };

  render() {
    const columns = [

      {
        title: 'Назва розділу',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Дата створення',
        dataIndex: 'date_created',
        key: 'date_created',
        render: (t, r) => formatDate(t),
      },
      {
        title: 'Дата оновлення',
        dataIndex: 'date_updated',
        key: 'date_updated',
        render: (t, r) => formatDate(t),
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (t, r) => getEventStatusTitle(r.status)
      },
      {
        title: 'Дії',
        dataIndex: 'action',
        key: 'action',
        render: (text, item) => (
          <AdminActionDropdown
            {...this.props}
            item={item}
          />
        ),
      }
    ];
    return (
      <AdminBaseLayoutContainer>
        <h2>Список документів</h2>
        <Divider/>
        <AdminFilters {...this.props} />
        <Table
          columns={columns}
          dataSource={this.props.docs_sections}
          rowKey="id"
          loading={this.props.loading}
          locale={{emptyText: "Немає документів"}}
        />
      </AdminBaseLayoutContainer>
    );
  }
}
