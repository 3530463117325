import _ from "lodash";
import React from "react";
import {
  Avatar,
  Breadcrumb,
  Button,
  Card,
  Col,
  Input,
  List,
  Modal,
  Pagination,
  Row,
  Cascader,
  Select,
} from "antd";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {getBaseUrl, getNoun, getServiceDomain} from "../../../../utils";
import axios from "axios";

import "../Referees.css";
import RefereeDescription from "../components/RefereeDescription";
import {formatCertification, prepareFilterOptions} from "../utils";
import {RANK_OPTIONS} from "../../../../admin/referee_certifications/utils";

const toQueryString = (params) => Object.entries(params).map((q) => q.join('=')).join('&');


const IconText = ({ text }) => (
  <span>
    {text}
  </span>
);

export default class RefereesListView extends React.Component {


  constructor(props) {
    super(props);
    this.debouncedSuggest = _.debounce(this.fetchReferees, 1500, { 'maxWait': 4 });
    this.state = {
      loading: true,
      referees: [],
      region_id: '',
      club_id: '',
      query: '',
      visibleReferee: null,
      page: 1,
      pages: 0,
      total: 0,
      page_size: 0,
      refereeHistories: [],
    };
  }

  componentDidMount() {
    const params = this.assignParams({});
    this.fetchReferees(params);
    this.getData(`${getBaseUrl()}/public/agencies`, (resAgencies) => {
      this.getData(`${getBaseUrl()}/public/clubs`, (resClubs) => {
        this.setState({
          loading: false,
          filters: prepareFilterOptions(resAgencies.data.agencies, resClubs.data.clubs),
        });
      });
    });
  }

  fetchReferees = (params) => {
    const queryString = toQueryString(params);
    this.getData(`${getBaseUrl()}/public/referees?${queryString}`, (res) => {
      this.setState({
        loading: false,
        referees: res.data.referees,
        page: res.data.paginator.page,
        pages: res.data.paginator.pages,
        total: res.data.paginator.total,
        page_size: res.data.paginator.page_size,
      });
    });
  };

  getData = (url, callback) => {
    axios.get(url)
      .then((res) => {
        callback(res);
      });
  };

  assignParams = (params) => {
    const defaultParams = {
      query: this.state.query,
      page: 1,
    };
    return Object.assign(defaultParams, params)
  };

  onSelectFilter = (d) => {
    const [regionId, clubId] = d;
    const params = this.assignParams({region_id: regionId, club_id: clubId});
    this.setState({...params, loading: true});
    this.debouncedSuggest(params);
  };

  onClickReferee = (referee) => {
    this.setState({
      visibleReferee: referee
    });
  };

  onCancel = () => {
    this.setState({
      visibleReferee: null,
    });
  };

  onSearch = (e) => {
    const params = this.assignParams({query: e.target.value});
    this.setState({...params, loading: true});
    this.debouncedSuggest(params);
  };

  onChangePage = (page) => {
    const params = this.assignParams({page: page});
    this.setState({...params, loading: true});
    this.debouncedSuggest(params);
  };

  onChangeCategory = (option) => {
    const params = this.assignParams({category: option});
    this.setState({...params, loading: true});
    this.debouncedSuggest(params);
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Судді - Українська Федерація карате</title>
        </Helmet>
        <Breadcrumb style={{ marginTop: '20px', marginBottom: "5px" }}>
          <Breadcrumb.Item>
            <Link to="/">
              {getServiceDomain()}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Судді
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          size="small"
          bordered={false}
          title="Список суддів"
        >
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div style={{ width: '100%', paddingTop: "10px" }}>
                <Input.Search
                  allowClear
                  size="large"
                  placeholder="Введіть ім'я або прізвище"
                  onChange={this.onSearch}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div style={{ width: '100%', paddingTop: "10px" }}>
                <Cascader
                  size="large"
                  expandTrigger="hover"
                  placeholder={"Виберіть фільтр"}
                  changeOnSelect
                  style={{ width: '100%' }}
                  options={this.state.filters}
                  onChange={this.onSelectFilter}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div style={{ width: '100%', paddingTop: "10px" }}>
                <Select
                  allowClear
                  size="large"
                  style={{ width: '100%' }}
                  onChange={this.onChangeCategory}
                  value={this.state.category}
                  placeholder="Виберіть категорію"
                >
                  {RANK_OPTIONS.map((r) =>
                    <Select.Option
                      key={r.value}
                      value={r.value}
                    >
                      {r.label}
                    </Select.Option>
                  )}
                </Select>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{paddingTop: "15px"}}>
                <b>{this.state.total}</b> {getNoun(this.state.total, 'суддя', 'судді', 'суддів')}
              </div>
            </Col>
          </Row>
          <br/>
        </Card>

        <Card
          loading={this.state.loading}
        >
          <Row type="flex" justify="space-around" align="middle">
            <Col>
              {this.state.total > 0 &&
              <div style={{marginBottom: "15px"}}>
                <Pagination
                  current={this.state.page}
                  total={this.state.total}
                  pageSize={this.state.page_size}
                  onChange={this.onChangePage}
                />
              </div>
              }
            </Col>
          </Row>
          <Row>
            <List
              size="middle"
              dataSource={this.state.referees}
              locale={{emptyText: "Немає даних"}}
              renderItem={item => (
                <List.Item
                  key={item.id}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={item.logo && item.logo.url}
                        size={80}
                        shape="square"
                      >
                        Avatar
                      </Avatar>
                    }
                    title={
                      <Button
                        style={{height: "50px"}}
                        size="large"
                        type="link"
                        onClick={() => this.onClickReferee(item)}
                      >
                        <span style={{whiteSpace: "normal"}}>{item.last_name} {item.first_name} {item.middle_name}</span>
                      </Button>
                    }
                    description={
                      <div style={{marginLeft: "15px"}}>
                        <Row type="flex" justify="start">
                          <Col xs={24} sm={24} md={6} lg={6} xl={6}><IconText text={<span><b>Регіон: </b>{item.region_name}</span>} /></Col>
                          <Col xs={24} sm={24} md={6} lg={6} xl={6}><IconText text={<span><b>Категорія: </b>{formatCertification(item.certifications).label}</span>} /></Col>
                          <Col xs={24} sm={24} md={6} lg={6} xl={6}><IconText text={<span><b>Строк ліцензії: </b>{formatCertification(item.certifications).certificate_date}</span>} /></Col>
                        </Row>
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </Row>
          <Row type="flex" justify="space-around" align="middle">
            <Col>
              {this.state.total > 0 &&
              <div style={{marginTop: "20px"}}>
                <Pagination
                  current={this.state.page}
                  total={this.state.total}
                  pageSize={this.state.page_size}
                  onChange={this.onChangePage}
                />
              </div>
              }
            </Col>
          </Row>
        </Card>
        {!!this.state.visibleReferee &&
        <Modal
          title={`${this.state.visibleReferee.last_name} ${this.state.visibleReferee.first_name} ${this.state.visibleReferee.middle_name}`}
          visible={!!this.state.visibleReferee}
          onCancel={this.onCancel}
          footer={null}
        >
          <RefereeDescription
            logo={this.state.visibleReferee.logo}
            firstName={this.state.visibleReferee.first_name}
            lastName={this.state.visibleReferee.last_name}
            middleName={this.state.visibleReferee.middle_name}
            regionName={this.state.visibleReferee.region_name}
            birthDate={this.state.visibleReferee.birth_date}
            clubName={this.state.visibleReferee.club_name}
            certifications={this.state.visibleReferee.certifications}
          />
        </Modal>
        }
      </React.Fragment>
    );
  }
}
