import {getBaseUrl} from '../../utils';
import io from '../../lib/io'

export async function postPartnerFile(file) {
  return await io.postFile(`${getBaseUrl()}/uploads/upload_partner_logo`, file);
}


export async function getPartners() {
  return await io.get(`${getBaseUrl()}/admin/partners`);
}


export async function postPartner(data) {
  return await io.post(`${getBaseUrl()}/admin/partners`, data);
}


export async function updatePartner(id, data) {
  return await io.post(`${getBaseUrl()}/admin/partners/${id}/edit`, data);
}


export async function getPartner(id) {
  return await io.get(`${getBaseUrl()}/admin/partners/${id}`);
}

export async function remove(id) {
  return await io.post(`${getBaseUrl()}/admin/partners/${id}/delete`);
}


export default {
  postPartnerFile,
  getPartners,
  postPartner,
  updatePartner,
  getPartner,
  remove,
}
