import React from "react";
import PropTypes from "prop-types";
import {Select} from "antd";
import IFormItem from "../../../components/IFormItem";
import {DEFAULT_OPTIONS, DEFAULT_USER_OPTIONS} from "./utils";


const SelectStatus = ({errors, value, onChange, options, valueKey}) => {
  return (
    <IFormItem
      label="Статус"
      errorKey="status"
      errors={errors}
    >
      <Select
        onChange={(s) => onChange(valueKey, s)}
        value={value}
      >
        {options.map(o => (
          <Select.Option key={o.value} value={o.value}>{o.label}</Select.Option>
        ))}
      </Select>
    </IFormItem>
  );
};

SelectStatus.propTypes = {
  errors: PropTypes.object.isRequired,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

SelectStatus.defaultProps = {
  options: DEFAULT_OPTIONS,
  valueKey: 'status',
};

export const UserSelectStatus = ({errors, value, onChange, options}) => {
  return (
    <SelectStatus
      errors={errors}
      options={options || DEFAULT_USER_OPTIONS}
      onChange={(vk, v) => onChange("visibility", v)}
      value={value}
    />
  )
};

export default SelectStatus
