import React from "react";
import {Button, Col, Input, Row} from "antd";

import IModalForm from "../../components/IModalForm";
import TrainerDescription from "./TrainerDescription";
import * as sertificationService from "../../services/admin/trainer_certifications";
import * as trainerService from "../../services/admin/trainers";

const initialState = {
  certificateNum: '',
  trainer: null,
  certifications: [],
  showModal: false,
};

const CheckTrainerCertificate = () => {
  const [state, setState] = React.useState(initialState);

  const onChange = (e) => {
    setState({...state, certificateNum: e.target.value});
  };

  const onCheck = async () => {
    const res = await sertificationService.getCertifiedTrainer(state.certificateNum);
    if (res.data.data) {
      const resTrainer = await trainerService.getById(res.data.data.trainer_id);
      const resCerts = await sertificationService.getTrainerCertifications(res.data.data.trainer_id);
      if (resTrainer.data.status === 'ok' && resTrainer.data.data) {
        setState({
          ...state,
          trainer: resTrainer.data.data,
          certifications: resCerts.data.data,
          showModal: true,
        })
      }
      return
    }
    setState({...state, showModal: true});
  };

  const onClose = () => {
    setState(initialState);
  };

  return (
    <Row style={{marginTop: "20px"}} gutter={24}>
      <Col lg={6}>
        <Input
          placeholder="Введіть номер сертифікату"
          value={state.certificateNum}
          onChange={onChange}
        />
      </Col>
      <Col lg={6}>
        <Button
          type="primary"
          icon="check"
          onClick={onCheck}
        >
          Перевірити
        </Button>
      </Col>
      {state.showModal &&
      <IModalForm
        title={"Інформація про тренера"}
        showModal={state.showModal}
        onCancel={onClose}
        showFooter={false}
      >
        {state.trainer
          ? <TrainerDescription
            trainer={state.trainer}
            certifications={state.certifications}
          />
          : <div><b>{`Сертифікат ${state.certificateNum} не знайдено`}</b></div>
        }
      </IModalForm>
      }
    </Row>
  )
};

export default CheckTrainerCertificate
