import _ from 'lodash';


export function getSubmitData(state) {
  const user = _.assign({}, state.user);
  return {
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    password: user.password,
    sections: state.selectedSections,
  };
}
