import {connect} from "react-redux";
import AdminDocumentsForm from './AdminDocumentsForm';
import {
  changeInput,
  submitForm,
  showDocumentForm,
  cancelDocumentForm,
  editDocsSection,
  uploadFile,
  submitDocumentForm,
  editDocument,
  onDeleteDocument,
  onDeleteFile

} from "./AdminDocumentsListActions";


const mapStateToProps = state => {
  return {
    ...state.docs,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeInput: (name, value) => {
      return dispatch(changeInput(name, value))
    },

    onSubmit: (history) => {
      return dispatch(submitForm(history))
    },

    onAddDocument: () => {
      return dispatch(showDocumentForm())
    },
    onCancel: () => {
      return dispatch(cancelDocumentForm())
    },
    onInit: (itemId) => {
      return dispatch(editDocsSection(itemId))
    },
    onUploadFile: (file) => {
      return dispatch(uploadFile(file))
    },
    onSubmitDocument: (history) => {
      return dispatch(submitDocumentForm(history))
    },
    onEditDocument: (itemId) => {
      return dispatch(editDocument(itemId))
    },
    onDeleteDocument: (itemId) => {
      return dispatch(onDeleteDocument(itemId))
    },
    onDeleteFile: () => {
      return dispatch(onDeleteFile())
    }

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDocumentsForm);
