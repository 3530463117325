


export const CONTRIBUTIONS_OPTIONS = [
  {value: 0, label: 'Не сплачені внески'},
  {value: 1, label: 'Сплачені внески'}
];

export const DEFAULT_CLUB_STATUS_OPTIONS = [
  {value: 'ACTIVE', label: 'ACTIVE'},
  {value: 'ARCHIVE', label: 'ARCHIVE'},
  {value: 'BLOCKED', label: 'BLOCKED'},
];