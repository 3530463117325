import Cookies from 'js-cookie'


const initialState = {
  token: Cookies.get("token"),
  loading: false,
  editMode: false,
  showModal: false,
  errors: {},

  title: '',
  status: 'public',
  docs_sections: [],
  docs_section: null,
  docs: [],
  file_id: null,
  file: null,
  is_new: false,

};


export default function reducers(state=initialState, action) {
  switch (action.type) {

    case 'CHANGE_VALUE':
      return {...state, [action.name]: action.value};

    case 'LOADING':
      return {...state, loading: action.loading};

    case 'CLEAR_FORM':
      return {...initialState};

    case 'SET_ERRORS':
      return {...state, errors: action.errors || {}};

    case 'SET_DOC_SECTIONS':
      return {...state, docs_sections: action.docs_sections};

    case 'SET_DOC_SECTION':
      const docsSectionData = {
        title: action.docs_section.title,
        status: action.docs_section.status,
      };
      return {...state, ...docsSectionData,  docs_section: action.docs_section, editMode: true};

    case 'SHOW_DOCUMENT_FORM':
      const m = {
        doc_title: '',
        file: null,
      };
      return {...state, showModal: true, ...m};

    case 'HIDE_DOCUMENT_FORM':
      return {...state, showModal: false};

    case 'SET_DOCUMENT':
      const document_data = {
        doc_title: action.doc.title,
        file_id: action.doc.file.id,
        file: action.doc.file,
        is_new: action.doc.is_new,
      };
      return {
        ...state,
        ...document_data,
        showModal: true,
        doc: action.doc,
        docEditMode: true
      };

    case 'SET_FILE':
      return {...state, loading: false, file: action.file};

    case 'SET_DOCS':
      return {...state, docs: action.docs};

    default:
      return state;
  }
}
