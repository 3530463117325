import React from "react";
import {Select} from "antd";
import FilterItem from "../../FilterItem";
import {DEFAULT_USER_OPTIONS} from "../../../SelectStatus/utils";


const SelectFilter = (props) => {

  return (
    <FilterItem
      field={props.field}
      type="select"
    >
      {({onChange, value}) =>
        <Select
          onChange={onChange}
          value={value}
          placeholder={!value && (props.placeholder || "Виберіть статус")}
          style={{width: "100%"}}
        >
          {props.options.map(o => (
            <Select.Option key={o.value} value={o.value}>{o.label}</Select.Option>
          ))}
        </Select>
      }
    </FilterItem>
  )
};


export const UserStatusSelectFilter = (props) => {
  return (
    <SelectFilter
      {...props}
      options={DEFAULT_USER_OPTIONS}
    />
  );
};

export default SelectFilter
