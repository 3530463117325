import {getBaseUrl} from '../../utils';
import io from '../../lib/io'

export async function postFile(file) {
  return await io.postFile(`${getBaseUrl()}/uploads/upload_rule_file`, file);
}


export async function getAll() {
  return await io.get(`${getBaseUrl()}/admin/rules-sections`);
}


export async function add(data) {
  return await io.post(`${getBaseUrl()}/admin/rules-sections`, data);
}


export async function save(id, data) {
  return await io.post(`${getBaseUrl()}/admin/rules-sections/${id}/edit`, data);
}

export async function getById(id) {
  return await io.get(`${getBaseUrl()}/admin/rules-sections/${id}`);
}

export async function remove(id) {
  return await io.post(`${getBaseUrl()}/admin/rules-sections/${id}/delete`);
}

export async function saveRule(id, data) {
  return await io.post(`${getBaseUrl()}/admin/rules-sections/rules/${id}/edit`, data);
}

export async function addRule(data) {
  return await io.post(`${getBaseUrl()}/admin/rules-sections/rules`, data);
}

export async function removeRule(id) {
  return await io.post(`${getBaseUrl()}/admin/rules-sections/rules/${id}/delete`);
}

export async function getAllRules(id) {
  return await io.get(`${getBaseUrl()}/admin/rules-sections/${id}/rules`);
}

export async function getRuleById(id) {
  return await io.get(`${getBaseUrl()}/admin/rules-sections/rules/${id}`);
}

export default {
  postFile,
  getAll,
  add,
  save,
  getById,
  remove,
  saveRule,
  addRule,
  removeRule,
  getAllRules,
  getRuleById
}
