import React from "react";
import {Route, Switch} from "react-router-dom";
import PublicBaseLayout from "../../../components/BaseLayout/PublicBaseLayout";
import AthletesListView from "./views/AthletesListView";

export default class Athletes extends React.Component {

  render() {
    return (
      <PublicBaseLayout>
        <Switch>
          <Route
            exact
            path={"/members/athletes"}
            component={AthletesListView}
          />
        </Switch>
      </PublicBaseLayout>
    );
  }
}
