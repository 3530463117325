import {connect} from "react-redux";
import AdminDocumentsList from "./AdminDocumentsList";
import {clearForm, editDocsSection, fetchDocSections, onDelete} from "./AdminDocumentsListActions";

const mapStateToProps = state => {
  return {
    ...state.docs,
  };
};

const mapDispatchToProps = dispatch => {
  return {

    onInit: () => {
      return dispatch(fetchDocSections());
    },

    onEdit: (itemId) => {
      window.location.href = `/admin/document/${itemId}`;
      return dispatch(editDocsSection(itemId));
    },
    onCreate: () => {
      return dispatch(clearForm())
    },
    onAdd: () => {
      window.location.href = '/admin/doc-create';
    },
    onDelete: (itemId) => {
      return dispatch(onDelete(itemId))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDocumentsList);
