import React from "react";
import {Button, Checkbox, Spin} from "antd";
import IModalForm from "../../../components/IModalForm";
const CheckboxGroup = Checkbox.Group;


class DownloadFormContainer extends React.Component {

  state = {
    loading: false,
    checkedList: this.props.defaultFieldOptions,
    fileUrl: null
  };

  onChange = checkedList => {
    this.setState({checkedList});
  };


  onSubmitDownload = () => {
    // TODO fix prepare data
    const filters ={};
    Object.entries(this.props.filters)
      .map((q) => filters[q[0]]=q[1].value);
    const data = {
      fields: this.state.checkedList,
      filters: filters
    };
    this.setState({fileUrl: null, loading: true});
    this.props.downloadAction(data).then((res) => {
      if (res.data.status === 'ok') {
        this.setState({fileUrl: res.data.file_url, loading: false})
      } else {
        console.error("Error on server")
      }
    });
  };

  render() {
    return (
      <IModalForm
        title={"Завантажити XLS"}
        submitText="Заванатжити"
        showModal={this.props.showModal}
        onCancel={this.props.onCancel}
        onSubmit={this.onSubmitDownload}
      >
        <Spin spinning={this.state.loading} size="large">
          <div>
            <CheckboxGroup
              options={this.props.fieldOptions}
              value={this.state.checkedList}
              onChange={this.onChange}
            />
          </div>
          <br/>
          {this.state.fileUrl
            ? <div>
              Файл:
              <a
                href={this.state.fileUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.state.fileUrl}
              </a>
            </div>
            : null
          }
        </Spin>
      </IModalForm>
    );
  }
}


export default class DownloadListFilterWraper extends React.Component {

  state = {
    showModal: false,
  };

  onDownload = () => {
    this.setState({showModal: true})
  };

  onCancelDownload = () => {
    this.setState({showModal: false})
  };

  render() {
    return (
      <React.Fragment>
        <Button
          type="primary"
          icon="file-excel"
          onClick={this.onDownload}>
          Завантажити
        </Button>
        {this.state.showModal &&
          <DownloadFormContainer
            filters={this.props.filters}
            fieldOptions={this.props.fieldOptions}
            defaultFieldOptions={this.props.defaultFieldOptions}
            showModal={this.state.showModal}
            onCancel={this.onCancelDownload}
            downloadAction={this.props.downloadAction}
          />
        }
      </React.Fragment>
    );
  }
}