import React from "react";
import PublicBaseLayout from "../../../components/BaseLayout/PublicBaseLayout";
import BeltsListView from "./views/BeltsListView";

export default class Belts extends React.Component {

  render() {
    return (
      <PublicBaseLayout>
        <BeltsListView/>
      </PublicBaseLayout>
    );
  }
}
