import React from "react";
import {Avatar, Col, Descriptions, Row} from "antd";
import {calculateAge, formatDate} from "../../../../../utils";
import {formatCategory} from "../../utils";

const TrainerDescription = ({logo, birthDate, region, club, category, licenseTerm}) => {
  return (
    <React.Fragment>
      <Row gutter={24}>
        <Col span={8}>
          <Avatar
            src={logo && logo.url}
            style={{margin: "0 auto", display: "block"}}
            shape="square" size={100}
          >
            Avatar
          </Avatar>
        </Col>
        <Col span={16}>
          <Descriptions
            size="small"
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          >
            <Descriptions.Item label="Дата народження">{formatDate(birthDate)} ({calculateAge(new Date(birthDate))})</Descriptions.Item>
            <Descriptions.Item label="Регіон">{region}</Descriptions.Item>
            <Descriptions.Item label="Клуб">{club}</Descriptions.Item>
            <Descriptions.Item label="Тренерська категорія">{formatCategory(category)}</Descriptions.Item>
            <Descriptions.Item label="Cтрок ліцензії">{licenseTerm ? formatDate(licenseTerm) : "Данні відсутні"}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </React.Fragment>
  );
};


export default TrainerDescription;
