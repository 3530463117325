import axios from 'axios';
import {getBaseUrl, SERVER_BASE_ERROR_MSG, SUCCESS_DELETED_MSG,} from '../../utils';
import service from '../../services/admin/events';
import {message} from 'antd';
import Cookies from 'js-cookie';

export const editEvent = (eventId) => {
  return (dispatch, getState) => {
    dispatch({
        type: "CLEAR_FORM",
    })
    fetchEvent(eventId)(dispatch, getState)
  }
};

export const createEvent = () => {
  return (dispatch, getState) => {
    dispatch({
        type: "CLEAR_FORM",
    })
  }
};

export const fetchEvent = (eventId) => {
  return (dispatch, getState) => {
    dispatch({
        type: "LOADING",
        loading: true,
    })

    const token = Cookies.get('token');

    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }

    const url = `${getBaseUrl()}/admin/events/${eventId}`;
    axios.get(url, {
        headers: headers
    })
    .then((res) => {
      if (res.data.status === 'ok') {
        dispatch({
          type: "SET_EVENT",
          event: res.data.event,
        })
      } else {
        console.error("error events")
      }
    });
  }
};

export const fetchEvents = () => {
  return (dispatch, getState) => {
    dispatch({
        type: "LOADING",
        loading: true,
    })

    const token = Cookies.get('token');

    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }

    const url = `${getBaseUrl()}/admin/events`;
    axios.get(url, {
        headers: headers
    })
    .then((res) => {
      if (res.data.status === 'ok') {
        dispatch({
          type: "SET_EVENTS",
          events: res.data.events,
        })
      }
      dispatch({
          type: "LOADING",
          loading: false,
      })
    });
  }
};

function onSetLoading(loading) {
  return (dispatch) => {
    dispatch({
      type: "LOADING",
      loading: loading,
    })
  }
}

export const showMessage = (msg) => {
  return async (dispatch) => {
    message[msg.level](msg.text)
  }
};

export function onDelete(itemId) {
  return async (dispatch, getState) => {
    try {
      onSetLoading(true)(dispatch);
      const res = await service.remove(itemId);
      if (res.data.status === 'ok') {
        fetchEvents()(dispatch, getState);
        onSetLoading(false)(dispatch);
        showMessage(SUCCESS_DELETED_MSG)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}