import React from 'react';
import { Form, Input, Select } from 'antd';
import IFormItem from '../../components/IFormItem';
import IPhotoUploader from '../../components/IPhotoUploader';

const Option = Select.Option;

export default function PartnerForm(props) {
  return (
    <Form>
      <IFormItem
        label="Статус"
        errorKey="status"
        errors={props.errors}
      >
        <Select
          showSearch
          onChange={(s) => props.onChangeInput("status", s)}
          value={props.status}
        >
          <Option value="public">Опубліковано</Option>
          <Option value="draft">Черновик</Option>
        </Select>
      </IFormItem>
      <IFormItem
        label="Зображення"
        errorKey="file"
        errors={props.errors}
      >
        <IPhotoUploader
          name="file"
          loading={props.loading}
          file={props.file}
          onUpload={props.onUpload}
        />
        <span>Мінімальне співвідношення 300x200</span>
      </IFormItem>
      <IFormItem
        label="Заголовок"
        errorKey="title"
        errors={props.errors}
      >
        <Input
          name="title"
          value={props.title}
          onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
        />
      </IFormItem>
      <IFormItem
        label="Посилання"
        errorKey="link"
        errors={props.errors}
      >
        <Input
          name="link"
          value={props.link}
          onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
        />
      </IFormItem>
      <IFormItem
        label="Порядковий номер"
        errorKey="order_num"
        errors={props.errors}
      >
        <Input
          name="order_num"
          value={props.order_num}
          onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
        />
      </IFormItem>
    </Form>
  );
}

