import axios from 'axios';
import {getBaseUrl} from '../../utils';
import Cookies from 'js-cookie';

export const uploadFile = (file) => {
  return (dispatch, getState) => {
    dispatch({
        type: "LOADING",
        loading: true,
    })

    const token = Cookies.get('token');

    let headers = {
      'Content-Type': 'multipart/form-data'
    };

    if (token) {
      headers["Authorization"] = `${token}`;
    }
    const formData = new FormData();
    formData.append("picture", file);
    const url = `${getBaseUrl()}/uploads/files`;
    axios.post(url, formData, {
        headers: headers
    })
    .then((res) => {
      if (res.data.status === 'ok') {
        dispatch({
          type: "SET_FILE",
          file: res.data.file,
        })
      } else {
        console.error("error upload")
      }
    });
  }
};

export const changeInput = (name, value) => {
  return (dispatch, getState) => {
    dispatch({
        type: "CHANGE_VALUE",
        name: name,
        value: value,
    })
  }
};

export const clearForm = (name, value) => {
  return (dispatch, getState) => {
    dispatch({
        type: "CLEAR_FORM",
    })
  }
};


export const submitForm = (history) => {
  return (dispatch, getState) => {
    dispatch({
        type: "LOADING",
        loading: true,
    })

    const token = Cookies.get('token');
    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }
    const state = getState().news;
    const data = {
      date_started: state.date_started.format("YYYY-MM-DD"),
      title: state.title,
      is_main: state.is_main,
      intro: state.intro,
      description: state.description,
      picture_id: state.file && state.file.id,
      status: state.status,
    }
    let url = '';
    if (state.news && state.editMode) {
      url = `${getBaseUrl()}/admin/news/${state.news.id}/edit`;
    } else {
      url = `${getBaseUrl()}/admin/news`;
    }
    axios.post(url, data, {
        headers: headers
    })
    .then((res) => {
      if (res.data.status === 'ok') {
          window.location.href = '/admin/news'
      } else {
        setErrors(res.data.errors)(dispatch);
      }
    });
  }
};

export const fetchNews = (newsId) => {
  return (dispatch, getState) => {
    dispatch({
        type: "LOADING",
        loading: true,
    })

    const token = Cookies.get('token');

    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }


    const url = `${getBaseUrl()}/admin/news/${newsId}`;
    axios.get(url, {
        headers: headers
    })
    .then((res) => {
      if (res.data.status === 'ok') {
        dispatch({
          type: "SET_NEWS",
          news: res.data.news,
        })
      } else {
        console.error("error news")
      }
    });
  }
};

export const setErrors = (errors) => {
  return async (dispatch) => {
    dispatch({
      type: "NEWS_SET_ERRORS",
      errors: errors,
    })
  }
};

export const fetchNews2 = (newsId) => {
  return (dispatch, getState) => {
    return null
  }
};