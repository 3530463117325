import { combineReducers } from 'redux';
import auth from "./components/AdminLogin/AdminLoginReducers";
import news from "./components/AdminNewsCreateForm/AdminNewsCreateFormReducers";
import edit_news from "./components/AdminNewsCreateForm/AdminNewsEditFormReducers";
import adminApp from "./components/BaseLayout/AdminAppReducers";
import events from "./components/AdminEventForm/AdminEventReducers";
import competitionsCalendar from "./components/CompetitionsCalendar/CompetitionsCalendarReducers";
import federationStructure from "./components/AdminFederatoinStructure/AdminFederationStructureReducers";
import docs from "./components/AdminDocumentsList/AdminDocumentsListReducers";
import users from "./components/UserList/reducers";
import partners from "./admin/partners/reducers";
import tournaments from "./admin/tournaments/reducers";
import banners from "./admin/banners/reducers";
import rules from "./admin/rules/reducers";

const rootReducers = combineReducers({
  auth,
  news,
  edit_news,
  adminApp,
  events,
  competitionsCalendar,
  federationStructure,
  docs,
  partners,
  tournaments,
  banners,
  rules,
  users,
});

export default rootReducers;
