

const actionTypes = {
  LOADING: 'LOADING',
  SET_PARTNERS: 'SET_PARTNERS',
  SHOW_MESSAGE: 'SHOW_MESSAGE',
  PARTNER_SHOW_FORM: 'PARTNER_SHOW_FORM',
  PARTNERS_HIDE_FORM: 'PARTNERS_HIDE_FORM',
  PARTNERS_CHANGE_INPUT: 'PARTNERS_CHANGE_INPUT',
  PARTNERS_SET_FILE: 'PARTNERS_SET_FILE',
  PARTNERS_SET_ERRORS: 'PARTNERS_SET_ERRORS',
  PARTNERS_SET_INITIAL_PARTNER: 'PARTNERS_SET_INITIAL_PARTNER',
  PARTNERS_SET_PARTNER: 'PARTNERS_SET_PARTNER',
};

export default actionTypes;