import React from "react";
import {Breadcrumb, Button, Card, Col, Collapse, Modal, Popover, Row} from "antd";
import {Link} from "react-router-dom";
import PublicBaseLayout from "../../../components/BaseLayout/PublicBaseLayout";
import {Helmet} from "react-helmet";
import {getBaseUrl, getServiceDomain} from "../../../utils";
import ukraine from "./views/ukraine.json";
import {ComposableMap, Geographies, ZoomableGroup} from "react-simple-maps";
import axios from "axios";
import Geography from "../../../libs/maps/Geography";

import "./Agencies.css";
import AgencyDescription from "./components/AgencyDescription";
import ClubDescription from "./components/ClubDescription/index";

const wrapperStyles = {
  width: "100%",
  margin: "0 auto",
};

const additionRegions = [
  {
    NAME_1: 'Kherson',
    name: 'Херсоньска область'
  },
  {
    NAME_1: 'Khmelnytskyy',
    name: 'Хмельницька область'
  }
];


export default class Agencies extends React.Component {

  state = {
    loading: true,
    agencies: [],
    clubs: [],
    visibleAgency: null,
    visibleAgencyName: '',
    currentClubs: [],
    visibleClub: null,
    hoverAgency: null,
  };

  componentDidMount() {
    this.getData(`${getBaseUrl()}/public/agencies`, (res) => {
      this.setState({
        loading: false,
        agencies: res.data.agencies.sort((a, b) => {
          return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});
        }),
      });
    });
    this.getData(`${getBaseUrl()}/public/clubs`, (res) => {
      this.setState({
        loading: false,
        clubs: res.data.clubs,
      });
    });
  }

  getData = (url, callback) => {
    axios.get(url)
      .then((res) => {
        callback(res);
      });
  };

  onShowAgency = (agency) => {
    if (!agency) {
      return
    }
    this.setState({
      visibleAgencyName: agency.name,
      visibleAgency: agency,
      currentClubs: this.state.clubs.filter((c) => c.region_id === agency.id)
    });
  };

  onShowClub = (e, club) => {
    e.preventDefault();
    this.setState({
      visibleClub: club,
    })
  };

  handleCancelAgency = () => {
    this.setState({
      visibleAgency: null,
    });
  };

  handleCancelClub = () => {
    this.setState({
      visibleClub: null,
    });
  };

  render() {
    return (
      <PublicBaseLayout>
        <Helmet>
          <title>Представництва - Українська Федерація карате</title>
        </Helmet>
        <Breadcrumb style={{ marginTop: '20px', marginBottom: "5px" }}>
          <Breadcrumb.Item>
            <Link to="/">
              {getServiceDomain()}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Представництва
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          title="Представництва"
          loading={this.state.loading}

        >
          <Row>
            <Col xs={0} sm={0} md={0} lg={18} xl={18} xxl={18}>
              <div
                className="scrollable-container"
                style={{display: "stick"}}
                ref={node => {
                  this.container = node;
                }}
              >
                <div>
                  <div
                    style={wrapperStyles}
                  >
                    <ComposableMap
                      projectionConfig={{
                        scale: 3500,
                        rotation: [0,0,0],
                      }}
                      height={700}
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    >
                      <ZoomableGroup center={[31.2, 49]} disablePanning>
                        <Geographies disableOptimization geography={ukraine}>
                          {(geographies, projection) => geographies.map((geography, index) => {
                            const region = this.state.agencies.find((a) => a.id === geography.properties.DB_ID);
                            let regionName = '';
                            if (!region) {
                              const additionRegion = additionRegions.find((a) => a.NAME_1 === geography.properties.NAME_1)
                              regionName = additionRegion && additionRegion.name
                            } else {
                              regionName = region.name
                            }
                            return (
                              <Popover
                                key={index}
                                trigger="hover"
                                title={regionName}
                                content={<div>Клуби федерації: <b>{region ? region.clubs_num : 0}</b></div>}
                              >
                                <Geography
                                  key={ index }
                                  geography={ geography }
                                  projection={ projection }
                                  active={this.state.hoverAgency && geography.properties.DB_ID === this.state.hoverAgency.id}
                                  onClick={(g, e) => {
                                    e.preventDefault();
                                    this.onShowAgency(region)}
                                  }
                                  style={{
                                    default: {
                                      fill: "#ECEFF1",
                                      stroke: "#607D8B",
                                      strokeWidth: 0.75,
                                      outline: "none",
                                      cursor: "pointer",
                                    },
                                    hover: {
                                      fill: "#4c69ae",
                                      stroke: "#607D8B",
                                      strokeWidth: 0.75,
                                      outline: "none",
                                      cursor: "pointer",
                                    },
                                    pressed: {
                                      fill: "#c2d1f0",
                                      stroke: "#607D8B",
                                      strokeWidth: 0.75,
                                      outline: "none",
                                      cursor: "pointer",
                                    },
                                  }}
                                />
                              </Popover>
                            );
                          })}

                        </Geographies>
                      </ZoomableGroup>
                    </ComposableMap>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}>
              <ul className="ant-list-items1" style={{height: "600px", overflow: "auto"}}>
                {this.state.agencies.map((r) => {
                    return (
                      <li
                        key={r.id}
                        className={this.state.hoverAgency && this.state.hoverAgency.id === r.id ? "ant-list-item1-hover" :"ant-list-item1"}
                        onClick={(e) => this.onShowAgency(r)}
                        onMouseEnter={(e) => this.setState({hoverAgency: r})}
                        onMouseLeave={(e) => this.setState({hoverAgency: null})}

                      >
                        <Button
                          type="link"
                          style={this.state.hoverAgency === r ? {color: "white"} : null}

                        >
                          {r.name}
                        </Button>
                      </li>
                    )
                  }
                )}
              </ul>
            </Col>
            <Col xs={24} sm={24} md={24} lg={0} xl={0} xll={0}>
              <Collapse
                accordion
                onChange={(key) => {
                  const agencyId = Number(key);
                  if (!agencyId) return;
                  const agency = this.state.agencies.find((a) => a.id === agencyId);
                  this.setState({
                    visibleAgencyName: agency.name,
                    currentClubs: this.state.clubs.filter((c) => c.region_id === agency.id),
                  });
                }}
              >
                {this.state.agencies.map((item) =>
                  <Collapse.Panel
                    key={item.id}
                    header={item.name}
                  >
                    <AgencyDescription
                      head={item.head}
                      email={item.email}
                      phoneNumber={item.phone_number}
                      clubs={this.state.currentClubs}
                      onShowClub={this.onShowClub}
                    />
                  </Collapse.Panel>
                )}
              </Collapse>
            </Col>
          </Row>
        </Card>
        {!!this.state.visibleAgency &&
        <Modal
          title={this.state.visibleAgency.name}
          visible={!!this.state.visibleAgency}
          onCancel={this.handleCancelAgency}
          footer={null}
        >
          <AgencyDescription
            head={this.state.visibleAgency.head}
            email={this.state.visibleAgency.email}
            phoneNumber={this.state.visibleAgency.phone_number}
            clubs={this.state.currentClubs}
            onShowClub={this.onShowClub}
          />
        </Modal>
        }
        {!!this.state.visibleClub &&
        <Modal
          title={`Клуб: ${this.state.visibleClub.short_name}`}
          visible={!!this.state.visibleClub}
          onCancel={this.handleCancelClub}
          footer={null}
        >
          <ClubDescription
            logo={this.state.visibleClub.logo}
            name={this.state.visibleAgencyName}
            ownerName={this.state.visibleClub.owner_name}
            address={this.state.visibleClub.address}
            email={this.state.visibleClub.email}
            website={this.state.visibleClub.website}
            phoneNumber={this.state.visibleClub.phone_number}
            entryDate={this.state.visibleClub.entry_date}
          />
        </Modal>
        }
      </PublicBaseLayout>
    );
  }
}
