import React from "react";
import {Table} from "antd";
import RefereeDocumentForm from "./RefereeDocumentForm";
import AdminActionDropdown from "../../../../components/AdminActionDropdown";
import * as documentService from "../../../../services/admin/documents";
import * as refereeService from "../../../../services/admin/referees";


const CONTENT_TYPE = 'docs_referees';
const initialState = {
  title: '',
  file: null,
  docs: [],
  errors: {},
  loading: true,
};

const RefereeDocumentContainer = ({refereeId}) => {

  if (!refereeId) {
    return
  }

  const [state, setState] = React.useState(initialState);

  const fetchData = async () => {
    const res = await documentService.getByObjectType(refereeId, CONTENT_TYPE);
    setState({
      ...state,
      docs: res.data.data,
      loading: false,
    });
  };

  React.useEffect(() => {
    async function fetchData() {
      const res = await documentService.getByObjectType(refereeId, CONTENT_TYPE);
      setState({
        ...state,
        docs: res.data.data,
        loading: false,
      });
    };
    fetchData()
  }, []);

  const handleOnChange = (e) => {
    setState({...state, [e.target.name]: e.target.value})
  };

  const handleOnDelete = async (id) => {
    await documentService.remove(id);
    setState({...state, file: null});
    fetchData()
  };

  const handleUploadFile = async (file) => {
    const res = await refereeService.uploadDocumentFile(file);
    setState({...state, file: res.data.file})
  };

  const handleOnSubmit = async () => {
    setState({...state, loading: true});
    const doc = {
      title: state.title,
      file: state.file && state.file.id,
      content_object_id: refereeId,
      content_type: CONTENT_TYPE,
    };
    const res = await documentService.add(doc);

    if (res.data.status === 'ok') {
      setState({
        ...state,
        loading: false,
        title: '',
        file: null
      })
      fetchData()
    } else {
      setState({...state, loading: false, errors: res.data.errors});
    }
  };

  const columns = [
    {
      title: 'Файл',
      dataIndex: 'file',
      key: 'file',
      render: (t, r) => <a
        href={r.file.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {r.title}
      </a>
    },
    {
      title: 'Дії',
      dataIndex: 'action',
      key: 'action',
      render: (text, r) => (
        <AdminActionDropdown
          onDelete={() => handleOnDelete(r.id)}
          item={r}
        />
      ),
    }
  ];

  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={state.docs}
        loading={state.loading}
        locale={{emptyText: "Немає докуменів"}}
      />
      <RefereeDocumentForm
        loadin={state.loading}
        title={state.title}
        file={state.file}
        errors={state.errors}
        onChange={handleOnChange}
        onUploadFile={handleUploadFile}
        onSubmit={handleOnSubmit}
      />
    </div>
  );
};


export default RefereeDocumentContainer;
