import React from "react";
import PropTypes from "prop-types";
import IFormItem from "../../../../components/IFormItem";
import {Button, Icon, Input, Modal, Switch, Upload} from "antd";


class Uploader extends React.Component {


  onUpload = (e) => {
    this.props.onUploadFile(e.file)
  };

  render() {
    const filesList = [];
    if (this.props.file) {
      filesList.push({
        uid: '1',
        name: this.props.file.original_name,
        status: 'done',
        url: this.props.file.url,
      })
    }
    return (
      <Upload
        onRemove
        fileList={filesList}
        customRequest={this.onUpload}
      >
        <Button>
          <Icon type="upload" /> Завантажити
        </Button>

      </Upload>
    );
  }
}


export default class DocumentForm extends React.Component {

  render() {
    return (
      <Modal
        title="Документ"
        visible={this.props.showModal}
        onCancel={this.props.onCancel}
        footer={[
          <Button key="back" onClick={this.props.onCancel}>Відмінити</Button>,
          <Button key="submit" type="primary" onClick={this.props.onSubmit}>
            Зберегти
          </Button>,
        ]}
      >
        <IFormItem
          label="Документ"
          errorKey="file"
          errors={this.props.errors}
        >
          <Uploader
            file={this.props.file}
            onUploadFile={this.props.onUploadFile}
          />
        </IFormItem>
        <IFormItem
          label="Назва документу"
          errorKey="title"
          errors={this.props.errors}
        >
          <Input
            name="title"
            value={this.props.title}
            onChange={(e) => this.props.changeInput("title", e.target.value)}
          />
        </IFormItem>
        <IFormItem
          label="Відмітити як новий"
          errorKey="is_new"
          errors={this.props.errors}
        >
          <Switch
            checked={this.props.isNew}
            onChange={(v) => this.props.changeInput("is_new", v)}
          />
        </IFormItem>
      </Modal>
    );
  }
}

DocumentForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
  changeInput: PropTypes.func.isRequired,
};
