import React from "react";
import moment from "moment";
import {Avatar, Col, Descriptions, Row} from "antd";

const ClubDescription = ({logo, name, ownerName, address, email, website, phoneNumber, entryDate}) => {
  return (
    <React.Fragment>
      <Row gutter={24}>
        <Col span={8}>
          <Avatar
            src={logo && logo.url}
            style={{margin: "0 auto", display: "block"}}
            shape="square" size={100}
          >
            Logo
          </Avatar>
        </Col>
        <Col span={16}>
          <Descriptions
            size="small"
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          >
            <Descriptions.Item label="Регіон">{name}</Descriptions.Item>
            <Descriptions.Item label="Керівник">{ownerName}</Descriptions.Item>
            <Descriptions.Item label="Адреса">{address}</Descriptions.Item>
            <Descriptions.Item label="Email"><a href={`mailto:${email}`}>{email}</a></Descriptions.Item>
            <Descriptions.Item label="URL"><a href={website} target="_blank" rel="noopener noreferrer">{website}</a></Descriptions.Item>
            <Descriptions.Item label="Тел"><a href={`tel:${phoneNumber}`}>{phoneNumber}</a></Descriptions.Item>
            <Descriptions.Item label="Дата вступу">{moment(entryDate).format("DD.MM.YYYY")}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </React.Fragment>
  );
};


export default ClubDescription;
