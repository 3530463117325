

const actionTypes = {
  LOADING: 'LOADING',
  SET_PARTNERS: 'SET_PARTNERS',
  SHOW_MESSAGE: 'SHOW_MESSAGE',
  BANNERS_SHOW_FORM: 'BANNERS_SHOW_FORM',
  BANNERS_HIDE_FORM: 'BANNERS_HIDE_FORM',
  BANNERS_CHANGE_INPUT: 'BANNERS_CHANGE_INPUT',
  BANNERS_SET_FILE: 'BANNERS_SET_FILE',
  BANNERS_SET_ERRORS: 'BANNERS_SET_ERRORS',
  BANNERS_SET_INITIAL_PARTNER: 'BANNERS_SET_INITIAL_PARTNER',
  BANNERS_SET_PARTNER: 'BANNERS_SET_PARTNER',
};

export default actionTypes;