import React from 'react';
import { hydrate } from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import { Provider } from "react-redux";
import configureStore from './store';
import { ensureReady, After } from '@jaredpalmer/after';
import routes from './routes';


const store = configureStore(window.__PRELOADED_STATE__);

ensureReady(routes).then(data => {
  return (
    hydrate(
      <BrowserRouter>
        <Provider store={store}>
          <After data={data} routes={routes} store={store}/>
        </Provider>
      </BrowserRouter>,
      document.getElementById('root')
    )
  );
});

if (module.hot) {
  module.hot.accept();
}
