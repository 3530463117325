import _ from "lodash";
import React from "react";
import {Icon, Layout, Menu, Button, Row, Col} from "antd";
import {Link} from "react-router-dom";
import Cookies from "js-cookie";
import logo from "../../../public/mini_logo.png";
import "../../App.css";
import "antd/dist/antd.css";

const { Header, Sider, Content, Footer } = Layout;

class BaseLayout extends React.Component {

  onToggleMenu = () => {
    this.props.onToggleMenu()
  };

  onSelectMenu = ({ selectedKeys }) => {
    this.props.onSelectMenu(selectedKeys)
  };

  onLogout = () => {
    Cookies.remove('token');
    window.location.href = "/"
  };

  render() {
    if (!this.props.user) {
      return null
    }
    const canViewStats = (
      _.includes(this.props.user.admin_sections, "regions") ||
      _.includes(this.props.user.admin_sections, "athletes") ||
      _.includes(this.props.user.admin_sections, "trainers") ||
      _.includes(this.props.user.admin_sections, "referees")
    );
    const canViewCert = (
      _.includes(this.props.user.admin_sections, "athletes_certification") ||
      _.includes(this.props.user.admin_sections, "trainers_certification") ||
      _.includes(this.props.user.admin_sections, "referees_certification")
    );
    return (
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={this.props.menuCollapsed}
        >
          <Row type="flex" justify="center">
            <Col>
              <Link to="/">
                <img style={{padding: "10px 0 0 0"}} width={"50"} src={logo} alt="logo" />
              </Link>
            </Col>
            <Col>
              <a href="/" target="_blank" rel="noopener noreferrer">
                <div style={{color: "white", padding: "25px 0 0 0", fontSize: "20px"}}><b>UKF</b></div>
              </a>
            </Col>
          </Row>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={this.props.selectedMenu}
            onSelect={this.onSelectMenu}
            onOpenChange={this.props.onOpenChange}
            openKeys={this.props.selectedSubMenu}
          >
            <Menu.Item key="dashboard">
              <Link to={`/admin`}>
                <Icon type="dashboard" />
                <span>Головна</span>
              </Link>
            </Menu.Item>
            {_.includes(this.props.user.admin_sections, "users") &&
            <Menu.Item key="users">
              <Link to={`/admin/users`}>
                <Icon type="user" />
                <span>Користувачі</span>
              </Link>
            </Menu.Item>
            }
            {_.includes(this.props.user.admin_sections, "news") &&
            <Menu.Item key="news">
              <Link to={`/admin/news`}>
                <Icon type="solution"/>
                <span>Новини</span>
              </Link>
            </Menu.Item>
            }
            <Menu.SubMenu
              key="events"
              title={<span><Icon type="environment" /><span>Турніри</span></span>}
            >
              {_.includes(this.props.user.admin_sections, "events") &&
              <Menu.Item key="events">
                <Link to={`/admin/events`}>
                  <Icon type="calendar"/>
                  <span>Календар</span>
                </Link>
              </Menu.Item>
              }
              {_.includes(this.props.user.admin_sections, "rules") &&
              <Menu.Item key="rules">
                <Link to={`/admin/rules`}>
                  <Icon type="book"/>
                  <span>Правила</span>
                </Link>
              </Menu.Item>
              }
            </Menu.SubMenu>
            <Menu.SubMenu
              key="federation"
              title={<span><Icon type="home" /><span>Федерація</span></span>}
            >
              {_.includes(this.props.user.admin_sections, "structures") &&
              <Menu.Item key="structures">
                <Link to={`/admin/structures`}>
                  <Icon type="share-alt"/>
                  <span>Структура</span>
                </Link>
              </Menu.Item>
              }
              {_.includes(this.props.user.admin_sections, "docs") &&
              <Menu.Item key="docs">
                <Link to={`/admin/documents`}>
                  <Icon type="file-text"/>
                  <span>Документи</span>
                </Link>
              </Menu.Item>
              }
              {_.includes(this.props.user.admin_sections, "terms") &&
              <Menu.Item key="terms">
                <Link to={`/admin/terms`}>
                  <Icon type="file-text"/>
                  <span>Умови вступу</span>
                </Link>
              </Menu.Item>
              }
            </Menu.SubMenu>
            {_.includes(this.props.user.admin_sections, "teams") &&
            <Menu.SubMenu
              key="teams"
              title={<span><Icon type="team"/><span>Збірні</span></span>}
            >
              <Menu.Item key="national">
                <Link to={`/admin/teams/national`}>
                  <Icon type="team"/>
                  <span>Національна</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="youth">
                <Link to={`/admin/teams/youth`}>
                  <Icon type="team"/>
                  <span>Молодіжна</span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            }
            {canViewStats &&
            <Menu.SubMenu
              key="statistics"
              title={<span><Icon
                type="bar-chart"/><span>Статистика</span></span>}
            >
              {_.includes(this.props.user.admin_sections, "regions") &&
              <Menu.Item key="agencies">
                <Link to={`/admin/agencies`}>
                  <Icon type="global"/>
                  <span>Представництва</span>
                </Link>
              </Menu.Item>
              }
              {_.includes(this.props.user.admin_sections, "clubs") &&
              <Menu.Item key="clubs">
                <Link to={`/admin/clubs`}>
                  <Icon type="gold"/>
                  <span>Клуби</span>
                </Link>
              </Menu.Item>
              }
              {_.includes(this.props.user.admin_sections, "athletes") &&
              <Menu.Item key="athletes">
                <Link to={`/admin/athletes`}>
                  <Icon type="team"/>
                  <span>Спротсмени</span>
                </Link>
              </Menu.Item>
              }
              {_.includes(this.props.user.admin_sections, "trainers") &&
              <Menu.Item key="trainers">
                <Link to={`/admin/trainers`}>
                  <Icon type="user"/>
                  <span>Тренери</span>
                </Link>
              </Menu.Item>
              }
              {_.includes(this.props.user.admin_sections, "referees") &&
              <Menu.Item key="referees">
                <Link to={`/admin/referees`}>
                  <Icon type="solution"/>
                  <span>Судді</span>
                </Link>
              </Menu.Item>
              }
            </Menu.SubMenu>
            }
            {canViewCert &&
            <Menu.SubMenu
              key="certifications"
              title={
                <span>
                  <Icon type="book"/>
                  <span>Атестації</span>
                </span>
              }
            >
              {_.includes(this.props.user.admin_sections, "athletes_certification") &&
              <Menu.Item key="cert_athletes">
                <Link to={`/admin/certifications/athletes`}>
                  <Icon type="team"/>
                  <span>Cпротсмени</span>
                </Link>
              </Menu.Item>
              }
              {_.includes(this.props.user.admin_sections, "trainers_certification") &&
              <Menu.Item key="cert_trainers">
                <Link to={`/admin/certifications/trainers`}>
                  <Icon type="user"/>
                  <span>Тренери</span>
                </Link>
              </Menu.Item>
              }
              {_.includes(this.props.user.admin_sections, "referees_certification") &&
              <Menu.Item key="cert_referees">
                <Link to={`/admin/certifications/referees`}>
                  <Icon type="solution"/>
                  <span>Судді</span>
                </Link>
              </Menu.Item>
              }
            </Menu.SubMenu>
            }
            {_.includes(this.props.user.admin_sections, "partners") &&
            <Menu.Item key="partners">
              <Link to={`/admin/partners`}>
                <Icon type="share-alt"/>
                <span>Партнери</span>
              </Link>
            </Menu.Item>
            }
            {_.includes(this.props.user.admin_sections, "tournaments") &&
            <Menu.Item key="tournaments">
              <Link to={`/admin/tournaments`}>
                <Icon type="global"/>
                <span>Закордонні турніри</span>
              </Link>
            </Menu.Item>
            }
            {_.includes(this.props.user.admin_sections, "banners") &&
            <Menu.Item key="banners">
              <Link to={`/admin/banners`}>
                <Icon type="switcher"/>
                <span>Банера</span>
              </Link>
            </Menu.Item>
            }
            {_.includes(this.props.user.admin_sections, "belts") &&
            <Menu.Item key="belts">
              <Link to={`/admin/belts`}>
                <Icon type="file-protect"/>
                <span>Почесні пояcи</span>
              </Link>
            </Menu.Item>
            }
            <Menu.SubMenu
              key="syncs"
              title={
                <span>
                  <Icon type="book"/>
                  <span>Синхронізація</span>
                </span>
              }
            >
            {_.includes(this.props.user.admin_sections, "users") &&
              <Menu.Item key="syncs_athletes">
                <Link to={`/admin/syncs/athletes`}>
                  <Icon type="file-protect"/>
                  <span>Спортсмени</span>
                </Link>
              </Menu.Item>
            }
              {_.includes(this.props.user.admin_sections, "users") &&
                <Menu.Item key="syncs_trainer">
                  <Link to={`/admin/syncs/trainers`}>
                    <Icon type="file-protect"/>
                    <span>Тренери</span>
                  </Link>
                </Menu.Item>
              }
            </Menu.SubMenu>
          </Menu>
        </Sider>
        <Layout style={{ minHeight: '100vh', overflow: 'scroll' }}>
          <Header style={{ background: '#fff', padding: 0 }}>
            <Icon
              style={{fontSize: "30px", cursor: "pointer", marginLeft: "17px", marginTop: "17px"}}
              className="trigger"
              type={this.props.menuCollapsed ? 'menu-unfold' : 'menu-fold'}
              onClick={this.onToggleMenu}
            />
            <div style={{width: "150px", float: "right"}}>
              <Button type="link" onClick={this.onLogout}>
                <Icon type="logout"/>
                <span> Вихід</span>
              </Button>
            </div>
          </Header>
          <Content style={{ margin: '24px 16px', padding: 24, background: '#fff' }}>
            {this.props.children}
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            Ukrainian Karate Federation ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

export default BaseLayout;
