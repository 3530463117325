

const actionTypes = {
  USERS_SET_LOADING: 'USERS_SET_LOADING',
  USERS_SET_USERS: 'USERS_SET_USERS',
  USERS_SET_ERRORS: 'USER_SET_ERRORS',
  USERS_SET_USER: 'USERS_SET_USER',
  USERS_SHOW_FORM: 'USERS_SHOW_FORM',
  USERS_HIDE_FORM: 'USERS_HIDE_FORM',
  USERS_SET_INITIAL_USER: 'USERS_SET_INITIAL_USER',
  USERS_CHANGE_INPUT: 'USERS_CHANGE_INPUT',
  USERS_SET_SECTIONS: 'USERS_SET_SECTIONS',
  USERS_SET_SELECTED_SECTION: 'USERS_SET_SELECTED_SECTION',
};

export default actionTypes;