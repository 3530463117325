import _ from "lodash";
import React from "react";
import {Avatar, Breadcrumb, Button, Card, Cascader, Col, Input, List, Modal, Pagination, Row, Select} from "antd";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {formatDate, getBaseUrl, getNoun, getServiceDomain} from "../../../../utils";
import {formatCategory, prepareFilterOptions, TRAINER_CATEGORY_ENUM, TRAINER_CATEGORY_TITLE} from "../utils";
import axios from "axios";

import TrainerDescription from "../components/TrainerDescription";

import './TrainersListView.css';


const toQueryString = (params) => Object.entries(params).map((q) => q.join('=')).join('&');

const IconText = ({ text }) => (
  <span>
    {text}
  </span>
);

export default class TrainersListView extends React.Component {


  constructor(props) {
    super(props);
    this.debouncedSuggest = _.debounce(this.fetchData, 1500, { 'maxWait': 4 });
    this.state = {
      loading: true,
      trainers: [],
      region_id: '',
      club_id: '',
      category: undefined,
      query: '',
      visibleItem: null,
      page: 1,
      pages: 0,
      total: 0,
    };
  }

  componentDidMount() {
    const params = this.assignParams({});
    this.fetchData(params);
    this.getData(`${getBaseUrl()}/public/agencies`, (resAgencies) => {
      this.getData(`${getBaseUrl()}/public/clubs`, (resClubs) => {
        this.setState({
          loading: false,
          filters: prepareFilterOptions(resAgencies.data.agencies, resClubs.data.clubs),
        });
      });
    });
  }

  fetchData = (params) => {
    const queryString = toQueryString(params);
    this.getData(`${getBaseUrl()}/public/trainers?${queryString}`, (res) => {

      this.setState({
        loading: false,
        trainers: res.data.trainers.sort((a, b) => {
          return a.last_name.localeCompare(b.last_name, undefined, {sensitivity: 'base'});
        }),
        page: res.data.paginator.page,
        pages: res.data.paginator.pages,
        total: res.data.paginator.total,
        page_size: res.data.paginator.page_size,
      });
    });
  };

  getData = (url, callback) => {
    axios.get(url)
      .then((res) => {
        callback(res);
      });
  };

  assignParams = (params) => {
    const defaultParams = {
      region_id: this.state.region_id,
      club_id: this.state.club_id,
      query: this.state.query,
      page: 1,
      category: this.state.category
    };
    return Object.assign(defaultParams, params)
  };


  onClickItem = (item) => {
    this.setState({
      visibleItem: item
    })
  };

  onCancel = () => {
    this.setState({
      visibleItem: null,
    });
  };

  onChangePage = (page) => {
    const params = this.assignParams({page: page});
    this.setState({...params, loading: true});
    this.debouncedSuggest(params);
  };

  onChangeCategory = (option) => {
    const params = this.assignParams({category: option});
    this.setState({...params, loading: true});
    this.debouncedSuggest(params);
  };

  onSelectFilter = (d) => {
    const [regionId, clubId] = d;
    const params = this.assignParams({region_id: regionId, club_id: clubId});
    this.setState({...params, loading: true});
    this.debouncedSuggest(params);
  };

  onSearch = (e) => {
    const params = this.assignParams({query: e.target.value});
    this.setState({...params, loading: true});
    this.debouncedSuggest(params);
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Тренери - Українська Федерація карате</title>
        </Helmet>
        <Breadcrumb style={{ marginTop: '20px', marginBottom: "5px" }}>
          <Breadcrumb.Item>
            <Link to="/">
              {getServiceDomain()}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Тренери
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          size="small"
          bordered={false}
          title="Список тренерів"
        >
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div style={{ width: '100%', paddingTop: "10px" }}>
                <Input.Search
                  allowClear
                  size="large"
                  placeholder="Введіть ім'я або прізвище"
                  onChange={this.onSearch}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div style={{ width: '100%', paddingTop: "10px" }}>
                <Cascader
                  size="large"
                  expandTrigger="hover"
                  placeholder={"Виберіть фільтр"}
                  changeOnSelect
                  style={{ width: '100%' }}
                  options={this.state.filters}
                  onChange={this.onSelectFilter}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div style={{ width: '100%', paddingTop: "10px" }}>
                <Select
                  allowClear
                  size="large"
                  style={{ width: '100%' }}
                  onChange={this.onChangeCategory}
                  value={this.state.category}
                  placeholder="Виберіть категорію"
                >
                  <Select.Option value={TRAINER_CATEGORY_ENUM.high}>
                    {TRAINER_CATEGORY_TITLE[TRAINER_CATEGORY_ENUM.high]}
                  </Select.Option>
                  <Select.Option value={TRAINER_CATEGORY_ENUM.first}>
                    {TRAINER_CATEGORY_TITLE[TRAINER_CATEGORY_ENUM.first]}
                  </Select.Option>
                  <Select.Option value={TRAINER_CATEGORY_ENUM.second}>
                    {TRAINER_CATEGORY_TITLE[TRAINER_CATEGORY_ENUM.second]}
                  </Select.Option>
                  <Select.Option value={TRAINER_CATEGORY_ENUM.third}>
                    {TRAINER_CATEGORY_TITLE[TRAINER_CATEGORY_ENUM.third]}
                  </Select.Option>
                  <Select.Option value={TRAINER_CATEGORY_ENUM.no}>
                    Без категоії
                  </Select.Option>
                </Select>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{paddingTop: "15px"}}>
                <b>{this.state.total}</b> {getNoun(this.state.total, 'тренер', 'тренера', 'тренерів')}
              </div>
            </Col>
          </Row>
          <br/>
        </Card>
        <Card
          loading={this.state.loading}
        >
          <Row type="flex" justify="space-around" align="middle">
            <Col>
              {this.state.total > 0 &&
              <div style={{marginBottom: "15px"}}>
                <Pagination
                  current={this.state.page}
                  total={this.state.total}
                  pageSize={this.state.page_size}
                  onChange={this.onChangePage}
                />
              </div>
              }
            </Col>
          </Row>
          <Row>
            <List
              size="middle"
              dataSource={this.state.trainers}
              locale={{emptyText: "Немає даних"}}
              renderItem={item => (
                <List.Item
                  key={item.id}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={item.logo && item.logo.url}
                        srcSet={item.logo && item.logo.url}
                        size={100}
                        shape="square"
                      >
                        Avatar
                      </Avatar>
                    }
                    title={
                      <Button
                        size="large"
                        type="link"
                        onClick={() => this.onClickItem(item)}
                      >
                        <span style={{whiteSpace: "normal"}}>{item.last_name} {item.first_name} {item.middle_name}</span>
                      </Button>
                    }
                    description={

                      <div style={{marginLeft: "15px"}}>
                        <Row type="flex" justify="start">
                          <Col xs={24} sm={24} md={6} lg={6} xl={6}><IconText text={<span><b>Клуб: </b>{item.club_name}</span>} /></Col>
                          <Col xs={24} sm={24} md={6} lg={6} xl={6}><IconText text={<span><b>Регіон: </b>{item.region_name}</span>} /></Col>
                          <Col xs={24} sm={24} md={6} lg={6} xl={6}><IconText text={<span><b>Cтрок ліцензії: </b>{item.license_term ? formatDate(item.license_term) : 'Данні відсутні'}</span>} /></Col>
                          <Col xs={24} sm={24} md={6} lg={6} xl={6}><IconText text={<span><b>Категорія: </b>{formatCategory(item.category)}</span>} /></Col>
                        </Row>
                      </div>

                    }
                  />
                </List.Item>
              )}
            />
          </Row>
          <Row type="flex" justify="space-around" align="middle">
            <Col>
              {this.state.total > 0 &&
              <div style={{marginTop: "20px"}}>
                <Pagination
                  current={this.state.page}
                  total={this.state.total}
                  pageSize={this.state.page_size}
                  onChange={this.onChangePage}
                />
              </div>
              }
            </Col>
          </Row>
        </Card>
        {!!this.state.visibleItem &&
        <Modal
          title={`${this.state.visibleItem.last_name} ${this.state.visibleItem.first_name} ${this.state.visibleItem.middle_name}`}
          visible={!!this.state.visibleItem}
          onCancel={this.onCancel}
          footer={null}
        >
          <TrainerDescription
            logo={this.state.visibleItem.logo}
            region={this.state.visibleItem.region_name}
            club={this.state.visibleItem.club_name}
            category={this.state.visibleItem.category}
            birthDate={this.state.visibleItem.birth_date}
            licenseTerm={this.state.visibleItem.license_term}
          />
        </Modal>
        }
      </React.Fragment>
    );
  }
}
