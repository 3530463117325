import React from "react";
import {Route, Switch} from "react-router-dom";
import PublicBaseLayout from "../../../components/BaseLayout/PublicBaseLayout";
import TrainersListView from "./views/TrainersListView";

export default class Trainers extends React.Component {

  render() {
    return (
      <PublicBaseLayout>
        <Switch>
          <Route
            exact
            path={"/members/trainers"}
            component={TrainersListView}
          />
        </Switch>
      </PublicBaseLayout>
    );
  }
}
