import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "antd";

export default function IModalForm(props) {
  const footer = props.showFooter
    ? [
        <Button key="back" onClick={props.onCancel}>
          Відмінити
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={props.onSubmit}
          loading={props.loading}
        >
          {props.submitText ? props.submitText : "Зберегти"}
        </Button>
      ]
    : [];
  return (
    <Modal
      title={props.title}
      visible={props.showModal}
      onCancel={props.onCancel}
      width={props.width || "50%"}
      footer={footer}
    >
      {props.children}
    </Modal>
  );
}

IModalForm.defaultProps = {
  showFooter: true,
};

IModalForm.propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};
