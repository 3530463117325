import axios from "axios";
import Moment from "moment";
import {getBaseUrl} from "../../utils";
import "./CompetitionsCalendar.css";
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

export const changePanel = (value, mode) => {
  return (dispatch, getState) => {
    dispatch({
      type: "CHANGE_PANEL",
      date: value,
      mode: mode,
    });
    fetchEvent(value)(dispatch, getState)
  }
};


export const fetchEvent = (date) => {
  return (dispatch, getState) => {
    dispatch({
      type: "LOADING",
      loading: true,
    });

    const url = `${getBaseUrl()}/public/get-events`;
    axios.get(url, {
      params: {
        year: date.format("YYYY"),
        month: date.format("M"),
      }
    })
      .then((res) => {
        const events = res.data.events;
        const startOfMonth = moment(date).startOf('month');
        const endOfMonth   = moment(date).endOf('month');
        const dayEventsMap = {};

        for (const m = moment(startOfMonth); m.isBefore(endOfMonth); m.add(1, 'days')) {
          dayEventsMap[m.date()] = events.filter((e) => {
            const startDate = moment(e.date_started);
            const endDate = moment(e.date_expired);
            const range = moment().range(startDate, endDate);
            return range.contains(m);
          });
        }

        dispatch({
          type: "SET_CALENDAR_EVENTS",
          events: res.data.events,
          dayEventsMap: dayEventsMap,
        })
      });
  }
};

export function saveCalendar(year) {
  return (dispatch, getState) => {
    dispatch({
      type: "LOADING",
      loading: true,
    });
    const data = {
      year: year,
    };
    const url = `${getBaseUrl()}/public/generate-calendar`;
    axios.post(url, data)
      .then((res) => {
        if (res.data.status === 'ok') {
          dispatch({
            type: "CALENDAR_SET_PDF_URL",
            pdfUrl: res.data.pdf_url,
          })
        } else {
          dispatch({
            type: "CALENDAR_SET_ERRORS",
            errors: res.data.errors,
          })
        }
      })
  }
}

export const fetchDateChoices = () => {
  return (dispatch, getState) => {
    const url = `${getBaseUrl()}/public/events/date-choices`;
    axios.get(url)
      .then((res) => {
        if (res.data.status === 'ok') {
          dispatch({
            type: "CALENDAR_SET_DATE_CHOICES",
            dateChoices: res.data.date_choices,
          })
        }
      });
  }
};