import actionTypes from './actionTypes';
import tournamentService from '../../services/admin/tournaments';
import {getSubmitData} from './utils';
import {message} from 'antd';

const SERVER_BASE_ERROR_MSG = {level: 'error', text: "Error on server"};
const SUCCESS_UPDATE_MSG = {level: 'success', text: "Успішно збережено"};
const SUCCESS_DELETED_MSG = {level: 'success', text: "Успішно видалено"};

function onInit() {
  return async (dispatch) => {
    onSetLoading(true)(dispatch);
    try {
      await fetchAll()(dispatch);
      onSetLoading(false)(dispatch)
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}

function fetchAll() {
  return async (dispatch) => {
    try {
      const res = await tournamentService.getAll();
      dispatch({
        type: actionTypes.SET_TOURNAMENTS,
        tournaments: res.data.tournaments
      });
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  }
}


function setInitial() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TOURNAMENTS_SET_INITIAL
    })
  }
}


function onAdd() {
  return (dispatch) => {
    setInitial()(dispatch);
    showModal(true)(dispatch)
  }
}

function showModal(visible) {
  return (dispatch) => {
    if (visible) {
      dispatch({
        type: actionTypes.TOURNAMENTS_SHOW_FORM
      })
    } else {
      dispatch({
        type: actionTypes.TOURNAMENTS_HIDE_FORM
      })
    }

  }
}


function onCancel() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TOURNAMENTS_HIDE_FORM
    })
  }
}


function onChangeInput(name, value) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TOURNAMENTS_CHANGE_INPUT,
      name: name,
      value: value,
    })
  }
}

function onSetLoading(loading) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOADING,
      loading: loading,
    })
  }
}


export const onUpload = (e) => {
  return async (dispatch) => {
    onSetLoading(true)(dispatch);
    try {
      const res = await tournamentService.postFile(e.file);
      dispatch({
        type: actionTypes.TOURNAMENTS_SET_FILE,
        file: res.data.file,
      })
    } catch (err) {
      showMessage({level: 'error', text: "Error on upload file"})(dispatch);
    }
  }
};


function onSubmit() {
  return async (dispatch, getState) => {
    onSetLoading(true)(dispatch);
    try {
      const state = getState().tournaments;
      const data = getSubmitData(state);
      let res = null;
      if (state.editMode && state.tournament) {
        res = await tournamentService.save(state.tournament.id, data);
      } else {
        res = await tournamentService.add(data);
      }
      onSetLoading(false)(dispatch);
      if (res.data.status === 'ok') {
        fetchAll()(dispatch);
        showModal(false)(dispatch);
        showMessage(SUCCESS_UPDATE_MSG)(dispatch);
      } else {
        setErrors(res.data.errors)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}


export const setErrors = (errors) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.TOURNAMENTS_SET_ERRORS,
      errors: errors,
    })
  }
};


export const showMessage = (msg) => {
  return async (dispatch) => {
    message[msg.level](msg.text)
  }
};


function onEdit(itemId) {
  return async (dispatch) => {
    try {
      const res = await tournamentService.getById(itemId);
      if (res.data.status === 'ok') {
        setItem(res.data.tournament)(dispatch);
        showModal(true)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}


function onDelete(itemId) {
  return async (dispatch) => {
    try {
      onSetLoading(true)(dispatch);
      const res = await tournamentService.remove(itemId);
      if (res.data.status === 'ok') {
        await fetchAll()(dispatch);
        onSetLoading(false)(dispatch);
        showMessage(SUCCESS_DELETED_MSG)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}

export const setItem = (tournament) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.TOURNAMENTS_SET,
      tournament: tournament,
    })
  }
};


export default {
  onInit,
  onAdd,
  onCancel,
  onUpload,
  onChangeInput,
  onSubmit,
  onEdit,
  onDelete,
}
