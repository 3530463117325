import React from "react";
import {Link} from "react-router-dom";
import {Badge, Breadcrumb, Button, Card, Col, Divider, Icon, Row, Pagination} from "antd";
import moment from "moment";
import PublicBaseLayout from "../BaseLayout/PublicBaseLayout";
import {getNews} from "../../services/public/news"
import {Helmet} from "react-helmet";
import "./NewsList.css";
import {getServiceDomain} from "../../utils";
import {toQueryString} from "../../admin/components/Filters/FilterProvider";

export default class NewsList extends React.Component {

  constructor(props) {
    super(props);
    this.fetchNews = this.fetchNews.bind(this);
    this.state = {}
  }

  static async getInitialProps() {
    try {
      const res = await getNews();
      return { news: res.data.news, paginator: res.data.paginator};
    } catch (err) {
      console.error(err)
    }
  }

  async fetchNews(page) {
    const queryString = toQueryString({page: page});
    const res = await getNews(queryString);
    this.setState({
      news: res.data.news,
      paginator: res.data.paginator,
    });
    window.scrollTo(0, 0);
  }

  render() {
    const news = this.state.news || this.props.news;
    const paginator = this.state.paginator || this.props.paginator;
    return (
      <PublicBaseLayout>
        <Helmet>
          <title>Основнi новини - Українська Федерація карате</title>
        </Helmet>
        <Breadcrumb style={{ marginTop: '20px', marginBottom: "5px" }}>
          <Breadcrumb.Item>
            <Link to="/">
              {getServiceDomain()}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Новини
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col>
            <Card >
              Останні новини
            </Card>
          </Col>
        </Row>
        {news && news.map((n) =>
          <Row key={n.id}>
            <Col>
              <Card loading={!news}>
                <Row>
                  <Col span={6}>
                    <img width={"100%"} alt="logo" src={n.picture_url} />
                  </Col>
                  <Col span={17} offset={1}>
                    <Link to={`/news/${n.slug}`}>
                      <h4 className="news-title">{n.title}</h4>
                    </Link>
                    <Badge
                      count={moment(n.date_started).format("DD.MM.YYYY")}
                      style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }}
                    />
                    <div style={{marginTop: "5px"}}>
                      {n.intro}
                    </div>
                    <Divider orientation="right">
                      <Button>
                        <Link to={`/news/${n.slug}`}>
                          Детальніше
                          <Icon type="right" />
                        </Link>
                      </Button>
                    </Divider>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
        <Card >
          <Row type="flex" justify="space-around" align="middle">
            <Col>
              {paginator && paginator.total > 0 &&

              <div style={{marginTop: "20px"}}>
                <Pagination
                  current={paginator.page}
                  total={paginator.total}
                  pageSize={paginator.page_size}
                  onChange={this.fetchNews}
                />
              </div>

              }
            </Col>
          </Row>
        </Card>
      </PublicBaseLayout>
    );
  }
}
