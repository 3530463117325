import React from "react";
import {Avatar, Col, Descriptions, Row, Divider, Table} from "antd";
import {calculateAge, formatDate} from "../../utils";
import {getRankTitle} from "../../admin/trainer_certifications/components/MemberForm/utils";


const columns = [
  {
    title: 'Дата',
    dataIndex: 'date_exam',
    width: 100,
    render: (t, r) => formatDate(r.date_exam),
  },
  {
    title: 'Номер сертифікату',
    dataIndex: 'certificate_num',
    width: 180,
  },
  {
    title: 'Категорія',
    dataIndex: 'rank',
    render: (t, r) => getRankTitle(r.rank),
  },
];

const TrainerDescription = ({trainer, certifications}) => {
  return (
    <React.Fragment>
      <Row gutter={24}>
        <Col span={10}>
          <Avatar
            src={trainer.logo && trainer.logo.url}
            style={{margin: "0 auto", display: "block"}}
            shape="square"
            size={150}
          >
            Avatar
          </Avatar>
        </Col>
        <Col span={14}>
          <Descriptions
            size="small"
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          >
            <Descriptions.Item label="ПІБ">{trainer.full_name}</Descriptions.Item>
            <Descriptions.Item label="Дата народження">{formatDate(trainer.birth_date)} ({calculateAge(new Date(trainer.birth_date))})</Descriptions.Item>
            <Descriptions.Item label="Регіон">{trainer.region_name}</Descriptions.Item>
            <Descriptions.Item label="Клуб">{trainer.club_name}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Divider><b>Сертифікати</b></Divider>
      <Row type="flex" justify="space-around" align="middle">
        <Col span={24}>
          <Table
            scroll={{ x: 350 }}
            rowKey="id"
            columns={columns}
            dataSource={certifications}
            size="middle"
            locale={{emptyText: "Немає данних"}}
            pagination={false}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};


export default TrainerDescription;
