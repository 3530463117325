import {getBaseUrl} from "../../utils";
import io from "../../lib/io";

export async function add(data) {
  return await io.post(`${getBaseUrl()}/admin/athlete-certifications`, data);
}

export function getAllUrl(queryString) {
  const query = queryString ? `?${queryString}` : '';
  return `${getBaseUrl()}/admin/athlete-certifications${query}`;
}


export function getRemoveUrl(id) {
  return `${getBaseUrl()}/admin/athlete-certifications/${id}/delete`;
}


export async function getAll() {
  return await io.get(getAllUrl());
}


export async function save(id, data) {
  return await io.post(`${getBaseUrl()}/admin/athlete-certifications/${id}/edit`, data);
}


export async function getById(id) {
  return await io.get(`${getBaseUrl()}/admin/athlete-certifications/${id}`);
}


export async function remove(id) {
  return await io.post(getRemoveUrl(id));
}


export async function getCertificatedMembers(certID, queryString) {
  const query = queryString ? `&${queryString}` : '';
  return await io.get(`${getBaseUrl()}/admin/certified-athletes?cert_id=${certID}${query}`);
}


export function getDownloadUrl(id) {
  return `${getBaseUrl()}/admin/athlete-certifications/${id}/download`;
}


export async function getCertifiedAthlete(certificateNum) {
  return await io.get(`${getBaseUrl()}/admin/athlete-certifications/get-certified-athlete?certification_num=${certificateNum}`);
}


export async function getAthleteCertifications(athleteId) {
  return await io.get(`${getBaseUrl()}/admin/athlete-certifications/get-athlete-certifications/${athleteId}`);
}


export default {
  getAllUrl,
  add,
  getAll,
  save,
  getById,
  remove,
  getRemoveUrl,
  getCertificatedMembers,
  getDownloadUrl,
  getCertifiedAthlete,
  getAthleteCertifications,
}
