import React from 'react';
import { Row, Col, Input, Cascader, DatePicker, Button, Divider } from 'antd';
import locale from 'antd/lib/date-picker/locale/uk_UA';


const { Search } = Input;
const { RangePicker } = DatePicker;

const ColProps = {
  xs: 24,
  sm: 12,
  style: {
    marginBottom: 16,
  },
};

const TwoColProps = {
  ...ColProps,
  xl: 96,
};

const options = [{
  value: 'test',
  label: 'test',
  children: [{
    value: 'test',
    label: 'test',
    children: [{
      value: 'test',
      label: 'test',
    }],
  }],
},];


export default class AdminFilters extends React.Component {

  render() {
    return (
      <Row gutter={24}>
        <Col {...ColProps} xl={{ span: 4 }} md={{ span: 8 }}>
          <Search placeholder="Введіть назву" />
        </Col>
        <Col {...ColProps} xl={{ span: 4 }} md={{ span: 8 }} id="addressCascader">
          <Cascader
            placeholder={"Виберіть фільтр"}
            style={{ width: '100%' }}
            options={options}
          />
        </Col>
        <Col {...ColProps} xl={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} id="createTimeRangePicker">
          <RangePicker
            style={{ width: '100%' }}
            locale={locale}
          />
        </Col>
        <Col {...TwoColProps} xl={{ span: 10 }} md={{ span: 24 }} sm={{ span: 24 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div>
              <Button icon="search" type="primary" className="margin-right">Пошук</Button>
              <Divider type="vertical"/>
              <Button icon="close">Скинути</Button>
            </div>
            {this.props.showDownload &&
            <div className="flex-vertical-center">
              <Button
                type="primary"
                icon="file-excel"
                onClick={this.props.onDownload}>
                Завантажити
              </Button>
            </div>
            }
            <div className="flex-vertical-center">
              <Button type="primary" icon="plus" onClick={this.props.onAdd}>
                Додати
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
