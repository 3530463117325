import {getBaseUrl} from "../../utils";
import io from "../../lib/io";


export function getCurrentUrl(type) {
  return `${getBaseUrl()}/public/teams/current?type=${type}`;
}

export function getAllUrl(type) {
  return `${getBaseUrl()}/public/teams?type=${type}`;
}

export function getTeamUrl(id) {
  return `${getBaseUrl()}/public/teams/${id}`;
}

export async function getCurrent(type) {
  return await io.get(getCurrentUrl(type));
}

export async function getAll(type) {
  return await io.get(getAllUrl(type));
}

export async function getTeam(id) {
  return await io.get(getTeamUrl(id));
}

export default {
  getCurrentUrl,
  getCurrent,
  getAll,
  getTeamUrl,
  getTeam,
}
