import _ from 'lodash';
import React from "react";
import {Spin} from "antd";
import {Redirect} from "react-router-dom";


export default (admin_section) => (WrappedComponent) => {
  class AdminCanWrapped extends React.Component {

    render() {
      if (!this.props.auth.user) {
        return (
          <div style={{position:"fixed", top:"50%", left: "50%"}}>
            <Spin tip="Завантаження..." spinning={true} size="large"/>
          </div>
        )
      }
      if (!_.includes(this.props.auth.user.admin_sections, admin_section)) {
        return <Redirect to="/admin" />
      } else {
        return (
          <WrappedComponent
            {...this.props}
          />
        )
      }

    }
  }

  return AdminCanWrapped;
};
