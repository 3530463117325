import React from 'react';
import {connect} from 'react-redux';
import UserList from './UserList';
import actions from './actions';
import AdminBaseLayoutContainer from "../../components/BaseLayout/AdminBaseLayoutContainer";


const mapStateToProps = state => {
  return {
    ...state.users,
  };
};

const mapDispatchToProps = actions;

class BannerListContainer extends React.Component {

  componentDidMount() {
    this.props.onInit()
  }

  render() {
    return (
      <AdminBaseLayoutContainer>
        <UserList {...this.props}/>
      </AdminBaseLayoutContainer>
    )
  }

}


export default connect(mapStateToProps, mapDispatchToProps)(BannerListContainer);
