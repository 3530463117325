import React from "react";
import {Breadcrumb, Card, Col, Collapse, Icon, List, Popover, Row, Avatar} from "antd";
import {Link} from "react-router-dom";
import axios from "axios";
import {Helmet} from "react-helmet";
import {getBaseUrl, getServiceDomain} from "../../utils";
import "./StructureList.css";
import PublicBaseLayout from "../BaseLayout/PublicBaseLayout";

const Panel = Collapse.Panel;


function renderMemberItem(member) {
  return (
    <div className="member-items">
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar src={member.file ? member.file.url: null} style={{objectFit: "cover"}} />}
          title={
            <Popover
              trigger="hover"
              placement="right"
              content={
                <Card
                  hoverable
                  style={{ width: 300 }}
                  cover={
                    <img
                      alt={member.full_name}
                      src={member.file ? member.file.url: null}
                    />
                  }
                >
                  <Card.Meta
                    title={member.full_name}
                    description={
                      <div>
                        {member.post_description ? <span><Icon type="idcard"/> {member.post_description}</span>: null}
                        <br/>
                        {member.email ? <span><Icon type="mail"/> {member.email}</span>: null}
                        <br/>
                        {member.phone ? <span><Icon type="phone"/> {member.phone}</span>: null}
                      </div>
                    }
                  />
                </Card>
              }
            >
              <a>{member.full_name}</a>
            </Popover>
          }
        />
      </List.Item>
    </div>
  );
}

export default class StructureList extends React.Component {

  state = {
    loading: true,
    structures_tree: [],
    structures: [],
  };

  componentDidMount() {
    this.getData((res) => {
      this.setState({
        loading: false,
        structures_tree: res.data.structures_tree,
      });
    });

    this.getStructures((res) => {
      this.setState({
        loading: false,
        structures: res.data.structures,
      });
    });
  }

  getData = (callback) => {
    const url = `${getBaseUrl()}/public/structures/tree`;
    axios.get(url)
      .then((res) => {
        callback(res);
      });
  };

  getStructures = (callback) => {
    const url = `${getBaseUrl()}/public/structures`;
    axios.get(url)
      .then((res) => {
        callback(res);
      });
  };

  renderTree = (item) => {
    if (item.is_pseudo_parent) {
      return (
        <ul className="pseudo-parent" style={{padding: "unset"}}>
          {this.renderTreeNodes(item.children)}
        </ul>
      );
    }
    return (
      <ul>
        {this.renderTreeNodes(item.children)}
      </ul>
    );
  };

  renderTreeNodes = (data) => {
    return data.map((item) => {
      let activeKey = null;
      if (item.parent_id === null && item.members.length > 0)  {
        activeKey = String(item.id)
      }
      return (
        <li key={item.id} style={{margin: "0 auto", width: `${(100 / data.length) - 2}%`}}>
          <Collapse defaultActiveKey={activeKey} className="tree-block" bordered={false}>
            <Panel
              key={String(item.id)}
              style={{border: 0}}
              disabled={item.members.length === 0}
              showArrow={false}
              bordered={false}
              header={<b style={{fontSize: "16px", color: '#4D69AC', marginRight: "12px"}}>{item.title}</b>}
            >
              <div className="member-items">
                <List
                  bordered={false}
                  size="small"
                  dataSource={item.members}
                  renderItem={renderMemberItem}
                />
              </div>
            </Panel>
          </Collapse>
          {item.children.length > 0 && this.renderTree(item)}
        </li>

      );
    });
  };


  render() {
    return (
      <PublicBaseLayout>
        <Helmet>
          <title>Структура та керівництво - Українська Федерація карате</title>
        </Helmet>
        <Breadcrumb style={{ marginTop: "20px", marginBottom: "5px" }}>
          <Breadcrumb.Item>
            <Link to="/">
              {getServiceDomain()}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Структура</Breadcrumb.Item>
        </Breadcrumb>
        {this.state.structures_tree.map(t =>
          <Row key={t.id}>
            <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>
              <Card
                bordered={false}
              >
                <div>
                  <div className="tree">
                    <ul>
                      {this.renderTreeNodes([t])}
                    </ul>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={24} sm={24} md={24} lg={0} xl={0} xll={0}>
            <Collapse>
              {this.state.structures.map((item) =>
                <Panel key={item.id} header={item.title}>
                  <List
                    size="small"
                    itemLayout="horizontal"
                    dataSource={item.members}
                    renderItem={renderMemberItem}
                  />
                </Panel>
              )}
            </Collapse>
          </Col>
        </Row>
      </PublicBaseLayout>
    );
  }
}
