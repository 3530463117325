
const sortByAlphabet = (a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});

const prepareOption = (value, label, children) => {return {value, label, children}};

const prepareToMap = (acc, curr) => {
  const option = prepareOption(curr.id, curr.short_name);
  if (!acc[curr.region_id]) {
    acc[curr.region_id] = [].concat(option)
  } else {
    acc[curr.region_id] = acc[curr.region_id].concat(option);
  }
  return acc
};

export const prepareFilterOptions = (regions, clubs) => {
  const regionClubMap = clubs.reduce(prepareToMap, {});
  return regions
    .sort(sortByAlphabet)
    .map((r) => {return prepareOption(r.id, r.name, regionClubMap[r.id])})
};

export const TRAINER_CATEGORY_ENUM = {
  high: "CATEGORY_HIGH",
  first: "CATEGORY_1",
  second: "CATEGORY_2",
  third: "CATEGORY_3",
  none: "NONE",
  no: 'NO'
};

export const TRAINER_CATEGORY_TITLE = {
  [TRAINER_CATEGORY_ENUM.high]: 'Вища категорія',
  [TRAINER_CATEGORY_ENUM.first]: '1 категорія',
  [TRAINER_CATEGORY_ENUM.second]: '2 категорія',
  [TRAINER_CATEGORY_ENUM.third]: '3 категорія',
  [TRAINER_CATEGORY_ENUM.none]: 'Данні відсутні',
  [TRAINER_CATEGORY_ENUM.no]: 'Данні відсутні',
};

export const formatCategory = (category) => {
  return TRAINER_CATEGORY_TITLE[category] || 'Данні відсутні'
};
