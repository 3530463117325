import React from "react";
import {Avatar, Divider, Spin, Table} from "antd";

import AdminFilters from "../../components/AdminFilters";
import IModalForm from "../../components/IModalForm";
import PartnerForm from "./PartnerForm";
import AdminActionDropdown from "../../components/AdminActionDropdown";
import {formatDate} from "../../utils";

function getEventStatusTitle(status) {
  const statuses =  {
    public: 'Опубліковано',
    draft: 'Черновик'
  };
  return statuses[status] || 'Не вказано'
}


export default class PartnerList extends React.Component {

  render() {
    const columns = [
      {
        title: 'Логотип',
        dataIndex: 'file',
        key: 'file',
        render: (t, r) => <Avatar src={r.file.url}/>
      },
      {
        title: 'Назва',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Посилання',
        dataIndex: 'link',
        key: 'link',
      },
      {
        title: 'Дата створення',
        dataIndex: 'date_created',
        key: 'date_created',
        render: (t, r) => formatDate(t),
      },
      {
        title: 'Дата оновлення',
        dataIndex: 'date_updated',
        key: 'date_updated',
        render: (t, r) => formatDate(t),
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (t, r) => getEventStatusTitle(r.status)
      },
      {
        title: 'Дії',
        dataIndex: 'action',
        key: 'action',
        render: (text, item) => (
          <AdminActionDropdown
            {...this.props}
            item={item}
          />
        ),
      }
    ];
    return (
      <div>
        <h2>Список партнерів</h2>
        <Divider/>
        <AdminFilters {...this.props} />
        <Table
          columns={columns}
          dataSource={this.props.partners}
          rowKey="id"
          loading={this.props.loading}
          locale={{emptyText: "Немає подій"}}
        />
        <IModalForm
          title={this.props.editMode ? "Редагувати партнера" : "Додати партнера"}
          showModal={this.props.showModal}
          onCancel={this.props.onCancel}
          onSubmit={this.props.onSubmit}
        >
          <Spin spinning={this.props.loading} size="large">
            <PartnerForm
              {...this.props}
              onChangeInput={this.props.onChangeInput}
            />
          </Spin>
        </IModalForm>
      </div>
    );
  }
}
