import axios from 'axios';
import {getBaseUrl, SERVER_BASE_ERROR_MSG, SUCCESS_DELETED_MSG,} from '../../utils';
import docSectionService from '../../services/admin/doc-sections';
import {message} from 'antd';
import Cookies from 'js-cookie';

export const changeInput = (name, value) => {
  return (dispatch, getState) => {
    dispatch({
      type: "CHANGE_VALUE",
      name: name,
      value: value,
    })
  }
};

export const fetchDocSections = () => {
  return (dispatch, getState) => {

    const token = Cookies.get('token');

    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }

    const url = `${getBaseUrl()}/admin/docs-sections`;
    axios.get(url, {
      headers: headers
    })
      .then((res) => {
        if (res.data.status === 'ok') {
          dispatch({
            type: "SET_DOC_SECTIONS",
            docs_sections: res.data.docs_sections,
          })
        } else {
          console.error("error request")
        }
      });
  }
};

export const submitForm = (history) => {
  return (dispatch, getState) => {
    dispatch({
      type: "LOADING",
      loading: true,
    });
    const token = Cookies.get('token');
    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }
    const state = getState().docs;
    const data = {
      status: state.status,
      title: state.title,
    };
    let url = '';
    if (state.docs_section && state.editMode) {
      url = `${getBaseUrl()}/admin/docs-sections/${state.docs_section.id}/edit`;
    } else {
      url = `${getBaseUrl()}/admin/docs-sections`;
    }
    axios.post(url, data, {
      headers: headers
    })
      .then((res) => {
        if (res.data.status === 'ok') {
          dispatch({
            type: "LOADING",
            loading: false,
          });
          history.push('/admin/documents')
        } else {
          dispatch({
            type: "SET_ERRORS",
            errors: res.data.errors,
          })
        }
      });
  }
};

export const editDocsSection = (itemId) => {
  return (dispatch, getState) => {
    if (itemId) {
      fetchDocSection(itemId)(dispatch, getState);
      fetchDocs(itemId)(dispatch, getState);
    } else {
      clearForm()(dispatch, getState)
    }
  }
};

export const fetchDocSection = (itemId) => {
  return (dispatch, getState) => {

    const token = Cookies.get('token');

    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }

    const url = `${getBaseUrl()}/admin/docs-sections/${itemId}`;
    axios.get(url, {
      headers: headers
    })
      .then((res) => {
        if (res.data.status === 'ok') {
          dispatch({
            type: "SET_DOC_SECTION",
            docs_section: res.data.docs_section,
          })
        } else {
          console.error("error request")
        }
      });
  }
};


export const showDocumentForm = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "SHOW_DOCUMENT_FORM",
    })
  }
};

export const cancelDocumentForm = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "HIDE_DOCUMENT_FORM",
    })
  }
};

export const clearForm = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "CLEAR_FORM",
    });
  }
};

export const uploadFile = (file) => {
  return (dispatch, getState) => {
    dispatch({
      type: "LOADING",
      loading: true,
    });

    const token = Cookies.get('token');

    let headers = {
      'Content-Type': 'multipart/form-data'
    };

    if (token) {
      headers["Authorization"] = `${token}`;
    }
    const formData = new FormData();
    formData.append("picture", file);
    const url = `${getBaseUrl()}/uploads/upload_docs_file`;
    axios.post(url, formData, {
      headers: headers
    })
      .then((res) => {
        if (res.data.status === 'ok') {
          dispatch({
            type: "SET_FILE",
            file: res.data.file,
          })
        } else {
          console.error("error upload")
        }
      });
  }
};

export const onDeleteFile = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_FILE",
      file: null,
    })
  }
}

export const submitDocumentForm = (history) => {
  return (dispatch, getState) => {
    dispatch({
      type: "LOADING",
      loading: true,
    });
    const token = Cookies.get('token');
    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }
    const state = getState().docs;
    const data = {
      docs_section_id: state.docs_section.id,
      file_id: state.file && state.file.id,
      doc_title: state.doc_title,
      is_new: state.is_new
    };
    let url = '';
    if (state.doc && state.docEditMode) {
      url = `${getBaseUrl()}/admin/docs-sections/docs/${state.doc.id}/edit`;
    } else {
      url = `${getBaseUrl()}/admin/docs-sections/docs`;
    }
    axios.post(url, data, {
      headers: headers
    })
      .then((res) => {
        if (res.data.status === 'ok') {
          fetchDocs(state.docs_section.id)(dispatch, getState);
          dispatch({
            type: "LOADING",
            loading: false,
          });
          dispatch({
            type: "HIDE_DOCUMENT_FORM",
          })
        } else {
          dispatch({
            type: "SET_ERRORS",
            errors: res.data.errors,
          })
        }
      });
  }
};

export const fetchDocs = (sectionId) => {
  return (dispatch, getState) => {

    const token = Cookies.get('token');

    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }

    const url = `${getBaseUrl()}/admin/docs-sections/${sectionId}/docs`;
    axios.get(url, {
      headers: headers
    })
      .then((res) => {
        if (res.data.status === 'ok') {
          dispatch({
            type: "SET_DOCS",
            docs: res.data.docs,
          })
        } else {
          console.error("error request")
        }
      });
  }
};

export const editDocument = (itemId) => {
  return (dispatch, getState) => {
    fetchDocument(itemId)(dispatch, getState);
  }
};

export const fetchDocument = (itemId) => {
  return (dispatch, getState) => {

    const token = Cookies.get('token');

    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }

    const url = `${getBaseUrl()}/admin/docs-sections/docs/${itemId}`;
    axios.get(url, {
      headers: headers
    })
      .then((res) => {
        if (res.data.status === 'ok') {
          dispatch({
            type: "SET_DOCUMENT",
            doc: res.data.doc,
          });
        } else {
          console.error("error request")
        }
      });
  }
};

function onSetLoading(loading) {
  return (dispatch) => {
    dispatch({
      type: "LOADING",
      loading: loading,
    })
  }
}

export const showMessage = (msg) => {
  return async (dispatch) => {
    message[msg.level](msg.text)
  }
};

export function onDelete(itemId) {
  return async (dispatch, getState) => {
    try {
      onSetLoading(true)(dispatch);
      const res = await docSectionService.remove(itemId);
      if (res.data.status === 'ok') {
        fetchDocSections()(dispatch, getState);
        onSetLoading(false)(dispatch);
        showMessage(SUCCESS_DELETED_MSG)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}


export function onDeleteDocument(itemId) {
  return async (dispatch, getState) => {
    try {
      onSetLoading(true)(dispatch);
      const res = await docSectionService.removeDocument(itemId);
      if (res.data.status === 'ok') {
        const state = getState().docs;
        fetchDocs(state.docs_section.id)(dispatch, getState);
        onSetLoading(false)(dispatch);
        showMessage(SUCCESS_DELETED_MSG)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}
