import io from '../../lib/io'
import { getBaseUrl } from '../../utils'


export function getAllSportSmenUrl(queryString) {
  const query = queryString ? `?${queryString}` : '';
  return `${getBaseUrl()}/admin/syncs-sportsmen${query}`;
}

export function getAllTrainerUrl(queryString) {
  const query = queryString ? `?${queryString}` : '';
  return `${getBaseUrl()}/admin/syncs-trainer${query}`;
}


export async function getAll () {
  return await io.get(getAllSportSmenUrl())
}

export default {
  getAllUrl: getAllSportSmenUrl,
  getAll,
}
