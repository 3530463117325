import _ from "lodash";
import React from "react";
import {Form, Input, Select} from "antd";
import IFormItem from "../../../../components/IFormItem";
import IPhotoUploader from "../../../../components/IPhotoUploader";
import moment from "moment";
import MyDatePicker from "../../../components/MyDatePicker/MyDatePicker";

const Option = Select.Option;

export default function BeltForm(props) {
  return (
    <Form>
      <IFormItem
        label="Статус"
        errorKey="status"
        errors={props.errors}
      >
        <Select
          showSearch
          onChange={(s) => props.onChangeInput("status", s)}
          value={props.status}
        >
          <Option value="public">Опубліковано</Option>
          <Option value="draft">Черновик</Option>
          <Option value="deleted">Видалено</Option>
        </Select>
      </IFormItem>
      <IFormItem
        label="Фото"
        errorKey="file_id"
        errors={props.errors}
      >
        <IPhotoUploader
          name="file"
          loading={props.loading}
          file={props.file}
          onUpload={props.onUpload}
        />
      </IFormItem>
      <IFormItem
        label="ПІБ"
        errorKey="name"
        errors={props.errors}
      >
        <Input
          name="name"
          value={props.name}
          onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
        />
      </IFormItem>
      <IFormItem
        label="Опис"
        errorKey="description"
        errors={props.errors}
      >
        <Input
          name="description"
          value={props.description}
          onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
        />
      </IFormItem>
      <IFormItem
        label="Дан"
        errorKey="dan"
        errors={props.errors}
      >
        <Select
          showSearch
          onChange={(s) => props.onChangeInput("dan", s)}
          value={props.dan}
        >
          {_.range(1, 11).map(i =>
            <Option key={i} value={i}>{i}</Option>
          )}
        </Select>
      </IFormItem>
      <IFormItem
        label="Номер сертифікату"
        errorKey="certificate"
        errors={props.errors}
      >
        <Input
          name="certificate"
          value={props.certificate}
          onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
        />
      </IFormItem>
      <IFormItem
        label={'Дата присвоєння'}
        errorKey="exam_date"
        errors={props.errors}
      >
        <MyDatePicker
          value={moment(props.exam_date)}
          onChange={(dt) => props.onChangeInput("exam_date", dt)}
        />
      </IFormItem>
    </Form>
  );
}
