import {connect} from "react-redux";
import AdminFederationStructure from './AdminFederationStructure';
import {
  changeInput,
  addStructure,
  fetchStructuresTree,
  editStructure,
  onDelete,
} from "./AdminFederationStructureAction";


const mapStateToProps = state => {
  return {
    ...state.federationStructure,
  };
};

const mapDispatchToProps = dispatch => {
  return {

    onInit: () => {
      return dispatch(fetchStructuresTree())
    },
    changeInput: (name, value) => {
      return dispatch(changeInput(name, value))
    },
    onCreate: () => {
      return dispatch(addStructure())
    },
    onEdit: (itemId) => {
      window.location.href = `/admin/structure/${itemId}`;
      return dispatch(editStructure(itemId))
    },
    onDelete: (itemId) => {
      return dispatch(onDelete(itemId))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminFederationStructure);
