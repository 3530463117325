


export function handleOnSort(onChange) {
  return (pagination, filters, sorter) => {
    if (!sorter.order) return;
    const by = sorter.order === 'ascend' ? `${sorter.field}`: `-${sorter.field}`;
    onChange('sort', 'text', by)
  }
}


export function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1)
}
