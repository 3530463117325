import moment from 'moment';
import Cookies from 'js-cookie';

export const VIEW_DATE_FORMAT = 'DD.MM.YYYY';
export const SUBMIT_DATE_FORMAT = 'YYYY-MM-DD';

export const SUCCESS_DELETED_MSG = {level: 'success', text: "Успішно видалено"};
export const SERVER_BASE_ERROR_MSG = {level: 'error', text: "Error on server"};


export function getScheme() {
  if (process.env.NODE_ENV === 'development') {
    return "http://"
  }
  return "https://"
}

export function getServiceDomain() {
  if (process.env.NODE_ENV === 'production') {
    return "karate.ua"
  }
  if (isServer()) {
    return "web:8000"
  } else {
    return "localhost:8000"
  }

}

export  function getBaseUrl() {
  return `${getScheme()}${getServiceDomain()}/app`
}

export  function getAuthorizationHeader() {
  const token = Cookies.get("token");
  const headers = {};

  if (token) {
    headers["Authorization"] = `${token}`;
  }
  return headers
}

export function namespacedTypes(namespace, constants, divider = '/') {
	return Object.freeze(
		constants.reduce((obj, constant) => {
			return {
				...obj,
				[constant]: `${namespace}${divider}${constant}`
			};
		}, {})
	);
}

export function getStatusTitle(status) {
  const statuses =  {
    public: 'Опубліковано',
    draft: 'Черновик',
    deleted: 'Видалено',
  };
  return statuses[status] || 'Не вказано'
}

export function isServer() {
  return !(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );

}

export function calculateAge(birthday) {
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function getNoun(number, one, two, five) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
}

export function formatDate(date) {
  return moment(date).format("DD.MM.YYYY")
}

export function formatDateTime(date) {
  return moment(date).format("YYYY-MM-DDTHH:mm:ss.sssZ")
}

export function formatSubmitDate(date) {
  return moment(date).format(SUBMIT_DATE_FORMAT)
}

export const toQueryString = (params) => Object.entries(params).map((q) => q.join('=')).join('&');


export const getUserVisibilityTitle = (v) => {
  const statuses =  {
    public: 'Активний',
    draft: 'Архів',
    deleted: 'Заблокований',
  };
  return statuses[v] || 'Не вказано'
};


export const VISIBILITY = {
  public: 'public',
  draft: 'draft',
  deleted: 'deleted',
};


export const formatGrade = (kuy, dan) => {
  if (Number(kuy)) {
    return `${kuy} Кю`
  } else if (Number(dan)) {
    return `${dan} Дан`
  } else {
    return 'Відсутні'
  }
};


export const sortByAlphabet = (a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});
