import {getBaseUrl} from '../../utils';
import io from '../../lib/io'


export async function getByObjectType(contentObjId, contentType) {
  return await io.get(`${getBaseUrl()}/public/documents?content_object_id=${contentObjId}&content_type=${contentType}`);
}


export default {
  getByObjectType
}
