import React from "react";
import {Avatar, Button, Col, Divider, Pagination, Row, Table, Tag} from "antd";


import AdminActionDropdown from "../../../components/AdminActionDropdown";
import AthleteForm from "../components/AthleteForm";
import service, {getAthleteUrl} from "../../../services/admin/athletes";
import clubService from "../../../services/admin/clubs";
import regionService from "../../../services/admin/agencies";
import moment from "moment";
import DownloadListFilterWraper from "../../components/DownloadLils/index";
import {formatDate, getNoun} from "../../../utils";
import FilterProvider from "../../components/Filters/FilterProvider";
import SearchFilter from "../../components/Filters/components/SearchFilter/index";
import FilterResults from "../../components/Filters/FilterResults";
import RemoteSelectFilter from "../../components/Filters/components/RemoteSelectFilter/index";
import {handleOnSort} from "../../libs/helpers";
import {getRankTitle} from "../../athlete_certifications/components/MemberForm/utils";


const fieldOptions = [
  { label: 'Прізвище', value: 'last_name' },
  { label: 'Імя', value: 'first_name' },
  { label: 'По батькові', value: 'middle_name' },
  { label: 'Клуб', value: 'club_name' },
  { label: 'Тренер', value: 'coach_name' },
  { label: 'Телефон', value: 'phone_number' },
  { label: 'Email', value: 'email' },
  { label: 'Регіон', value: 'region_name' },
  { label: 'Адреса', value: 'address' },
  { label: 'Статус', value: 'status' },
];
const defaultFieldOptions = [
  'first_name',
  'last_name',
  'middle_name',
  'club_name',
  'coach_name',
  'phone_number',
  'email'
];

const renderRedTag = (t) => <Tag color='red'>{t}</Tag>;

function renderIdCard(idCardDate) {
  if (!idCardDate) {
    return renderRedTag('Відсутня')
  }
  const d = new Date(idCardDate);
  const y = d.getFullYear();
  const current_y = new Date().getFullYear();
  if (y >= current_y) {
    return formatDate(idCardDate)
  }
  return renderRedTag('Відсутня')
}


export default class AthletesListView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentId: null,
      status: "public",
      showModal: false,
      editMode: false,
      loading: false,
      errors: {},
    }
  }


  onAdd = () => {
    this.setState({
      currentId: null,
      editMode: false,
      showModal: true,
      status: 'public',
    })
  };

  onEdit = (rowId) => {
    service.getById(rowId).then((res) => {
      const {data} = res.data;
      this.setState({
        currentId: rowId,
        editMode: true,
        showModal: true,
        status: data.status,
        first_name: data.first_name,
        last_name: data.last_name,
        middle_name: data.middle_name,
        eng_name: data.eng_name,
        gender: data.gender,
        club_id: data.club_id,
        sport_title: data.sport_title,
        league_id: data.league_id,
        kyu: data.kyu,
        dan: data.dan,
        email: data.email,
        phone_number: data.phone_number,
        owner_name: data.owner_name,
        address: data.address,
        website: data.website,
        birth_date: moment(data.birth_date),
        file: data.logo,
        rank: data.rank,
        reason: data.reason,
        trainer_id: data.trainer_id,
      })
    });
  };

  onChangeInput = (name, value) => {
    this.setState({[name]: value})
  };

  preparePostData = (data) => {
    return {
      status: data.status,
      reason: data.reason,
    }
  };

  onCancel = () => {
    this.setState({showModal: false})
  };


  onSubmit = async (postData) => {
    this.setState({loading: true});
    const data = this.preparePostData(postData);
    let res;
    if (this.state.editMode) {
      res = await service.save(this.state.currentId, data);
    } else {
      // res = await service.add(data);
    }

    if (res.data.status === 'ok') {
      this.setState({
        loading: false,
        showModal: false,
        editMode: false,
        currentId: null,
      })
    } else {
      this.setState({
        loading: false,
        errors: res.data.errors,
      })
    }
  };

  render() {
    return (
      <div>
        <h2>Список спортсменів</h2>
        <Divider/>
        <FilterProvider
          onFetchUrl={(queryString) => {return getAthleteUrl(queryString)}}
          onRemoveUrl={service.getRemoveUrl}
        >
          {({loading, onChange, fields, onClearAll, onRemove}) => {
            const columns = [
              {
                title: 'Фото',
                dataIndex: 'logo',
                key: 'logo',
                render: (t, r) => <Avatar
                  src={r.logo && r.logo.url}
                  size={60}
                  shape="square"
                >
                  Avatar
                </Avatar>
              },
              {
                title: 'Прізвище',
                dataIndex: 'last_name',
                key: 'last_name',
                sorter: true,
              },
              {
                title: 'Ім’я',
                dataIndex: 'first_name',
                key: 'first_name',
                sorter: true
              },
              {
                title: 'Клуб',
                dataIndex: 'club_name',
                key: 'club_name',
                sorter: true
              },
              {
                title: 'Регіон',
                dataIndex: 'region_name',
                key: 'region_name',
                sorter: true,
              },
              {
                title: 'Статус',
                dataIndex: 'status',
                key: 'status',
                sorter: true,
              },
              {
                title: 'Кю/Дан',
                dataIndex: 'rank',
                key: 'rank',
                render: (t, r) => getRankTitle(t)
              },
              {
                title: 'Id Card',
                dataIndex: 'id_card_purchase_date',
                key: 'id_card_purchase_date',
                render: (t, r) => renderIdCard(t),
              },
              {
                title: 'Дії',
                dataIndex: 'action',
                key: 'action',
                render: (text, item) => (
                  <AdminActionDropdown
                    {...this.props}
                    labelKey="last_name"
                    onDelete={onRemove}
                    onEdit={this.onEdit}
                    item={item}
                  />
                ),
              }
            ];
            return (
              <React.Fragment>
                <Row gutter={24}>
                  <Col lg={4}>
                    <SearchFilter
                      field="query"
                      placeholder="Введіть імя або прізвище"
                    />
                  </Col>
                  <Col lg={4}>
                    <RemoteSelectFilter
                      field="club_id"
                      fetchUrl={clubService.getAllClubsUrl()}
                      dataKey="clubs"
                      valueKey="id"
                      titleKey="name"
                      placeholder="Виберіть клуб"
                      allowClear
                    />
                  </Col>
                  <Col lg={4}>
                    <RemoteSelectFilter
                      field="region_id"
                      fetchUrl={regionService.getAllUrl()}
                      dataKey="agencies"
                      valueKey="id"
                      titleKey="short_name"
                      placeholder="Виберіть регіон"
                      allowClear
                    />
                  </Col>
                  <Col lg={4}>
                    <Button
                      icon="close"
                      onClick={onClearAll}
                    >
                      Скинути
                    </Button>
                  </Col>
                  <Col lg={4}>
                    <DownloadListFilterWraper
                      filters={fields}
                      fieldOptions={fieldOptions}
                      defaultFieldOptions={defaultFieldOptions}
                      downloadAction={service.download}
                    />
                  </Col>
                  <Col lg={4}>
                    <Button type="primary" icon="plus" onClick={this.onAdd}>
                      Додати
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <FilterResults
                    dataKey="athletes"
                  >
                    {({data, paginator}) => {
                      return (
                        <React.Fragment>
                          <Row>
                            <Col lg={12}>
                              <br/>
                              <b>{paginator.total}</b> {getNoun(paginator.total, 'спортсмен', 'спортсмена', 'спортсменів')}
                              <br/>
                            </Col>
                          </Row>
                          <Row>
                            <Table
                              columns={columns}
                              dataSource={data}
                              rowKey="id"
                              loading={loading}
                              locale={{emptyText: "Немає данних"}}
                              pagination={false}
                              onChange={handleOnSort(onChange)}
                            />
                          </Row>
                          <br/>
                          <Row type="flex" justify="end">
                            <Pagination
                              current={paginator.page}
                              total={paginator.total}
                              pageSize={paginator.page_size}
                              onChange={(page) => {
                                window.scrollTo(0, 0);
                                onChange('page', 'text', page)
                              }}
                            />
                          </Row>
                        </React.Fragment>
                      )
                    }}
                  </FilterResults>
                </Row>
              </React.Fragment>
            )
          }}
        </FilterProvider>
        {this.state.showModal &&
        <AthleteForm
          {...this.state}
          editMode={this.state.editMode}
          showModal={this.state.showModal}
          onCancel={this.onCancel}
          onSubmit={this.onSubmit}
          onChangeInput={this.onChangeInput}
          errors={this.state.errors}
        />
        }
      </div>
    );
  }
}
