import React from 'react';
import { Form, Input, Select, Divider, Button, Spin, Table } from 'antd';
import IFormItem from '../../components/IFormItem';
import RuleForm from './RuleForm';
import IModalForm from "../../components/IModalForm";
import AdminActionDropdown from "../../components/AdminActionDropdown";
const Option = Select.Option;

export default function RuleSectionForm(props) {
  const columns = [
    {
      title: 'Файл',
      dataIndex: 'picture_url',
      key: 'picture_url',
      render: (t, r) => <a src={r.file.url}>{r.title}</a>
    },
    {
      title: 'Дата створення',
      dataIndex: 'date_created',
      key: 'date_created',
    },
    {
      title: 'Дата оновлення',
      dataIndex: 'date_updated',
      key: 'date_updated',
    },
    {
      title: 'Дії',
      dataIndex: 'action',
      key: 'action',
      render: (text, r) => (
        <AdminActionDropdown
          {...props}
          onEdit={(item) => props.onEditRule(r.id)}
          onDelete={(item) => props.onDeleteRule(r.id)}
          item={r}
        />
      ),
    }
  ];
  return (
    <Form>
      <IFormItem
        label="Статус"
        errorKey="status"
        errors={props.errors}
      >
        <Select
          showSearch
          onChange={(s) => props.onChangeInput("status", s)}
          value={props.status}
        >
          <Option value="public">Опубліковано</Option>
          <Option value="draft">Черновик</Option>
          <Option value="deleted">Видалено</Option>
        </Select>
      </IFormItem>
      <IFormItem
        label={'Назва розділу'}
        errorKey="title"
        errors={props.errors}
      >
        <Input
          name="title"
          value={props.title}
          onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
        />
      </IFormItem>
      <div>
        <Divider/>
        {props.editMode &&
        <div>
          <Button
            icon="plus"
            htmlType="button"
            onClick={props.onAddRule}
          >
            Додати документ
          </Button>
          <Divider/>
          <Table
            columns={columns}
            dataSource={props.rules}
            rowKey="id"
            loading={props.loading}
            locale={{emptyText: "Немає докуменів"}}
          />
        </div>
        }
        <IModalForm
          title={props.editDocumentMode ? "Редагувати документ" : "Додати документ"}
          showModal={props.showDocumentModal}
          onCancel={props.onCancelRule}
          onSubmit={props.onSubmitRule}
        >
          <Spin spinning={props.loading} size="large">
            <RuleForm
              {...props}
            />
          </Spin>
        </IModalForm>
      </div>
    </Form>
  );
}

