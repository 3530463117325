import React from "react";
import {Avatar, Button, Col, Divider, Pagination, Row, Table} from "antd";
import AdminActionDropdown from "../../../components/AdminActionDropdown";
import RefereeForm from "../components/RefereeForm";
import service from "../../../services/admin/referees";
import regionService from "../../../services/admin/agencies";
import DownloadListFilterWraper from "../../components/DownloadLils/index";
import {getNoun, getUserVisibilityTitle} from "../../../utils";
import FilterProvider from "../../components/Filters/FilterProvider";
import SearchFilter from "../../components/Filters/components/SearchFilter/index";
import FilterResults from "../../components/Filters/FilterResults";
import RemoteSelectFilter from "../../components/Filters/components/RemoteSelectFilter/index";
import {handleOnSort} from "../../libs/helpers";
import {getRankTitle} from "../../referee_certifications/utils";
import {UserStatusSelectFilter} from "../../components/Filters/components/SelectFilter/SelectFilter";


const fieldOptions = [
  { label: 'Прізвище', value: 'last_name' },
  { label: 'Імя', value: 'first_name' },
  { label: 'По батькові', value: 'middle_name' },
  { label: 'Регіон', value: 'region_name' },
  { label: 'Email', value: 'email' },
];

const defaultFieldOptions = [
  'first_name',
  'last_name',
  'middle_name',
];



export default class RefereesListView extends React.Component {

  render() {
    return (
      <div>
        <h2>Список суддів</h2>
        <Divider/>
        <FilterProvider
          onFetchUrl={(queryString) => {return service.getAllUrl(queryString)}}
          onRemoveUrl={service.getRemoveUrl}
        >
          {({loading, onChange, fields, onClearAll, onRemove, editRow, onCancel, showForm, onEdit, onAdd}) => {
            const columns = [
              {
                title: 'Фото',
                dataIndex: 'logo',
                key: 'logo',
                render: (t, r) => <Avatar
                  src={r.logo && r.logo.url}
                  size={60}
                  shape="square"
                >
                  Avatar
                </Avatar>
              },
              {
                title: 'Прізвище',
                dataIndex: 'last_name',
                key: 'last_name',
                sorter: true,
              },
              {
                title: 'Ім’я',
                dataIndex: 'first_name',
                key: 'first_name',
                sorter: true,
              },
              {
                title: 'Регіон',
                dataIndex: 'region_name',
                key: 'region_name',
                sorter: true,
              },
              {
                title: 'Категорія',
                dataIndex: 'rank',
                key: 'rank',
                render: (t, r) => getRankTitle(t)
              },
              {
                title: 'Статус',
                dataIndex: 'visibility',
                key: 'visibility',
                sorter: true,
                render: (t, r) => getUserVisibilityTitle(t)
              },
              {
                title: 'Дії',
                dataIndex: 'action',
                key: 'action',
                render: (text, item) => (
                  <AdminActionDropdown
                    {...this.props}
                    labelKey="last_name"
                    onDelete={onRemove}
                    onEdit={onEdit}
                    item={item}
                  />
                ),
              }
            ];
            const rowCol = 3;
            return (
              <React.Fragment>
                <Row gutter={24}>
                  <Col lg={4}>
                    <SearchFilter
                      field="query"
                      placeholder="Введіть імя або прізвище"
                    />
                  </Col>
                  <Col lg={rowCol}>
                    <RemoteSelectFilter
                      field="region_id"
                      fetchUrl={regionService.getAllUrl()}
                      dataKey="agencies"
                      valueKey="id"
                      titleKey="short_name"
                      placeholder="Виберіть регіон"
                      allowClear
                    />
                  </Col>
                  <Col lg={rowCol}>
                    <RemoteSelectFilter
                      field="rank"
                      fetchUrl={service.getCategoryUrl()}
                      dataKey="data"
                      valueKey="id"
                      titleKey="title"
                      placeholder="Виберіть категорію"
                      allowClear
                    />
                  </Col>
                  <Col lg={rowCol}>
                    <UserStatusSelectFilter
                      field="visibility"
                    />
                  </Col>
                  <Col lg={rowCol}>
                    <Button
                      icon="close"
                      onClick={onClearAll}
                    >
                      Скинути
                    </Button>
                  </Col>
                  <Col lg={rowCol}>
                    <DownloadListFilterWraper
                      filters={fields}
                      fieldOptions={fieldOptions}
                      defaultFieldOptions={defaultFieldOptions}
                      downloadAction={service.download}
                    />
                  </Col>
                  <Col lg={rowCol}>
                    <Button type="primary" icon="plus" onClick={onAdd}>
                      Додати
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <FilterResults
                    dataKey="referees"
                  >
                    {({data, paginator}) => {
                      return (
                        <React.Fragment>
                          <Row>
                            <br/>
                            <b>{paginator.total}</b> {getNoun(paginator.total, 'суддя', 'судді', 'суддів')}
                            <br/>
                          </Row>
                          <Row>
                            <Table
                              columns={columns}
                              dataSource={data}
                              rowKey="id"
                              loading={loading}
                              locale={{emptyText: "Немає данних"}}
                              pagination={false}
                              onChange={handleOnSort(onChange)}
                            />
                          </Row>
                          <br/>
                          <Row type="flex" justify="end">
                            <Pagination
                              current={paginator.page}
                              total={paginator.total}
                              pageSize={paginator.page_size}
                              onChange={(page) => {
                                window.scrollTo(0, 0);
                                onChange('page', 'text', page)
                              }}
                            />
                          </Row>
                        </React.Fragment>
                      )
                    }}
                  </FilterResults>
                </Row>
                {showForm &&
                <RefereeForm
                  refereeId={editRow}
                  onCancel={onCancel}
                  showForm={showForm}
                  onSubmit={() => {
                    window.scrollTo(0, 0);
                    onChange('page', 'text', 1)
                  }}
                />
                }
              </React.Fragment>
            )
          }}
        </FilterProvider>
      </div>
    );
  }
}
