import Cookies from 'js-cookie'
import moment from 'moment';


const initialState = {
  events: [],
  event: null,
  editMode: false,
  token: Cookies.get("token"),
  date_started: moment(),
  date_expired: moment(),
  title: '',
  place: '',
  members: '',
  link: '',
  loading: false,
  addition: '',
  status: 'public',
  files: [],
  errors: {}
};


export default function news(state=initialState, action) {
  switch (action.type) {
    case 'CHANGE_VALUE':
      return {...state, [action.name]: action.value};

    case 'LOADING':
      return {...state, loading: action.loading};

    case 'CLEAR_FORM':
      return {...initialState};

    case 'SET_ERRORS':
      return {...state, errors: action.errors};

    case 'SET_EVENTS':
      return {...state, events: action.events};

    case 'SET_EVENT':
      const data = {
        date_started: moment(action.event.date_started),
        date_expired: moment(action.event.date_expired),
        title: action.event.title,
        place: action.event.place,
        members: action.event.members,
        link: action.event.link,
        loading: false,
        status: action.event.status,
        editMode: true,
        files: action.event.files,
      };
      return {...state, ...data, event: action.event};

    case 'SET_FILE':
      return {...state, loading: false, files: state.files.concat(action.file)};

    case 'REMOVE_FILE':
      return {...state, files: state.files.filter((f) => f.id !== action.file.uid)};

    default:
      return state;
  }
}