import {connect} from "react-redux";
import AdminFederationStructureForm from './AdminFederationStructureForm';
import {
  changeInput,
  submitForm,
  showMemberForm,
  cancelMemberForm,
  submitMemberForm,
  uploadFile,
  editMember,
  editStructure,
  onDeleteMember,
} from "./AdminFederationStructureAction";


const mapStateToProps = state => {
  return {
    ...state.federationStructure,
  };
};

const mapDispatchToProps = dispatch => {
  return {

    onInit: (itemId) => {
      return dispatch(editStructure(itemId))
    },
    changeInput: (name, value) => {
      return dispatch(changeInput(name, value))
    },
    onAddMember: () => {
      return dispatch(showMemberForm())
    },
    onEditMember: (itemId) => {
      return dispatch(editMember(itemId))
    },
    onCancel: () => {
      return dispatch(cancelMemberForm())
    },
    onSubmit: (history) => {
      return dispatch(submitForm(history))
    },
    onSubmitMember: (history) => {
      return dispatch(submitMemberForm(history))
    },
    onUploadFile: (file) => {
      return dispatch(uploadFile(file))
    },
    onDeleteMember: (itemId) => {
      return dispatch(onDeleteMember(itemId))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminFederationStructureForm);
