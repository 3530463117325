import actionTypes from './actionTypes';
import service from '../../services/admin/rules';
import {getSubmitData, getSubmitDocumentData} from './utils';
import {message} from 'antd';

const SERVER_BASE_ERROR_MSG = {level: 'error', text: "Error on server"};
const SUCCESS_UPDATE_MSG = {level: 'success', text: "Успішно збережено"};
const SUCCESS_DELETED_MSG = {level: 'success', text: "Успішно видалено"};

function onInit() {
  return async (dispatch) => {
    onSetLoading(true)(dispatch);
    try {
      await fetchAll()(dispatch);
      onSetLoading(false)(dispatch)
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}

function fetchAll() {
  return async (dispatch) => {
    try {
      const res = await service.getAll();
      dispatch({
        type: actionTypes.RULES_SET_RULES_SECTIONS,
        rules_sections: res.data.rules_sections
      });
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  }
}

function fetchAllRules(sectionId) {
  return async (dispatch) => {
    try {
      const res = await service.getAllRules(sectionId);
      dispatch({
        type: actionTypes.RULES_SET_RULES,
        rules: res.data.rules
      });
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  }
}

function setInitial() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RULES_SET_INITIAL
    })
  }
}


function onAdd() {
  return (dispatch) => {
    setInitial()(dispatch);
    showModal(true)(dispatch)
  }
}

function showModal(visible) {
  return (dispatch) => {
    if (visible) {
      dispatch({
        type: actionTypes.RULES_SHOW_FORM
      })
    } else {
      dispatch({
        type: actionTypes.RULES_HIDE_FORM
      })
    }

  }
}


function onCancel() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RULES_HIDE_FORM
    })
  }
}


function onChangeInput(name, value) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RULES_CHANGE_INPUT,
      name: name,
      value: value,
    })
  }
}

function onSetLoading(loading) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RULES_LOADING,
      loading: loading,
    })
  }
}


export const onUpload = (e) => {
  return async (dispatch) => {
    onSetLoading(true)(dispatch);
    try {
      const res = await service.postFile(e.file);
      dispatch({
        type: actionTypes.TOURNAMENTS_SET_FILE,
        file: res.data.file,
      })
    } catch (err) {
      showMessage({level: 'error', text: "Error on upload file"})(dispatch);
    }
  }
};


function onSubmit() {
  return async (dispatch, getState) => {
    onSetLoading(true)(dispatch);
    try {
      const state = getState().rules;
      const data = getSubmitData(state);
      let res = null;
      if (state.editMode && state.rules_section) {
        res = await service.save(state.rules_section.id, data);
      } else {
        res = await service.add(data);
      }
      onSetLoading(false)(dispatch);
      if (res.data.status === 'ok') {
        fetchAll()(dispatch);
        showModal(false)(dispatch);
        showMessage(SUCCESS_UPDATE_MSG)(dispatch);
      } else {
        setErrors(res.data.errors)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}


export const setErrors = (errors) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.RULES_SET_ERRORS,
      errors: errors,
    })
  }
};


export const showMessage = (msg) => {
  return async (dispatch) => {
    message[msg.level](msg.text)
  }
};


function onEdit(itemId) {
  return async (dispatch) => {
    try {
      const res = await service.getById(itemId);
      if (res.data.status === 'ok') {
        setItem(res.data.rules_section)(dispatch);
        fetchAllRules(res.data.rules_section.id)(dispatch);
        showModal(true)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}

export const setRuleItem = (item) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.RULES_SET_RULE,
      rule: item,
    })
  }
};

function onEditRule(itemId) {
  return async (dispatch) => {
    try {
      const res = await service.getRuleById(itemId);
      if (res.data.status === 'ok') {
        setRuleItem(res.data.rule)(dispatch);
        onShowRuleForm(true)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}

function onDeleteRule(itemId) {
  return async (dispatch, getState) => {
    try {
      onSetLoading(true)(dispatch);
      const res = await service.removeRule(itemId);
      if (res.data.status === 'ok') {
        const state = getState().rules;
        await fetchAllRules(state.rules_section.id)(dispatch);
        onSetLoading(false)(dispatch);
        showMessage(SUCCESS_DELETED_MSG)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}

function onDelete(itemId) {
  return async (dispatch) => {
    try {
      onSetLoading(true)(dispatch);
      const res = await service.remove(itemId);
      if (res.data.status === 'ok') {
        await fetchAll()(dispatch);
        onSetLoading(false)(dispatch);
        showMessage(SUCCESS_DELETED_MSG)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}

export const setItem = (item) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.RULES_SET_RULES_SECTION,
      rules_section: item,
    })
  }
};

export const onShowRuleForm = (visible) => {
  return (dispatch) => {
    if (visible) {
      dispatch({
        type: actionTypes.RULES_SHOW_DOCUMENT_FORM
      })
    } else {
      dispatch({
        type: actionTypes.RULES_HIDE_DOCUMENT_FORM
      })
    }
  }
};

export const onAddRule = () => {
  return async (dispatch) => {
    onShowRuleForm(true)(dispatch);
    dispatch({
      type: actionTypes.RULES_SET_INITIAL_RULE,
    })
  }
};

export const onCancelRule = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.RULES_HIDE_DOCUMENT_FORM,
    })
  }
};

function onSubmitRule() {
  return async (dispatch, getState) => {
    onSetLoading(true)(dispatch);
    try {
      const state = getState().rules;
      const data = getSubmitDocumentData(state);
      let res = null;
      if (state.editDocumentMode && state.rule) {
        res = await service.saveRule(state.rule.id, data);
      } else {
        res = await service.addRule(data);
      }
      onSetLoading(false)(dispatch);
      if (res.data.status === 'ok') {
        await fetchAllRules(state.rules_section.id)(dispatch);
        onCancelRule()(dispatch);
        showMessage(SUCCESS_UPDATE_MSG)(dispatch);
      } else {
        setErrors(res.data.errors)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}

export const onUploadFile = (file) => {
  return async (dispatch) => {
    onSetLoading(true)(dispatch);
    try {
      const res = await service.postFile(file);
      dispatch({
        type: actionTypes.RULES_SET_FILE,
        file: res.data.file,
      })
    } catch (err) {
      showMessage({level: 'error', text: "Error on upload file"})(dispatch);
    }
  }
};

export default {
  onInit,
  onAdd,
  onCancel,
  onUpload,
  onChangeInput,
  onSubmit,
  onEdit,
  onDelete,
  onCancelRule,
  onSubmitRule,
  onAddRule,
  onUploadFile,
  onEditRule,
  onDeleteRule,
}
