import {connect} from "react-redux";
import AdminEventsList from './AdminEventsList';
import {editEvent, createEvent, fetchEvents, onDelete} from "./AdminEventListActions";

const mapStateToProps = state => {
  return {
    ...state.events,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onAdd: () => {
      window.location.href = '/admin/event-create';
    },
    onEdit: (eventId) => {
      window.location.href = `/admin/event/${eventId}`;
      return dispatch(editEvent(eventId))
    },

    onCreate: () => {
      return dispatch(createEvent())
    },

    onMount: () => {
      return dispatch(fetchEvents())
    },

    onDelete: (itemId) => {
      return dispatch(onDelete(itemId))
    }

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminEventsList);
