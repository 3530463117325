import React from "react";
import {Col, Form, Input, Row, Select} from "antd";
import IFormItem from "../../../../components/IFormItem";
import IPhotoUploader from "../../../../components/IPhotoUploader";
import {capitalize} from "../../../libs/helpers";
import ClubsCascader from "../../../components/ClubCascader/ClubCascader";
import MyDatePicker from "../../../components/MyDatePicker/MyDatePicker";
import {UserSelectStatus} from "../../../components/SelectStatus/SelectStatus";
import {VISIBILITY} from "../../../../utils";


const ChangeVisibility = ({visibility, reason, onChange, errors}) => {

  return (
    <React.Fragment>
      <UserSelectStatus
        value={visibility}
        errors={errors}
        onChange={onChange}
      />
      {(VISIBILITY.deleted === visibility || VISIBILITY.draft === visibility) &&
      <IFormItem
        label="Примітка"
        errorKey="reason"
        errors={errors}
      >
        <Input.TextArea
          rows="3"
          name="reason"
          placeholder="Введіть примітку"
          value={reason}
          onChange={(e) => onChange(
            e.target.name,
            e.target.value
          )}
        />
      </IFormItem>
      }
    </React.Fragment>
  );
};

export default function RefereeForm(props) {
  return (
    <Form>
      <Row gutter={24}>
        <Col span={8}>
          <IFormItem
            label="Прізвище"
            errorKey="last_name"
            errors={props.errors}
          >
            <Input
              name="last_name"
              placeholder="Введіть прізвище"
              value={props.last_name}
              onChange={(e) => props.onChangeInput(
                e.target.name,
                capitalize(e.target.value)
              )}
            />
          </IFormItem>
          <IFormItem
            label="Ім’я"
            errorKey="first_name"
            errors={props.errors}
          >
            <Input
              name="first_name"
              placeholder="Введіть ім’я"
              value={props.first_name}
              onChange={(e) => props.onChangeInput(
                e.target.name,
                capitalize(e.target.value)
              )}
            />
          </IFormItem>
          <IFormItem
            label="По батькові"
            errorKey="middle_name"
            errors={props.errors}
          >
            <Input
              name="middle_name"
              placeholder="Введіть по батькові"
              value={props.middle_name}
              onChange={(e) => props.onChangeInput(
                e.target.name,
                capitalize(e.target.value)
              )}
            />
          </IFormItem>
          <IFormItem
            label="Прізвище (Eng)"
            errorKey="last_name_eng"
            errors={props.errors}
          >
            <Input
              name="last_name_eng"
              placeholder="Введіть прізвище (Eng)"
              value={props.last_name_eng}
              onChange={(e) => props.onChangeInput(
                e.target.name,
                e.target.value.toUpperCase()
              )}
            />
          </IFormItem>
          <IFormItem
            label="Ім’я (Eng)"
            errorKey="first_name_eng"
            errors={props.errors}
          >
            <Input
              name="first_name_eng"
              placeholder="Введіть ім’я (Eng)"
              value={props.first_name_eng}
              onChange={(e) => props.onChangeInput(
                e.target.name,
                e.target.value.toUpperCase()
              )}
            />
          </IFormItem>
          <IFormItem
            label={'Дата народження'}
            errorKey="birth_date"
            errors={props.errors}
          >
            <MyDatePicker
              value={props.birth_date}
              onChange={dt => props.onChangeInput("birth_date", dt)}
            />
          </IFormItem>
        </Col>
        <Col span={8}>
          <IFormItem
            label='Стать'
            errorKey="gender"
            errors={props.errors}
          >
            <Select
              placeholder={!props.gender && "Виберіть стать"}
              style={{ width: "100%" }}
              onChange={(s) => props.onChangeInput("gender", s)}
              value={props.gender}
            >
              <Select.Option value="MALE">Чоловік</Select.Option>
              <Select.Option value="FEMALE">Жінка</Select.Option>
            </Select>
          </IFormItem>
          <ClubsCascader
            value={[props.region_id, props.club_id]}
            errors={props.errors}
            onChange={props.onChangeInput}
          />
          <IFormItem
            label="Телефон"
            errorKey="phone_number"
            errors={props.errors}
          >
            <Input
              name="phone_number"
              placeholder="Введіть телефон"
              value={props.phone_number}
              onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
            />
          </IFormItem>
          <IFormItem
            label="Email"
            errorKey="email"
            errors={props.errors}
          >
            <Input
              name="email"
              placeholder="Введіть email"
              value={props.email}
              onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
            />
          </IFormItem>
          <IFormItem
            label="Адреса"
            errorKey="address"
            errors={props.errors}
          >
            <Input.TextArea
              rows="4"
              name="address"
              placeholder="Введіть адресу"
              value={props.address}
              onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
            />
          </IFormItem>
        </Col>
        <Col span={8}>
          <IFormItem
            label="Аватар"
            errorKey="file_id"
            errors={props.errors}
          >
            <IPhotoUploader
              name="file"
              loading={props.loading}
              file={props.logo}
              onUpload={props.onUpload}
            />
          </IFormItem>
          <ChangeVisibility
            visibility={props.visibility}
            reason={props.reason}
            errors={props.errors}
            onChange={props.onChangeInput}
          />
        </Col>
      </Row>
    </Form>
  );
}
