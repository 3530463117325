import React from "react";
import {Button, Col, Input, Row} from "antd";

import IModalForm from "../../components/IModalForm";
import AthleteDescription from "./AthleteDescription";
import * as sertificationService from "../../services/admin/athlete_certifications";
import * as athleteService from "../../services/admin/athletes";

const initialState = {
  certificateNum: '',
  athlete: null,
  certifications: [],
  showModal: false,
};

const CheckCertificate = () => {
  const [state, setState] = React.useState(initialState);

  const onChange = (e) => {
    setState({...state, certificateNum: e.target.value});
  };

  const onCheck = async () => {
    const res = await sertificationService.getCertifiedAthlete(state.certificateNum);
    if (res.data.data) {
      const resAthlete = await athleteService.getById(res.data.data.athlete_id);
      const resCerts = await sertificationService.getAthleteCertifications(res.data.data.athlete_id);
      if (resAthlete.data.status === 'ok' && resAthlete.data.data) {
        setState({
          ...state,
          athlete: resAthlete.data.data,
          certifications: resCerts.data.data,
          showModal: true,
        })
      }
      return
    }
    setState({...state, showModal: true});
  };

  const onClose = () => {
    setState(initialState);
  };

  return (
    <Row style={{marginTop: "20px"}} gutter={24}>
      <Col lg={6}>
        <Input
          placeholder="Введіть номер сертифікату"
          value={state.certificateNum}
          onChange={onChange}
        />
      </Col>
      <Col lg={6}>
        <Button
          type="primary"
          icon="check"
          onClick={onCheck}
        >
          Перевірити
        </Button>
      </Col>
      {state.showModal &&
      <IModalForm
        title={"Інформація про спортсмена"}
        showModal={state.showModal}
        onCancel={onClose}
        showFooter={false}
      >
        {state.athlete
          ? <AthleteDescription
            athlete={state.athlete}
            certifications={state.certifications}
          />
          : <div><b>{`Сертифікат ${state.certificateNum} не знайдено`}</b></div>
        }
      </IModalForm>
      }
    </Row>
  )
};

export default CheckCertificate
