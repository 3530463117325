import {getBaseUrl} from '../../utils';
import io from '../../lib/io'

// export async function add(data) {
//   return await io.post(`${getBaseUrl()}/admin/agencies`, data);
// }


export async function download(data) {
  return await io.post(`${getBaseUrl()}/admin/agencies/download`, data);
}

export function getAllUrl(queryString) {
  const query = queryString ? `?${queryString}` : '';
  return `${getBaseUrl()}/admin/agencies${query}`;
}


export async function getAll() {
  return await io.get(getAllUrl());
}

// export async function save(id, data) {
//   return await io.post(`${getBaseUrl()}/admin/agencies/${id}/edit`, data);
// }

export async function getById(id) {
  return await io.get(`${getBaseUrl()}/admin/agencies/${id}`);
}

export function getRemoveUrl(id) {
  return `${getBaseUrl()}/admin/agencies/${id}/delete`;
}

export async function remove(id) {
  return await io.post(getRemoveUrl(id));
}

export default {
  // add,
  getAllUrl,
  getAll,
  // save,
  getById,
  download,
  remove,
  getRemoveUrl,
}
