import React from "react";
import {Button, Col, Divider, Pagination, Row, Table} from "antd";
import AdminActionDropdown from "../../../../components/AdminActionDropdown/index";
import {getNoun} from "../../../../utils";
import FilterProvider from "../../../components/Filters/FilterProvider";
import SearchFilter from "../../../components/Filters/components/SearchFilter/index";
import FilterResults from "../../../components/Filters/FilterResults";
import {handleOnSort} from "../../../libs/helpers";
import TeamForm from "../TeamForm/index";


export default class TeamListView extends React.Component {

  render() {
    return (
      <div>
        <h2>{this.props.title}</h2>
        <Divider/>
        <FilterProvider
          onFetchUrl={(queryString) => {return this.props.fetchUrl(queryString)}}
          onRemoveUrl={this.props.removeUrl}
        >
          {({loading, onChange, onClearAll, showForm, editRow, onCancel, onAdd, onEdit, onRemove}) => {
            return (
              <React.Fragment>
                <Row gutter={24}>
                  <Col lg={4}>
                    <SearchFilter
                      field="query"
                      placeholder="Введіть назву"
                    />
                  </Col>
                  <Col lg={4}>
                    <Button
                      icon="close"
                      onClick={onClearAll}
                    >
                      Скинути
                    </Button>
                  </Col>
                  <Col lg={4}>
                    <Button type="primary" icon="plus" onClick={onAdd}>
                      Додати
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <FilterResults
                    dataKey="data"
                  >
                    {({data, paginator}) => {
                      const columns = [
                        {
                          title: 'Назва',
                          dataIndex: 'title',
                          key: 'title',
                          sorter: true,
                          width: "40%",
                        },
                        {
                          title: 'Статус',
                          dataIndex: 'status',
                          key: 'status',
                        },
                        {
                          title: 'Дії',
                          dataIndex: 'action',
                          key: 'action',
                          align: 'center',
                          render: (text, item) => (
                            <AdminActionDropdown
                              {...this.props}
                              labelKey="title"
                              fetchRow={true}
                              onDelete={onRemove}
                              onEdit={onEdit}
                              item={item}
                            />
                          ),
                        }
                      ];
                      return (
                        <React.Fragment>
                          <Row>
                            <br/>
                            <b>{paginator.total}</b>
                            {getNoun(paginator.total, ' збірна', ' збірні', ' збірних')}
                            <br/>
                          </Row>
                          <Row>
                            <Table
                              columns={columns}
                              dataSource={data}
                              rowKey="id"
                              loading={loading}
                              locale={{emptyText: "Немає данних"}}
                              pagination={false}
                              onChange={handleOnSort(onChange)}
                            />
                          </Row>
                          <br/>
                          <Row type="flex" justify="end">
                            <Pagination
                              current={paginator.page}
                              total={paginator.total}
                              pageSize={paginator.page_size}
                              onChange={(page) => {
                                window.scrollTo(0, 0);
                                onChange('page', 'text', page)
                              }}
                            />
                          </Row>
                        </React.Fragment>
                      )
                    }}
                  </FilterResults>
                </Row>
                {showForm &&
                <TeamForm
                  type={this.props.type}
                  team={editRow}
                  showForm={showForm}
                  onSubmit={
                    () => {
                      window.scrollTo(0, 0);
                      onChange('page', 'text', 1)
                    }
                  }
                  onCancel={onCancel}
                />
                }
              </React.Fragment>
            );
          }}
        </FilterProvider>
      </div>
    );
  }
}