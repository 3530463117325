import React from "react";
import {Form, Input} from "antd";
import moment from "moment";
import IFormItem from "../../../../components/IFormItem";
import SelectStatus from "../../../components/SelectStatus";
import MyDatePicker from "../../../components/MyDatePicker/MyDatePicker";

const ExamForm = (
  {
    onChange,
    errors,
    status,
    protocol_num,
    date_exam,
    location,
    examiner,
  }
) => {
  return (
    <Form>
      <SelectStatus
        value={status}
        errors={errors}
        onChange={onChange}
      />
      <IFormItem
        label="Номер документу"
        errorKey="protocol_num"
        errors={errors}
      >
        <Input
          name="protocol_num"
          placeholder="Введіть номер документу"
          value={protocol_num}
          onChange={(e) => onChange(
            e.target.name,
            e.target.value
          )}
        />
      </IFormItem>
      <IFormItem
        label="Дата екзамена"
        errorKey="date_exam"
        errors={errors}
      >
        <MyDatePicker
          value={moment(date_exam)}
          onChange={(v) => {
            onChange("date_exam", v);
          }}
        />
      </IFormItem>
      <IFormItem
        label="Екзаменатор"
        errorKey="examiner"
        errors={errors}
      >
        <Input
          name="examiner"
          placeholder="Введіть екзаменатора"
          value={examiner}
          onChange={(e) => {
            onChange(e.target.name, e.target.value)
          }}
        />
      </IFormItem>
      <IFormItem
        label="Місце проведення"
        errorKey="location"
        errors={errors}
      >
        <Input
          name="location"
          placeholder="Введіть місце проведення"
          value={location}
          onChange={(e) => {
            onChange(e.target.name, e.target.value)
          }}
        />
      </IFormItem>
    </Form>
  );
};

export default ExamForm
