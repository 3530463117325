import React from "react";
import {Breadcrumb, Card} from "antd";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import PublicBaseLayout from "../../components/BaseLayout/PublicBaseLayout";
import {getNewsById} from "../../services/public/news";
import ReactHtmlParser from "react-html-parser";
import "./News.css";
import {getServiceDomain} from "../../utils";

export default class News extends React.Component {

  static async getInitialProps({req, res, match}) {
    try {
      const res = await getNewsById(match.params.id);
      return { news: res.data.news};
    } catch (err) {
      console.error(err)
    }
  }

  createMarkup = (description) => {
    return {__html: description};
  };

  render() {
    const news = this.props.news ? this.props.news : {};
    return (
      <PublicBaseLayout>
        <Helmet
          defaultTitle={news.title}
        >
          <title>{news.title}</title>

          <meta name="description" content={news.intro} />

          <meta property="og:url" content={`https://karate.ua/news/${news.id}`}/>
          <meta property="og:image" content={news.picture_url} />
          <meta property="og:title" content={news.title} />
          <meta property="og:description" content={news.intro} />

          <meta name="twitter:image" content={news.picture_url}/>
          <meta name="twitter:title" content={news.title} />
          <meta name="twitter:description" content={news.intro} />

          <link rel="image_src" href={news.picture_url} />
        </Helmet>
        <Breadcrumb style={{ marginTop: '20px', marginBottom: "5px" }}>
          <Breadcrumb.Item>
            <Link to="/">
              {getServiceDomain()}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/news">
              Новини
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {news.title}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          loading={!this.props.news}
          title={<h1>{news.title}</h1>}
          cover={<img alt="" src={news.picture_url} />}
        >
          <p>
            {ReactHtmlParser(news.description)}
          </p>
        </Card>
      </PublicBaseLayout>
    );
  }
}
