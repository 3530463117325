import React from "react";
import {Breadcrumb, Card, Col, Row} from "antd";
import {Link} from "react-router-dom";
import {getServiceDomain} from "../../../utils";
import PublicBaseLayout from "../../../components/BaseLayout/PublicBaseLayout";
import {Helmet} from "react-helmet";

export default class Contacts extends React.Component {

  render() {
    return (
      <PublicBaseLayout>
        <Helmet>
          <title>Контакти - Українська Федерація карате</title>
        </Helmet>
        <Breadcrumb style={{ marginTop: '20px', marginBottom: "5px" }}>
          <Breadcrumb.Item>
            <Link to="/">
              {getServiceDomain()}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Контакти
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card title="Контакти">
          <Row type="flex" justify="center" align="top">
            <Col span={22}>
              <p><b>Адреса:</b> вулиця Анни Ахматової, 46а, Київ, 03035</p>
              <p><b>Email:</b> <a href="mailto:secretariat@wkf.com.ua">secretariat@wkf.com.ua</a></p>
              <p><b>Телефон:</b> <a href="tel:+380966622020">+380966622020</a></p>
              <p><b>Графік роботи:</b> пн-пт с 9:00 до 18:00</p>
            </Col>
          </Row>
          <br/>
          <Row type="flex" justify="center" align="top">
            <Col span={22}>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2542.852371342892!2d30.615994566045085!3d50.406588829469335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1z0LLRg9C70LjRhtGPINCQ0L3QvdC4INCQ0YXQvNCw0YLQvtCy0L7RlywgNDbQsCwg0JrQuNGX0LIsIDAzMDM1!5e0!3m2!1sru!2sua!4v1565036508149!5m2!1sru!2sua"
                height="450"
                frameBorder="0"
                style={{border:0, width: "100%"}}
                allowFullScreen
              >
              </iframe>
            </Col>
          </Row>
        </Card>
      </PublicBaseLayout>
    );
  }
}
