


export function getSubmitData(state) {
  return {
    status: state.status,
    title: state.title,
    link: state.link,
    file_id: state.file && state.file.id,
  };
}
