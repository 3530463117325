import React from "react";
import {Avatar, Button, Descriptions, Divider, List} from "antd";


const AgencyDescription = ({head, email, phoneNumber, clubs, onShowClub}) => {
  return (
    <React.Fragment>
      <Descriptions
        size="small"
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="Керівник">{head}</Descriptions.Item>
        <Descriptions.Item label="Email"><a href={`mailto:${email}`}>{email}</a></Descriptions.Item>
        <Descriptions.Item label="Тел."><a href={`tel:${phoneNumber}`}>{phoneNumber}</a></Descriptions.Item>
      </Descriptions>
      <Divider orientation="center">Клуби Федерації:</Divider>
      <List
        size="small"
        dataSource={clubs}
        locale={{emptyText: "Немає даних"}}
        renderItem={item => (
          <List.Item key={item.id}>
            <List.Item.Meta
              avatar={<Avatar
                shape="square"
                src={item.logo ? item.logo.url : null}
              >Logo</Avatar>}
              title={
                <Button
                  type="link"
                  onClick={(e) => onShowClub(e, item)}
                >
                  {item.short_name}
                </Button>
              }
            />
          </List.Item>
        )}
      />

    </React.Fragment>
  );
};


export default AgencyDescription;