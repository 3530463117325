import React from "react";
import _ from "lodash";
import {Button, Col, Divider, Row, Table, Tabs, Avatar} from "antd";
import MemberForm from "../MemberForm/MemberForm";
import IModalForm from "../../../../components/IModalForm";
import ExamForm from "./ExamForm";
import * as serviceCertification from "../../../../services/admin/referee_certifications";
import {formatSubmitDate} from "../../../../utils";
import AdminActionDropdown from "../../../../components/AdminActionDropdown";
import {getRankTitle} from "../../utils";
import * as refereeService from "../../../../services/admin/referees";

const { TabPane } = Tabs;


const ExamFormContainer = ({certId, onCancel, onSubmit}) => {
  const [loading, setLoading] = React.useState(false);
  const [showForm, addMember] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [fields, changeField] = React.useState({status: 'public'});
  const [members, setMember] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      if (certId) {
        const certRes = await serviceCertification.getById(certId);
        const memberRes = await serviceCertification.getCertificatedMembers(certId);
        changeField(certRes.data.data);
        setMember(memberRes.data.data)
      }
    };
    fetchData();
  }, []);

  const onChange = (name, value) => {
    changeField({...fields, [name]: value})
  };

  const handleSubmit = async () => {
    setLoading(true);

    const data = {
      status: fields.status,
      protocol_num: fields.protocol_num,
      date_exam: formatSubmitDate(fields.date_exam),
      examiner: fields.examiner,
      region_id: fields.region_id,
      club_id: fields.club_id,
      location: fields.location,
      type: 'athletes',
      members: members.map(m => {
        return {...m, 'referee_id': m.id, 'certificate_date': formatSubmitDate(m.certificate_date)}
      }),
    };
    let res;
    if (certId) {
      res = await serviceCertification.save(certId, data)
    } else {
      res = await serviceCertification.add(data);
    }
    setLoading(false);
    if (res.data.status === 'ok') {
      onCancel()
    } else {
      setErrors(res.data.errors);
    }
    onSubmit()
  };

  const handleAddMember = () => {
    changeField({...fields,
      'referee_id': null,
      'certificate_num': null,
      'rank': null,
      'certificate_date': undefined,
    });
    addMember(true)
  };

  const onAddMember = async () => {
    const err = {};
    if (!fields.referee_id) {
        err['referee_id'] = ["Виберіть суддю"];
    }
    if (!fields.certificate_num) {
        err['certificate_num'] = ["Введіть сертифікат"];
    }
    if (!fields.rank) {
      err['rank'] = ["Виберіть категорію"];
    }
    if (!fields.certificate_date) {
      err['certificate_date'] = ["Виберіть дату"];
    }

    if (!_.isEmpty(err)) {
      setErrors(err);
      console.error(err);
      return
    }

    const res = await refereeService.getById(fields.referee_id);
    addMember(false);
    setMember(members
      .filter(m => m.referee_id !== fields.referee_id)
      .concat({
      ...res.data.data,
      referee_id: res.data.data.id,
      certificate_num: fields.certificate_num,
      certificate_date: fields.certificate_date,
      order_num: fields.order_num,
      location: fields.location,
      head: fields.head,
      rank: fields.rank,
    }));
  };

  const onRemoveMember = (rowId) => {
    setMember(members.filter(m => m.referee_id !== rowId))
  };

  const onEditMember = (row) => {
    const member = members.filter(m => m.referee_id === row.id)[0];
    changeField({...fields,
      'referee_id': row.id,
      'certificate_num': member.certificate_num,
      'rank': member.rank,
      'certificate_date': member.certificate_date,
    });
    addMember(true)
  };

  const columns = [
    {
      title: 'Фото',
      dataIndex: 'logo',
      key: 'logo',
      render: (t, r) => <Avatar
        src={r.logo && r.logo.url}
        size={50}
        shape="square"
      >
        Avatar
      </Avatar>
    },
    {
      title: 'ПІБ',
      dataIndex: 'name',
      render: (t, r) => `${r.full_name}`,
    },
    {
      title: 'Номер сертифікату',
      dataIndex: 'certificate_num',
    },
    {
      title: 'Категорія',
      dataIndex: 'rank',
      render: (t, r) => getRankTitle(r.rank),
    },
    {
      title: 'Дії',
      dataIndex: 'action',
      key: 'action',
      render: (text, item) => (
        <AdminActionDropdown
          labelKey="name"
          fetchRow={true}
          onDelete={onRemoveMember}
          onEdit={onEditMember}
          item={item}
        />
      ),
    }

  ];

  return (
    <IModalForm
      title="Додати протокол"
      showModal={true}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      loading={loading}
    >
      <Tabs onChange={() => null} type="card">
        <TabPane tab="Протокол екзамену" key="1">
          <ExamForm
            {...fields}
            loading={loading}
            errors={errors}
            onChange={onChange}
          />
        </TabPane>
        <TabPane tab="Атестовані судді" key="2">
          <Button
            type="primary"
            icon="plus"
            onClick={handleAddMember}
          >
            Додати
          </Button>
          {showForm &&
          <IModalForm
            title="Суддя"
            loading={loading}
            showModal={true}
            submitText="Додати"
            onSubmit={onAddMember}
            onCancel={() => addMember(false)}
          >
            <MemberForm
              {...fields}
              loading={loading}
              errors={errors}
              onChange={onChange}
            />
          </IModalForm>
          }
          <Divider/>
          <Row type="flex" justify="space-around" align="middle">
            <Col span={24}>
              <Table
                rowKey="full_name"
                columns={columns}
                dataSource={members}
                size="middle"
                locale={{emptyText: "Немає данних"}}
                pagination={true}
              />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </IModalForm>
  );
};

export default ExamFormContainer
