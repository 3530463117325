
export function getSubmitData(state) {
  return {
    status: state.status,
    title: state.title,
  };
}

export function getSubmitDocumentData(state) {
  return {
    rules_section_id: state.rules_section.id,
    rule_title: state.rule_title,
    file_id: state.file && state.file.id,
    is_new: state.is_new
  };
}