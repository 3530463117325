import React from "react";
import {Form, Input, Transfer} from "antd";
import IFormItem from "../../components/IFormItem";


class App extends React.Component {

  render() {
    return (
      <Transfer
        listStyle={{width: "40%"}}
        titles={['Розділи', 'Доступні розділи']}
        operations={['Додати', 'Зняти']}
        dataSource={this.props.options.map(o => {
          return {...o, key: o.value}
        })}
        locale={
          { itemUnit: "",
            itemsUnit: "",
            searchPlaceholder: "Введіть назву розділу",
            notFoundContent: "Немає данних"
          }
        }
        showSearch
        targetKeys={this.props.selectedOptions}
        onChange={this.props.onChange}
        render={item => item.label}
      />
    );
  }
}


export default class UserForm extends React.Component {


  onChangeInput = (e) => {
    this.props.onChangeInput(e.target.name, e.target.value)
  };

  render() {
    return (
      <Form>
        <IFormItem
          label="Email"
          errorKey="email"
          errors={this.props.errors}
        >
          <Input
            name="email"
            value={this.props.email}
            onChange={this.onChangeInput}
          />
        </IFormItem>
        <IFormItem
          label="Імʼя"
          errorKey="first_name"
          errors={this.props.errors}
        >
          <Input
            name="first_name"
            value={this.props.first_name}
            onChange={this.onChangeInput}
          />
        </IFormItem>
        <IFormItem
          label="Призвище"
          errorKey="last_name"
          errors={this.props.errors}
        >
          <Input
            name="last_name"
            value={this.props.last_name}
            onChange={this.onChangeInput}
          />
        </IFormItem>
        <IFormItem
          label="Пароль"
          errorKey="password"
          errors={this.props.errors}
        >
          <Input
            type="password"
            name="password"
            value={this.props.password}
            onChange={this.onChangeInput}
          />
        </IFormItem>
        <IFormItem
          label="Відмітити права доступу"
          errorKey="sections"
          errors={this.props.errors}
        >
          <App
            options={this.props.sections}
            selectedOptions={this.props.selectedSections}
            onChange={this.props.onChangeSection}
          />
        </IFormItem>
      </Form>
    );
  }
}
