import actionTypes from './actionTypes';
import _ from 'lodash';

const initialState = {
  loading: false,
  editMode: false,
  showModal: false,
  errors: {},
  user: null,
  users: [],
  sections: [],
  selectedSections: [],
};


export default function reducer(state=initialState, action) {
  switch (action.type) {

    case actionTypes.USERS_SET_USERS:
      return {...state, users: action.users};

    case actionTypes.USERS_SET_USER:
      return {...state,
        user: action.user,
        selectedSections: action.user.admin_sections,
        editMode: true,
        errors: {},
      };

    case actionTypes.USERS_SET_LOADING:
      return {...state, loading: action.loading};

    case actionTypes.USERS_SET_ERRORS:
      return {...state, errors: action.errors};

    case actionTypes.USERS_SHOW_FORM:
      return {...state, showModal: true};

    case actionTypes.USERS_HIDE_FORM:
      return {...state, showModal: false};

    case actionTypes.USERS_SET_INITIAL_USER:
      return {...state,
        user: null,
        editMode: false,
        errors: {},
        selectedSections: []
      };

    case actionTypes.USERS_CHANGE_INPUT:
      const user = _.assign({}, state.user);
      user[action.name] = action.value;
      return {...state, user: user};

    case actionTypes.BANNERS_SET_ERRORS:
      return {...state, errors: action.errors};

    case actionTypes.USERS_SET_SECTIONS:
      return {...state, sections: action.sections};

    case actionTypes.USERS_SET_SELECTED_SECTION:
      return {...state, selectedSections: action.sections};

    default:
      return state;
  }
}
