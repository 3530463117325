import React from "react";
import AdminBaseLayoutContainer from "../../components/BaseLayout/AdminBaseLayoutContainer";
import TermsView from "./views/TermsView";


export default class Terms extends React.Component {

  render() {
    return (
      <AdminBaseLayoutContainer>
        <TermsView />
      </AdminBaseLayoutContainer>
    )
  }
}
