import {getBaseUrl} from '../../utils';
import io from '../../lib/io'

export async function add(data) {
  return await io.post(`${getBaseUrl()}/admin/belts`, data);
}

export async function getAll() {
  return await io.get(`${getBaseUrl()}/admin/belts`);
}

export async function save(id, data) {
  return await io.post(`${getBaseUrl()}/admin/belts/${id}/edit`, data);
}

export async function getById(id) {
  return await io.get(`${getBaseUrl()}/admin/belts/${id}`);
}

export async function remove(id) {
  return await io.post(`${getBaseUrl()}/admin/belts/${id}/delete`);
}

export default {
  add,
  getAll,
  save,
  getById,
  remove,
}
