import React from "react";
import {Button, Form, Icon, Input, Select, Switch, Upload} from "antd";
import moment from "moment";
import axios from "axios";
import {getBaseUrl} from "../../utils";
import IFormItem from "../../components/IFormItem";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {Editor} from "@tinymce/tinymce-react";
import AdminBaseLayoutContainer from "../BaseLayout/AdminBaseLayoutContainer";
import Cookies from "js-cookie";

import "./AdminNewsCreateForm.css";
import MyDatePicker from "../../admin/components/MyDatePicker/MyDatePicker";


const FormItem = Form.Item;
const Option = Select.Option;

moment.locale('ua');


class Avatar extends React.Component {


  onUpload = (e) => {
    this.props.uploadFile(e.file)
  }

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.props.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Завантажити</div>
      </div>
    );
    return (
      <Upload
        name="picture"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={this.onUpload}
      >
        {this.props.file ? <img width={200} src={this.props.file.url} alt="avatar" /> : uploadButton}
      </Upload>
    );
  }
}



export default class AdminNewsCreateForm extends React.Component {

  componentDidMount() {
    if (this.props.editMode && this.props.match.params.id && this.props.match.params.id !== 'create') {
      this.props.fetchNews(this.props.match.params.id)
    } else {
      this.props.clearForm()
    }
  }

  onChangeInput = (e) => {
    this.props.changeInput(e.target.name, e.target.value)
  }

  onChangeEditor = (editorState) => {
    this.props.changeInput('editorState', editorState);
  }

  onSubmit = (e) => {
    this.props.onSubmit(this.props.history)
  }

  render() {
    const ed = {
      image_dimensions: false,
      relative_urls: false,
      height : "480",
      plugins: 'code, print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount imagetools  contextmenu colorpicker textpattern help',
      toolbar1: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | image code',
      images_upload_handler: (blobInfo, success, failure) => {
        const token = Cookies.get('token');

        let headers = {
          'Content-Type': 'multipart/form-data'
        };

        if (token) {
          headers["Authorization"] = `${token}`;
        }
        const formData = new FormData();
        formData.append("picture", blobInfo.blob());
        const url = `${getBaseUrl()}/uploads/upload_news_attachments_file`;
        axios.post(url, formData, {
          headers: headers
        })
          .then((res) => {
            if (res.data.status === 'ok') {
              success(`${res.data.file.relative_url}`, "100%");
            } else {
              console.error("error upload");
              failure('Помилка при завантаженні');
              return;
            }
          });
      }
    };
    return (
      <AdminBaseLayoutContainer>
        <Form onSubmit={this.handleSubmit}>
          <FormItem
            label={'Дата публікації'}
          >
            <MyDatePicker
              value={this.props.date_started}
              onChange={(dt) => this.props.changeInput("date_started", dt)}
            />
          </FormItem>
          <FormItem
            label="Додати до слайдеру"
          >
            <Switch
              checked={this.props.is_main}
              onChange={(v) => this.props.changeInput("is_main", v)}
            />
          </FormItem>
          <FormItem
            label={'Зображення'}
          >
            <Avatar {...this.props}/>
            <span>Фото буде обрізано. Мінімальне співвідношення 980x550</span>
          </FormItem>
          <FormItem
            label={'Статус'}
          >
            <Select
              style={{ width: 200 }}
              onChange={(s) => this.props.changeInput("status", s)}
              value={this.props.status}
            >
              <Option value="public">Опубліковано</Option>
              <Option value="draft">Черновик</Option>
            </Select>
          </FormItem>

          <IFormItem
            label={'Заголовок'}
            errorKey="title"
            errors={this.props.errors}
          >
            <Input
              name="title"
              value={this.props.title}
              onChange={this.onChangeInput}
            />
          </IFormItem>
          <IFormItem
            label={'Інтро'}
            errorKey="intro"
            errors={this.props.errors}
          >
            <Input.TextArea
              rows="4"
              name="intro"
              value={this.props.intro}
              onChange={this.onChangeInput}
            />
          </IFormItem>
          <IFormItem
            label="Опис"
            errorKey="description"
            errors={this.props.errors}
          >
            <Editor
              apiKey='re6jcnkm2tvblni6kqytmvg6ucn24hgwjja9dzrhh7jzjqx2'
              { ...{init:ed}} onEditorChange={(v) => this.props.changeInput('description', v)} value={this.props.description}/>
          </IFormItem>
          <FormItem>
            <Button
              type="primary"
              htmlType="button"
              onClick={this.onSubmit}
            >
              Зберегти
            </Button>
          </FormItem>
        </Form>
      </AdminBaseLayoutContainer>
    );
  }
}
