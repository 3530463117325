import {getBaseUrl} from '../../utils';
import io from '../../lib/io'

export function getAllUrl(queryString) {
  const query = queryString ? `?${queryString}` : '';
  return `${getBaseUrl()}/admin/events${query}`;
}

export async function remove(id) {
  return await io.post(`${getBaseUrl()}/admin/events/${id}/delete`);
}

export function getRemoveUrl(id) {
  return`${getBaseUrl()}/admin/events/${id}/delete`
}

export default {
  remove,
  getAllUrl,
  getRemoveUrl,
}
