import axios from 'axios';
import {getBaseUrl} from '../../utils';
import Cookies from 'js-cookie';

export const loadUser = () => {
  return (dispatch, getState) => {
    dispatch({type: "USER_LOADING"});
    const token = Cookies.get('token');

    let headers = {
      "Content-Type": "application/json",
    };


    if (token) {
      headers["Authorization"] = `${token}`;
    }

    axios({
      method: 'get',
      url: `${getBaseUrl()}/admin/users/auth`,
      headers: headers
    })
    .then((res) => {
      if (res.data.status === 'ok') {
        if (res.data.user) {
          dispatch({type: 'USER_LOADED', user: res.data.user });
          return res.data;
        } else {
          Cookies.remove('token');
          dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
        }
      } else {
        Cookies.remove('token');
        dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
        return res.data;
      }
    });
  }
};

export const login = (email, password, history) => {
  return (dispatch, getState) => {
    axios.post(`${getBaseUrl()}/admin/login`, {email, password})
    .then((res) => {
      if (res.data.status === 'ok') {
        dispatch({type: 'LOGIN_SUCCESSFUL', data: res.data });
        history.push('/admin');
        return res.data;
      } else {
        dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
        throw res.data;
      }
    });
  }
};