
export const selectMenu = (selectedMenu) => {
  return (dispatch) => {
    dispatch({
        type: "SELECT_MENU",
        selectedMenu: selectedMenu,
    })
  }
};

export const selectSubMenu = (selectedSubMenu) => {
  return (dispatch) => {
    dispatch({
        type: "SELECT_SUB_MENU",
        selectedSubMenu: selectedSubMenu,
    })
  }
};


export const onToggleMenu = () => {
  return (dispatch) => {
    dispatch({
      type: "TOGGLE_MENU",
    })
  }
};
