import React from "react";
import PropTypes from "prop-types";
import {Breadcrumb, Card, Col, Collapse, Divider, Row, Spin, Modal} from "antd";
import {Link} from "react-router-dom";
import {formatDate, getServiceDomain} from "../../../../../utils";
import {Helmet} from "react-helmet";
import * as serviceTeams from "../../../../../services/public/teams";
import MemberInfo from "../../components/MemberInfo/index";
import AthleteDescription from "../../../Athletes/components/AthleteDescriptoin/index";
import * as athleteService from "../../../../../services/public/athletes";

export default class Team extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      team: null,
      members: [],
      teams: [],
      teamsLoading: true,
      visible: false,
      visibleAthlete: null,
      histories: [],
    }
  }

  componentDidMount() {
    const {id, type} = this.props;
    const fetch = id ? serviceTeams.getTeam(id) : serviceTeams.getCurrent(type);
    fetch.then((res) => {
      const {data} = res.data;

      if (!data) {
        return
      }
      this.setState({
        loading: false,
        team: data,
        members: data.members.filter(m => m.type === this.props.type)
      })
    })
  };

  onGetTeams = () => {
    this.setState({teamsLoading: true});
    serviceTeams.getAll(this.props.type).then((res) => {
      const {data} = res.data;
      this.setState({
        loading: false,
        teamsLoading: false,
        teams: data,
      })
    })
  };

  onCancel = () => {
    this.setState({
      visibleAthlete: null,
      visible: false,
    })
  };

  onShowDetail = async (id) => {
    this.setState({
      loadingDetails: true,
      visible: true,
    });
    const res = await athleteService.getById(id);
    const historiesRes = await athleteService.getHistories(id);
    this.setState({
      visibleAthlete: res.data.data,
      histories: historiesRes.data.athlete_histories,
      loadingDetails: false,
    })
  };


  render() {
    const {team, members, teams} = this.state;
    if (!team) {
      return (
        <Card title="Немає данних"/>
      )
    }
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.props.title} - Українська Федерація карате</title>
        </Helmet>
        <Breadcrumb style={{ marginTop: '20px', marginBottom: "5px" }}>
          <Breadcrumb.Item>
            <Link to="/">
              {getServiceDomain()}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {this.props.title}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          title={<h1
            style={{fontSize: "21px"}}
          >
            {team && team.title}
            <br/>
            <a
              href={team.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`(${formatDate(team.date_started)} - ${formatDate(team.date_expired)}, ${team.location})`}
              </a>
          </h1>
          }
          loading={this.state.loading}
          hoverable
        >
          <Row gutter={24} type="flex" justify="center" align="top">
            {members.map(r => (
              <Col key={r.id} xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} style={{marginTop: "20px"}}>
                <MemberInfo
                  id={r.id}
                  fullName={r.full_name}
                  category={r.category}
                  challenges={r.challenges}
                  logoUrl={r.logo && r.logo.url}
                  onClick={() => this.onShowDetail(r.id)}
                />
              </Col>
            ))}
          </Row>
          {team.files.length > 0 &&
          <React.Fragment>
            <Divider>Документи:</Divider>
            <Row>
              <Col>
                <ul>
                  {team.files.map((f) =>
                    <li key={f.id}>
                      <a style={{overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }} target="_blank" rel="noopener noreferrer" href={f.url}>
                        {f.original_name}
                      </a>
                    </li>
                  )}
                </ul>
              </Col>
            </Row>
          </React.Fragment>
          }
          {!this.props.id &&
          <React.Fragment>
            <Collapse onChange={this.onGetTeams} style={{marginTop: "20px"}}>
              <Collapse.Panel header="Переглянути архів збірних" key="1">
                {this.state.teamsLoading
                  ? <Spin />
                  : <div>
                    {teams.length === 0 &&
                    <div>Немає данних</div>
                    }
                    <ul>
                      {teams.map((t) =>
                        <li key={t.id}>
                          <a
                            href={`/teams/${this.props.type}/${t.id}`}>
                            {t.title}
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                }
              </Collapse.Panel>
            </Collapse>
          </React.Fragment>
          }
        </Card>
        {this.state.visible &&
        <Modal
          title={this.state.visibleAthlete && `${this.state.visibleAthlete.last_name} ${this.state.visibleAthlete.first_name} ${this.state.visibleAthlete.middle_name}`}
          visible={this.state.visible}
          onCancel={this.onCancel}
          footer={null}
        >
          {this.state.loadingDetails && <Row type="flex" justify="space-around" align="middle"><Spin size="large"/></Row>}
          {!this.state.loadingDetails && this.state.visibleAthlete &&
          <AthleteDescription
            logo={this.state.visibleAthlete.logo}
            region={this.state.visibleAthlete.region_name}
            club={this.state.visibleAthlete.club_name}
            kyu={this.state.visibleAthlete.kyu}
            dan={this.state.visibleAthlete.dan}
            birthDate={this.state.visibleAthlete.birth_date}
            athleteHistories={this.state.histories}
          />
          }
        </Modal>
        }
      </React.Fragment>
    )
  }
}

Team.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
};
