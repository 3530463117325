import React, {Component} from "react";
import {Badge, Button, Card, Col, Divider, Icon, List, Row} from "antd";
import axios from "axios";
import {getBaseUrl} from "./utils";
import {Link} from "react-router-dom";
import Slider from "react-slick";
import moment from "moment";
import PublicBaseLayout from "./components/BaseLayout/PublicBaseLayout";
import newsPublicService from "./services/public/news";
import {Helmet} from "react-helmet";
import logo from "../public/logo.png";
import "./Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const IconText = ({ type, text }) => (
  <span>
    <Icon type={type} style={{ marginRight: 8 }} />
    {text}
  </span>
);


class Home extends Component {

  static async getInitialProps() {
    try {
      const res_main_news = await newsPublicService.getMainNews();
      const res_last_news = await newsPublicService.getLastNews();
      return { news: res_last_news.data.news, mainNews: res_main_news.data.news};
    } catch (err) {
      console.error(err)
    }
  }

  state = {
    loading: true,
    news: [],
    events: [],
    mainNews: [],
    partners: [],
    tournaments: [],
    banners: [],
    sliderHover: false,
  };

  componentDidMount() {

    this.getEvents((res) => {
      this.setState({
        loading: false,
        events: res.data.events,
      });
    });

    this.getPartners((res) => {
      this.setState({
        loading: false,
        partners: res.data.partners,
      });
    });

    this.getTournaments((res) => {
      this.setState({
        loading: false,
        tournaments: res.data.tournaments,
      });
    });

    this.getBanners((res) => {
      this.setState({
        loading: false,
        banners: res.data.banners,
      });
    });

  }

  getEvents = (callback) => {
    const url = `${getBaseUrl()}/public/last-events`;
    axios.get(url)
      .then((res) => {
        callback(res);
      });
  };

  getPartners = (callback) => {
    const url = `${getBaseUrl()}/public/partners`;
    axios.get(url)
      .then((res) => {
        callback(res);
      });
  };

  getTournaments = (callback) => {
    const url = `${getBaseUrl()}/public/tournaments`;
    axios.get(url)
      .then((res) => {
        callback(res);
      });
  };

  getBanners = (callback) => {
    const url = `${getBaseUrl()}/public/bnrs`;
    axios.get(url)
      .then((res) => {
        callback(res);
      });
  };

  toggleHover = () => {
    this.setState({sliderHover: !this.state.sliderHover})
  };

  render() {
    const partnerSliderSettings = {
      dots: false,
      infinite: true,
      slidesToShow: Math.min(this.state.partners.length, 5),
      slidesToScroll: this.state.partners.length / 2,
      autoplay: true,
      speed: 5000,
      autoplaySpeed:2000,
      width: 300,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 5000,
            autoplaySpeed:2000,
            cssEase: "linear",
            width: 300,
          }
        }
      ]
    };
    const newsSliderSettings = {
      dots: true,
      infinite: true,
      width: 980,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      adaptiveHeight: true,
      swipeToSlide: true,
    };
    const tournamentSliderSettings = {
      dots: false,
      infinite: true,
      speed: 800,
      slidesToShow: Math.min(3, this.state.tournaments.length),
      slidesToScroll: Math.min(3, this.state.tournaments.length),
      autoplay: true,
      width: 300,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            width: 300,
          }
        }
      ]

    };

    const renderText = this.state.sliderHover ? 24 : 0;
    return (
      <PublicBaseLayout>
        <Helmet>
          <title>Українська федерація карате</title>

          <meta name="description" content="Сайт Української Федерації карате" />

          <meta property="og:url" content="https://karate.ua"/>
          <meta property="og:image" content={`https://karate.ua${logo}`} />
          <meta property="og:title" content="Українська федерація карате" />
          <meta property="og:description" content="Сайт Української Федерації карате" />

          <meta name="twitter:image" content={`https://karate.ua${logo}`} />
          <meta name="twitter:title" content="Українська федерація карате" />
          <meta name="twitter:description" content="Сайт Української Федерації карате" />
          <link rel="image_src" href={`https://karate.ua${logo}`} />
        </Helmet>
        <div style={{marginTop: "10px"}}></div>
        <Row>
          <Col span={24} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
            <Slider {...newsSliderSettings} style={{opacity: 1}}>
              {this.props.mainNews && this.props.mainNews.map((obj) =>
                <div key={obj.id} >
                  <Link to={`/news/${obj.slug}`}>
                    <img alt={obj.title} src={obj.picture_url} width={"100%"}
                         style={{objectFit: "cover", maxHeight: "550px"}}/>
                    <Row>
                      <Col xs={24} sm={24} md={renderText} lg={renderText} xl={renderText}>
                        <div key={obj.id} className="slider-text">
                          <span>{obj.title}</span>
                        </div>
                      </Col>
                    </Row>
                  </Link>
                </div>
              )}
            </Slider>
          </Col>
        </Row>
        <Row>
          <Col>
            <Divider />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <Row style={{marginTop: "11px"}}>
              <Col>
                <Card
                  loading={this.state.loading}
                  title="Останні новини"
                  style={{ width: '100%' }}
                  actions={[
                    <Button type="link" href="/news">Всі новини<Icon type="double-right" /></Button>
                  ]}
                >
                  <List
                    itemLayout="vertical"
                    dataSource={this.props.news ? this.props.news : []}
                    locale={{emptyText: "Немає новин"}}
                    renderItem={n => (
                      <List.Item
                        actions={[
                          <IconText type="calendar" text={moment(n.date_started).format("DD.MM.YYYY")} />
                        ]}
                      >
                        <Row gutter={12}>
                          <Col xs={24} sm={24} md={24} lg={10} xl={8}>
                            <Link to={`/news/${n.slug}`} >
                              <img width={"100%"} alt="logo" src={n.picture_url}/>
                            </Link>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={16}>
                            <List.Item.Meta
                              title={<Link to={`/news/${n.slug}`} >{n.title}</Link>}
                              description={n.intro}
                            />
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </Card>
              </Col>
            </Row>
            <Row>
              <Divider />
              <Card
                loading={this.state.loading}
                title="Найближчі події"
                style={{ width: '100%' }}
              >
                <List
                  itemLayout="horizontal"
                  dataSource={this.state.events}
                  locale={{emptyText: "Немає подій"}}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Badge count={`${moment(item.date_started).format("DD.MM.YYYY")} - ${moment(item.date_expired).format("DD.MM.YYYY")}`} style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }} />}
                        title={<a href={item.link} target="_blank" rel="noopener noreferrer">{item.title}</a>}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Row>
          </Col>
          <Col xs={0} sm={0} md={6} lg={6} xl={6}>
            {this.state.banners.length > 0 &&
            <List
              itemLayout="horizontal"
              dataSource={this.state.banners}
              locale={{emptyText: "Немає турнірів"}}
              renderItem={item => (
                <List.Item
                  extra={
                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                      <img width={"100%"} alt={item.title} src={item.file.url} />
                    </a>
                  }
                >
                </List.Item>
              )}
            />
            }
          </Col>
        </Row>
        {this.state.tournaments.length > 0 &&
        <Row>
          <Divider />
          <Col>
            <Card
              title="Закордонні турніри"
              style={{ width: '100%' }}
            >
              <Slider {...tournamentSliderSettings} style={{height: "150px", lineHeight: "150px"}}>
                {this.state.tournaments.map((obj) =>
                  <a key={obj.id} href={obj.link} target="_blank" rel="noopener noreferrer">
                    {obj.title}
                    <img alt={obj.title} src={obj.file.url} style={{ width: "300px" }} />
                  </a>
                )}
              </Slider>
            </Card>
          </Col>
        </Row>
        }
        {this.state.banners.length > 0 &&
        <Row >
          <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
            <Card
              style={{ width: '100%' }}
            >
              <List
                itemLayout="horizontal"
                dataSource={this.state.banners}
                locale={{emptyText: "Немає турнірів"}}
                renderItem={item => (
                  <List.Item
                    extra={
                      <a href={item.link} target="_blank" rel="noopener noreferrer">
                        <img width={"100%"} alt={item.title} src={item.file.url} />
                      </a>
                    }
                  >
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        </Row>
        }
        {this.state.partners.length > 0 &&
        <Row>
          <Divider />
          <Col>
            <Card
              hoverable
              title="Партнери"
              style={{ width: '100%' }}
            >
              <Slider {...partnerSliderSettings}>
                {this.state.partners.map((obj) =>
                  <a key={obj.id} href={obj.link} target="_blank" rel="noopener noreferrer">
                    <img alt={obj.title} src={obj.file.url} style={{ margin: "0 auto", width: "60%" }} />
                  </a>
                )}
              </Slider>
            </Card>
          </Col>
        </Row>
        }
      </PublicBaseLayout>
    );
  }
}

export default Home;