import React from "react";
import {Avatar, Divider, message, Table} from "antd";
import AdminFilters from "../AdminFilters";
import AdminActionDropdown from "../AdminActionDropdown";
import {
  formatDate,
  SERVER_BASE_ERROR_MSG,
  SUCCESS_DELETED_MSG
} from "../../utils";
import service from "../../services/admin/news";
import AdminBaseLayoutContainer from "../BaseLayout/AdminBaseLayoutContainer";



function getNewsStatusTitle(status) {
  const statuses =  {
    public: 'Опубліковано',
    draft: 'Черновик'
  };
  return statuses[status] || 'Не вказано'
}

export default class AdminNewsList extends React.Component {
  state = {
    loading: true,
    loadingMore: false,
    showLoadingMore: true,
    data: [],
  }
  componentDidMount() {
    this.getData((res) => {
      this.setState({
        loading: false,
        data: res.data.news,
      });
    });
  }
  getData = (callback) => {
    service.getAll().then((res) => {
      callback(res);
    });
  };


  onDelete = (itemId) => {
    try {
      this.setState({loading: true});
      service.remove(itemId).then((res) =>{
        if (res.data.status === 'ok') {
          this.getData((res) => {
            this.setState({
              loading: false,
              data: res.data.news,
            });
          });
          message.success(SUCCESS_DELETED_MSG.text)
        }
      });

    } catch (err) {
      message.error(SERVER_BASE_ERROR_MSG.text);
    }
  };

  render() {
    const columns = [
      {
        title: 'Обкладинка',
        dataIndex: 'picture_url',
        key: 'picture_url',
        render: (t, r) => <Avatar src={r.file.url}/>
      },
      {
        title: 'Заголовок',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Дата публікації',
        dataIndex: 'date_started',
        key: 'date_created',
        render: (t, r) => formatDate(t),
      },
      {
        title: 'Дата оновлення',
        dataIndex: 'date_updated',
        key: 'date_updated',
        render: (t, r) => formatDate(t),
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (t, r) => getNewsStatusTitle(r.status)
      },
      {
        title: 'Дії',
        dataIndex: 'action',
        key: 'action',
        render: (text, item) => (
          <AdminActionDropdown
            {...this.props}
            onEdit={(itemId) => this.props.history.push(`/admin/news/${itemId}`)}
            onDelete={this.onDelete}
            item={item}
          />
        ),
      }
    ];
    const { data } = this.state;
    return (
      <AdminBaseLayoutContainer>
        <h2>Список новин</h2>
        <Divider/>
        <AdminFilters
          {...this.props}
          onAdd={() => this.props.history.push(`/admin/news-create`)}

        />
        <Table
          columns={columns}
          dataSource={data}
          rowKey="id"
          loading={this.props.loading}
          locale={{emptyText: "Немає подій"}}
        />
      </AdminBaseLayoutContainer>
    );
  }
}
