import Cookies from 'js-cookie';

const initialState = {
  token: Cookies.get("token"),
  menuCollapsed: false,
  selectedMenu: [],
  selectedSubMenu: [],
  foo: null,
};


export default function news(state=initialState, action) {
  switch (action.type) {

    case 'INIT':
      return{...state, foo: action.foo};

    case 'SELECT_MENU':
      return {...state, selectedMenu: action.selectedMenu};

    case 'SELECT_SUB_MENU':
      return {...state, selectedSubMenu: action.selectedSubMenu};

    case 'TOGGLE_MENU':
      return {...state, menuCollapsed: !state.menuCollapsed};

    default:
      return state;
  }
}
