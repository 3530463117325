import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import FilterContext from "./contexs";
import * as io from "../../../lib/io";


export const toQueryString = (params) => {
  const fetcherMap = {
    select: (v) => v,
    search: (v) => v,
    text: (v) => v,
  };
  return Object.entries(params).map((q) =>
    [q[0], fetcherMap[q[1].type]? fetcherMap[q[1].type](q[1].value) : q[1]].join('=')).join('&')
};

class FilterProvider extends React.Component {

  render() {
    const {
      children, data, onChange,
      loading, fields, onClearAll,
      showForm, editRow, onAdd,
      onEdit, onCancel, onRemove,
      onDownload,
    } = this.props;
    return (
      <FilterContext.Provider
        value={{ data, onChange, fields }}
      >
        {children({
          loading,
          data,
          onChange,
          onClearAll,
          fields,
          showForm,
          editRow,
          onAdd,
          onEdit,
          onCancel,
          onRemove,
          onDownload,
        })}
      </FilterContext.Provider>
    );
  }
}


class FilterProviderContainer extends React.Component {

  constructor(props) {
    super(props);
    this.fetch = _.debounce(this.fetchData, 1500, { 'maxWait': 4 });
    this.state = {
      fields: {},
      data: null,
      editRow: null,
      showForm: false,
      loading: true,
    }
  }

  componentDidMount() {
    const queryString = toQueryString(this.state.fields);
    this.fetchData(queryString);
  }

  onChange = (field, type, value) => {
    const fields = Object.assign(
      this.state.fields, {
        page: 1,
        [field]: {type, value},
      });

    this.setState({
      fields: fields,
      loading: true,
    });

    const queryString = toQueryString(fields);
    if (type === "search") {
      this.fetch(queryString);
    } else {
      this.fetchData(queryString)
    }
  };

  onClearAll = () => {
    this.setState({
      fields: {},
      loading: true,
    });
    const queryString = toQueryString({});
    this.fetchData(queryString);
  };

  fetchData = (queryString) => {
    io.get(this.props.onFetchUrl(queryString)).then((res) => {
      this.setState({
        loading: false,
        data: res.data
      })
    })
  };

  onAdd = () => {
    this.setState({
      editRow: null,
      showForm: true,
    })
  };

  onEdit = (item) => {
    this.setState({
      editRow: item,
      showForm: true
    })
  };

  onDownload = (itemId) => {
    io.post(this.props.onDownloadUrl(itemId)).then((res) => {
      window.open(res.data.file_url, "_blank");
    })
  };

  onRemove = (rowId) => {
    io.post(this.props.onRemoveUrl(rowId))
    .then((res) => this.setState(
      {loading: true},
      () => this.fetchData(toQueryString(this.state.fields)))
    );
  };

  onCancel = () => {
    this.setState({
      showForm: false,
      editRow: null,
    })
  };

  render() {
    return (
      <FilterProvider
        loading={this.state.loading}
        data={this.state.data}
        fields={this.state.fields}
        onChange={this.onChange}
        onClearAll={this.onClearAll}
        showForm={this.state.showForm}
        editRow={this.state.editRow}
        onAdd={this.onAdd}
        onEdit={this.onEdit}
        onCancel={this.onCancel}
        onRemove={this.onRemove}
        onDownload={this.onDownload}
      >
        {this.props.children}
      </FilterProvider>
    );
  }
};


FilterProviderContainer.propTypes = {
  onRemoveUrl: PropTypes.func,
  onFetchUrl: PropTypes.func,
};

export default FilterProviderContainer