import React from "react";
import {Avatar, Col, Descriptions, Divider, Row, Table} from "antd";
import {calculateAge, formatDate} from "../../../../../utils";
import {getRankTitle} from "../../../../../admin/athlete_certifications/components/MemberForm/utils";
import {formatGrade} from "../../../../../utils";

const columns = [
  {
    title: 'Дата',
    dataIndex: 'date',
    width: 100,
    render: (t, r) => formatDate(r.date),
  },
  {
    title: 'Турнір',
    dataIndex: 'event_name',
    width: 180,
    render: (t, r) => {
      if (r.draw_url) {
        if (r.draw_url.search('eventId=130500') > 0 || r.draw_url.search('eventId=12400') > 0) {
          return (
            <a href="#">{r.event_name}</a>
          )
        }
        return (
          <a href={r.draw_url} target="_blank" rel="noopener noreferrer">{r.event_name}</a>
        );
      }
      return r.event_name

    },
  },
  {
    title: 'Категория',
    dataIndex: 'category_name',
  },
  {
    title: 'Місце',
    dataIndex: 'rank',
  },
];


const AthleteDescription = ({logo, birthDate, region, club, kyu, dan, athleteHistories, rank}) => {
  return (
    <React.Fragment>
      <Row gutter={24}>
        <Col span={10}>
          <Avatar
            src={logo && logo.url}
            style={{margin: "0 auto", display: "block"}}
            shape="square"
            size={150}
          >
            Avatar
          </Avatar>
        </Col>
        <Col span={14}>
          <Descriptions
            size="small"
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          >
            <Descriptions.Item label="Дата народження">{formatDate(birthDate)} ({calculateAge(new Date(birthDate))})</Descriptions.Item>
            <Descriptions.Item label="Регіон">{region}</Descriptions.Item>
            <Descriptions.Item label="Клуб">{club}</Descriptions.Item>
            <Descriptions.Item label="Кю/Дан">{rank ? getRankTitle(rank) : formatGrade(kyu, dan)}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Divider><b>Статистика</b></Divider>
      <Row type="flex" justify="space-around" align="middle">
        <Col span={24}>
          <Table
            scroll={{ x: 350 }}
            rowKey="id"
            columns={columns}
            dataSource={athleteHistories}
            size="middle"
            locale={{emptyText: "Немає данних"}}
            pagination={false}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};


export default AthleteDescription;
