import React from "react";
import {Spin} from "antd";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {auth} from "../../actions";

const mapStateToProps = state => {
  return {
    auth: state.auth,
    token: state.adminApp.token,
    ...state.adminApp,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadUser: () => {
      return dispatch(auth.loadUser());
    }
  };
};


const withAdminAuth = (WrappedComponent) => {
  class AdminAuthWrapped extends React.Component {

    componentDidMount() {
      this.props.loadUser();
    }

    render() {
      if (this.props.auth.isLoading) {
        return (
          <div style={{position:"fixed", top:"50%", left: "50%"}}>
            <Spin tip="Завантаження..." spinning={true} size="large"/>
          </div>
        );
      } else if (!this.props.auth.isAuthenticated) {
        return <Redirect to="/login" />
      } else {
        return (
          <WrappedComponent
            {...this.props}
          />
        )
      }

    }
  }

  return AdminAuthWrapped;
};

export default (component) => connect(mapStateToProps, mapDispatchToProps)(withAdminAuth(component));
