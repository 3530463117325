import React from "react";
import {Form, Input, Select} from "antd";
import IFormItem from "../../../../components/IFormItem";
import RemoteSelect from "../../../components/RemoteSelect/index";
import {RANK_OPTIONS} from "../../utils";
import moment from "moment";
import * as refereeService from "../../../../services/admin/referees";
import MyDatePicker from "../../../components/MyDatePicker/MyDatePicker";
import {toQueryString} from "../../../../utils";


const MemberForm = ({referee_id, order_num, location, head, certificate_num, certificate_date, rank, errors, onChange}) => {
  return (
    <Form>
      <IFormItem
        label="Суддя"
        errorKey="referee_id"
        errors={errors}
      >
        <RemoteSelect
          showSearch
          prefetch
          allowClear
          fetchUrlFunc={(value) => refereeService.getAllUrl(toQueryString({query: value, referee_id: referee_id}))}
          dataKey="referees"
          valueKey="id"
          titleKeys={["first_name", "last_name"]}
          placeholder="Виберіть суддю"
          value={referee_id}
          onChange={(value) => onChange("referee_id", value)}
        />
      </IFormItem>
      <IFormItem
        label="Номер сертіфікату"
        errorKey="certificate_num"
        errors={errors}
      >
        <Input
          name="certificate_num"
          placeholder="Введіть номер сертіфікату"
          value={certificate_num}
          onChange={(e) => onChange(
            e.target.name,
            e.target.value
          )}
        />
      </IFormItem>
      <IFormItem
        label="Строк дії ліцензії"
        errorKey="certificate_date"
        errors={errors}
      >
        <MyDatePicker
          value={certificate_date ? moment(certificate_date) : moment().add(1, 'years')}
          onChange={(v) => {
            onChange("certificate_date", v);
          }}
        />
      </IFormItem>
      <IFormItem
        label="Ктегорія"
        errorKey="rank"
        errors={errors}
      >
        <Select
          onChange={(s) => onChange("rank", s)}
          value={rank}
        >
          {RANK_OPTIONS.map(o =>
            <Select.Option key={o.value} value={o.value}>{o.label}</Select.Option>
          )}
        </Select>
      </IFormItem>
    </Form>
  );
};

export default MemberForm