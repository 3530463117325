import React from "react";
import FilterItem from "../../FilterItem";
import RemoteSelect from "../../../RemoteSelect";


const RemoteSelectFilter = (props) => {

  return (
    <FilterItem
      field={props.field}
      type="select"
    >
      {({onChange, value}) =>
        <RemoteSelect
          {...props}
          fetchUrl={props.fetchUrl}
          dataKey={props.dataKey}
          valueKey={props.valueKey}
          titleKey={props.titleKey}
          placeholder={props.placeholder}
          value={value}
          onChange={onChange}
        />
      }
    </FilterItem>
  )
};

export default RemoteSelectFilter