import React from "react";
import {Button, Table, Divider, notification, Icon} from "antd";
import TermsForm from "../components/TermsForm";
import AdminActionDropdown from "../../../components/AdminActionDropdown";
import * as pageService from "../../../services/admin/pages";
import * as documentService from "../../../services/admin/documents";

export default class TermsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      description: '',
      title: '',
      showModal: false,
      is_new: false,
      loading: false,
      docs: [],
      files: [],
    }
  }

  componentDidMount() {
    pageService.getBySlug('terms').then((res) => {
      const {data} = res.data;
      documentService.getByObjectType(data.id, 'terms').then((resDoc) => {
        this.setState({
          content_object_id: data.id,
          description: data.description,
          docs: resDoc.data.data
        })
      })
    });
  }

  onChangeInput = (name, value) => {
    this.setState({[name]: value})
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
      currentDocId: null,
      title: '',
      file: null,
      is_new: false,
    })
  };

  onAddDocument = async () => {
    let content_object_id = this.state.content_object_id;
    const doc = {
      title: this.state.title,
      file: this.state.file && this.state.file.id,
      is_new: this.state.is_new,
      content_object_id: content_object_id,
      content_type: "terms",
    };
    if (!this.state.content_object_id) {
      const res = await pageService.add(doc);
      const {data} = res.data;
      if (res.data.status === 'ok') {
        content_object_id = data.content_object_id;
        this.setState({
          loading: false,
          showModal: false,
          content_object_id: content_object_id,
          currentId: null,
        })
      } else {
        this.setState({
          loading: false,
          errors: res.data.errors,
        })
      }
    }

    let res;
    if (this.state.currentDocId) {
      res = await documentService.save(this.state.currentDocId, doc);
    } else {
      res = await documentService.add(doc);
    }

    if (res.data.status === 'ok') {
      this.setState({
        loading: false,
        showModal: false,
        docs: this.state.docs.filter(d => d.id !== this.state.currentDocId).concat(res.data.data)
      })
    } else {
      this.setState({
        loading: false,
        errors: res.data.errors,
      })
    }
  };


  uploadFile = (file) => {
    pageService.uploadFile(file)
      .then((res) => {
        this.setState({
          file: res.data.file,
          files: this.state.files.concat(res.data.file),
        });
      })
  };

  removeFile = (file) => {
    this.setState({files: this.state.files.filter(f => f.id !== file.uid)})
  };

  onSubmit = async () => {
    const data = {
      description: this.state.description,
      slug: 'terms',
      documents: this.state.docs,

    };
    const res = await pageService.add(data);
    if (res.data.status === 'ok') {
      this.setState({
        loading: false,
        showModal: false,
        editMode: false,
        currentId: null,
      });
      notification.open({
        placement: 'topLeft',
        message: 'Успішно збережено',
        icon: <Icon type="check" style={{ color: 'green' }} />,
      });
    } else {
      this.setState({
        loading: false,
        errors: res.data.errors,
      })
    }
  };

  onEditDocument = (rowId) => {
    const currentDoc = this.state.docs.filter(d => d.id === rowId)[0];

    if (!currentDoc) {
      return
    }
    this.setState({
      currentDocId: rowId,
      showModal: true,
      title: currentDoc.title,
      is_new: currentDoc.is_new,
      file: currentDoc.file,
    })
  };

  onDeleteDocument = async (rowId) => {
    documentService.remove(rowId)
      .then((res) => {
        this.setState({
          docs: this.state.docs.filter(d => d.id !== rowId)
        });
      })
  };

  render() {
    const columns = [
      {
        title: 'Файл',
        dataIndex: 'file',
        key: 'file',
        render: (t, r) => <a src={r.file.url}>{r.title}</a>
      },
      {
        title: 'Дії',
        dataIndex: 'action',
        key: 'action',
        render: (text, r) => (
          <AdminActionDropdown
            {...this.props}
            onEdit={(item) => this.onEditDocument(r.id)}
            onDelete={(item) => this.onDeleteDocument(r.id)}
            item={r}
          />
        ),
      }
    ];
    return (
      <React.Fragment>
        <h2>Умови вступу</h2>
        <Divider/>
        <TermsForm
          errors={this.state.errors}
          description={this.state.description}
          showModal={this.state.showModal}
          file={this.state.file}
          title={this.state.title}
          isNew={this.state.is_new}
          onUploadFile={this.uploadFile}
          changeInput={this.onChangeInput}
          onAddDocument={this.toggleModal}
          onSubmitDocument={this.onAddDocument}
          onCancel={this.toggleModal}
          onSumbit={this.onSubmit}
        />
        <Divider/>
        {this.state.docs.length > 0 &&
        <Table
          columns={columns}
          dataSource={this.state.docs}
          rowKey="id"
          loading={this.state.loading}
          locale={{emptyText: "Немає докуменів"}}
        />
        }
        <Divider/>
        <Button
          type="primary"
          onClick={this.onSubmit}
        >
          Зберегти
        </Button>
      </React.Fragment>
    );
  }
}