import Home from './Home';
import NewsList from './components/NewsList/NewsList'
import News from './components/News/News'
import AdminLogin from "./components/AdminLogin/AdminLoginContainer"
import StructureList from "./components/StructureList/StructureList";
import DocsList from "./components/DocsList/DocsList";
import CompetitionsCalendarContainer
  from "./components/CompetitionsCalendar/CompetitionsCalendarContainer";
import RulesList from "./site/pages/Rules/RulesList";
import Dashboard from "./admin/dashboard/Dashboard";
import AdminNewsList from "./components/AdminNewsList/AdminNewsList";
import AdminNewsCreateFormContainer
  from "./components/AdminNewsCreateForm/AdminNewsCreateFormContainer";
import UserListContainer from "./components/UserList/UserListContainer";
import AdminEventListContainer
  from "./components/AdminEventsList/AdminEventListContainer";
import AdminEventContainer
  from "./components/AdminEventForm/AdminEventContainer";
import RulesListContainer from "./admin/rules/ListContainer";
import AdminFederationStructureContainer
  from "./components/AdminFederatoinStructure/AdminFederationStructureContainer";
import AdminFederationStructureFormContainer
  from "./components/AdminFederatoinStructure/AdminFederationStructureFormContainer";
import AdminDocumentsFormContainer
  from "./components/AdminDocumentsList/AdminDocumentsFormContainer";
import AdminDocumentsListContainer
  from "./components/AdminDocumentsList/AdminDocumentsListContainer";
import PartnerListContainer from "./admin/partners/PartnerListContainer";
import TournamentsListContainer from "./admin/tournaments/ListContainer";
import BannerListContainer from "./admin/banners/BannerListContainer";
import BeltList from "./admin/belts";
import AdminNewsEditFormContainer
  from "./components/AdminNewsCreateForm/AdminNewsEditFormContainer";
import Agencies from "./site/pages/Agencies";
import Athletes from "./site/pages/Athletes";
import Trainers from "./site/pages/Trainers";
import Belts from "./site/pages/Belts";
import Referees from "./site/pages/Referees";
import NationalTeam from "./site/pages/Teams/views/NationalTeamsView";
import YouthTeamsView from "./site/pages/Teams/views/YouthTeamsView";
import Terms from "./site/pages/Terms";
import Statistics from "./site/pages/Statistics";

import AdminAuth from "./admin/auth/withAdminAuth";
import CanWrapper from "./admin/permissions/CanWrapper";
import AdminAgencies from "./admin/agencies";
import AdminClubs from "./admin/clubs";
import AdminAthletes from "./admin/athletes";
import AdminTrainers from "./admin/trainers";
import AdminReferees from "./admin/referees";
import AdminTeams from "./admin/teams";
import AdminTerms from "./admin/terms";
import AdminAthleteCertifications from "./admin/athlete_certifications";
import AdminTrainerCertifications from "./admin/trainer_certifications";
import AdminRefereeCertifications from "./admin/referee_certifications";
import AdminSyncSportsmen from "./admin/syncs_sportsmen";
import AdminSyncTrainer from "./admin/syncs_trainers";

import Contacts from "./site/pages/Contacts";

export default [
  {
    path: '/',
    component: Home,
    exact: true
  },
  {
    path: '/news',
    exact: true,
    component: NewsList,
  },
  {
    path: '/news/:id',
    exact: true,
    component: News,
  },
  {
    path: '/federation/structure',
    exact: true,
    component: StructureList,
  },
  {
    path: '/federation/docs',
    exact: true,
    component: DocsList,
  },
  {
    path: '/federation/agencies',
    exact: true,
    component: Agencies,
  },
  {
    path: '/federation/belts',
    exact: true,
    component: Belts,
  },
  {
    path: '/federation/terms',
    exact: true,
    component: Terms,
  },
  {
    path: '/federation/statistics',
    exact: true,
    component: Statistics,
  },
  {
    path: '/federation/contacts',
    exact: true,
    component: Contacts,
  },
  {
    path: '/teams/national',
    exact: true,
    component: NationalTeam,
  },
  {
    path: '/teams/youth',
    exact: true,
    component: YouthTeamsView,
  },
  {
    path: '/teams/national/:id',
    exact: true,
    component: NationalTeam,
  },
  {
    path: '/teams/youth/:id',
    exact: true,
    component: YouthTeamsView,
  },
  {
    path: '/competitions/calendar',
    exact: true,
    component: CompetitionsCalendarContainer,
  },
  {
    path: '/competitions/rules',
    exact: true,
    component: RulesList,
  },
  {
    path: '/members/athletes',
    component: Athletes,
  },
  {
    path: '/members/trainers',
    component: Trainers,
  },
  {
    path: '/members/referees',
    exact: true,
    component: Referees,
  },
  {
    exact: true,
    path: '/admin',
    component: AdminAuth(Dashboard),
  },
  {
    path: '/admin/users',
    component: AdminAuth(CanWrapper("users")(UserListContainer)),
    exact: true
  },
  {
    path: '/admin/news',
    component: AdminAuth(CanWrapper("news")(AdminNewsList)),
    exact: true
  },
  {
    path: '/admin/news-create',
    component: AdminAuth(CanWrapper("news")(AdminNewsCreateFormContainer)),
    exact: true
  },
  {
    path: '/admin/news/:id',
    component: AdminAuth(CanWrapper("news")(AdminNewsEditFormContainer)),
    exact: true
  },
  {
    path: '/admin/events',
    component: AdminAuth(CanWrapper("events")(AdminEventListContainer)),
    exact: true
  },
  {
    path: '/admin/event-create',
    component: AdminAuth(CanWrapper("events")(AdminEventContainer)),
    exact: true
  },
  {
    path: '/admin/event/:id',
    component: AdminAuth(CanWrapper("events")(AdminEventContainer)),
    exact: true
  },
  {
    path: '/admin/rules',
    component: AdminAuth(CanWrapper("rules")(RulesListContainer)),
    exact: true
  },
  {
    path: '/admin/structures',
    component: AdminAuth(CanWrapper("structures")(AdminFederationStructureContainer)),
    exact: true
  },
  {
    path: '/admin/structure-create',
    component: AdminAuth(CanWrapper("structures")(AdminFederationStructureFormContainer)),
    exact: true
  },
  {
    path: '/admin/structure/:id',
    component: AdminAuth(CanWrapper("structures")(AdminFederationStructureFormContainer)),
    exact: true
  },
  {
    path: '/admin/documents',
    component: AdminAuth(CanWrapper("docs")(AdminDocumentsListContainer)),
    exact: true
  },
  {
    path: '/admin/doc-create',
    component: AdminAuth(CanWrapper("docs")(AdminDocumentsFormContainer)),
    exact: true
  },
  {
    path: '/admin/document/:id',
    component: AdminAuth(CanWrapper("docs")(AdminDocumentsFormContainer)),
    exact: true
  },
  {
    path: '/admin/partners',
    component: AdminAuth(CanWrapper("partners")(PartnerListContainer)),
    exact: true
  },
  {
    path: '/admin/tournaments',
    component: AdminAuth(CanWrapper("tournaments")(TournamentsListContainer)),
    exact: true
  },
  {
    path: '/admin/banners',
    component: AdminAuth(CanWrapper("banners")(BannerListContainer)),
    exact: true
  },
  {
    path: '/admin/belts',
    component: AdminAuth(CanWrapper("belts")(BeltList)),
    exact: true
  },
  {
    path: '/admin/agencies',
    component: AdminAuth(CanWrapper("regions")(AdminAgencies)),
    exact: true
  },
  {
    path: '/admin/clubs',
    component: AdminAuth(CanWrapper("clubs")(AdminClubs)),
    exact: true
  },
  {
    path: '/admin/athletes',
    component: AdminAuth(CanWrapper("athletes")(AdminAthletes)),
    exact: true
  },
  {
    path: '/admin/trainers',
    component: AdminAuth(CanWrapper("trainers")(AdminTrainers)),
    exact: true
  },
  {
    path: '/admin/referees',
    component: AdminAuth(CanWrapper("referees")(AdminReferees)),
    exact: true
  },
  {
    path: '/admin/teams',
    component: AdminAuth(CanWrapper("teams")(AdminTeams)),
    exact: false
  },
  {
    path: '/admin/terms',
    component: AdminAuth(CanWrapper("terms")(AdminTerms)),
    exact: false
  },
  {
    path: '/admin/certifications/athletes',
    component: AdminAuth(CanWrapper("athletes_certification")(AdminAthleteCertifications)),
    exact: false
  },
  {
    path: '/admin/certifications/trainers',
    component: AdminAuth(CanWrapper("trainers_certification")(AdminTrainerCertifications)),
    exact: false
  },
  {
    path: '/admin/certifications/referees',
    component: AdminAuth(CanWrapper("referees_certification")(AdminRefereeCertifications)),
    exact: false
  },
  {
    path: '/admin/jobs',
    component: AdminAuth(CanWrapper("users")(AdminRefereeCertifications)),
    exact: false
  },
  {
    path: '/admin/syncs/athletes',
    component: AdminAuth(CanWrapper("users")(AdminSyncSportsmen)),
    exact: false
  },
  {
    path: '/admin/syncs/trainers',
    component: AdminAuth(CanWrapper("users")(AdminSyncTrainer)),
    exact: false
  },
  {
    path: '/login',
    component: AdminLogin,
    exact: true
  }
];
