import React from "react";
import PropTypes from 'prop-types';
import FilterContext from "./contexs";

const FilterItem = (props) => {
  const handleOnChange = (onChange) => {
    return (value) => onChange(props.field, props.type, value);
  };

  return (
    <FilterContext.Consumer>
      {({onChange, fields}) => {
        return (
          props.children({
            onChange: handleOnChange(onChange),
            value: fields[props.field] && fields[props.field].value
          })
        )
      }}
    </FilterContext.Consumer>
  )

};

FilterItem.propTypes = {
  field: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};


export default FilterItem
