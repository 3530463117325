export const RANK_OPTIONS = [
  {value: 'CATEGORY_3', label: '3 Категорія'},
  {value: 'CATEGORY_2', label: '2 Категорія'},
  {value: 'CATEGORY_1', label: '1 Категорія'},
  {value: 'CATEGORY_HIGH', label: 'Вища Категорія'},

];

export function getRankTitle(rank) {
  const r = RANK_OPTIONS.filter(o => o.value === rank)[0];
  return r ? r['label'] : 'Не вказано'
}
