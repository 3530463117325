import {getBaseUrl} from '../../utils';
import io from '../../lib/io'


export async function getByContentType(contentType) {
  return await io.get(`${getBaseUrl()}/public/pages?slug=${contentType}`);
}


export default {
  getByContentType
}
