import {getBaseUrl} from "../../utils";
import io from "../../lib/io";


export async function remove(id) {
  return await io.post(`${getBaseUrl()}/admin/docs-sections/${id}/delete`);
}


export async function removeDocument(docId) {
  return await io.post(`${getBaseUrl()}/admin/docs-sections/docs/${docId}/delete`);
}


export default {
  removeDocument,
  remove,
}
