import Cookies from "js-cookie";
import moment from "moment";

const initialState = {
  token: Cookies.get("token"),
  date_started: moment(),
  title: '',
  is_main: false,
  intro: '',
  description: '',
  editorState: '',
  loading: false,
  file: null,
  news: null,
  editMode: false,
};


export default function news(state=initialState, action) {
  switch (action.type) {
    case 'CHANGE_VALUE':
      return {...state, [action.name]: action.value};

    case 'LOADING':
      return {...state, loading: action.loading};

    case 'SET_FILE':
      return {...state, loading: false, file: action.file};

    case 'SET_NEWS':
      const data = {
        title: action.news.title,
        is_main: action.news.is_main,
        intro: action.news.intro,
        description: action.news.description,
        date_started: moment(action.news.date_started),
        file: action.news.file,
      };
      return {...state, ...data, title: action.news.title, loading: false, news: action.news, editMode: true};

    default:
      return state;
  }
}
