import React from "react";
import Team from "../components/Team/index";
import PublicBaseLayout from "../../../../components/BaseLayout/PublicBaseLayout";

export default class YouthTeamsView extends React.Component {

  render() {
    return (
      <PublicBaseLayout>
        <Team
          id={this.props.match.params.id}
          title="Молодіжна збірна"
          type="youth"
        />
      </PublicBaseLayout>
    );
  }
}
