import React from "react";
import AdminBaseLayoutContainer from "../../components/BaseLayout/AdminBaseLayoutContainer";
import RefereesListView from "./views/RefereesListView";


class RefereesList extends React.Component {

  render() {
    return (
      <AdminBaseLayoutContainer>
        <RefereesListView />
      </AdminBaseLayoutContainer>
    )
  }

}


export default RefereesList;
