import React from "react";
import {Avatar, Button, Col, Divider, Row, Table, Tabs} from "antd";
import MemberForm from "../MemberForm/MemberForm";
import IModalForm from "../../../../components/IModalForm";
import ExamForm from "./ExamForm";
import * as serviceCertification from "../../../../services/admin/athlete_certifications";
import {formatSubmitDate} from "../../../../utils";
import * as athleteService from "../../../../services/admin/athletes";
import AdminActionDropdown from "../../../../components/AdminActionDropdown";
import {getRankTitle} from "../MemberForm/utils";

const { TabPane } = Tabs;


const ExamFormContainer = ({certId, onCancel, onSubmit}) => {
  const [loading, setLoading] = React.useState(false);
  const [showForm, addMember] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [fields, changeField] = React.useState({status: 'public'});
  const [members, setMember] = React.useState([]);
  const [queryData, setQueryData] = React.useState(null);
  const [editMember, setEditMember] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      if (certId) {
        const certRes = await serviceCertification.getById(certId);
        const memberRes = await serviceCertification.getCertificatedMembers(certId);
        changeField(certRes.data.data);
        setMember(memberRes.data.data)
      }
    };
    fetchData();
  }, []);

  const onChange = (name, value) => {
    if (name === 'region_club') {
      changeField({...fields, 'region_id': value[0], 'club_id': value[1]});
      const filterData = {'region_id': value[0], 'club_id': value[1]};
      setQueryData(filterData);
      return
    }
    changeField({...fields, [name]: value})
  };

  const handleSubmit = async () => {
    setLoading(true);

    const data = {
      status: fields.status,
      protocol_num: fields.protocol_num,
      date_exam: formatSubmitDate(fields.date_exam),
      examiner: fields.examiner,
      region_id: fields.region_id,
      club_id: fields.club_id,
      type: 'athletes',
      members: members.map(m => {
        return {...m, 'athlete_id': m.id}
      }),
    };
    let res;
    if (certId) {
      res = await serviceCertification.save(certId, data)
    } else {
      res = await serviceCertification.add(data);
    }
    setLoading(false);
    if (res.data.status === 'ok') {
      onCancel()
    } else {
      setErrors(res.data.errors);
    }
    onSubmit()
  };

  const handleAddMember = () => {
    changeField({...fields,
      'athlete_id': null,
      'certificate_num': null,
      'rank': null,
    });
    addMember(true)
    setEditMember(null)
  };

  const onAddMember = async () => {
    if (!fields.athlete_id || !fields.certificate_num || !fields.rank) {
      setErrors({
        athlete_id: ["Виберіть спортсмена"],
        certificate_num: ["Введіть сертифікат"],
        rank: ["Виберіть Кю/Дан"]
      });
      return
    }
    const res = await athleteService.getById(fields.athlete_id);
    setMember(members
      .filter(m => m.athlete_id !== fields.athlete_id)
      .concat({
        ...res.data.data,
        athlete_id: res.data.data.id,
        certificate_num: fields.certificate_num,
        rank: fields.rank,
      }));
    addMember(false)
    setEditMember(null)
  };

  const onRemoveMember = (rowId) => {
    setMember(members.filter(m => m.athlete_id !== rowId))
  };

  const onEditMember = (row) => {
    const member = members.filter(m => m.athlete_id === row.id)[0];
    changeField({...fields,
      'athlete_id': row.id,
      'certificate_num': member.certificate_num,
      'rank': member.rank
    });
    addMember(true)
    setEditMember(row)
  };

  const columns = [
    {
      title: 'Фото',
      dataIndex: 'logo',
      key: 'logo',
      render: (t, r) => <Avatar
        src={r.logo && r.logo.url}
        size={50}
        shape="square"
      >
        Avatar
      </Avatar>
    },
    {
      title: 'ПІБ',
      dataIndex: 'name',
      render: (t, r) => `${r.full_name}`,
    },
    {
      title: 'Номер сертифікату',
      dataIndex: 'certificate_num',
    },
    {
      title: 'Кю/Дан',
      dataIndex: 'rank',
      render: (t, r) => getRankTitle(r.rank),
    },
    {
      title: 'Дії',
      dataIndex: 'action',
      key: 'action',
      render: (text, item) => (
        <AdminActionDropdown
          labelKey="name"
          fetchRow={true}
          onDelete={onRemoveMember}
          onEdit={onEditMember}
          item={item}
        />
      ),
    }

  ];

  return (
    <IModalForm
      title="Додати протокол"
      showModal={true}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      loading={loading}
    >
      <Tabs onChange={() => null} type="card">
        <TabPane tab="Протокол екзамену" key="1">
          <ExamForm
            {...fields}
            loading={loading}
            errors={errors}
            onChange={onChange}
          />
        </TabPane>
        <TabPane tab="Атестовані спротсмени" key="2">
          <Button
            type="primary"
            icon="plus"
            onClick={handleAddMember}
          >
            Додати
          </Button>
          {showForm &&
          <IModalForm
            title="Спортсмен"
            loading={loading}
            showModal={true}
            submitText="Додати"
            onSubmit={onAddMember}
            onCancel={() => addMember(false)}
          >
            <MemberForm
              {...fields}
              member={editMember}
              queryData={queryData}
              certId={certId}
              loading={loading}
              errors={errors}
              onChange={onChange}
            />
          </IModalForm>
          }
          <Divider/>
          <Row type="flex" justify="space-around" align="middle">
            <Col span={24}>
              <Table
                rowKey="id"
                columns={columns}
                dataSource={members}
                size="middle"
                locale={{emptyText: "Немає данних"}}
                pagination={true}
              />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </IModalForm>
  );
};

export default ExamFormContainer
