import {getBaseUrl} from '../../utils';
import io from '../../lib/io'



export async function remove(id) {
  return await io.post(`${getBaseUrl()}/admin/structures/${id}/delete`);
}

export async function removeMember(id) {
  return await io.post(`${getBaseUrl()}/admin/structures/members/${id}/delete`);
}
export default {
  remove,
  removeMember,
}
