import React from 'react'
import { Button, Col, Divider, Pagination, Row, Table } from 'antd'
import { formatDateTime, getNoun } from '../../../utils'
import FilterProvider from '../../components/Filters/FilterProvider'
import SearchFilter
  from '../../components/Filters/components/SearchFilter/index'
import FilterResults from '../../components/Filters/FilterResults'
import { handleOnSort } from '../../libs/helpers'
import * as serviceSyncs from '../../../services/admin/syncs'

const expandedRowRender = (data) => {
  const columns = [
    {
      title: 'external_id',
      dataIndex: 'external_id',
      // render: (t, r) => `${r.full_name}`,
    },
  ]
  return (r) => <div style={{
    height: "auto",
    maxHeight: "500px",
    overflow: "auto",
    backgroundColor: "#eeeeee",
  }}>
    <pre>{JSON.stringify(JSON.parse(r.info), null, 2) }</pre>
  </div>
}

export default class SyncsView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      errors: {},
      members: [],
      loading: false,
      editMember: null,
      category: '',
      challenges: '',
      status: 'public',
      files: [],
      extendedData: {},
    }
  }
  
  render () {
    return (
      <div>
        <h2>Синхронізація спортсменів</h2>
        <Divider/>
        <FilterProvider
          onFetchUrl={(queryString) => serviceSyncs.getAllSportSmenUrl(queryString)}
        >
          {({
            loading,
            onChange,
            onClearAll,
          }) => {
            return (
              <React.Fragment>
                <Row gutter={24}>
                </Row>
                <Row>
                  <FilterResults
                    dataKey="data"
                  >
                    {({ data, paginator }) => {
                      const columns = [
                        {
                          title: 'ID',
                          dataIndex: 'id',
                          key: 'id',
                          sorter: true,
                        },
                        {
                          title: 'Дата створення',
                          dataIndex: 'date_created',
                          key: 'date_created',
                          sorter: true,
                          render: (t, r) => formatDateTime(t),
                        },
                      ]
                      return (
                        <React.Fragment>
                          <Row>
                            <br/>
                            <b>{paginator.total}</b>
                            <br/>
                          </Row>
                          <Row>
                            <Table
                              columns={columns}
                              expandedRowRender={expandedRowRender(this.state.extendedData)}
                              // onExpand={this.fetchExtendedData}
                              dataSource={data}
                              rowKey="id"
                              loading={loading}
                              locale={{ emptyText: 'Немає данних' }}
                              pagination={false}
                              onChange={handleOnSort(onChange)}
                            />
                          </Row>
                          <br/>
                          <Row type="flex" justify="end">
                            <Pagination
                              current={paginator.page}
                              total={paginator.total}
                              pageSize={paginator.page_size}
                              onChange={(page) => {
                                window.scrollTo(0, 0)
                                onChange('page', 'text', page)
                              }}
                            />
                          </Row>
                        </React.Fragment>
                      )
                    }}
                  </FilterResults>
                </Row>
              </React.Fragment>
            )
          }}
        </FilterProvider>
      </div>
    )
  }
}
