import {SUBMIT_DATE_FORMAT} from "../../utils";


export function getSubmitData(state) {
  return {
    status: state.status,
    date_started: state.date_started.format(SUBMIT_DATE_FORMAT),
    title: state.title,
    link: state.link,
    file_id: state.file && state.file.id,
  };
}
