import {getBaseUrl} from '../../utils';
import io from '../../lib/io'


export async function getById(id) {
  return await io.get(`${getBaseUrl()}/public/athletes/${id}`);
}

export async function getHistories(id) {
  return await io.get(`${getBaseUrl()}/public/athletes/${id}/histories`);
}

export default {
  getById,
  getHistories,
}
