import React from "react";
import {Button, Divider, Form, Icon, Input, Table, Modal, Select, Switch, Upload} from "antd";
import AdminActionDropdown from "../AdminActionDropdown";
import AdminBaseLayoutContainer from "../BaseLayout/AdminBaseLayoutContainer";
const FormItem = Form.Item;
const Option = Select.Option;

class Uploader extends React.Component {


  onUpload = (e) => {
    this.props.onUploadFile(e.file)
  };

  render() {
    const filesList = [];
    if (this.props.file) {
      filesList.push({
        uid: '1',
        name: this.props.file.original_name,
        status: 'done',
        url: this.props.file.url,
      })
    }
    return (
      <Upload
        onRemove={this.props.onDeleteFile}
        fileList={filesList}
        customRequest={this.onUpload}
      >
        <Button>
          <Icon type="upload" /> Завантажити
        </Button>

      </Upload>
    );
  }
}

export default class AdminDocumentsForm extends React.Component {

  componentDidMount() {
    if (this.props.match.params.id && Number.isInteger(Number(this.props.match.params.id))) {
      this.props.onInit(Number(this.props.match.params.id))
    } else {
      this.props.onInit()
    }
  }

  onChangeInput = (e) => {
    this.props.changeInput(e.target.name, e.target.value)
  };

  onSubmit = (e) => {
    this.props.onSubmit(this.props.history)
  };

  getErrorMessageFields = (name) => {
    return {
      validateStatus: this.props.errors[name] ? "error": null,
      help: this.props.errors[name],
    }
  };

  onAddDocument = () => {
    this.props.onAddDocument()
  };

  render() {
    const columns = [
      {
        title: 'Файл',
        dataIndex: 'picture_url',
        key: 'picture_url',
        render: (t, r) => <a src={r.file.url}>{r.title}</a>
      },
      {
        title: 'Дата створення',
        dataIndex: 'date_created',
        key: 'date_created',
      },
      {
        title: 'Дата оновлення',
        dataIndex: 'date_updated',
        key: 'date_updated',
      },
      {
        title: 'Дії',
        dataIndex: 'action',
        key: 'action',
        render: (text, r) => (
          <AdminActionDropdown
            {...this.props}
            onEdit={(item) => this.props.onEditDocument(r.id)}
            onDelete={(item) => this.props.onDeleteDocument(r.id)}
            item={r}
          />
        ),
      }
    ];
    return (
      <AdminBaseLayoutContainer>
      <Form>
        <FormItem
          label={'Статус'}
        >
          <Select
            style={{ width: 200 }}
            onChange={(s) => this.props.changeInput("status", s)}
            value={this.props.status}
          >
            <Option value="public">Опубліковано</Option>
            <Option value="draft">Черновик</Option>
          </Select>
        </FormItem>
        <FormItem
          label={'Назва розділу'}
          {...this.getErrorMessageFields('title')}
        >
          <Input
            name="title"
            value={this.props.title}
            onChange={this.onChangeInput}
          />
        </FormItem>

        {this.props.docs_section &&
        <div>
          <Divider/>
          <Button
            icon="plus"
            htmlType="button"
            onClick={this.onAddDocument}
          >
            Додати документ
          </Button>
          <Divider/>
        </div>
        }
        <Table
          columns={columns}
          dataSource={this.props.docs}
          rowKey="id"
          loading={this.props.loading}
          locale={{emptyText: "Немає докуменів"}}
        />
        <Modal
          title="Документ"
          visible={this.props.showModal}
          onCancel={this.props.onCancel}
          footer={[
            <Button key="back" onClick={this.props.onCancel}>Відмінити</Button>,
            <Button key="submit" type="primary" onClick={this.props.onSubmitDocument}>
              Зберегти
            </Button>,
          ]}
        >
          <FormItem
            label={'Документ'}
            {...this.getErrorMessageFields('file_id')}
          >
            <Uploader {...this.props}/>
          </FormItem>
          <FormItem
            label={'Назва документу'}
            {...this.getErrorMessageFields('doc_title')}
          >
            <Input
              name="doc_title"
              value={this.props.doc_title}
              onChange={this.onChangeInput}
            />
          </FormItem>
          <FormItem
            label="Відмітити як новий"
          >
            <Switch
              checked={this.props.is_new}
              onChange={(v) => this.props.changeInput("is_new", v)}
            />
          </FormItem>
        </Modal>
        <Divider/>
        <FormItem>
          <Button
            type="primary"
            htmlType="button"
            onClick={this.onSubmit}
          >
            Зберегти
          </Button>
        </FormItem>
      </Form>
      </AdminBaseLayoutContainer>
    );
  }
}
