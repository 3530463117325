import React from 'react';
import {Upload, Icon} from 'antd';


export default function IPhotoUploader(props) {
  return (
    <Upload
      name={props.name}
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      customRequest={props.onUpload}
    >
      {props.file ?
        <img width={200} src={props.file.url} alt="file"/>
        : <div>
          <Icon type={props.loading ? 'loading' : 'plus'} />
          <div className="ant-upload-text">Завантажити</div>
        </div>
      }
    </Upload>
  );
}
