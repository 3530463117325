import React from "react";
import PropTypes from "prop-types";
import {Card, Icon, Avatar} from "antd";

const MemberInfo = ({id, logoUrl, fullName, category, challenges, onDelete, onEdit}) => {
  return (
    <Card
      style={{cursor: "grab"}}
      hoverable
      cover={
        <Avatar
          src={logoUrl}
          size={205}
          shape="square"
        >
          Avatar
        </Avatar>
      }
      actions={[
        <Icon
          type="edit"
          key="edit"
          onClick={() => onEdit(id)}
        />,
        <Icon
          type="delete"
          key="delete"
          twoToneColor="red"
          onClick={() => onDelete(id)}
        />,
      ]}
    >
      <Card.Meta
        title={fullName}
        description={`Категорія: ${category} \n Викликів в збірну: ${challenges}`}
      />
    </Card>
  );
};

MemberInfo.propTypes = {
  id: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};


export default MemberInfo