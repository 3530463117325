import {getBaseUrl} from "../../utils";
import io from "../../lib/io";

export async function add(data) {
  return await io.post(`${getBaseUrl()}/admin/pages`, data);
}

export async function save(id, data) {
  return await io.post(`${getBaseUrl()}/admin/pages/${id}/edit`, data);
}


export async function getBySlug(slug) {
  return await io.get(`${getBaseUrl()}/admin/pages/${slug}`);
}


export async function uploadFile(file) {
  return await io.postFile(`${getBaseUrl()}/uploads/upload_pages_docs_file`, file);
}

export default {
  add,
  save,
  getBySlug,
  uploadFile,
}
