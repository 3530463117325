import moment from 'moment';
import './CompetitionsCalendar.css';

moment.locale('uk');

const initialState = {
  date: moment(),
  mode: 'month',
  loading: false,
  events: [],
  dayEventsMap: {},
  selectedDate: null,
  pdfUrl: null,
  errors: {},
  dateChoices: []
};


export default function news(state=initialState, action) {
  switch (action.type) {
    case 'CHANGE_PANEL':
      return {...state, date: action.date, mode: action.mode};

    case 'SELECT_DATE':
      return {...state, selectedDate: action.date};

    case 'LOADING':
      return {...state, loading: action.loading};

    case 'SET_CALENDAR_EVENTS':
      return {
        ...state,
        loading: false,
        events: action.events,
        dayEventsMap: action.dayEventsMap
      };

    case 'CALENDAR_SET_PDF_URL':
      return {
        ...state,
        loading: false,
        pdfUrl: action.pdfUrl,
      };

    case 'CALENDAR_SET_ERRORS':
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };

    case 'CALENDAR_SET_DATE_CHOICES':
      return {...state, dateChoices: action.dateChoices};

    default:
      return state;
  }
}