import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

const FormItem = Form.Item;


function getErrorMessageFields(props) {
  return {
    validateStatus: !!props.errors && props.errors[props.errorKey] ? "error": null,
    help: !!props.errors && props.errors[props.errorKey],
  }
};


export default function IFormItem(props) {
  return (
    <FormItem
      label={props.label}
      {...getErrorMessageFields(props)}
    >
      {props.children}
    </FormItem>
  );
}


IFormItem.propTypes = {
  errors: PropTypes.object.isRequired,
  errorKey: PropTypes.string
};
