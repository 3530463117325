import {getBaseUrl} from '../../utils';
import io from '../../lib/io'

// export async function add(data) {
//   return await io.post(`${getBaseUrl()}/admin/athletes`, data);
// }


export function getAthleteUrl(queryString) {
  const query = queryString ? `?${queryString}` : '';
  return `${getBaseUrl()}/admin/athletes${query}`;
}

export async function getAll(queryString) {
  return await io.get(getAthleteUrl(queryString));
}

export async function save(id, data) {
  return await io.post(`${getBaseUrl()}/admin/athletes/${id}/edit`, data);
}

export async function getById(id) {
  return await io.get(`${getBaseUrl()}/admin/athletes/${id}`);
}

export function getRemoveUrl(id) {
  return `${getBaseUrl()}/admin/athletes/${id}/delete`;
}

export async function remove(id) {
  return await io.post(getRemoveUrl(id));
}


export async function download(data) {
  return await io.post(`${getBaseUrl()}/admin/athletes/download`, data);
}

export default {
  // add,
  getAll,
  save,
  getById,
  remove,
  getRemoveUrl,
  download,
  getAthleteUrl,
}
