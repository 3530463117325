import React from 'react';
import AdminNewsCreateForm from './AdminNewsCreateForm';
import {connect} from "react-redux";
import {uploadFile, changeInput, submitForm, fetchNews2, clearForm} from "./AdminNewsCreateFormAction";

const Create = (props) => (
    <div className='container__box'>
        <AdminNewsCreateForm
          {...props}
        />
    </div>
);


const mapStateToProps = state => {
  return {
    ...state.news,
    editMode: false,
    isAuthenticated: state.auth.isAuthenticated,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    uploadFile: (file) => {
      return dispatch(uploadFile(file));
    },
    changeInput: (name, value) => {
      return dispatch(changeInput(name, value))
    },
    onSubmit: (history) => {
      return dispatch(submitForm(history))
    },
    fetchNews: (newsId) => {
      return dispatch(fetchNews2(newsId))
    },
    clearForm: () => {
      return dispatch(clearForm())
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Create);
