import AdminNewsCreateForm from './AdminNewsCreateForm';
import {connect} from "react-redux";
import {uploadFile, changeInput, submitForm, fetchNews, clearForm} from "./AdminNewsCreateFormAction";

const mapStateToProps = state => {
  return {
    ...state.news,
    editMode: true,
    isAuthenticated: state.auth.isAuthenticated,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    uploadFile: (file) => {
      return dispatch(uploadFile(file));
    },
    changeInput: (name, value) => {
      return dispatch(changeInput(name, value))
    },
    onSubmit: (history) => {
      return dispatch(submitForm(history))
    },
    fetchNews: (newsId) => {
      return dispatch(fetchNews(newsId))
    },
    clearForm: () => {
      return dispatch(clearForm())
    }

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminNewsCreateForm);
