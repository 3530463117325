import React from "react";
import {Avatar, Button, Col, Divider, Pagination, Row, Table, Tag} from "antd";
import AdminActionDropdown from "../../../components/AdminActionDropdown";
import ClubForm from "../components/ClubForm";
import service, {getAllUrl} from "../../../services/admin/clubs";
import moment from "moment";
import DownloadListFilterWraper from "../../components/DownloadLils/index";
import {getNoun} from "../../../utils";
import FilterProvider from "../../components/Filters/FilterProvider";
import SearchFilter from "../../components/Filters/components/SearchFilter/index";
import RemoteSelectFilter from "../../components/Filters/components/RemoteSelectFilter/index";
import * as regionService from "../../../services/admin/agencies";
import FilterResults from "../../components/Filters/FilterResults";
import {handleOnSort} from "../../libs/helpers";
import SelectFilter from "../../components/Filters/components/SelectFilter/SelectFilter";
import {CONTRIBUTIONS_OPTIONS, DEFAULT_CLUB_STATUS_OPTIONS} from "../utils";


const fieldOptions = [
  { label: 'Повна назва', value: 'name' },
  { label: 'Скорочена назва', value: 'short_name' },
  { label: 'Регіон', value: 'region' },
  { label: 'Керівник', value: 'owner_name' },
  { label: 'Телефон', value: 'phone_number' },
  { label: 'Email', value: 'email' },
  { label: 'Статус', value: 'status' },
  { label: 'Адреса', value: 'address' },
  { label: 'Сайт', value: 'website' },
  { label: 'Дата вступу', value: 'entry_date' },
];
const defaultFieldOptions = ['name', 'short_name', 'region', 'owner_name', 'phone_number', 'email', 'status'];


export default class ClubsListView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      contributions: [],
      contributionId: null,
      currentId: null,
      status: "public",
      clubs: [],
      showModal: false,
      editMode: false,
      showSubModal: false,
      loading: false,
      errors: {}
    }
  }

  componentDidMount() {
    service.getAll().then((res) => {
      this.setState({clubs: res.data.clubs})
    });
  }

  onClickAddContributions = () => {
    this.setState({
      contributionId: null,
      showSubModal: true,
      amount: '',
      year: '',
      date: moment(),
    })
  };

  onAdd = () => {
    this.setState({
      currentId: null,
      editMode: false,
      showModal: true,
      status: 'public',
      name: '',
      short_name: '',
      email: '',
      phone_number: '',
      head: '',
      address: '',
      website: '',
    })
  };

  onEdit = (rowId) => {
    service.getById(rowId).then((res) => {
      this.setState({
        currentId: rowId,
        editMode: true,
        showModal: true,
        status: res.data.club.status,
        name: res.data.club.name,
        short_name: res.data.club.short_name,
        email: res.data.club.email,
        phone_number: res.data.club.phone_number,
        owner_name: res.data.club.owner_name,
        address: res.data.club.address,
        website: res.data.club.website,
        entry_date: res.data.club.entry_dat ? moment(res.data.club.entry_date) : moment(),
        block_date: res.data.club.block_date ? moment(res.data.club.block_date) : moment(),
        entry_protocol_num: res.data.club.entry_protocol_num,
        block_protocol_num: res.data.club.block_protocol_num,
        file: res.data.club.logo,
      }, this.getContributions)
    });
  };

  onChangeInput = (name, value) => {
    this.setState({[name]: value})
  };

  preparePostData = (data) => {
    return {
      status: data.status,
      entry_date: data.entry_date && data.entry_date.format("YYYY-MM-DD"),
      block_date: data.block_date && data.block_date.format("YYYY-MM-DD"),
      entry_protocol_num: data.entry_protocol_num,
      block_protocol_num: data.block_protocol_num,
    }
  };

  onCancel = () => {
    this.setState({showModal: false})
  };


  onSubmit = async (postData) => {
    this.setState({loading: true});
    const data = this.preparePostData(postData);
    let res;
    if (this.state.editMode) {
      res = await service.save(this.state.currentId, data);
    } else {
      // res = await service.add(data);
    }

    if (res.data.status === 'ok') {
      this.setState({
        loading: false,
        showModal: false,
        editMode: false,
        currentId: null,
      })
    } else {
      this.setState({
        loading: false,
        errors: res.data.errors,
      })
    }
  };

  getContributions = () => {
    service.getContributions(this.state.currentId).then((res) => {
      this.setState({contributions: res.data.contributions})
    })
  };

  onCancelContribution = () => {
    this.setState({showSubModal: false})
  };

  onSubmitContribution = () => {
    const data = {
      date: this.state.date && this.state.date.format("YYYY-MM-DD"),
      amount: this.state.amount,
      year: this.state.year,
    };
    const action = this.state.contributionId
      ? () => service.updateContribution(this.state.contributionId, data)
      : () => service.saveContribution(this.state.currentId, data);

    action().then((res) => {
      if (res.data.status === 'ok') {
        this.setState({
          loading: false,
          showSubModal: false,
        }, this.getContributions)
      } else {
        this.setState({
          loading: false,
          errors: res.data.errors,
        })
      }
    });
  };

  onEditContribution = async (rowId) => {
    const res = await service.getContribution(rowId);
    const {data} = res.data;
    this.setState({
      contributionId: data.id,
      showSubModal: true,
      date: moment(data.date),
      year: data.year,
      amount: data.amount,
    })
  };

  onRemoveContribution = (rowId) => {
    service.removeContribution(rowId).then((res) => {
      if (res.data.status === 'ok') {
        this.setState({
          loading: false,
          showSubModal: false,
        }, this.getContributions)
      } else {
        this.setState({
          loading: false,
          errors: res.data.errors,
        })
      }
    });
  };

  render() {
    return (
      <div>
        <h2>Список клубів</h2>
        <Divider/>
        <FilterProvider
          onFetchUrl={(queryString) => {return getAllUrl(queryString)}}
          onRemoveUrl={service.getRemoveUrl}
        >
          {({loading, onChange, fields, onClearAll, onRemove}) => {
            const columns = [
              {
                title: 'Фото',
                dataIndex: 'logo',
                key: 'logo',
                render: (t, r) => <Avatar
                  src={r.logo && r.logo.url}
                  size={50}
                  shape="square"
                >
                  Avatar
                </Avatar>
              },
              {
                title: 'Назва',
                dataIndex: 'short_name',
                key: 'short_name',
                sorter: true,
              },
              {
                title: 'Регіон',
                dataIndex: 'region_name',
                key: 'region_name',
                sorter: true,
              },
              {
                title: 'Керівник',
                dataIndex: 'owner_name',
                key: 'owner_name',
                sorter: true,
              },
              {
                title: 'Телефон',
                dataIndex: 'phone_number',
                key: 'phone_number',
              },
              {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
              },
              {
                title: 'Внески',
                dataIndex: 'contributions',
                key: 'contributions',
                sorter: true,
                render: (t, r) => (
                  <span>
                    <Tag color={r.contributions > 0 ? 'green' : 'red'}>
                    {r.contributions}
                    </Tag>
                  </span>
                ),
              },
              {
                title: 'Статус',
                dataIndex: 'status',
                key: 'status',
                sorter: true,
                render: (t, r) => (
                  <span>
                    {r.status !== 'ACTIVE'
                      ? <Tag color='red'>
                        {r.status}
                      </Tag>
                      : r.status
                    }
                  </span>
                ),
              },
              {
                title: 'Дії',
                dataIndex: 'action',
                key: 'action',
                render: (text, item) => (
                  <AdminActionDropdown
                    {...this.props}
                    labelKey="name"
                    onDelete={onRemove}
                    onEdit={this.onEdit}
                    item={item}
                  />
                ),
              }
            ];
            return (
              <React.Fragment>
                <Row gutter={16}>
                  <Col lg={4}>
                    <SearchFilter
                      field="query"
                      placeholder="Введіть назву"
                    />
                  </Col>
                  <Col lg={4}>
                    <RemoteSelectFilter
                      field="region_id"
                      fetchUrl={regionService.getAllUrl("sort=short_name")}
                      dataKey="agencies"
                      valueKey="id"
                      titleKey="short_name"
                      placeholder="Виберіть регіон"
                      allowClear
                    />
                  </Col>
                  <Col lg={4}>
                    <SelectFilter
                      field="contributions"
                      placeholder="Виберіть статус внеску"
                      options={CONTRIBUTIONS_OPTIONS}
                    />
                  </Col>
                  <Col lg={4}>
                    <SearchFilter
                      field="year"
                      placeholder="Введіть рік внеску"
                    />
                  </Col>
                  <Col lg={2}>
                    <Button
                      icon="close"
                      onClick={onClearAll}
                    >
                      Скинути
                    </Button>
                  </Col>
                  <Col lg={3}>
                    <DownloadListFilterWraper
                      filters={fields}
                      fieldOptions={fieldOptions}
                      defaultFieldOptions={defaultFieldOptions}
                      downloadAction={service.download}
                    />
                  </Col>
                  <Col lg={2}>
                    <Button type="primary" icon="plus" onClick={this.onAdd}>
                      Додати
                    </Button>
                  </Col>
                </Row>
                <Row gutter={16} style={{marginTop: "20px"}}>
                  <Col lg={4}>
                    <SelectFilter
                      field="status"
                      placeholder="Виберіть статус"
                      options={DEFAULT_CLUB_STATUS_OPTIONS}
                    />
                  </Col>
                </Row>
                <Row>
                  <FilterResults
                    dataKey="clubs"
                  >
                    {({data, paginator, res}) => {
                      return (
                        <React.Fragment>
                          <div style={{margin: "20px 0 20px 5px"}}>
                            <Row>
                              <Col lg={2}>
                                <b>{paginator.total}</b> {getNoun(paginator.total, 'клуб', 'клуба', 'клубів')}
                              </Col>
                              <Col lg={20}>
                                <b>
                                  Сума внесків за {(fields['year'] && fields['year']['value']) || '2020'} рік:
                                  {res
                                    ? <Tag style={{marginLeft: "10px"}} color={res.all_contributions_amount > 0 ? 'green' : 'red'}>
                                      {res.all_contributions_amount}
                                    </Tag>
                                    :null
                                  }
                                </b>
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Table
                              columns={columns}
                              dataSource={data}
                              rowKey="id"
                              loading={loading}
                              locale={{emptyText: "Немає данних"}}
                              pagination={false}
                              onChange={handleOnSort(onChange)}
                            />
                          </Row>
                          <br/>
                          <Row type="flex" justify="end">
                            <Pagination
                              current={paginator.page}
                              total={paginator.total}
                              pageSize={paginator.page_size}
                              onChange={(page) => {
                                window.scrollTo(0, 0);
                                onChange('page', 'text', page)
                              }}
                            />
                          </Row>
                        </React.Fragment>
                      )
                    }}
                  </FilterResults>
                </Row>
              </React.Fragment>
            )
          }}
        </FilterProvider>
        {this.state.showModal &&
        <ClubForm
          {...this.state}
          showModal={this.state.showModal}
          showSubModal={this.state.showSubModal}
          onSubmitContribution={this.onSubmitContribution}
          onRemoveContribution={this.onRemoveContribution}
          onEditContribution={this.onEditContribution}
          onCancelContribution={this.onCancelContribution}
          onClickContributions={this.onClickAddContributions}
          onChangeInput={this.onChangeInput}
          onCancel={this.onCancel}
          onSubmit={this.onSubmit}
          errors={this.state.errors}
        />
        }
      </div>
    );
  }
}
