import React from "react";
import {Breadcrumb, Card, Col, Row, Divider, Badge} from "antd";
import {Link} from "react-router-dom";
import {getServiceDomain} from "../../../utils";
import PublicBaseLayout from "../../../components/BaseLayout/PublicBaseLayout";
import {Helmet} from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import * as pageService from "../../../services/public/pages";
import * as documentsService from "../../../services/public/documents";

export default class Terms extends React.Component {

  static async getInitialProps({req, res, match}) {
    try {
      const res = await pageService.getByContentType("terms");
      const docRes = await documentsService.getByObjectType(res.data.data.id, "terms");
      return { data: res.data.data, docs: docRes.data.data};
    } catch (err) {
      console.error(err)
    }
  }

  render() {
    const data = this.props.data ? this.props.data : {};
    const docs = this.props.docs ? this.props.docs : [];
    return (
      <PublicBaseLayout>
        <Helmet>
          <title>Умови вступу - Українська Федерація карате</title>
        </Helmet>
        <Breadcrumb style={{ marginTop: '20px', marginBottom: "5px" }}>
          <Breadcrumb.Item>
            <Link to="/">
              {getServiceDomain()}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Умови вступу
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card title="Умови вступу">
          <Row>
            <Col>
              <p>
                {ReactHtmlParser(data.description)}
              </p>
            </Col>
          </Row>
          <Divider>Документи</Divider>
          <Row>
            <Col>
              <ul>
                {docs.map((d) =>
                  <li key={d.id}>
                    <a target="_blank" rel="noopener noreferrer" href={d.file.url}>
                      {d.title} <Badge count={d.is_new ? 'new': null} style={{ backgroundColor: '#4aa88d' }}/>
                    </a>
                  </li>
                )}
              </ul>
            </Col>
          </Row>
        </Card>
      </PublicBaseLayout>
    );
  }
}
