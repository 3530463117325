import React from "react";
import {Divider} from "antd";
import AdminBaseLayoutContainer from "../../components/BaseLayout/AdminBaseLayoutContainer";


export default class Dashboard extends React.Component {

  render() {
    return (
      <AdminBaseLayoutContainer>
        <h2>Адмін панель</h2>
        <Divider/>
      </AdminBaseLayoutContainer>
    );
  }
}
