import {getBaseUrl} from '../../utils';
import io from '../../lib/io'

export async function getMainNews() {
  return await io.get(`${getBaseUrl()}/public/main-news`);
}

export async function getLastNews() {
  return await io.get(`${getBaseUrl()}/public/last-news`);
}

export async function getNews(queryString) {
  const query = queryString ? `?${queryString}` : '';
  return await io.get(`${getBaseUrl()}/public/news${query}`);
}

export async function getNewsById(id) {
  return await io.get(`${getBaseUrl()}/public/news/${id}`);
}

export default {
  getMainNews,
  getLastNews,
  getNews,
  getNewsById,
}
