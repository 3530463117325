import React from "react";
import {Divider, Table, Row, Col, Button, Pagination} from "antd";
import AdminActionDropdown from "../AdminActionDropdown";
import moment from "moment";
import AdminBaseLayoutContainer from "../BaseLayout/AdminBaseLayoutContainer";
import {formatDate, getNoun} from "../../utils";
import FilterProvider from "../../admin/components/Filters/FilterProvider";
import SearchFilter
  from "../../admin/components/Filters/components/SearchFilter";
import FilterResults from "../../admin/components/Filters/FilterResults";
import {getAllUrl, getRemoveUrl} from "../../services/admin/events";
import RangeFilter from "../../admin/components/Filters/components/RangeFilter";

function getEventStatusTitle(status) {
  const statuses =  {
    public: 'Опубліковано',
    draft: 'Черновик'
  };
  return statuses[status] || 'Не вказано'
}

export default class AdminEventsList extends React.Component {

  componentDidMount() {
    this.props.onMount()
  }

  onEdit = (itemId) => {
    this.props.onEdit(itemId);
  };

  onCreate = () => {
    this.props.onAdd();
  };

  render() {
    return (
      <AdminBaseLayoutContainer>
        <h2>Список подій</h2>
        <Divider/>
        <FilterProvider
          onFetchUrl={(queryString) => {return getAllUrl(queryString)}}
          onRemoveUrl={getRemoveUrl}
        >
          {({loading, onChange, fields, onClearAll, onRemove}) => {
            const columns = [
              {
                title: 'Дата початку',
                dataIndex: 'date_started',
                key: 'date_started',
                render: (t, r) => formatDate(t),
                sorter: (a, b) => moment(a.date_started) - moment(b.date_started),
              },
              {
                title: 'Дата закінчення',
                dataIndex: 'date_expired',
                key: 'date_expired',
                render: (t, r) => formatDate(t),
                sorter: (a, b) => moment(a.date_started) - moment(b.date_started),
              },
              {
                title: 'Назва події',
                dataIndex: 'title',
                key: 'title',
              },
              {
                title: 'Місце проведення',
                dataIndex: 'place',
                key: 'place',
              },
              {
                title: 'Учасники',
                dataIndex: 'members',
                key: 'members',
              },
              {
                title: 'Статус',
                dataIndex: 'status',
                key: 'status',
                render: (t, r) => getEventStatusTitle(r.status)
              },
              {
                title: 'Дії',
                dataIndex: 'action',
                key: 'action',
                render: (text, item) => (
                  <AdminActionDropdown
                    {...this.props}
                    item={item}
                  />
                ),
              }
            ];
            return (
              <React.Fragment>
                <Row gutter={16}>
                  <Col lg={4}>
                    <SearchFilter
                      field="query"
                      placeholder="Введіть назву"
                    />
                  </Col>
                  <Col lg={4}>
                    <RangeFilter
                      field="date_range"
                    />
                  </Col>
                  <Col lg={2}>
                    <Button
                      icon="close"
                      onClick={onClearAll}
                    >
                      Скинути
                    </Button>
                  </Col>
                  <Col lg={2}>
                    <Button type="primary" icon="plus" onClick={this.onCreate}>
                      Додати
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <FilterResults
                    dataKey="events"
                  >
                    {({data, paginator, res}) => {
                      return (
                        <React.Fragment>
                          <div style={{margin: "20px 0 20px 5px"}}>
                            <Row>
                              <Col lg={2}>
                                <b>{paginator.total}</b> {getNoun(paginator.total, 'клуб', 'клуба', 'клубів')}
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Table
                              columns={columns}
                              dataSource={data}
                              rowKey="id"
                              loading={loading}
                              locale={{emptyText: "Немає данних"}}
                              pagination={false}
                              onChange={onChange}
                            />
                          </Row>
                          <br/>
                          <Row type="flex" justify="end">
                            <Pagination
                              current={paginator.page}
                              total={paginator.total}
                              pageSize={paginator.page_size}
                              onChange={(page) => {
                                window.scrollTo(0, 0);
                                onChange('page', 'text', page)
                              }}
                            />
                          </Row>
                        </React.Fragment>
                      )
                    }}
                  </FilterResults>
                </Row>
              </React.Fragment>
            )
          }}
        </FilterProvider>
      </AdminBaseLayoutContainer>
    );
  }
}
