
export const RANK_TYPE_LOCAL = 'local';
export const RANK_TYPE_INTER = 'inter';

// TODO move to server
export const RANK_OPTIONS = [
  {value: 'CATEGORY_3', label: '3 Категорія', type: RANK_TYPE_LOCAL, order: 3},
  {value: 'CATEGORY_2', label: '2 Категорія', type: RANK_TYPE_LOCAL, order: 2},
  {value: 'CATEGORY_1', label: '1 Категорія', type: RANK_TYPE_LOCAL, order: 1},
  {value: 'CATEGORY_NATIONAL', label: 'Національна Категорія', type: RANK_TYPE_LOCAL, order: 0},
  // EKF kumite
  {value: 'CATEGORY_EKF_KUMITE_JUDGE_B', label: 'EKF Kumite Judge‐B', type: RANK_TYPE_INTER, order: 0},
  {value: 'CATEGORY_EKF_KUMITE_JUDGE_A', label: 'EKF Kumite Judge‐A', type: RANK_TYPE_INTER, order: 0},
  {value: 'CATEGORY_EKF_KUMITE_REFEREE_B', label: 'EKF Kumite Referee‐B', type: RANK_TYPE_INTER, order: 0},
  {value: 'CATEGORY_EKF_KUMITE_REFEREE_A', label: 'EKF Kumite Referee‐A', type: RANK_TYPE_INTER, order: 0},
  // EKF kata
  {value: 'CATEGORY_EKF_KATA_REFEREE_A', label: 'EKF Kata Judge‐A', type: RANK_TYPE_INTER, order: 0},
  {value: 'CATEGORY_EKF_KATA_REFEREE_B', label: 'EKF Kata Judge‐B', type: RANK_TYPE_INTER, order: 0},

  // WKF kumite
  {value: 'CATEGORY_WKF_KUMITE_JUDGE_B', label: 'WKF Kumite Judge‐B', type: RANK_TYPE_INTER, order: 0},
  {value: 'CATEGORY_WKF_KUMITE_JUDGE_A', label: 'WKF Kumite Judge‐A', type: RANK_TYPE_INTER, order: 0},
  {value: 'CATEGORY_WKF_KUMITE_REFEREE_B', label: 'WKF Kumite Referee‐B', type: RANK_TYPE_INTER, order: 0},
  {value: 'CATEGORY_WKF_KUMITE_REFEREE_A', label: 'WKF Kumite Referee‐A', type: RANK_TYPE_INTER, order: 0},
  // WKF kata
  {value: 'CATEGORY_WKF_KATA_REFEREE_B', label: 'WKF Kata Judge‐B', type: RANK_TYPE_INTER, order: 0},
  {value: 'CATEGORY_WKF_KATA_REFEREE_A', label: 'WKF Kata Judge‐A', type: RANK_TYPE_INTER, order: 0},
];

export function getRankTitle(rank) {
  const r =  RANK_OPTIONS.filter(o => o.value === rank)[0];
  return r ? r['label'] : 'Не вказано'
}
