import _ from "lodash";
import React from "react";
import {Breadcrumb, Card, Col, Row} from "antd";
import {Link} from "react-router-dom";
import axios from "axios";
import {getBaseUrl, getServiceDomain} from "../../../../utils";
import {Helmet} from "react-helmet";

import "./BeltsList.css";

export default class BeltsListView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      belts: [],
    };
  }

  componentDidMount() {
    this.getData((res) => {
      this.setState({
        loading: false,
        belts: _.chunk(res.data.belts, 3),
      });
    });
  }

  getData = (callback) => {
    const url = `${getBaseUrl()}/public/belts`;
    axios.get(url)
      .then((res) => {
        callback(res);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Почесні пояси - Українська Федерація карате</title>
        </Helmet>
        <Breadcrumb style={{ marginTop: '20px', marginBottom: "5px" }}>
          <Breadcrumb.Item>
            <Link to="/">
              {getServiceDomain()}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Почесні пояси
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          size="small"
          bordered={false}
          title="Почесні пояси"
        >
          {this.state.belts.map((r, index) =>
            <Row key={index} type="flex" justify="space-around" align="top">
              {r.map((s) =>
                <Col key={s.id} xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} style={{marginBottom: "20px"}}>
                  <Card
                    size="large"
                    title={<b>Почесний {s.dan} дан</b>}
                    loading={this.state.loading}
                    hoverable
                    style={{ width: 300 }}
                    cover={<img
                      alt="example"
                      src={s.file.url}
                      style={{objectFit: "contain", maxHeight: 300, maxWidth: 300}}
                    />}
                  >
                    <Card.Meta
                      title={<div style={{
                        whiteSpace: "initial", textAlign: "center"}}><b>{s.name}</b></div>}
                      description={s.description}
                    />
                    <Card.Meta description={`Сертифікат: № ${s.certificate}`} />
                  </Card>
                </Col>
              )}
            </Row>
          )}
        </Card>
      </React.Fragment>
    );
  }
}
