import React from "react";
import moment from "moment";
import {Col, Form, Input, Row, Select, Spin, Table, Tabs} from "antd";
import IFormItem from "../../../../components/IFormItem";
import IPhotoUploader from "../../../../components/IPhotoUploader";
import RemoteSelect from "../../../components/RemoteSelect/index";
import * as clubService from "../../../../services/admin/clubs";
import MyDatePicker from "../../../components/MyDatePicker/MyDatePicker";
import {getRankTitle} from "../../../trainer_certifications/components/MemberForm/utils";
import SelectStatus from "../../../components/SelectStatus/SelectStatus";
import IModalForm from "../../../../components/IModalForm";
import {formatDate} from "../../../../utils";

const {TabPane} = Tabs;

const columns = [
  {
    title: 'Категорія',
    dataIndex: 'rank',
    render: (t, r) => getRankTitle(r.rank),
  },
  {
    title: 'Дата атестації',
    dataIndex: 'date_exam',
    render: (t, r) => formatDate(r.date_exam)
  },
  {
    title: 'Строк ліцензії',
    dataIndex: 'certificate_date',
    render: (t, r) => formatDate(r.certificate_date)
  },
];


export const DEFAULT_STATUS = [
  {value: 'ACTIVE', label: 'ACTIVE'},
  {value: 'ARCHIVE', label: 'ARCHIVE'},
  {value: 'BLOCKED', label: 'BLOCKED'},
];

const ChangeStatus = ({status, reason, onChange, errors}) => {

  return (
    <React.Fragment>
      <SelectStatus
        value={status}
        errors={errors}
        options={DEFAULT_STATUS}
        onChange={(name, value) => onChange({target: {name, value}})}
      />
      <IFormItem
        label="Примітка"
        errorKey="reason"
        errors={errors}
      >
        <Input.TextArea
          rows="3"
          name="reason"
          placeholder="Введіть примітку"
          value={reason}
          onChange={onChange}
        />
      </IFormItem>
    </React.Fragment>
  );
};

const getInitialState = (
  {
    status,
    reason,
    errors
  }
) => {
  return {
    status,
    reason,
    errors,
  }
};

export default function TrainerForm(props) {
  const [state, setState] = React.useState(getInitialState(props));

  const handleOnChange = (e) => {
    setState({...state, [e.target.name]: e.target.value})
  };

  const handleOnSubmit = () => {
    props.onSubmit(state)
  };
  return (
    <IModalForm
      title={props.editMode ? "Редагувати тренера" : "Додати тренера"}
      showModal={props.showModal}
      onCancel={props.onCancel}
      onSubmit={handleOnSubmit}
    >
      <Spin spinning={props.loading} size="large">
        <Tabs>
          <TabPane tab="Інформація" key="1">
            <Form>
              <Row gutter={24}>
                <Col span={8}>
                  <IFormItem
                    label="Прізвище"
                    errorKey="last_name"
                    errors={props.errors}
                  >
                    <Input
                      name="last_name"
                      placeholder="Введіть прізвище"
                      value={props.last_name}
                      onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
                    />
                  </IFormItem>
                  <IFormItem
                    label="Ім’я"
                    errorKey="first_name"
                    errors={props.errors}
                  >
                    <Input
                      name="first_name"
                      value={props.first_name}
                      placeholder="Введіть ім’я"
                      onChange={(e) => props.onChangeInput(
                        e.target.name,
                        e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
                      )}
                    />
                  </IFormItem>
                  <IFormItem
                    label="По батькові"
                    errorKey="middle_name"
                    errors={props.errors}
                  >
                    <Input
                      name="middle_name"
                      placeholder="Введіть по батькові"
                      value={props.middle_name}
                      onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
                    />
                  </IFormItem>
                  <IFormItem
                    label="Прізвище Ім’я (Eng)"
                    errorKey="eng_name"
                    errors={props.errors}
                  >
                    <Input
                      name="eng_name"
                      placeholder="Введіть прізвище ім’я (Eng)"
                      value={props.eng_name}
                      onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
                    />
                  </IFormItem>
                  <IFormItem
                    label={'Дата народження'}
                    errorKey="birth_date"
                    errors={props.errors}
                  >
                    <MyDatePicker
                      value={moment(props.birth_date)}
                      onChange={(dt) => props.onChangeInput("birth_date", dt)}
                    />
                  </IFormItem>
                  <IFormItem
                    label={'Стать'}
                    errorKey="gender"
                    errors={props.errors}
                  >
                    <Select
                      style={{ width: 200 }}
                      placeholder="Виберіть стать"
                      onChange={(s) => props.onChangeInput("gender", s)}
                      value={props.gender}
                    >
                      <Select.Option value="MALE">Чоловік</Select.Option>
                      <Select.Option value="FEMALE">Жінка</Select.Option>
                    </Select>
                  </IFormItem>
                </Col>
                <Col span={8}>
                  <IFormItem
                    label="Клуб"
                    errorKey="club_id"
                    errors={props.errors}
                  >
                    <RemoteSelect
                      prefetch
                      fetchUrl={clubService.getAllUrl()}
                      dataKey="clubs"
                      valueKey="id"
                      titleKey="short_name"
                      value={String(props.club_id)}
                      placeholder="Виберіть клуб"
                      onChange={(s) => props.onChangeInput("club_id", s)}
                    />
                  </IFormItem>
                  <IFormItem
                    label={'Спортивна кваліфікація'}
                    errorKey="sport_title"
                    errors={props.errors}
                  >
                    <Select
                      placeholder="Виберіть Спортивну кваліфікацію"
                      style={{ width: '100%' }}
                      onChange={(s) => props.onChangeInput("sport_title", s)}
                      value={props.sport_title}
                    >
                      <Select.Option value="-">-</Select.Option>
                      <Select.Option value="III р.">III р.</Select.Option>
                      <Select.Option value="II р.">II р.</Select.Option>
                      <Select.Option value="I р.">I р.</Select.Option>
                      <Select.Option value="КМСУ">КМСУ</Select.Option>
                      <Select.Option value="МСУ">МСУ</Select.Option>
                      <Select.Option value="МСУМК">МСУМК</Select.Option>
                      <Select.Option value="ЗМСУ">ЗМСУ</Select.Option>
                    </Select>
                  </IFormItem>
                  <IFormItem
                    label="WKF-ID"
                    errorKey="league_id"
                    errors={props.errors}
                  >
                    <Input
                      name="league_id"
                      placeholder="Введіть WKF-ID"
                      value={props.league_id}
                      onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
                    />
                  </IFormItem>
                  <IFormItem
                    label="Категорія"
                    errorKey="rank"
                    errors={props.errors}
                  >
                    <Input
                      name="rank"
                      value={getRankTitle(props.rank)}
                      disabled
                    />
                  </IFormItem>
                  <IFormItem
                    label="Email"
                    errorKey="email"
                    errors={props.errors}
                  >
                    <Input
                      name="email"
                      placeholder="Введіть email"
                      value={props.email}
                      onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
                    />
                  </IFormItem>
                  <IFormItem
                    label="Телефон"
                    errorKey="phone_number"
                    errors={props.errors}
                  >
                    <Input
                      name="phone_number"
                      placeholder="Введіть телефон"
                      value={props.phone_number}
                      onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
                    />
                  </IFormItem>
                </Col>
                <Col span={8}>
                  <IFormItem
                    label="Аватар"
                    errorKey="file_id"
                    errors={props.errors}
                  >
                    <IPhotoUploader
                      name="file"
                      loading={props.loading}
                      file={props.file}
                      onUpload={props.onUpload}
                    />
                  </IFormItem>
                  <ChangeStatus
                    status={state.status}
                    reason={state.reason}
                    errors={state.errors}
                    onChange={handleOnChange}
                  />
                </Col>
              </Row>
            </Form>
          </TabPane>
          <TabPane tab="Ліцензії" key="2">
            <Table
              rowKey="id"
              columns={columns}
              dataSource={props.certifications}
              size="middle"
              locale={{emptyText: "Немає данних"}}
              pagination={false}
            />
          </TabPane>
        </Tabs>
      </Spin>
    </IModalForm>
  );
}
