import React from "react";
import {Button, Form, Icon, Input, Switch, Upload} from "antd";
import IFormItem from "../../components/IFormItem";

class Uploader extends React.Component {


  onUpload = (e) => {
    this.props.onUploadFile(e.file)
  };

  render() {
    const filesList = [];
    if (this.props.file) {
      filesList.push({
        uid: '1',
        name: this.props.file.original_name,
        status: 'done',
        url: this.props.file.url,
      })
    }
    return (
      <Upload
        onRemove
        fileList={filesList}
        customRequest={this.onUpload}
      >
        <Button>
          <Icon type="upload" /> Завантажити
        </Button>
      </Upload>
    );
  }
}

export default class RuleForm extends React.Component {

  render() {
    return (
      <Form>
        <IFormItem
          label="Документ"
          errorKey="file_id"
          errors={this.props.errors}
        >
          <Uploader {...this.props}/>
        </IFormItem>
        <IFormItem
          label="Назва документу"
          errorKey="rule_title"
          errors={this.props.errors}
        >
          <Input
            name="rule_title"
            value={this.props.rule_title}
            onChange={(e) => this.props.onChangeInput(e.target.name, e.target.value)}
          />
        </IFormItem>
        <IFormItem
          label="Відмітити як новий"
          errorKey="is_new"
          errors={this.props.errors}
        >
          <Switch
            checked={this.props.is_new}
            onChange={(v) => this.props.onChangeInput("is_new", v)}
          />
        </IFormItem>
      </Form>
    );
  }
}
