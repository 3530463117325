import React from 'react';
import AdminBaseLayoutContainer from "../../components/BaseLayout/AdminBaseLayoutContainer";
import BeltListView from "./views/BeltListView";


class BeltList extends React.Component {

  render() {
    return (
      <AdminBaseLayoutContainer>
        <BeltListView />
      </AdminBaseLayoutContainer>
    )
  }

}


export default BeltList;
