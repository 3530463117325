import _ from "lodash";
import React from "react";
import {Avatar, Breadcrumb, Button, Card, Cascader, Col, Input, List, Modal, Pagination, Row} from "antd";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {
  calculateAge,
  formatDate,
  formatGrade,
  getBaseUrl,
  getNoun,
  getServiceDomain
} from "../../../../utils";
import {prepareFilterOptions} from "../utils";
import axios from "axios";

import "../Athletes.css";
import AthleteDescription from "../components/AthleteDescriptoin/index";
import CheckCertificate from "../../../../shared/CheckCertificate/index";
import {getRankTitle} from "../../../../admin/athlete_certifications/components/MemberForm/utils";


const toQueryString = (params) => Object.entries(params).map((q) => q.join('=')).join('&');

const IconText = ({ text }) => (
  <span>
    {text}
  </span>
);

export default class AthletesListView extends React.Component {


  constructor(props) {
    super(props);
    this.debouncedSuggest = _.debounce(this.fetchAthletes, 1500, { 'maxWait': 4 });
    this.state = {
      loading: true,
      athletes: [],
      region_id: '',
      club_id: '',
      query: '',
      visibleAthlete: null,
      page: 1,
      pages: 0,
      total: 0,
      athleteHistories: [],
    };
  }

  componentDidMount() {
    const params = this.assignParams({});
    this.fetchAthletes(params);
    this.getData(`${getBaseUrl()}/public/agencies`, (resAgencies) => {
      this.getData(`${getBaseUrl()}/public/clubs`, (resClubs) => {
        this.setState({
          loading: false,
          filters: prepareFilterOptions(resAgencies.data.agencies, resClubs.data.clubs),
        });
      });
    });
  }

  fetchAthletes = (params) => {
    const queryString = toQueryString(params);
    this.getData(`${getBaseUrl()}/public/athletes?${queryString}`, (res) => {
      this.setState({
        loading: false,
        athletes: res.data.athletes.sort((a, b) => {
          return a.last_name.localeCompare(b.last_name, undefined, {sensitivity: 'base'});
        }),
        page: res.data.paginator.page,
        pages: res.data.paginator.pages,
        total: res.data.paginator.total,
        page_size: res.data.paginator.page_size,
      });
    });
  };

  getData = (url, callback) => {
    axios.get(url)
      .then((res) => {
        callback(res);
      });
  };

  assignParams = (params) => {
    const defaultParams = {
      region_id: this.state.region_id,
      club_id: this.state.club_id,
      query: this.state.query,
      page: 1,
    };
    return Object.assign(defaultParams, params)
  };

  onSelectFilter = (d) => {
    const [regionId, clubId] = d;
    const params = this.assignParams({region_id: regionId, club_id: clubId});
    this.setState({...params, loading: true});
    this.debouncedSuggest(params);
  };

  onClickAthlete = (athlete) => {
    this.setState({
      visibleAthlete: athlete
    });
    this.getData(`${getBaseUrl()}/public/athletes/${athlete.id}/histories`, (res) => {
      this.setState({
        loading: false,
        athleteHistories: res.data.athlete_histories,
      });
    });
  };

  onCancel = () => {
    this.setState({
      visibleAthlete: null,
    });
  };

  onSearch = (e) => {
    const params = this.assignParams({query: e.target.value});
    this.setState({...params, loading: true});
    this.debouncedSuggest(params);
  };

  onChangePage = (page) => {
    const params = this.assignParams({page: page});
    this.setState({...params, loading: true});
    this.debouncedSuggest(params);
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Спортсмени - Українська Федерація карате</title>
        </Helmet>
        <Breadcrumb style={{ marginTop: '20px', marginBottom: "5px" }}>
          <Breadcrumb.Item>
            <Link to="/">
              {getServiceDomain()}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Спортсмени
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          size="small"
          bordered={false}
          title="Список спортсменів"
        >
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div style={{ width: '100%', paddingTop: "10px" }}>
                <Input.Search
                  allowClear
                  placeholder="Введіть ім'я або прізвище"
                  onChange={this.onSearch}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div style={{ width: '100%', paddingTop: "10px" }}>
                <Cascader
                  expandTrigger="hover"
                  placeholder={"Виберіть фільтр"}
                  changeOnSelect
                  style={{ width: '100%' }}
                  options={this.state.filters}
                  onChange={this.onSelectFilter}
                />
              </div>
            </Col>
          </Row>
          <CheckCertificate/>
          <Row>
            <Col>
              <div style={{paddingTop: "15px"}}>
                <b>{this.state.total}</b> {getNoun(this.state.total, 'спортсмен', 'спротсмена', 'спортсменів')}
              </div>
            </Col>
          </Row>
          <br/>
        </Card>

        <Card
          loading={this.state.loading}
        >
          <Row type="flex" justify="space-around" align="middle">
            <Col>
              {this.state.total > 0 &&
              <div style={{marginBottom: "15px"}}>
                <Pagination
                  current={this.state.page}
                  total={this.state.total}
                  pageSize={this.state.page_size}
                  onChange={this.onChangePage}
                />
              </div>
              }
            </Col>
          </Row>
          <Row>
            <List
              size="middle"
              dataSource={this.state.athletes}
              locale={{emptyText: "Немає даних"}}
              renderItem={item => (
                <List.Item
                  key={item.id}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={item.logo && item.logo.url}
                        size={80}
                        shape="square"
                      >
                        Avatar
                      </Avatar>
                    }
                    title={
                      <Button
                        style={{height: "50px"}}
                        size="large"
                        type="link"
                        onClick={() => this.onClickAthlete(item)}
                      >
                        <span style={{whiteSpace: "normal"}}>{item.last_name} {item.first_name} {item.middle_name}</span>
                      </Button>
                    }
                    description={
                      <div style={{marginLeft: "15px"}}>
                        <Row type="flex" justify="center">
                          <Col xs={24} sm={24} md={6} lg={6} xl={6}><IconText text={<span><b>Клуб: </b><br/>{item.club_name}</span>} /></Col>
                          <Col xs={24} sm={24} md={6} lg={6} xl={6}><IconText text={<span><b>Регіон: </b><br/>{item.region_name}</span>} /></Col>
                          <Col xs={24} sm={24} md={6} lg={6} xl={6}><IconText text={<span><b>Дата народження: </b><br/>{formatDate(item.birth_date)} ({calculateAge(new Date(item.birth_date))})</span>} /></Col>
                          <Col xs={24} sm={24} md={4} lg={4} xl={4}><IconText text={<span><b>ID Card: </b><br/>{item.id_card_purchase_date ? 'Так' : 'Ні'}</span>} /></Col>
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}><IconText text={<span><b>Кю/Дан: </b><br/>{item.rank ? getRankTitle(item.rank) : formatGrade(item.kyu, item.dan)}</span>} /></Col>
                        </Row>
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </Row>
          <Row type="flex" justify="space-around" align="middle">
            <Col>
              {this.state.total > 0 &&
              <div style={{marginTop: "20px"}}>
                <Pagination
                  current={this.state.page}
                  total={this.state.total}
                  pageSize={this.state.page_size}
                  onChange={this.onChangePage}
                />
              </div>
              }
            </Col>
          </Row>
        </Card>
        {!!this.state.visibleAthlete &&
        <Modal
          title={`${this.state.visibleAthlete.last_name} ${this.state.visibleAthlete.first_name} ${this.state.visibleAthlete.middle_name}`}
          visible={!!this.state.visibleAthlete}
          onCancel={this.onCancel}
          footer={null}
        >
          <AthleteDescription
            logo={this.state.visibleAthlete.logo}
            region={this.state.visibleAthlete.region_name}
            club={this.state.visibleAthlete.club_name}
            kyu={this.state.visibleAthlete.kyu}
            dan={this.state.visibleAthlete.dan}
            rank={this.state.visibleAthlete.rank}
            birthDate={this.state.visibleAthlete.birth_date}
            athleteHistories={this.state.athleteHistories}
          />
        </Modal>
        }
      </React.Fragment>
    );
  }
}
