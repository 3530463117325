import React from "react";
import * as athleteService from "../../../../services/admin/athletes";
import {formatSubmitDate} from "../../../../utils";
import * as teamService from "../../../../services/admin/teams";
import IModalForm from "../../../../components/IModalForm";
import TeamForm from "./TeamForm";
import {Spin} from "antd";
import moment from "moment";


function sortList(list) {
  return list.slice().sort((first, second) => first.order - second.order);
}

class TeamFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      members: [],
      loading: false,
      editMember: null,
      category: '',
      challenges: '',
      status: 'public',
      files: [],
    }
  }

  componentDidMount() {
    const {team} = this.props;
    if (team) {
      teamService.getById(team.id).then((res) => {
        const {data} = res.data;
        this.setState({
          status: data.status,
          title: data.title,
          location: data.location,
          link: data.link,
          date_started: moment(data.date_started),
          date_expired: moment(data.date_expired),
          members: sortList(data.members),
          files: data.files,
        })
      });
    }
  }

  onChangeInput = (name, value) => {
    this.setState({[name]: value})
  };

  onAddMember = (member) => {
    if (!member.id || !member.category) {
      this.setState({
        errors: {
          id: ["Виберіть спортсмена"],
          category: ["Введіть категорію"]
        }
      });
      return
    }
    this.setState({
      loading: true,
    });
    athleteService.getById(member.id)
      .then((res) => {
        const newMember = res.data.data;
        newMember['category'] = member.category;
        newMember['type'] = member.type;
        newMember['challenges'] = member.challenges;
        newMember['order'] = member.order || this.state.members.length;
        let members = [...this.state.members];

        const memberId = this.state.editMember
          ? this.state.editMember.id
          : newMember.id;

        const memberIndex = members.findIndex(m => m.id === Number(memberId));

        if (memberIndex >= 0) {
          members[memberIndex] = newMember
        } else {
          members = members.concat(newMember);
        }

        this.setState({
          members: members,
          id: undefined,
          category: undefined,
          challenges: 0,
          editMember: null,
          loading: false,
          errors: {}
        })
      });
  };

  onDeleteMember = (memberId) => {
    this.setState({
      members: this.state.members.filter(m => m.id !== Number(memberId)),
      loading: false,
    })
  };

  onEditMember = (memberId) => {
    const member = this.state.members.filter(m => m.id === Number(memberId))[0];
    this.setState({
      editMember: member,
      id: member.id,
      category: member.category,
      challenges: member.challenges,
      loading: false,
      errors: {}
    })
  };

  onCancelMember = () => {
    this.setState({
      editMember: null,
    })
  };

  preparePostData = () => {
    return {
      type: this.props.type,
      status: this.state.status,
      title: this.state.title,
      location: this.state.location,
      date_started: formatSubmitDate(this.state.date_started),
      date_expired: formatSubmitDate(this.state.date_expired),
      link: this.state.link,
      members: this.state.members.map((m) => {
        return {
          id: m.id,
          category: m.category,
          type: m.type,
          order: m.order,
          challenges: m.challenges,
        }
      }),
      file_ids: this.state.files.map(f => f.id)
    }
  };

  onSubmit = async () => {
    this.setState({loading: true});
    const data = this.preparePostData();
    let res;
    if (!!this.props.team) {
      res = await teamService.save(this.props.team.id, data);
    } else {
      res = await teamService.add(data);
    }

    const onSuccess = () => {
      this.props.onCancel();
      this.props.onSubmit();
    };

    if (res.data.status === 'ok') {
      this.setState({
        loading: false,
        showModal: false,
        editMode: false,
        currentId: null,
      }, onSuccess)
    } else {
      this.setState({
        loading: false,
        errors: res.data.errors,
      })
    }
  };

  uploadFile = (file) => {
    teamService.uploadFile(file)
      .then((res) => {
        this.setState({
          files: this.state.files.concat(res.data.file)
        });
      })
  };

  removeFile = (file) => {
    this.setState({files: this.state.files.filter(f => f.id !== file.uid)})
  };

  sortList = (list) => {
    this.setState({
      ...this.state,
      members: sortList(list)
    })
  };

  reorderList = (sourceIndex, destinationIndex) => {
    if (destinationIndex === sourceIndex) {
      return;
    }
    const list = [...this.state.members];
    if (destinationIndex === 0) {
      list[sourceIndex].order = list[0].order - 1;
      this.sortList(list);
      return;
    }
    if (destinationIndex === list.length - 1) {
      list[sourceIndex].order = list[list.length - 1].order + 1;
      this.sortList(list);
      return;
    }
    if (destinationIndex < sourceIndex) {
      list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex - 1].order) / 2;
      this.sortList(list);
      return;
    }
    list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex + 1].order) / 2;
    this.sortList(list);
  };

  render() {
    return (
      <IModalForm
        title="Додати збірну"
        showModal={this.props.showForm}
        onCancel={this.props.onCancel}
        onSubmit={this.onSubmit}
      >
        <Spin spinning={this.state.loading} size="large">
          <TeamForm
            {...this.state}
            type={this.props.type}
            loading={this.state.loading}
            members={this.state.members}
            errors={this.state.errors}
            editMember={this.state.editMember}
            onChangeInput={this.onChangeInput}
            onAddMember={this.onAddMember}
            onDeleteMember={this.onDeleteMember}
            onEditMember={this.onEditMember}
            onCancelMember={this.onCancelMember}
            uploadFile={this.uploadFile}
            removeFile={this.removeFile}
            reorderList={this.reorderList}
          />
        </Spin>
      </IModalForm>
    );
  }
}

export default TeamFormContainer
