import React from "react";
import AdminBaseLayoutContainer from "../../components/BaseLayout/AdminBaseLayoutContainer";
import YouthTeamListView from "./views/YouthTeamListView";
import NationalTeamListView from "./views/NationalTeamListView";
import {Route, Switch} from 'react-router-dom';

class NationalTeamList extends React.Component {

  render() {
    return (
      <AdminBaseLayoutContainer>
        <Switch>
          <Route
            exact
            path={"/admin/teams/national"}
            component={NationalTeamListView}
          />
          <Route
            exact
            path={"/admin/teams/youth"}
            component={YouthTeamListView}
          />
        </Switch>
      </AdminBaseLayoutContainer>
    )
  }

}


export default NationalTeamList;
