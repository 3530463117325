import React from "react";
import PropTypes from "prop-types";
import {Cascader} from "antd";
import IFormItem from "../../../components/IFormItem";
import * as serviceRegion from "../../../services/admin/agencies";
import * as serviceClubs from "../../../services/admin/clubs";
import {prepareFilterOptions} from "../../../site/pages/Athletes/utils";


const ClubsCascader = ({value, errors, onChange, changeOnSelect, placeholder, label, style}) => {
  const [options, setOptions] = React.useState([]);
  React.useEffect(() => {
    async function fetchData() {
      const regionRes = await serviceRegion.getAll();
      const clubRes = await serviceClubs.getAllClubs();
      const res = prepareFilterOptions(
        regionRes.data.agencies,
        clubRes.data.clubs
      );
      setOptions([{value: null, label: "УФК", children: []}].concat(res))
    };
    fetchData();
  }, []);


  return (
    <IFormItem
      label={label || "Клуб"}
      errorKey="club_id"
      errors={errors}
    >
      <Cascader
        changeOnSelect={changeOnSelect}
        expandTrigger="hover"
        placeholder={placeholder || "Виберіть клуб"}
        style={style || { width: '100%' }}
        value={value}
        options={options}
        onChange={(v) => {
          onChange('region_club', v);
        }}
      />
    </IFormItem>
  );
};

ClubsCascader.propTypes = {
  value: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ClubsCascader
