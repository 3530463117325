import React from "react";
import {Redirect} from "react-router-dom";
import {Button, Card, Form, Icon, Input} from "antd";
import "../../App.css";
const FormItem = Form.Item;

export default class AdminLogin extends React.Component {
  state = {
    email: null,
    password: null,
  }

  onChangeInput = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.login(this.state.email, this.state.password, this.props.history);
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />
    }
    return (
      <Card style={{ margin: "0 auto", maxWidth: "400px"}} >
        <Form className="login-form">
          <FormItem>
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
              name="email"
              value={this.state.email}
              onChange={this.onChangeInput}
            />
          </FormItem>
          <FormItem>
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Пароль"
              name="password"
              value={this.state.password}
              onChange={this.onChangeInput}
            />
          </FormItem>
          <div style={{margin: "0 auto"}}>
            <FormItem>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                onClick={this.onSubmit}
              >
                Увійти
              </Button>
            </FormItem>
          </div>
        </Form>
      </Card>
    )
  }
}