import Cookies from 'js-cookie';
import actionTypes from './actionTypes';

const initialState = {
  token: Cookies.get("token"),
  loading: false,
  editMode: false,
  showModal: false,
  errors: {},
  message: null,

  banner: null,
  banners: [],
  file: null,
  status: 'public',
  link: null,
  title: '',
  order_num: '1',
};


export default function reducer(state=initialState, action) {
  switch (action.type) {

    case actionTypes.BANNERS_SET_INITIAL_PARTNER:
      const emptyPartner = {
        editMode: false,
        banner: null,
        file: null,
        status: 'public',
        link: null,
        title: '',
        order_num: '1',
      };
      return {...state, ...emptyPartner};

    case actionTypes.LOADING:
      return {...state, loading: action.loading};

    case actionTypes.SET_PARTNERS:
      return {...state, banners: action.banners};

    case actionTypes.BANNERS_SHOW_FORM:
      return {...state, showModal: true};

    case actionTypes.BANNERS_HIDE_FORM:
      return {...state, showModal: false};

    case actionTypes.SHOW_MESSAGE:
      return {...state, message: action.message};

    case actionTypes.BANNERS_SET_FILE:
      return {...state, loading: false, file: action.file};

    case actionTypes.BANNERS_CHANGE_INPUT:
      return {...state, [action.name]: action.value};

    case actionTypes.BANNERS_SET_ERRORS:
      return {...state, errors: action.errors};

    case actionTypes.BANNERS_SET_PARTNER:
      return {...state, ...action.banner, banner: action.banner, editMode: true};

    default:
      return state;
  }
}
