import Cookies from 'js-cookie'

const initialState = {
  token: Cookies.get("token"),
  isAuthenticated: null,
  isLoading: true,
  user: null,
  errors: {},
};


export default function auth(state=initialState, action) {

  switch (action.type) {

    case 'USER_LOADING':
      return {...state, isLoading: true};

    case 'USER_LOADED':
      return {...state, isAuthenticated: true, isLoading: false, user: action.user};

    case 'AUTHENTICATION_ERROR':
      return {...state, isAuthenticated: false, isLoading: false, user: null};

    case 'LOGIN_SUCCESSFUL':
      Cookies.set("token", action.data.token);
      return {
        ...state,
        ...action.data,
        isAuthenticated: true,
        isLoading: false,
        errors: null
      };

    case 'LOGOUT_SUCCESSFUL':
      return {
        ...state,
        errors: action.data,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false
      };

    default:
      return state;
  }
}