import React from "react";
import {Link} from "react-router-dom";
import {Col, Divider, Drawer, Icon, Layout, Menu, Row, BackTop} from "antd";

import logo from "../../../public/logo.png";
import calendar_icon from "../../../public/calendar.png";
import fb_icon from "../../../public/facebook.png";
import insta_icon from "../../../public/instagram.png";
import youtube_icon from "../../../public/youtube.png";
import "../../App.css";
import miniLogo from "../../../public/mini_logo.png";
import {getServiceDomain} from "../../utils";

const { Header, Content, Footer } = Layout;

const FooterRow = () => (
  <div style={{background: "#001529"}}>
    <Row type="flex" justify="center" align="middle">
      <Col xs={0} sm={0} md={0} lg={2} xl={2} xxl={2}>
        <div style={{paddingTop: "50px"}}>
          <Link to={`/`} style={{height: "110px"}}>
            <img style={{width: "100px"}} alt="ukf" src={miniLogo}/>
          </Link>
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
        <Row type="flex" justify="center" align="middle">
          <Col span={24}>
            <h3 style={{color: "white", marginTop: "20px"}}>УФК В МЕРЕЖІ</h3>
          </Col>
        </Row>
        <Row type="flex" justify="center" align="middle">
          <Col span={24}>
            <div style={{paddingTop: "15px"}}>
              <a href="https://www.facebook.com/karate.ua" target="_blank" rel="noopener noreferrer">
                <Icon
                  width="180"
                  type="facebook"
                  style={{
                    color: "white",
                    fontSize: '35px',
                    verticalAlign: "middle"
                  }}
                />
                <span style={{color: "white"}}> @KARATE.UA</span>
              </a>
            </div>
            <div style={{paddingTop: "15px", paddingLeft: "20px"}}>
              <a href="https://www.instagram.com/ukf_official" target="_blank" rel="noopener noreferrer">
                <Icon
                  width="180"
                  type="instagram"
                  style={{
                    color: "white",
                    fontSize: '35px',
                    verticalAlign: "middle"
                  }}
                />
                <span style={{color: "white"}}> @UKF_OFFICIAL</span>
              </a>
            </div>
            <div style={{paddingTop: "15px"}}>
              <a href="https://www.youtube.com/channel/UCDI9TpMuORoMElKyjI9pcIQ" target="_blank" rel="noopener noreferrer">
                <Icon
                  width="180"
                  type="youtube"
                  style={{
                    color: "white",
                    fontSize: '35px',
                    verticalAlign: "middle"
                  }}
                />
                <span style={{color: "white"}}> UKFOFFICIAL</span>
              </a>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}>
        <Divider style={{height: "200px"}} type="vertical" />
      </Col>
      <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
        <Row type="flex" justify="center" align="middle">
          <Col span={24}>
            <h3 style={{color: "white", marginTop: "15px"}}>КОРИСНІ ПОСИЛАННЯ</h3>
          </Col>
        </Row>
        <Row type="flex" justify="center" align="middle">
          <Col span={8}>
            <div style={{paddingTop: "15px"}}>
              <a style={{color: "white"}} href="https://www.wkf.net/" target="_blank" rel="noopener noreferrer">
                WKF
              </a>
            </div>
            <div style={{paddingTop: "15px"}}>
              <a style={{color: "white"}} href="http://www.europeankaratefederation.net/" target="_blank" rel="noopener noreferrer">
                EKF
              </a>
            </div>
            <div style={{paddingTop: "15px"}}>
              <a style={{color: "white"}} href="http://www.olympic.org/" target="_blank" rel="noopener noreferrer">
                IOC
              </a>
            </div>
            <div style={{paddingTop: "15px"}}>
              <a style={{color: "white"}} href="http://www.wada-ama.org/en/" target="_blank" rel="noopener noreferrer">
                W.A.D.A
              </a>
            </div>
          </Col>
          <Col span={8}>
            <div style={{paddingTop: "15px"}}>
              <a style={{color: "white"}} href="http://noc-ukr.org/" target="_blank" rel="noopener noreferrer">
                НОК
              </a>
            </div>
            <div style={{paddingTop: "15px"}}>
              <a style={{color: "white"}} href="http://www.dsmsu.gov.ua/index/ua/" target="_blank" rel="noopener noreferrer">
                МІНСПОРТ
              </a>
            </div>
            <div style={{paddingTop: "15px"}}>
              <a style={{color: "white"}} href="http://scu.org.ua/" target="_blank" rel="noopener noreferrer">
                СКУ
              </a>
            </div>
            <div style={{paddingTop: "15px"}}>
              <a style={{color: "white"}} href="http://uspa.com.ua/" target="_blank" rel="noopener noreferrer">
                АСЖУ
              </a>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row type="flex" justify="center" align="middle">
      <Col span={24}>
        <div style={{color: "white", paddingTop: "20px"}}>
          ©
          <a style={{color: "white", paddingLeft: "5px"}} href={getServiceDomain()}>Karate.ua</a>
          <br/>
          <span style={{paddingLeft: "10px"}}>Ukrainian Karate Federation 2019</span>
        </div>
      </Col>
    </Row>
  </div>
)

function renderMenu(theme, mode) {
  const withIconDown = mode === "horizontal";
  return (
    <Menu
      theme={theme}
      mode={mode}
      style={{ lineHeight: '52px', paddingRight: "3px", border: 0 }}
    >
      <Menu.Item>
        <Link to={`/news`}>
          Новини
        </Link>
      </Menu.Item>
      <Menu.SubMenu
        title={
          <span>
            {withIconDown && <Icon type="down" />}
            Федерація
          </span>
        }
      >
        <Menu.Item >
          <Link to={`/federation/structure`}>
            Структура
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={`/federation/agencies`}>
            Представництва
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={`/federation/docs`}>
            Документація
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={`/federation/terms`}>
            Умови вступу
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={`/federation/belts`}>
            Почесні пояси
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={`/federation/statistics`}>
            Статистика
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={`/federation/contacts`}>
            Контакти
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        title={
          <span>
            {withIconDown && <Icon type="down" />}
            Збірна
            </span>
        }
      >
        <Menu.Item>
          <Link to={`/teams/national`}>
            Національна
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={`/teams/youth`}>
            Молодіжна та кадетcько-юніорська
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        title={
          <span>
            {withIconDown && <Icon type="down" />}
            Турніри
          </span>
        }
      >
        <Menu.Item>
          <Link to={`/competitions/calendar`}>
            Календар
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={`/competitions/rules`}>
            Правила
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        title={
          <span>
            {withIconDown && <Icon type="down" />}
            Учасники
          </span>
        }
      >
        <Menu.Item>
          <Link to={`/members/athletes`}>
            Спортсмени
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={`/members/trainers`}>
            Тренери
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={`/members/referees`}>
            Судді
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  )

}


export default class PublicBaseLayout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showDrawer: false
    }
  }

  onToggleMenu = (e) => {
    this.setState({showDrawer: !this.state.showDrawer})
  };

  renderSocialIcons = () => {
    const iconUrls = [
      {alt: "ukf_events", link: 'https://karate.ua/competitions/calendar', icon: calendar_icon},
      {alt: "ukf_facebook", link: 'https://www.facebook.com/karate.ua', icon: fb_icon},
      {alt: "ukf_instagram", link: 'https://www.instagram.com/ukf_official', icon: insta_icon},
      {alt: "ukf_youtube", link: 'https://www.youtube.com/channel/UCDI9TpMuORoMElKyjI9pcIQ', icon: youtube_icon},
    ];
    return (
      iconUrls.map(r =>
        <Col key={r.alt} span={1} style={{paddingTop: "3px"}}>
          <a href={r.link} target="_blank" rel="noopener noreferrer">
            <img alt={r.alt} src={r.icon} width="35px"/>
          </a>
        </Col>
      )
    )
  };


  render() {
    return (
      <div className="App1">
        <Layout className="layout" style={{minHeight:"100vh"}}>
          <Row style={{ margin: '0 auto', maxWidth: "980px", width: "100%" }}>
            <Col xs={0} sm={0} md={0} lg={8} xl={24} xxl={24} >
              <Row gutter={55} style={{position: "absolute", width: "980px", top: "0px", left:"40px"}}>
                <Col span={18}> </Col>
                {this.renderSocialIcons()}
              </Row>
            </Col>
          </Row>
          <Header style={{height: "52px", marginTop: "40px", marginBottom: "43px", padding: "0 0 0 0"}}>
            <Row type="flex" justify="center" style={{height: "110px"}}>
              <Col style={{ margin: '0 auto', maxWidth: "980px", width: "100%", height: "110px" }}>
                <Row type="flex" justify="start" style={{height: "110px"}}>
                  <Col xs={20} sm={20} md={20} lg={8} xl={7} xxl={7} style={{height: "110px"}}>
                    <Link to={`/`} style={{height: "110px"}}>
                      <img className="logo" style={{
                        position: "relative",
                        top: "-65px",
                        width: "202px",
                      }} alt="ukf" src={logo}/>
                    </Link>
                  </Col>
                  <Col xs={0} sm={0} md={0} lg={{ span: 16, push: 1 }} xl={{ span: 16, push: 2 }} xxl={{ span: 16, push: 2 }}>
                    {renderMenu("dark", "horizontal")}
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={0} xl={0} xxl={0}>
                    <Drawer
                      placement="right"
                      closable={true}
                      visible={this.state.showDrawer}
                      onClose={this.onToggleMenu}
                    >
                      {renderMenu("light", "inline")}
                    </Drawer>
                    <div
                      style={{
                        background: "#001529",
                        border: "#001529",
                        color: "white",
                        width: "50px",
                        height: "52px",
                        lineHeight: "52px",
                        textAlign: "center",
                        fontSize: "25px",
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      onClick={this.onToggleMenu}
                    >
                      <Icon type="bars" />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Header>
          <Content style={{ margin: '0 auto', maxWidth: "980px", width: "90%" }}>
            {this.props.children}
            <Divider/>
          </Content>
          <Footer style={{
            margin: '0 auto',
            maxWidth: "980px",
            width: "90%",
            background: "#001529",
          }}>
            <div style={{
              paddingTop: "20px",
              position: "relative",
              textAlign: "center",
            }}
            >
              <FooterRow/>
            </div>
          </Footer>
          <BackTop />
        </Layout>
      </div>
    );
  }
}
