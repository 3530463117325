import axios from 'axios';
import {getBaseUrl, SERVER_BASE_ERROR_MSG, SUCCESS_DELETED_MSG,} from '../../utils';
import service from '../../services/admin/structures';
import {message} from 'antd';
import Cookies from 'js-cookie';

export const addStructure = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "ADD_STRUCTURE",
    });
    fetchFederationChoices()(dispatch, getState);
  }
};

export const editStructure = (itemId) => {
  return (dispatch, getState) => {
    dispatch({
      type: "CLEAR_FORM",
    });
    dispatch({
      type: "EDIT_STRUCTURE",
    });
    fetchStructure(itemId)(dispatch, getState);
    fetchFederationChoices()(dispatch, getState);
    fetchMembers(itemId)(dispatch, getState);
  }
};

export const editMember = (itemId) => {
  return (dispatch, getState) => {
    fetchMember(itemId)(dispatch, getState);
  }
};

export const fetchMember = (itemId) => {
  return (dispatch, getState) => {

    const token = Cookies.get('token');

    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }

    const url = `${getBaseUrl()}/admin/structures/members/${itemId}`;
    axios.get(url, {
      headers: headers
    })
      .then((res) => {
        if (res.data.status === 'ok') {
          dispatch({
            type: "SET_MEMBER",
            member: res.data.member,
          })
        } else {
          console.error("error request")
        }
      });
  }
};

export const fetchStructure = (itemId) => {
  return (dispatch, getState) => {

    const token = Cookies.get('token');

    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }

    const url = `${getBaseUrl()}/admin/structures/${itemId}`;
    axios.get(url, {
      headers: headers
    })
      .then((res) => {
        if (res.data.status === 'ok') {
          dispatch({
            type: "SET_STRUCTURE",
            structure: res.data.structure,
          })
        } else {
          console.error("error request")
        }
      });
  }
};

export const fetchMembers = (structureId) => {
  return (dispatch, getState) => {

    const token = Cookies.get('token');

    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }

    const url = `${getBaseUrl()}/admin/structures/${structureId}/members`;
    axios.get(url, {
      headers: headers
    })
      .then((res) => {
        if (res.data.status === 'ok') {
          dispatch({
            type: "SET_MEMBERS",
            members: res.data.members,
          })
        } else {
          console.error("error request")
        }
      });
  }
};

export const fetchFederationChoices = () => {
  return (dispatch, getState) => {

    const token = Cookies.get('token');

    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }

    const url = `${getBaseUrl()}/admin/get-structures`;
    axios.get(url, {
      headers: headers
    })
      .then((res) => {
        if (res.data.status === 'ok') {
          dispatch({
            type: "SET_STRUCTURE_CHOICES",
            structureChoices: res.data.structures,
          })
        } else {
          console.error("error request")
        }
      });
  }
};

export const fetchStructuresTree = () => {
  return (dispatch, getState) => {

    const token = Cookies.get('token');

    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }

    const url = `${getBaseUrl()}/admin/structures/tree`;
    axios.get(url, {
      headers: headers
    })
      .then((res) => {
        if (res.data.status === 'ok') {
          dispatch({
            type: "SET_STRUCTURE_TREE",
            structuresTree: res.data.structures_tree,
          })
        } else {
          console.error("error request")
        }
      });
  }
};

export const changeInput = (name, value) => {
  return (dispatch, getState) => {
    dispatch({
      type: "CHANGE_VALUE",
      name: name,
      value: value,
    })
  }
};

export const showMemberForm = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "SHOW_MEMBER_FORM",
    })
  }
};

export const cancelMemberForm = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "HIDE_MEMBER_FORM",
    })
  }
};

export const submitForm = (history) => {
  return (dispatch, getState) => {
    dispatch({
      type: "LOADING",
      loading: true,
    });
    const token = Cookies.get('token');
    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }
    const state = getState().federationStructure;
    const data = {
      status: state.status,
      title: state.title,
      parent_id: state.parent_id,
      is_pseudo_parent: state.is_pseudo_parent,
    };
    let url = '';
    if (state.structure && state.editMode) {
      url = `${getBaseUrl()}/admin/structures/${state.structure.id}/edit`;
    } else {
      url = `${getBaseUrl()}/admin/structures`;
    }
    axios.post(url, data, {
      headers: headers
    })
      .then((res) => {
        if (res.data.status === 'ok') {
          dispatch({
            type: "LOADING",
            loading: false,
          })
          history.push('/admin/structures')
        } else {
          dispatch({
            type: "SET_ERRORS",
            errors: res.data.errors,
          })
        }
      });
  }
};


export const submitMemberForm = (history) => {
  return (dispatch, getState) => {
    dispatch({
      type: "LOADING",
      loading: true,
    });
    const token = Cookies.get('token');
    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }
    const state = getState().federationStructure;
    const data = {
      structure_id: state.structure.id,
      picture_id: state.file.id,
      status: state.member_status,
      full_name: state.member_full_name,
      post_description: state.member_post_description,
      email: state.member_email,
      phone: state.member_phone,
    };
    let url = '';
    if (state.member && state.memberEditMode) {
      url = `${getBaseUrl()}/admin/structures/members/${state.member.id}/edit`;
    } else {
      url = `${getBaseUrl()}/admin/structures/members`;
    }
    axios.post(url, data, {
      headers: headers
    })
      .then((res) => {
        if (res.data.status === 'ok') {
          fetchMembers(state.structure.id)(dispatch, getState)
          dispatch({
            type: "LOADING",
            loading: false,
          })
          dispatch({
            type: "HIDE_MEMBER_FORM",
          })
        } else {
          dispatch({
            type: "SET_ERRORS",
            errors: res.data.errors,
          })
        }
      });
  }
};

export const uploadFile = (file) => {
  return (dispatch, getState) => {
    dispatch({
      type: "LOADING",
      loading: true,
    })

    const token = Cookies.get('token');

    let headers = {
      'Content-Type': 'multipart/form-data'
    };

    if (token) {
      headers["Authorization"] = `${token}`;
    }
    const formData = new FormData();
    formData.append("picture", file);
    const url = `${getBaseUrl()}/uploads/upload_member_photo`;
    axios.post(url, formData, {
      headers: headers
    })
      .then((res) => {
        if (res.data.status === 'ok') {
          dispatch({
            type: "SET_FILE",
            file: res.data.file,
          })
        } else {
          console.error("error upload")
        }
      });
  }
};

function onSetLoading(loading) {
  return (dispatch) => {
    dispatch({
      type: "LOADING",
      loading: loading,
    })
  }
}

export const showMessage = (msg) => {
  return async (dispatch) => {
    message[msg.level](msg.text)
  }
};

export function onDelete(itemId) {
  return async (dispatch, getState) => {
    try {
      onSetLoading(true)(dispatch);
      const res = await service.remove(itemId);
      if (res.data.status === 'ok') {
        fetchStructuresTree()(dispatch, getState);
        onSetLoading(false)(dispatch);
        showMessage(SUCCESS_DELETED_MSG)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}

export function onDeleteMember(itemId) {
  return async (dispatch, getState) => {
    try {
      onSetLoading(true)(dispatch);
      const res = await service.removeMember(itemId);
      if (res.data.status === 'ok') {
        const state = getState().federationStructure;
        fetchMembers(state.structure.id)(dispatch, getState);
        onSetLoading(false)(dispatch);
        showMessage(SUCCESS_DELETED_MSG)(dispatch)
      }
    } catch (err) {
      showMessage(SERVER_BASE_ERROR_MSG)(dispatch);
    }
  };
}