import React from "react";
import {Form, Input} from "antd";
import IFormItem from "../../../../components/IFormItem";
import moment from "moment";
import MyDatePicker from "../../../components/MyDatePicker/MyDatePicker";


export default function ContributionsForm(props) {
  return (
    <Form>
      <IFormItem
        label={'Дата внеску'}
        errorKey="date"
        errors={props.errors}
      >
        <MyDatePicker
          value={moment(props.date)}
          onChange={(dt) => props.onChangeInput("date", dt)}
        />
      </IFormItem>
      <IFormItem
        label={'Рік внеску'}
        errorKey="year"
        errors={props.errors}
      >
        <Input
          type="number"
          name="year"
          value={props.year}
          onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
        />
      </IFormItem>
      <IFormItem
        label="Внести суму"
        errorKey="amount"
        errors={props.errors}
      >
        <Input
          type="number"
          name="amount"
          value={props.amount}
          onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
        />
      </IFormItem>
    </Form>
  );
}
