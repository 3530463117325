import React from 'react';
import AdminBaseLayoutContainer from "../../components/BaseLayout/AdminBaseLayoutContainer";
import AgenciesListView from "./views/AgenciesListView";


export default class AgenciesList extends React.Component {

  render() {
    return (
      <AdminBaseLayoutContainer>
        <AgenciesListView />
      </AdminBaseLayoutContainer>
    )
  }
}
