import React from "react";
import TeamListView from "../components/TeamList";
import * as teamService from "../../../services/admin/teams";

export default class YouthTeamListView extends  React.Component {

  render() {
    return (
      <TeamListView
        type="youth"
        title="Список молодіжних збірних"
        fetchUrl={teamService.getAllYouthUrl}
        removeUrl={teamService.getRemoveUrl}
      />
    )
  }
}
