import React from "react";
import Team from "../components/Team/index";
import PublicBaseLayout from "../../../../components/BaseLayout/PublicBaseLayout";

export default class NationalTeamsView extends React.Component {

  render() {
    return (
      <PublicBaseLayout>
        <Team
          id={this.props.match.params.id}
          title="Національна збірна"
          type="national"
        />
      </PublicBaseLayout>
    );
  }
}
