import React from "react";
import {Spin, Tabs, Table} from "antd";
import IModalForm from "../../../../components/IModalForm";
import RefereeForm from "./RefereeForm";
import * as serviceReferee from "../../../../services/admin/referees";
import {formatDate, formatSubmitDate} from "../../../../utils";
import {getRankTitle} from "../../../referee_certifications/utils";
import * as serviceCertification from "../../../../services/admin/referee_certifications";
import RefereeDocumentContainer from "../RefereeDocuments/index";

const {TabPane} = Tabs;

const columns = [
  {
    title: 'Категорія',
    dataIndex: 'rank',
    render: (t, r) => getRankTitle(r.rank),
  },
  {
    title: 'Дата атестації',
    dataIndex: 'date_exam',
    render: (t, r) => formatDate(r.date_exam)
  },
  {
    title: 'Строк ліцензії',
    dataIndex: 'certificate_date',
    render: (t, r) => formatDate(r.certificate_date)
  },
];

const RefereeFormContainer = ({refereeId, onCancel, onSubmit, showForm}) => {
  const [state, setState] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [fields, changeField] = React.useState({status: 'public'});

  React.useEffect(() => {
    async function fetchData() {
      if (refereeId) {
        const res = await serviceReferee.getById(refereeId);
        changeField(res.data.data);
        const certRes = await serviceCertification.getRefereeCertifications(refereeId);
        setState({...state, certifications: certRes.data.data})
      }
    };
    fetchData();
  }, []);

  const onChange = (name, value) => {
    if (name === 'region_club') {
      changeField({...fields, 'region_id': value[0], 'club_id': value[1]});
      return
    }
    changeField({...fields, [name]: value})
  };

  const handleSubmit = async () => {
    setLoading(true);

    const data = {
      status: fields.status,
      visibility: fields.visibility,
      reason: fields.reason,
      first_name: fields.first_name,
      last_name: fields.last_name,
      middle_name: fields.middle_name,
      first_name_eng: fields.first_name_eng,
      last_name_eng: fields.last_name_eng,
      gender: fields.gender,
      region_id: fields.region_id,
      club_id: fields.club_id,
      phone_number: fields.phone_number,
      email: fields.email,
      address: fields.address,
      file_id: fields.logo && fields.logo.id,
      birth_date: fields.birth_date && formatSubmitDate(fields.birth_date),
    };
    let res;
    if (refereeId) {
      res = await serviceReferee.save(refereeId, data)
    } else {
      res = await serviceReferee.add(data);
    }
    setLoading(false);
    if (res.data.status === 'ok') {
      onCancel()
    } else {
      setErrors(res.data.errors);
    }
    onSubmit()
  };

  const onUpload = (e) => {
    serviceReferee.uploadFile(e.file)
      .then((res) => {
        changeField({...fields, "logo": res.data.file})
      })
  };

  return (
    <IModalForm
      title={refereeId ? `Редагувати суддю ID:${refereeId}` : "Додати суддю"}
      showModal={showForm}
      loading={loading}
      onCancel={onCancel}
      onSubmit={handleSubmit}
    >
      <Spin spinning={false} size="large">
        <Tabs>
          <TabPane tab="Інформація" key="1">
            <RefereeForm
              {...fields}
              errors={errors}
              onChangeInput={onChange}
              onUpload={onUpload}
            />
          </TabPane>
          <TabPane tab="Ліцензії" key="2" disabled={!refereeId}>
             <Table
              rowKey="id"
              columns={columns}
              dataSource={state.certifications}
              size="middle"
              locale={{emptyText: "Немає данних"}}
              pagination={false}
            />
          </TabPane>
          <TabPane tab="Документи" key="3" disabled={!refereeId}>
            <RefereeDocumentContainer
              refereeId={refereeId}
            />
          </TabPane>
        </Tabs>
      </Spin>
    </IModalForm>

  );
};

export default RefereeFormContainer
