

const actionTypes = {
  RULES_LOADING: 'RULES_LOADING',
  RULES_SET_RULES_SECTIONS: 'RULES_SET_RULES_SECTIONS',
  RULES_SHOW_FORM: 'RULES_SHOW_FORM',
  RULES_HIDE_FORM: 'RULES_HIDE_FORM',
  RULES_CHANGE_INPUT: 'RULES_CHANGE_INPUT',
  RULES_SET_FILE: 'RULES_SET_FILE',
  RULES_SET_ERRORS: 'RULES_SET_ERRORS',
  RULES_SET_INITIAL: 'RULES_SET_INITIAL',
  RULES_SET_RULES_SECTION: 'RULES_SET_RULES_SECTION',
  RULES_SHOW_DOCUMENT_FORM: 'RULES_SHOW_DOCUMENT_FORM',
  RULES_HIDE_DOCUMENT_FORM: 'RULES_HIDE_DOCUMENT_FORM',
  RULES_SET_RULES: 'RULES_SET_RULES',
  RULES_SET_RULE: 'RULES_SET_RULE',
  RULES_SET_INITIAL_RULE: 'RULES_SET_INITIAL_RULE',
};

export default actionTypes;