

const actionTypes = {
  LOADING: 'LOADING',
  SET_TOURNAMENTS: 'SET_TOURNAMENTS',
  SHOW_MESSAGE: 'SHOW_MESSAGE',
  TOURNAMENTS_SHOW_FORM: 'TOURNAMENTS_SHOW_FORM',
  TOURNAMENTS_HIDE_FORM: 'TOURNAMENTS_HIDE_FORM',
  TOURNAMENTS_CHANGE_INPUT: 'TOURNAMENTS_CHANGE_INPUT',
  TOURNAMENTS_SET_FILE: 'TOURNAMENTS_SET_FILE',
  TOURNAMENTS_SET_ERRORS: 'TOURNAMENTS_SET_ERRORS',
  TOURNAMENTS_SET_INITIAL: 'TOURNAMENTS_SET_INITIAL',
  TOURNAMENTS_SET: 'TOURNAMENTS_SET',
};

export default actionTypes;