// TODO move to config
export const  RANK_OPTIONS = [
  {value: 'kyu_11', label: '11 Кю', num: 1},
  {value: 'kyu_10', label: '10 Кю', num: 2},
  {value: 'kyu_9', label: '9 Кю', num: 3},
  {value: 'kyu_8', label: '8 Кю', num: 4},
  {value: 'kyu_7', label: '7 Кю', num: 5},
  {value: 'kyu_6', label: '6 Кю', num: 6},
  {value: 'kyu_5', label: '5 Кю', num: 7},
  {value: 'kyu_4', label: '4 Кю', num: 8},
  {value: 'kyu_3', label: '3 Кю', num: 9},
  {value: 'kyu_2', label: '2 Кю', num: 10},
  {value: 'kyu_1', label: '1 Кю', num: 11},
  {value: 'dan_1', label: '1 Дан', num: 12},
  {value: 'dan_2', label: '2 Дан', num: 13},
  {value: 'dan_3', label: '3 Дан', num: 14},
  {value: 'dan_4', label: '4 Дан', num: 15},
  {value: 'dan_5', label: '5 Дан', num: 16},
  {value: 'dan_6', label: '6 Дан', num: 17},
  {value: 'dan_7', label: '7 Дан', num: 18},
  {value: 'dan_8', label: '8 Дан', num: 19},
  {value: 'dan_9', label: '9 Дан', num: 20},
  {value: 'dan_10', label: '10 Дан', num: 21},
];

export function getRankTitle(rank, defaultLabel='Не вказано') {
  const r = RANK_OPTIONS.filter(o => o.value === rank)[0];
  return r ? r['label'] : defaultLabel
}

export function getRankOption(rank, defaultLabel='Не вказано') {
  const r = RANK_OPTIONS.filter(o => o.value === rank)[0];
  return r ? r : defaultLabel
}

export function getRankFromKyuDan(kyu, dan) {
  if (Number(kyu)) {
    const resKyu = getRankOption(`kyu_${kyu}`, null)
    return resKyu && resKyu.value
  } else if (Number(dan)) {
    const resDan = getRankOption(`dan_${dan}`, null)
    return resDan && resDan.value
  } else {
    return null
  }
}
