import {getBaseUrl} from "../../utils";
import io from "../../lib/io";


export async function remove(id) {
  return await io.post(`${getBaseUrl()}/admin/documents/${id}/delete`);
}

export async function add(data) {
  return await io.post(`${getBaseUrl()}/admin/documents`, data);
}

export async function save(id, data) {
  return await io.post(`${getBaseUrl()}/admin/documents/${id}/edit`, data);
}

export async function getByObjectType(contentObjId, contentType) {
  return await io.get(`${getBaseUrl()}/admin/documents?content_object_id=${contentObjId}&content_type=${contentType}`);
}


export default {
  remove,
  add,
  save,
  getByObjectType
}
