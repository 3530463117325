import React from "react";
import AdminBaseLayoutContainer from "../../components/BaseLayout/AdminBaseLayoutContainer";
import CertificationsView from "./views/CertificationsView";


export default class Certifications extends React.Component {

  render() {
    return (
      <AdminBaseLayoutContainer>
        <CertificationsView />
      </AdminBaseLayoutContainer>
    )
  }
}
