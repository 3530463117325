import React from "react";
import PropTypes from "prop-types";
import {DatePicker} from "antd";
import moment from "moment";
import {VIEW_DATE_FORMAT} from "../../../utils";
import locale from "antd/lib/date-picker/locale/uk_UA";
moment.locale('ua');


const MyDatePicker = ({dateFormat, value, onChange}) => {
  return (
    <DatePicker
      format={dateFormat}
      value={value ? moment(value) : moment()}
      locale={locale}
      onChange={onChange}
    />
  )
};

MyDatePicker.defaultProps = {
  dateFormat: VIEW_DATE_FORMAT,
  value: undefined,
};

MyDatePicker.propTypes = {
  dateFormat: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default MyDatePicker;
