import React from "react";
import {Badge, Breadcrumb, Card, Col, Row} from "antd";
import {Link} from "react-router-dom";
import axios from "axios";
import {getBaseUrl, getServiceDomain} from "../../utils";
import "./DocsList.css";
import PublicBaseLayout from "../BaseLayout/PublicBaseLayout";
import {Helmet} from "react-helmet";

export default class DocsList extends React.Component {

  state = {
    loading: true,
    docs_sections: [],
  };

  componentDidMount() {
    this.getData((res) => {
      this.setState({
        loading: false,
        docs_sections: res.data.docs_sections,
      });
    });
  }

  getData = (callback) => {
    const url = `${getBaseUrl()}/public/docs`;
    axios.get(url)
      .then((res) => {
        callback(res);
      });
  };


  render() {
    return (
      <PublicBaseLayout>
        <Helmet>
          <title>Нормативні документи - Українська Федерація карате</title>
        </Helmet>
        <Breadcrumb style={{ marginTop: '20px', marginBottom: "5px" }}>
          <Breadcrumb.Item>
            <Link to="/">
              {getServiceDomain()}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Нормативні документи
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col span={24}>
            {this.state.docs_sections.map((s) =>
              <Card key={s.id} title={s.title}>
                <ul>
                  {s.docs.map((d) =>
                    <li key={d.id}>
                      <a target="_blank" rel="noopener noreferrer" href={d.file.url}>
                        {d.title} <Badge count={d.is_new ? 'new': null} style={{ backgroundColor: '#4aa88d' }}/></a>

                    </li>
                  )}
                </ul>
              </Card>
            )}
          </Col>
        </Row>
      </PublicBaseLayout>
    );
  }
}
