import React from "react";

const FilterContext = React.createContext({
  loading: false,
  data: null,
  onChange: undefined,
  onClearAll: undefined,
  fields: {},
  showForm: false,
  editRow: null,
  onAdd: undefined,
  onEdit: undefined,
  onCancel: undefined,
  onRemove: undefined,
});

export default FilterContext
