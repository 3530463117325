import axios from 'axios';
import {getBaseUrl, SUBMIT_DATE_FORMAT} from '../../utils';
import Cookies from 'js-cookie';

export const changeInput = (name, value) => {
  return (dispatch, getState) => {
    dispatch({
        type: "CHANGE_VALUE",
        name: name,
        value: value,
    })
  }
};


export const clearForm = () => {
  return (dispatch, getState) => {
    dispatch({
        type: "CLEAR_FORM",
    })
  }
};


export const submitForm = (history) => {
  return (dispatch, getState) => {
    dispatch({
        type: "LOADING",
        loading: true,
    });
    const token = Cookies.get('token');
    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }
    const state = getState().events;
    const data = {
      status: state.status,
      date_started: state.date_started.format(SUBMIT_DATE_FORMAT),
      date_expired: state.date_expired.format(SUBMIT_DATE_FORMAT),
      title: state.title,
      link: state.link,
      place: state.place,
      members: state.members,
      file_ids: state.files.map(f => f.id)
    };
    let url = '';
    if (state.event && state.editMode) {
      url = `${getBaseUrl()}/admin/events/${state.event.id}/edit`;
    } else {
      url = `${getBaseUrl()}/admin/events`;
    }
    axios.post(url, data, {
        headers: headers
    })
    .then((res) => {
      if (res.data.status === 'ok') {
        dispatch({
          type: "LOADING",
          loading: false,
        });
        history.push('/admin/events')
      } else {
        dispatch({
          type: "SET_ERRORS",
          errors: res.data.errors,
        })
      }
    });
  }
};


export const uploadFile = (file) => {
  return (dispatch, getState) => {
    dispatch({
      type: "LOADING",
      loading: true,
    });

    const token = Cookies.get('token');

    let headers = {
      'Content-Type': 'multipart/form-data'
    };

    if (token) {
      headers["Authorization"] = `${token}`;
    }
    const formData = new FormData();
    formData.append("file", file);
    const url = `${getBaseUrl()}/uploads/upload_event_docs_file`;
    axios.post(url, formData, {
      headers: headers
    })
      .then((res) => {
        if (res.data.status === 'ok') {
          dispatch({
            type: "SET_FILE",
            file: res.data.file,
          })
        } else {
          console.error("error upload")
        }
      });
  }
};

export const removeFile = (file) => {
  return (dispatch, getState) => {
    dispatch({
        type: "REMOVE_FILE",
        file: file,
    })
  }
};

export const fetchEvent = (eventId) => {
  return (dispatch, getState) => {
    dispatch({
        type: "LOADING",
        loading: true,
    })

    const token = Cookies.get('token');

    let headers = {};

    if (token) {
      headers["Authorization"] = `${token}`;
    }

    const url = `${getBaseUrl()}/admin/events/${eventId}`;
    axios.get(url, {
        headers: headers
    })
    .then((res) => {
      if (res.data.status === 'ok') {
        dispatch({
          type: "SET_EVENT",
          event: res.data.event,
        })
      } else {
        console.error("error events")
      }
    });
  }
};