import React from "react";
import {Form, Input} from "antd";
import IFormItem from "../../../../components/IFormItem";


export default function AgencyForm(props) {
  return (
    <Form>
      <IFormItem
        label="Повна назва"
        errorKey="name"
        errors={props.errors}
      >
        <Input
          name="name"
          value={props.name}
          onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
        />
      </IFormItem>
      <IFormItem
        label="Скорочена назва"
        errorKey="short_name"
        errors={props.errors}
      >
        <Input
          name="short_name"
          value={props.short_name}
          onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
        />
      </IFormItem>
      <IFormItem
        label="Email"
        errorKey="email"
        errors={props.errors}
      >
        <Input
          name="email"
          value={props.email}
          onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
        />
      </IFormItem>
      <IFormItem
        label="Телефон"
        errorKey="phone_number"
        errors={props.errors}
      >
        <Input
          name="phone_number"
          value={props.phone_number}
          onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
        />
      </IFormItem>
      <IFormItem
        label="Керівник"
        errorKey="head"
        errors={props.errors}
      >
        <Input
          name="head"
          value={props.head}
          onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
        />
      </IFormItem>
      <IFormItem
        label="Адреса"
        errorKey="address"
        errors={props.errors}
      >
        <Input
          name="address"
          value={props.address}
          onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
        />
      </IFormItem>
      <IFormItem
        label="Сайт"
        errorKey="website"
        errors={props.errors}
      >
        <Input
          name="website"
          value={props.website}
          onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
        />
      </IFormItem>
    </Form>
  );
}
