import io from "../../lib/io";
import {getBaseUrl} from "../../utils";


export async function getRegions(queryString) {
  const query = queryString ? `?${queryString}` : '';
  return await io.get(`${getBaseUrl()}/public/agencies${query}`);
}

export default {
  getRegions
}
