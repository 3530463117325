import React from "react";
import PropTypes from "prop-types";
import {Avatar, Card} from "antd";


const Picture = ({url}) => {
  if (!url) {
    return (
      <Avatar
        src={url}
        size={205}
        shape="square"
        style={{
          objectFit: "contain",
          maxWidth: 300,
          margin: "0 auto",
        }}
      >
        Avatar
      </Avatar>
    )
  }
  return (
    <img
      alt="avatar"
      src={url}
      style={{
        objectFit: "contain",
        maxHeight: 300,
        maxWidth: 300,
        margin: "0 auto",
      }}
    />
  );
};

const MemberInfo = ({id, logoUrl, fullName, category, challenges, onClick}) => {
  const decs = challenges
    ? <span>Категорія: {category} <br/> Викликів в збірну: {challenges}</span>
    : `Категорія: ${category}`;
  return (
    <Card
      key={id}
      cover={<Picture url={logoUrl}/>}
      onClick={onClick}
    >
      <Card.Meta
        title={fullName}
        description={decs}
      />
    </Card>
  );
};

MemberInfo.propTypes = {
  id: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
};


export default MemberInfo