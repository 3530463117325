import {getBaseUrl} from '../../utils';
import io from '../../lib/io'


export async function add(data) {
  return await io.post(`${getBaseUrl()}/admin/referees`, data);
}


export function getAllUrl(queryString) {
  const query = queryString ? `?${queryString}` : '';
  return `${getBaseUrl()}/admin/referees${query}`;
}


export function getCategoryUrl() {
  return `${getBaseUrl()}/admin/referees/get-categories`;
}

export async function getAll(queryString) {
  return await io.get(getAllUrl(queryString));
}


export async function save(id, data) {
  return await io.post(`${getBaseUrl()}/admin/referees/${id}/edit`, data);
}


export async function getById(id) {
  return await io.get(`${getBaseUrl()}/admin/referees/${id}`);
}


export function getRemoveUrl(id) {
  return `${getBaseUrl()}/admin/referees/${id}/delete`;
}


export async function remove(id) {
  return await io.post(getRemoveUrl(id));
}


export async function download(data) {
  return await io.post(`${getBaseUrl()}/admin/referees/download`, data);
}

export async function uploadFile(file) {
  return await io.postFile(`${getBaseUrl()}/uploads/upload_referee_logo_file`, file);
}

export async function uploadDocumentFile(file) {
  return await io.postFile(`${getBaseUrl()}/uploads/upload_referees_docs_file`, file);
}

export default {
  getAllUrl,
  add,
  getAll,
  save,
  getById,
  remove,
  getRemoveUrl,
  download,
  uploadFile,
  getCategoryUrl,
  uploadDocumentFile,
}
