import React from "react";
import {Badge, Breadcrumb, Card, Col, Row} from "antd";
import {Link} from "react-router-dom";
import axios from "axios";
import {getBaseUrl, getServiceDomain} from "../../../utils";
import "./RulesList.css";
import PublicBaseLayout from "../../../components/BaseLayout/PublicBaseLayout";
import {Helmet} from "react-helmet";

export default class RulesList extends React.Component {

  state = {
    loading: true,
    rules_sections: [],
  };

  componentDidMount() {
    this.getData((res) => {
      this.setState({
        loading: false,
        rules_sections: res.data.rules_sections,
      });
    });
  }

  getData = (callback) => {
    const url = `${getBaseUrl()}/public/rules`;
    axios.get(url)
      .then((res) => {
        callback(res);
      });
  };


  render() {
    return (
      <PublicBaseLayout>
        <Helmet>
          <title>Правила - Українська Федерація карате</title>
        </Helmet>
        <Breadcrumb style={{ marginTop: '20px', marginBottom: "5px" }}>
          <Breadcrumb.Item>
            <Link to="/">
              {getServiceDomain()}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Правила
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col span={24}>
            {this.state.rules_sections.map((s) =>
              <Card key={s.id} title={s.title}>
                <ul>
                  {s.rules.map((d) =>
                    <li key={d.id}>
                      <a target="_blank" rel="noopener noreferrer" href={d.file.url}>
                        {d.title} <Badge count={d.is_new ? 'new': null} style={{ backgroundColor: '#4aa88d' }}/></a>
                    </li>
                  )}
                </ul>
              </Card>
            )}
          </Col>
        </Row>
      </PublicBaseLayout>
    );
  }
}
