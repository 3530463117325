import React from "react";
import {Dropdown, Icon, Menu, Button, Modal} from "antd";

const confirm = Modal.confirm;

function showConfirm(props) {
  const labelKey = props.labelKey ? props.labelKey : "title";
  confirm({
    title: `Ви хочете видалити "${props.item[labelKey]}"?`,
    okText: 'Так',
    cancelText: 'Відмінити',
    onOk() {
      props.onDelete(props.item.id)
    },
    onCancel() {},
  });
}

function showDownloadConfirm(props) {
  const labelKey = props.labelKey ? props.labelKey : "title";
  confirm({
    title: `Завантажити протокол "${props.item[labelKey]}"?`,
    okText: 'Так',
    cancelText: 'Відмінити',
    onOk() {
      props.onDownload(props.item.id)
    },
    onCancel() {},
  });
}

export default function AdminActionDropdown(props) {
  const row = props.fetchRow ? props.item: props.item.id;
  return (
    <Dropdown overlay={
      <Menu>
        {props.onEdit ?
          <Menu.Item onClick={() => props.onEdit(row)}>
            <Icon type="edit" style={{color: "#1890ff"}}/>
             Редагувати
            </Menu.Item>
          : null
        }
        {props.onDownload ?
          <Menu.Item onClick={() => showDownloadConfirm(props)}>
            <Icon type="download"/>
             Завантажити
            </Menu.Item>
          : null
        }
        <Menu.Item onClick={() => showConfirm(props)}>
          <Icon type="delete" style={{color: "#f5222d"}}/>
          Видалити
        </Menu.Item>
      </Menu>
    }>
      <Button>
        <Icon type="bars" />
        <Icon type="down" />
      </Button>
    </Dropdown>
  );
}
