import React from "react";
import moment from "moment";
import {Avatar, Divider, Spin, Table} from "antd";

import AdminFilters from "../../../components/AdminFilters";
import IModalForm from "../../../components/IModalForm";
import AdminActionDropdown from "../../../components/AdminActionDropdown";
import BeltForm from "../components/BeltForm";
import io from "../../../lib/io";
import {formatDate, getBaseUrl} from "../../../utils";
import service from "../../../services/admin/belts";

export async function postFile(file) {
  return await io.postFile(`${getBaseUrl()}/uploads/upload_belts_avatar`, file);
}

function getStatusTitle(status) {
  const statuses =  {
    public: 'Опубліковано',
    draft: 'Черновик',
    deleted: 'Видалено'
  };
  return statuses[status] || 'Не вказано'
}


export default class BeltListView extends React.Component {

  constructor(props) {
    super(props);
    this.onUpload = this.onUpload.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      currentId: null,
      status: "public",
      belts: [],
      showModal: false,
      editMode: false,
      loading: false,
      file: null,
      errors: {}
    }
  }

  componentDidMount() {
    service.getAll().then((res) => {
      this.setState({belts: res.data.belts})
    });
  }

  onAdd = () => {
    this.setState({
      currentId: null,
      editMode: false,
      showModal: true,
      status: 'public',
      name: "",
      description: "",
      dan: null,
      certificate: "",
      exam_date: moment(),
      file: null,
    })
  };

  onEdit = (rowId) => {
    service.getById(rowId).then((res) => {
      this.setState({
        currentId: rowId,
        editMode: true,
        showModal: true,
        status: res.data.belt.status,
        name: res.data.belt.name,
        description: res.data.belt.description,
        dan: res.data.belt.dan,
        certificate: res.data.belt.certificate,
        exam_date: moment(res.data.belt.exam_date),
        file: res.data.belt.file,
      })
    });
  };

  onRemove = (rowId) => {
    service.remove(rowId).then((res) => {
      this.setState({loading: true});
      service.getAll().then((res) => {
        this.setState({belts: res.data.belts, loading: false})
      });
    });
  };

  onChangeInput = (name, value) => {
    this.setState({[name]: value})
  };

  preparePostData = () => {
    return {
      status: this.state.status,
      name: this.state.name,
      description: this.state.description,
      dan: this.state.dan,
      certificate: this.state.certificate,
      exam_date: this.state.exam_date && this.state.exam_date.format("YYYY-MM-DD"),
      file_id: this.state.file && this.state.file.id,
    }
  };

  onCancel = () => {
    this.setState({showModal: false})
  };

  async onUpload(e) {
    const res = await postFile(e.file);
    this.setState({file: res.data.file});
  };

  async onSubmit() {
    const callBack = () => {
      service.getAll().then((res) => {
        this.setState({belts: res.data.belts})
      });
    };
    this.setState({loading: true});
    const data = this.preparePostData();
    let res;
    if (this.state.editMode) {
      res = await service.save(this.state.currentId, data);
    } else {
      res = await service.add(data);
    }

    if (res.data.status === 'ok') {
      this.setState({
        loading: false,
        showModal: false,
        editMode: false,
        currentId: null,
      }, callBack)
    } else {
      this.setState({
        loading: false,
        errors: res.data.errors,
      })
    }
  };

  render() {
    const columns = [
      {
        title: 'Фото',
        dataIndex: 'file',
        key: 'file',
        render: (t, r) => <Avatar src={r.file.url}/>
      },
      {
        title: 'ПІБ',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Дан',
        dataIndex: 'dan',
        key: 'dan',
      },
      {
        title: 'Номер Сертифікату',
        dataIndex: 'certificate',
        key: 'certificate',
      },
      {
        title: 'Дата присвоєння поясу',
        dataIndex: 'exam_date',
        key: 'exam_date',
        render: (t, r) => formatDate(t),
      },
      {
        title: 'Дата створення',
        dataIndex: 'date_created',
        key: 'date_created',
        render: (t, r) => formatDate(t),
      },
      {
        title: 'Дата оновлення',
        dataIndex: 'date_updated',
        key: 'date_updated',
        render: (t, r) => formatDate(t),
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (t, r) => getStatusTitle(r.status)
      },
      {
        title: 'Дії',
        dataIndex: 'action',
        key: 'action',
        render: (text, item) => (
          <AdminActionDropdown
            {...this.props}
            labelKey="name"
            onDelete={this.onRemove}
            onEdit={this.onEdit}
            item={item}
          />
        ),
      }
    ];
    return (
      <div>
        <h2>Список закордонних турнірів</h2>
        <Divider/>
        <AdminFilters
          {...this.props}
          onAdd={this.onAdd}
        />
        <Table
          columns={columns}
          dataSource={this.state.belts}
          rowKey="id"
          loading={this.state.loading}
          locale={{emptyText: "Немає данних"}}
        />
        <IModalForm
          title={this.state.editMode ? "Редагувати почесний пояс" : "Додати почесний пояс"}
          showModal={this.state.showModal}
          onCancel={this.onCancel}
          onSubmit={this.onSubmit}
        >
          <Spin spinning={this.state.loading} size="large">
            <BeltForm
              {...this.state}
              onUpload={this.onUpload}
              onChangeInput={this.onChangeInput}
              errors={this.state.errors}
            />
          </Spin>
        </IModalForm>
      </div>
    );
  }
}
