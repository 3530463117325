import {connect} from "react-redux";
import AdminEventForm from './AdminEventForm';
import {changeInput, submitForm, uploadFile, removeFile, fetchEvent, clearForm} from "./AdminEventActions";


const mapStateToProps = state => {
  return {
    ...state.events,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onInit: (itemId) => {
      if (itemId) {
        return dispatch(fetchEvent(itemId))
      }
      return dispatch(clearForm())
    },
    changeInput: (name, value) => {
      return dispatch(changeInput(name, value))
    },
    onUploadFile: (file) => {
      return dispatch(uploadFile(file))
    },
    onRemoveFile: (file) => {
      return dispatch(removeFile(file))
    },
    onSubmit: (history) => {
      return dispatch(submitForm(history))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminEventForm);
