import React from "react";
import TeamListView from "../components/TeamList";
import * as teamService from "../../../services/admin/teams";

export default class NationalTeamListView extends  React.Component {

  render() {
    return (
      <TeamListView
        type="national"
        title="Список національних збірних"
        fetchUrl={teamService.getAllNationalUrl}
        removeUrl={teamService.getRemoveUrl}
      />
    )
  }
}