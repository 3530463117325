import React from "react";
import {Button, Col, Divider, Pagination, Row, Table, Avatar} from "antd";
import AdminActionDropdown from "../../../components/AdminActionDropdown/index";
import {formatDate, getNoun} from "../../../utils";
import FilterProvider from "../../components/Filters/FilterProvider";
import SearchFilter from "../../components/Filters/components/SearchFilter/index";
import FilterResults from "../../components/Filters/FilterResults";
import {handleOnSort} from "../../libs/helpers";
import ExamForm from "../components/ExamForm";
import * as serviceCertification from "../../../services/admin/athlete_certifications";
import RemoteSelectFilter from "../../components/Filters/components/RemoteSelectFilter/index";
import * as regionService from "../../../services/admin/agencies";
import * as clubService from "../../../services/admin/clubs";
import {getRankTitle} from "../components/MemberForm/utils";
import CheckCertificate from "../../../shared/CheckCertificate/index";


const expandedRowRender = (data) => {
  const columns = [
    {
      title: 'Фото',
      dataIndex: 'logo',
      key: 'logo',
      render: (t, r) => <Avatar
        src={r.logo && r.logo.url}
        size={50}
        shape="square"
      >
        Avatar
      </Avatar>
    },
    {
      title: 'ПІБ',
      dataIndex: 'name',
      render: (t, r) => `${r.full_name}`,
    },
    {
      title: 'Номер сертифікату',
      dataIndex: 'certificate_num',
    },
    {
      title: 'Кю/Дан',
      dataIndex: 'rank',
      render: (t, r) => getRankTitle(r.rank),
    },
  ];
  return (r) => <Table columns={columns} dataSource={data[r.id]} pagination={false} />;
};


export default class CertificationsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      members: [],
      loading: false,
      editMember: null,
      category: '',
      challenges: '',
      status: 'public',
      files: [],
      extendedData: {},
    }
  }

  fetchExtendedData = async (expended, r) => {
    if (expended) {
      const memberRes = await serviceCertification.getCertificatedMembers(r.id);
      const newData = {...this.state.extendedData, [r.id]: memberRes.data.data};
      this.setState({extendedData: newData})
    }
  };

  render() {
    return (
      <div>
        <h2>Аттестации спортсменів</h2>
        <Divider/>
        <FilterProvider
          onFetchUrl={(queryString) => serviceCertification.getAllUrl(queryString)}
          onRemoveUrl={serviceCertification.getRemoveUrl}
          onDownloadUrl={serviceCertification.getDownloadUrl}
        >
          {({loading, onChange, onClearAll, showForm, editRow, onCancel, onAdd, onEdit, onRemove, onDownload}) => {
            return (
              <React.Fragment>
                <Row gutter={24}>
                  <Col lg={4}>
                    <SearchFilter
                      field="protocol_num"
                      placeholder="Введіть номер протоколу"
                    />
                  </Col>
                  <Col lg={4}>
                    <RemoteSelectFilter
                      field="region_id"
                      fetchUrl={regionService.getAllUrl()}
                      dataKey="agencies"
                      valueKey="id"
                      titleKey="name"
                      placeholder="Виберіть регіон"
                      allowClear
                    />
                  </Col>
                  <Col lg={4}>
                    <RemoteSelectFilter
                      field="club_id"
                      fetchUrl={clubService.getAllUrl()}
                      dataKey="clubs"
                      valueKey="id"
                      titleKey="short_name"
                      placeholder="Виберіть клуб"
                      allowClear
                    />
                  </Col>
                  <Col lg={4}>
                    <Button
                      icon="close"
                      onClick={onClearAll}
                    >
                      Скинути
                    </Button>
                  </Col>
                  <Col lg={4}>
                    <Button type="primary" icon="plus" onClick={onAdd}>
                      Додати
                    </Button>
                  </Col>
                </Row>
                <CheckCertificate />
                <Row>
                  <FilterResults
                    dataKey="data"
                  >
                    {({data, paginator}) => {
                      const columns = [
                        {
                          title: 'Номер протоколу',
                          dataIndex: 'protocol_num',
                          key: 'protocol_num',
                          sorter: true,
                        },
                        {
                          title: 'Дата екзамену',
                          dataIndex: 'date_exam',
                          key: 'date_exam',
                          sorter: true,
                          render: (t, r) => formatDate(t),
                        },
                        {
                          title: 'Клуб',
                          dataIndex: 'club_name',
                          key: 'club_name',
                          sorter: true,
                        },
                        {
                          title: 'Атест. спортсменів',
                          dataIndex: 'members_num',
                          key: 'members_num',
                          sorter: true,
                        },
                        {
                          title: 'Дії',
                          dataIndex: 'action',
                          key: 'action',
                          render: (text, item) => (
                            <AdminActionDropdown
                              {...this.props}
                              labelKey="protocol_num"
                              fetchRow={true}
                              onDelete={onRemove}
                              onEdit={onEdit}
                              onDownload={onDownload}
                              item={item}
                            />
                          ),
                        }
                      ];
                      return (
                        <React.Fragment>
                          <Row>
                            <br/>
                            <b>{paginator.total}</b>
                            {getNoun(paginator.total, ' атестація', ' атестації', ' атестацій')}
                            <br/>
                          </Row>
                          <Row>
                            <Table
                              columns={columns}
                              expandedRowRender={expandedRowRender(this.state.extendedData)}
                              onExpand={this.fetchExtendedData}
                              dataSource={data}
                              rowKey="id"
                              loading={loading}
                              locale={{emptyText: "Немає данних"}}
                              pagination={false}
                              onChange={handleOnSort(onChange)}
                            />
                          </Row>
                          <br/>
                          <Row type="flex" justify="end">
                            <Pagination
                              current={paginator.page}
                              total={paginator.total}
                              pageSize={paginator.page_size}
                              onChange={(page) => {
                                window.scrollTo(0, 0);
                                onChange('page', 'text', page)
                              }}
                            />
                          </Row>
                        </React.Fragment>
                      )
                    }}
                  </FilterResults>
                </Row>
                {showForm &&
                <ExamForm
                  certId={editRow && editRow.id}
                  onCancel={onCancel}
                  onSubmit={() => {
                    window.scrollTo(0, 0);
                    onChange('page', 'text', 1)
                  }}
                />
                }
              </React.Fragment>
            );
          }}
        </FilterProvider>
      </div>
    );
  }
}
