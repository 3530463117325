import axios from 'axios';
import {getAuthorizationHeader} from '../utils'


export async function postFile(url, file, dataName='file' ) {
  const formData = new FormData();
  formData.append(dataName, file);
  const headers = getAuthorizationHeader();
  headers['Content-Type'] = 'multipart/form-data';
  return await axios.post(url, formData, {headers});
}


export async function get(url) {
  const headers = getAuthorizationHeader();
  return await axios.get(url, {headers});
}


export async function post(url, data) {
  const headers = getAuthorizationHeader();
  return await axios.post(url, data, {headers});
}


export default {
  get,
  post,
  postFile,
}
