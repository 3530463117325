import React from "react";
import AdminBaseLayoutContainer from "../../components/BaseLayout/AdminBaseLayoutContainer";
import AthletesListView from "./views/AthletesListView";


class ClubList extends React.Component {

  render() {
    return (
      <AdminBaseLayoutContainer>
        <AthletesListView />
      </AdminBaseLayoutContainer>
    )
  }

}


export default ClubList;
