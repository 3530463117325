import Cookies from 'js-cookie';
import actionTypes from './actionTypes';

const initialState = {
  token: Cookies.get("token"),
  loading: false,
  editMode: false,
  editDocumentMode: false,
  showModal: false,
  errors: {},
  message: null,
  showDocumentModal: false,
  rules_section: null,
  rules_sections: [],
  rule: null,
  rules: [],
  file: null,
  status: 'public',
  link: null,
  title: '',
  rule_title: '',
  is_new: false,

};


export default function reducer(state=initialState, action) {
  switch (action.type) {

    case actionTypes.RULES_SET_RULES_SECTIONS:
      return {...state, rules_sections: action.rules_sections};

    case actionTypes.RULES_SET_RULES:
      return {...state, rules: action.rules};

    case actionTypes.RULES_SHOW_FORM:
      return {...state, showModal: true};

    case actionTypes.RULES_HIDE_FORM:
      return {...state, showModal: false};

    case actionTypes.RULES_SHOW_DOCUMENT_FORM:
      return {...state, showDocumentModal: true};

    case actionTypes.RULES_HIDE_DOCUMENT_FORM:
      return {...state, showDocumentModal: false};

    case actionTypes.RULES_SET_INITIAL:
      const emptyRuleSection = {
        editMode: false,
        rules_section: null,
        status: 'public',
        title: '',
      };
      return {...state, ...emptyRuleSection};

    case actionTypes.RULES_SET_INITIAL_RULE:
      const emptyRule = {
        editDocumentMode: false,
        rule_title: '',
        is_new: false,
        file: null,
      };
      return {...state, ...emptyRule};

    case actionTypes.RULES_LOADING:
      return {...state, loading: action.loading};

    case actionTypes.SHOW_MESSAGE:
      return {...state, message: action.message};

    case actionTypes.RULES_SET_FILE:
      return {...state, loading: false, file: action.file};

    case actionTypes.RULES_CHANGE_INPUT:
      return {...state, [action.name]: action.value};

    case actionTypes.RULES_SET_ERRORS:
      return {...state, errors: action.errors};

    case actionTypes.RULES_SET_RULES_SECTION:
      const item = {...action.rules_section};
      return {...state, ...item, rules_section: action.rules_section, editMode: true};

    case actionTypes.RULES_SET_RULE:
      const data = {
        rule_title: action.rule.title,
        is_new: action.rule.is_new,
        file: action.rule.file,
      };
      return {...state, ...data, rule: action.rule, editDocumentMode: true};

    default:
      return state;
  }
}
