
const sortByAlphabet = (a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});

const prepareOption = (value, label, children) => {return {value, label, children}};

const prepareToMap = (acc, curr) => {
  const option = prepareOption(curr.id, curr.short_name);
  if (!acc[curr.region_id]) {
    acc[curr.region_id] = [].concat(option)
  } else {
    acc[curr.region_id] = acc[curr.region_id].concat(option);
  }
  return acc
};

export const prepareFilterOptions = (regions, clubs) => {
  const regionClubMap = clubs.reduce(prepareToMap, {});
  return regions
    .sort(sortByAlphabet)
    .map((r) => {return prepareOption(r.id, r.name, regionClubMap[r.id])})
};
