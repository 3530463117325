import React from "react";
import {Button, Form} from "antd";
import moment from "moment";
import IFormItem from "../../../../components/IFormItem/index";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {Editor} from "@tinymce/tinymce-react";
import DocumentForm from "../DocumentForm/index";

import "./TermsForm.css";


moment.locale('ua');

export default class TermsForm extends React.Component {

  render() {
    const ed = {
      image_dimensions: false,
      relative_urls: false,
      height: "200",
      plugins: 'code, print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount imagetools  contextmenu colorpicker textpattern help',
      toolbar1: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | image code',
    };

    return (
      <Form>
        <IFormItem
          label="Опис"
          errorKey="description"
          errors={this.props.errors}
        >
          <Editor
            apiKey='re6jcnkm2tvblni6kqytmvg6ucn24hgwjja9dzrhh7jzjqx2'
            { ...{init:ed}}
            onEditorChange={(v) => this.props.changeInput('description', v)}
            value={this.props.description}/>
        </IFormItem>
        <Button
          icon="plus"
          htmlType="button"
          onClick={this.props.onAddDocument}
        >
          Додати документ
        </Button>
        <DocumentForm
          file={this.props.file}
          errors={this.props.errors}
          showModal={this.props.showModal}
          onCancel={this.props.onCancel}
          onUploadFile={this.props.onUploadFile}
          onSubmit={this.props.onSubmitDocument}
          title={this.props.title}
          isNew={this.props.isNew}
          changeInput={this.props.changeInput}
        />
      </Form>
    );
  }
};
