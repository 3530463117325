import React from "react";
import PropTypes from "prop-types";
import {Select, Spin} from "antd";
import * as io from "../../../lib/io";
import "./RemoteSelect.css";

const { Option } = Select;

export default class RemoteSelect extends React.Component {

  state = {
    data: [],
    loading: false,
    showPlaceholder: true,
  };

  componentDidMount() {
    if (this.props.prefetch) {
      this.fetchData()
    }
  }

  getFetchUrl = (value) => {
    if (this.props.fetchUrlFunc) {
      return this.props.fetchUrlFunc(value)
    } else {
      if (value) {
        return `${this.props.fetchUrl}?query=${value}`
      } else {
        return this.props.fetchUrl
      }
    }
  };

  renderTitle = (r) => {
    if (this.props.renderTitle) {
      return this.props.renderTitle(r)
    } else {
      const titleByKeys = (r) => this.props.titleKeys && this.props.titleKeys.length > 0 ? this.props.titleKeys.map(k => r[k]).reverse().join(" ") : "---"
      return r[this.props.titleKey] ? r[this.props.titleKey] : titleByKeys(r)
    }
  }

  fetchData = () => {
    this.setState({
      loading: true,
    });
    io.get(this.getFetchUrl()).then((res) => {
      const data = res.data[this.props.dataKey].map(r => ({
        text: this.renderTitle(r),
        value: r[this.props.valueKey],
      }));
      const dataMap = res.data[this.props.dataKey].reduce((acc, curr) => {
        acc[curr[this.props.valueKey]] = curr;
        return acc
      }, {});
      this.setState({
        loading: false,
        data: data,
        dataMap: dataMap
      })
    })
  };

  handleChange = value => {
    if (this.props.passItem) {
      this.props.onChange(value ? this.state.dataMap[value] : undefined)
    } else {
      this.props.onChange(value ? value : undefined)
    }
  };

  onSearch = (value) => {
    this.setState({
      loading: true,
      showPlaceholder: !value
    });
    io.get(this.getFetchUrl(value)).then((res) => {
      const data = res.data[this.props.dataKey].map(r => ({
        text: this.renderTitle(r),
        value: r[this.props.valueKey],
      }));
      const dataMap = res.data[this.props.dataKey].reduce((acc, curr) => {
        acc[curr[this.props.valueKey]] = curr;
        return acc
      }, {});
      this.setState({
        loading: false,
        data: data,
        dataMap: dataMap
      })
    })
  };

  render() {
    const { loading, data } = this.state;
    return (
      <Select
        {...this.props}
        value={this.props.value && String(this.props.value)}
        showSearch={this.props.showSearch}
        showArrow={this.props.showArrow}
        placeholder={(!this.props.value && this.state.showPlaceholder)  ? this.props.placeholder : ''}
        notFoundContent={loading ? <Spin size="small" /> : null}
        filterOption={false}
        onDropdownVisibleChange={this.fetchData}
        onChange={this.handleChange}
        onSearch={this.onSearch}
        style={{ width: '100%' }}
      >
        {data.map(d => (
          <Option key={d.value}>{d.text}</Option>
        ))}
      </Select>
    );
  }
}

RemoteSelect.defaultProps = {
  placeholder: 'Виберіть філтьр',
  prefetch: false,
  showSearch: false,
  showArrow: true,
};

RemoteSelect.propTypes = {
  prefetch: PropTypes.bool,
  showArrow: PropTypes.bool,
  showSearch: PropTypes.bool,
  fetchUrl: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  titleKey: PropTypes.string,
  valueKey: PropTypes.string.isRequired,
  titleKeys: PropTypes.array,
  placeholder: PropTypes.string,
  fetchUrlFunc: PropTypes.func,
};
