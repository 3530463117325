import React from "react";
import {Divider, Spin, Table} from "antd";
import {formatDate, getStatusTitle} from "../../utils";
import AdminFilters from "../AdminFilters/index";
import AdminActionDropdown from "../../components/AdminActionDropdown";
import IModalForm from "../../components/IModalForm";
import UserForm from "./UserForm";

export default class UserList extends React.Component {


  render() {
    const columns = [
      {
        title: 'Імʼя',
        dataIndex: 'first_name',
        key: 'first_name',
      },
      {
        title: 'Призвище',
        dataIndex: 'last_name',
        key: 'last_name',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Дата створення',
        dataIndex: 'date_created',
        key: 'date_created',
        render: (t, r) => formatDate(t),
      },
      {
        title: 'Дата оновлення',
        dataIndex: 'date_updated',
        key: 'date_updated',
        render: (t, r) => formatDate(t),
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (t, r) => getStatusTitle(r.status)
      },
      {
        title: 'Дії',
        dataIndex: 'action',
        key: 'action',
        render: (text, item) => (
          <AdminActionDropdown
            {...this.props}
            labelKey={"email"}
            item={item}
          />
        ),
      }
    ];
    return (
      <div>
        <h2>Список користувачів</h2>
        <Divider/>
        <AdminFilters {...this.props} />
        <Table
          columns={columns}
          dataSource={this.props.users}
          rowKey="id"
          loading={this.props.loading}
          locale={{emptyText: "Немає користувачів"}}
        />
        <IModalForm
          title={this.props.editMode ? "Редагувати користувача" : "Додати користувача"}
          showModal={this.props.showModal}
          onCancel={this.props.onCancel}
          onSubmit={this.props.onSubmit}
        >
          <Spin spinning={this.props.loading} size="large">
            <UserForm
              {...this.props}
              {...this.props.user}
            />
          </Spin>
        </IModalForm>
      </div>
    );
  }
}
