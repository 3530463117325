import React from "react";
import {Button, Cascader, Col, DatePicker, Divider, Input, Popover, Row, Switch, Tree} from "antd";
import {Link} from "react-router-dom";
import AdminActionDropdown from "../AdminActionDropdown";
import AdminBaseLayoutContainer from "../BaseLayout/AdminBaseLayoutContainer";


const TreeNode = Tree.TreeNode;
const { Search } = Input;
const { RangePicker } = DatePicker;

const ColProps = {
  xs: 24,
  sm: 12,
  style: {
    marginBottom: 16,
  },
}

const TwoColProps = {
  ...ColProps,
  xl: 96,
}

const options = [{
  value: 'test',
  label: 'test',
  children: [{
    value: 'test',
    label: 'test',
    children: [{
      value: 'test',
      label: 'test',
    }],
  }],
},];

export default class AdminFederationStructure extends React.Component {

  componentDidMount() {
    this.props.onInit()
  }



  renderTreeNodes = (data) => {
    return data.map((item) => {
      if (item.children) {
        return (
          <TreeNode
            key={item.id}
            title={
              <Popover
                placement="right"
                trigger="hover"
                title={
                  <p>
                    <b>{item.title} </b>
                    <AdminActionDropdown
                      {...this.props}
                      item={item}
                    />
                  </p>
                }
                content={
                  <div>

                    {item.members.map((m) =>
                     <p key={m.id}>- {m.full_name}</p>
                    )}
                  </div>
                }
              >
                <p>{item.title}</p>
              </Popover>

            }>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.id} {...item}/>;
    });
  }

  render() {
    return (
      <AdminBaseLayoutContainer>
        <h2>Структура федерації</h2>
        <Divider/>
        <Row gutter={24}>
          <Col {...ColProps} xl={{ span: 4 }} md={{ span: 8 }}>
            <Search placeholder="Search Name" onSearch={(e) => null} />
          </Col>
          <Col {...ColProps} xl={{ span: 4 }} md={{ span: 8 }} id="addressCascader">
            <Cascader
              style={{ width: '100%' }}
              options={options}
              placeholder="Please pick an address"
            />
          </Col>
          <Col {...ColProps} xl={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} id="createTimeRangePicker">
            <RangePicker
              style={{ width: '100%' }}
            />
          </Col>
          <Col {...TwoColProps} xl={{ span: 10 }} md={{ span: 24 }} sm={{ span: 24 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <div>
                <Button type="primary" className="margin-right">Search</Button>
                <Button>Reset</Button>
              </div>
              <div className="flex-vertical-center">
                <Switch
                  className="ant-switch-large"
                  style={{ marginRight: 16 }}
                  defaultChecked={false}
                  checkedChildren="Motion"
                  unCheckedChildren="Motion"
                />
                <Link to="/admin/structure-create" replace>
                  <Button type="ghost" onClick={this.props.onCreate}>Додати</Button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Tree
          showLine
          defaultExpandAll
          defaultSelectedKeys={this.props.structuresTree.map(n => String(n.id))}
          defaultExpandParent
        >
          {this.renderTreeNodes(this.props.structuresTree)}
        </Tree>
      </AdminBaseLayoutContainer>
    );
  }
}
