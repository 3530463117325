import React from "react";
import moment from "moment";
import {Button, Col, Divider, Form, Input, Row, Spin, Table} from "antd";
import IFormItem from "../../../../components/IFormItem";
import IPhotoUploader from "../../../../components/IPhotoUploader";
import IModalForm from "../../../../components/IModalForm";
import CountributionForm from "../ContributionForm";
import AdminActionDropdown from "../../../../components/AdminActionDropdown";
import MyDatePicker from "../../../components/MyDatePicker/MyDatePicker";
import SelectStatus from "../../../components/SelectStatus/SelectStatus";


export const DEFAULT_STATUS = [
  {value: 'ACTIVE', label: 'ACTIVE'},
  {value: 'ARCHIVE', label: 'ARCHIVE'},
  {value: 'BLOCKED', label: 'BLOCKED'},
];


const ChangeStatus = ({status, entryProtocolNum, entryDate, blockProtocolNum, blockDate, onChange, errors}) => {

  return (
    <React.Fragment>
      <SelectStatus
        value={status}
        errors={errors}
        options={DEFAULT_STATUS}
        onChange={(name, value) => onChange({
          target: {name, value}
        })}
      />
      {("ACTIVE" === status) &&
      <div>
        <IFormItem
          label="Номер протоколу вступу"
          errorKey="entry_protocol_num"
          errors={errors}
        >
          <Input
            name="entry_protocol_num"
            placeholder="Введіть номер протоколу вступу"
            value={entryProtocolNum}
            onChange={onChange}
          />
        </IFormItem>
        <IFormItem
          label={'Дата вступу'}
          errorKey="entry_date"
          errors={errors}
        >
          <MyDatePicker
            value={entryDate ? moment(entryDate): null}
            onChange={(dt) => onChange(
              {target: {name: "entry_date", value: dt}}
            )}
          />
        </IFormItem>
      </div>
      }
      {("BLOCKED" === status) &&
      <div>
        <IFormItem
          label="Номер протоколу"
          errorKey="block_protocol_num"
          errors={errors}
        >
          <Input
            name="block_protocol_num"
            placeholder="Введіть номер протоколу"
            value={blockProtocolNum}
            onChange={onChange}
          />
        </IFormItem>
        <IFormItem
          label={'Дата блокування'}
          errorKey="block_date"
          errors={errors}
        >
          <MyDatePicker
            value={blockDate ? moment(blockDate) : null}
            onChange={(dt) => onChange(
              {target: {name: "block_date", value: dt}}
            )}
          />
        </IFormItem>
      </div>

      }
    </React.Fragment>
  );
};

const getInitialState = (
  {
    status,
    entry_date,
    entry_protocol_num,
    block_protocol_num,
    block_date,
  }) => {
  return {
    status: status,
    entry_date: entry_date,
    entry_protocol_num: entry_protocol_num,
    block_protocol_num: block_protocol_num,
    block_date: block_date,
  }
};

export default function AgencyForm(props) {

  const [state, setState] = React.useState(getInitialState(props));

  const handleOnChange = (e) => {
    setState({...state, [e.target.name]: e.target.value})
  };

  const handleOnSubmit = () => {
    props.onSubmit(state)
  };

  const columns = [
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Рік',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Внесок',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Дії',
      dataIndex: 'action',
      key: 'action',
      render: (text, item) => (
        <AdminActionDropdown
          {...props}
          labelKey="date"
          onDelete={props.onRemoveContribution}
          onEdit={props.onEditContribution}
          item={item}
        />
      ),
    }
  ];

  return (
    <IModalForm
      title={props.editMode ? "Редагувати клуб" : "Додати клуб"}
      showModal={props.showModal}
      onCancel={props.onCancel}
      onSubmit={handleOnSubmit}
    >
      <Spin spinning={props.loading} size="large">
        <Form>
          <Row gutter={24}>
            <Col span={8}>
              <IFormItem
                label="Повна назва"
                errorKey="name"
                errors={props.errors}
              >
                <Input
                  name="name"
                  value={props.name}
                  onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
                />
              </IFormItem>
              <IFormItem
                label="Скорочена назва"
                errorKey="short_name"
                errors={props.errors}
              >
                <Input
                  name="short_name"
                  value={props.short_name}
                  onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
                />
              </IFormItem>
              {/*TODO add region*/}
              <IFormItem
                label="Керівник"
                errorKey="owner_name"
                errors={props.errors}
              >
                <Input
                  name="owner_name"
                  value={props.owner_name}
                  onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
                />
              </IFormItem>
            </Col>
            <Col span={8}>
              <IFormItem
                label="Email"
                errorKey="email"
                errors={props.errors}
              >
                <Input
                  name="email"
                  value={props.email}
                  onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
                />
              </IFormItem>
              <IFormItem
                label="Телефон"
                errorKey="phone_number"
                errors={props.errors}
              >
                <Input
                  name="phone_number"
                  value={props.phone_number}
                  onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
                />
              </IFormItem>
              <IFormItem
                label="Адреса"
                errorKey="address"
                errors={props.errors}
              >
                <Input
                  name="address"
                  value={props.address}
                  onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
                />
              </IFormItem>
              <IFormItem
                label="Сайт"
                errorKey="website"
                errors={props.errors}
              >
                <Input
                  name="website"
                  value={props.website}
                  onChange={(e) => props.onChangeInput(e.target.name, e.target.value)}
                />
              </IFormItem>
            </Col>
            <Col span={8}>
              <IFormItem
                label="Лого"
                errorKey="file_id"
                errors={props.errors}
              >
                <IPhotoUploader
                  name="file"
                  loading={props.loading}
                  file={props.file}
                  onUpload={props.onUpload}
                />
              </IFormItem>
              <ChangeStatus
                status={state.status}
                blockDate={state.block_date}
                entryDate={state.entry_date}
                entryProtocolNum={state.entry_protocol_num}
                blockProtocolNum={state.block_protocol_num}
                errors={props.errors}
                onChange={handleOnChange}
              />
            </Col>
          </Row>
          <Divider>Внески</Divider>
          <Table
            columns={columns}
            dataSource={props.contributions}
            rowKey="id"
            loading={props.loading}
            locale={{emptyText: "Немає данних"}}
          />
          <Button
            type="primary"
            icon="plus"
            onClick={props.onClickContributions}
          >
            Додати внесок
          </Button>
          <IModalForm
            title={"Додати внесок"}
            showModal={props.showSubModal}
            onCancel={props.onCancelContribution}
            onSubmit={props.onSubmitContribution}
          >
            <Spin spinning={props.loading} size="large">
              <CountributionForm
                amount={props.amount}
                date={props.date}
                year={props.year}
                onChangeInput={props.onChangeInput}
                errors={props.errors}
              />
            </Spin>
          </IModalForm>
        </Form>
      </Spin>
    </IModalForm>
  );
}
