import AdminLogin from './AdminLogin';
import {connect} from "react-redux";
import * as auth from "./AdminLoginActions";

const mapStateToProps = state => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map(field => {
      return {field, message: state.auth.errors[field]};
    });
  }
  return {
    count: state.counter,
    errors,
    isAuthenticated: state.auth.isAuthenticated
  };
}

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password, history) => {
      return dispatch(auth.login(username, password, history));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
