import React from "react";
import FilterContext from "./contexs";

const defaultPaginator = {
  page: 1,
  total: 0,
  page_size: 50,
} ;


const FilterResults = (props) => {

  return (
    <FilterContext.Consumer>
      {({data}) => props.children({
        data: data ? data[props.dataKey] : [],
        paginator: data ? data.paginator : defaultPaginator,
        res: data,
      })}
    </FilterContext.Consumer>
  )

};

export default FilterResults