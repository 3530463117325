import React from "react";
import {Divider, Spin, Table} from "antd";

import AdminFilters from "../../components/AdminFilters";
import IModalForm from "../../components/IModalForm";
import AdditionForm from "./Form";
import AdminActionDropdown from "../../components/AdminActionDropdown";
import {formatDate, getStatusTitle} from "../../utils";


export default class RulessList extends React.Component {

  render() {
    const columns = [

      {
        title: 'Назва розділу',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Дата створення',
        dataIndex: 'date_created',
        key: 'date_created',
        render: (t, r) => formatDate(t),
      },
      {
        title: 'Дата оновлення',
        dataIndex: 'date_updated',
        key: 'date_updated',
        render: (t, r) => formatDate(t),
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (t, r) => getStatusTitle(r.status)
      },
      {
        title: 'Дії',
        dataIndex: 'action',
        key: 'action',
        render: (text, item) => (
          <AdminActionDropdown
            {...this.props}
            item={item}
          />
        ),
      }
    ];
    return (
      <div>
        <h2>Список привил</h2>
        <Divider/>
        <AdminFilters {...this.props} />
        <Table
          columns={columns}
          dataSource={this.props.rules_sections}
          rowKey="id"
          loading={this.props.loading}
          locale={{emptyText: "Немає првил"}}
        />
        <IModalForm
          title={this.props.editMode ? "Редагувати категорію правил" : "Додати категорію правил"}
          showModal={this.props.showModal}
          onCancel={this.props.onCancel}
          onSubmit={this.props.onSubmit}
        >
          <Spin spinning={this.props.loading} size="large">
            <AdditionForm
              {...this.props}
            />
          </Spin>
        </IModalForm>
      </div>
    );
  }
}